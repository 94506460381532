import { required, minLength } from '@vuelidate/validators'
import axios from 'axios'
import Toastify from 'toastify-js'
import { reactive, ref, toRefs } from 'vue'
import { useVuelidate } from '@vuelidate/core'

const api = {
  login: `${process.env.VUE_APP_MICROSERVICE_API_SECURITY}/login`
}

export const rules = {
  username: {
    required
  },
  password: {
    required,
    minLength: minLength(6)
  }
}

export function auth ({ router, store }) {
  const formData = reactive({
    username: '',
    password: ''
  })

  const validate = useVuelidate(rules, toRefs(formData))

  const user = ref()

  const login = () => {
    validate.value.$touch()
    if (validate.value.$invalid) {
      Toastify({
        text: 'This is a toast',
        backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
        className: 'info'
      }).showToast()
      return
    }
    axios.post(api.login, formData).then(({ data }) => {
      store.dispatch('auth/setUser', data)
      const route = { name: 'dashboard' }
      const { routeName } = data.group
      if (routeName) {
        route.name = routeName
      }
      router.push(route)
    }).catch(response => {
      console.error(response)
    })
  }

  return { validate, formData, user, login }
}

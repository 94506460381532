<template>
  <div class="card">
    <TabMenu :model="items" />
    <router-view />
  </div>
</template>
<script>
import { ref } from 'vue'
  export default {
    name: 'minorBoxSetting',
    setup () {
      const items = ref([
        {
          label: 'Crear caja',
          icon: 'pi pi-fw pi-plus',
          to: '/admin/caja-menor/configuracion/creacion-caja-menor'
        },
        {
          label: 'Listado de cajas',
          icon: 'pi pi-fw pi-list',
          to: '/admin/caja-menor/configuracion/listado'
        }
      ])

      return { items }
    }
}
</script>

<style lang="scss" scoped>
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: #1c3faa;
  color: #1c3faa;
}
</style>

import http from '@/libs/http'
export default class LicIndicadoresValores {
  get (params = {}) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-indicadores`, {
      params,
      headers: { loading: true }
    })
  }

  store (data) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-indicadores`, data, {
      headers: { loading: true }
    })
  }
}

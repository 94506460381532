import http from '../../../../../libs/http'

const api = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}`

const costFilters = (query) => http.get(`${api}/gerencia/cost-filters`, { params: { ...query } })
const apiListCost = (query) => http.get(`${api}/gerencia/cost-list`, { params: { ...query } })
const apiListCostGastos = (query) => http.get(`${api}/gerencia/cost-list-gastos`, { params: { ...query } })
const apiListCostZonificacion = (query) => http.get(`${api}/gerencia/cost-list-zonificacion`, { params: { ...query } })
const apiListCostBodega = (query) => http.get(`${api}/gerencia/cost-list-bodega`, { params: { ...query } })

export {
  costFilters,
  apiListCost,
  apiListCostGastos,
  apiListCostZonificacion,
  apiListCostBodega
}

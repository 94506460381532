import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'

import Index from './views'
const Incomes = () => import('./components/homeTabs/tabIngresos/main')
const Expenses = () => import('./components/homeTabs/tabEgresos/main')
const IncomeCreate = () => import('./views/incomes/create.vue')
const ExpensesCreate = () => import('./views/expenses/create.vue')
const ExpenseLegalize = () => import('./views/expenses/legalizeExpense')
const ExpenseLegalizeAdd = () => import('./views/expenses/legalizeExpenseAdd')

export default [
  {
    path: '/pharmasan/contabilidad',
    component: Layouts,
    name: 'pharmasan.contabilidad.caja-menor',
    redirect: { name: 'pharmasan.contabilidad.caja-menor.ver-caja-menor' },
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: 'caja-menor',
        component: Index,
        name: 'pharmasan.contabilidad.caja-menor.ver-caja-menor',
        redirect: { name: 'pharmasan.contabilidad.caja-menor.ver-caja-menor.ingresos' },
        children: [
          {
            path: 'ingresos',
            component: Incomes,
            name: 'pharmasan.contabilidad.caja-menor.ver-caja-menor.ingresos',
            meta: {
              access: ['pharmasan.contabilidad.caja-menor.ver-caja-menor'],
              activeMenu: 'pharmasan.contabilidad.caja-menor.ver-caja-menor'
            }
          },
          {
            path: 'egresos',
            component: Expenses,
            name: 'pharmasan.contabilidad.caja-menor.ver-caja-menor.egresos',
            meta: {
              access: ['pharmasan.contabilidad.caja-menor.ver-caja-menor'],
              activeMenu: 'pharmasan.contabilidad.caja-menor.ver-caja-menor'
            }
          }
        ]
      },
      {
        path: 'ingresos/crear',
        component: IncomeCreate,
        name: 'pharmasan.contabilidad.caja-menor.ver-caja-menor.ingresos.crear',
        meta: {
          access: ['pharmasan.contabilidad.caja-menor.ver-caja-menor.access'],
          activeMenu: 'pharmasan.contabilidad.caja-menor.ver-caja-menor'
        }
      },
      {
        path: 'ingresos/editar/:id',
        component: IncomeCreate,
        name: 'pharmasan.contabilidad.caja-menor.ver-caja-menor.ingresos.editar',
        meta: {
          access: ['pharmasan.contabilidad.caja-menor.ver-caja-menor.access'],
          activeMenu: 'pharmasan.contabilidad.caja-menor.ver-caja-menor'
        }
      },
      {
        path: 'egresos/crear',
        component: ExpensesCreate,
        name: 'pharmasan.contabilidad.caja-menor.ver-caja-menor.egresos.crear',
        meta: {
          access: ['pharmasan.contabilidad.caja-menor.ver-caja-menor.access'],
          activeMenu: 'pharmasan.contabilidad.caja-menor.ver-caja-menor'
        }
      },
      {
        path: 'egresos/legalizar/:id',
        component: ExpenseLegalize,
        name: 'pharmasan.contabilidad.caja-menor.ver-caja-menor.egresos.legalizar',
        meta: {
          access: ['pharmasan.contabilidad.caja-menor.ver-caja-menor.access'],
          activeMenu: 'pharmasan.contabilidad.caja-menor.ver-caja-menor'
        }
      },
      {
        path: 'egresos/legalizar/agregar',
        component: ExpenseLegalizeAdd,
        name: 'pharmasan.contabilidad.caja-menor.ver-caja-menor.egresos.legalizar.agregar',
        meta: {
          access: ['pharmasan.contabilidad.caja-menor.ver-caja-menor.access'],
          activeMenu: 'pharmasan.contabilidad.caja-menor.ver-caja-menor'
        }
      },
      {
        path: 'egresos/editar/:id',
        component: ExpensesCreate,
        name: 'pharmasan.contabilidad.caja-menor.ver-caja-menor.egresos.editar',
        meta: {
          access: ['pharmasan.contabilidad.caja-menor.ver-caja-menor.access'],
          activeMenu: 'pharmasan.contabilidad.caja-menor.ver-caja-menor'
        }
      },
      {
        path: 'informe-caja',
        component: () => import('./views/informeCaja/index.vue'),
        name: 'pharmasan.contabilidad.caja-menor.ver-caja-menor.informe-caja',
        meta: {
          access: ['pharmasan.contabilidad.caja-menor.ver-caja-menor.access'],
          activeMenu: 'pharmasan.contabilidad.caja-menor.ver-caja-menor'
        }
      },
      {
        path: 'arqueo-caja/:id',
        component: () => import('./views/arqueoCaja/index.vue'),
        name: 'pharmasan.contabilidad.caja-menor.ver-caja-menor.arqueo-caja',
        meta: {
          access: ['pharmasan.contabilidad.caja-menor.ver-caja-menor.access'],
          activeMenu: 'pharmasan.contabilidad.caja-menor.ver-caja-menor'
        }
      }
    ]
  }
]

import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
// import securityAccess from '@/middleware/securityAccess'
export default [
  {
    path: '/pharmasan/recursos-humanos',
    component: Layouts,
    name: 'pharmasan.recursos-humanos',
    redirect: { name: 'pharmasan.recursos-humanos.empleados' },
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: 'empleados',
        name: 'pharmasan.recursos-humanos.empleados',
        component: () => import('../views/empleados/index.vue'),
        meta: {
          access: [
            'pharmasan.recursos-humanos.empleados'
          ]
        }
      },
      {
        path: 'empleados/crear',
        name: 'pharmasan.recursos-humanos.empleados.crear',
        component: () => import('../views/empleados/pages/crear/index.vue'),
        meta: {
          access: [
            'pharmasan.recursos-humanos.empleados.access'
          ],
          activeMenu: 'pharmasan.recursos-humanos.empleados'
        }
      },
      {
        path: 'empleados/editar/:id?',
        name: 'pharmasan.recursos-humanos.empleados.editar',
        component: () => import('../views/empleados/pages/crear/index.vue'),
        activeMenu: 'pharmasan.recursos-humanos.empleados',
        meta: {
          access: [
            'pharmasan.recursos-humanos.empleados.access'
          ],
          activeMenu: 'pharmasan.recursos-humanos.empleados'
        }
      },
      {
        path: 'empleados/ver/:id?',
        name: 'pharmasan.recursos-humanos.empleados.ver',
        component: () => import('../views/empleados/pages/ver/index.vue'),
        activeMenu: 'pharmasan.recursos-humanos.empleados',
        meta: {
          access: [
            'pharmasan.recursos-humanos.empleados'
          ],
          activeMenu: 'pharmasan.recursos-humanos.empleados'
        }
      },
      {
        path: 'configuracion',
        name: 'pharmasan.recursos-humanos.configuracion',
        component: () => import('../views/configuracion/index.vue'),
        redirect: { name: 'pharmasan.recursos-humanos.configuracion.home' },
        meta: {
          access: [
            'pharmasan.recursos-humanos.configuracion'
          ]
        },
        children: [
          {
            path: 'home',
            name: 'pharmasan.recursos-humanos.configuracion.home',
            component: () => import('../views/configuracion/pages/home/index.vue'),
            meta: {
              access: [
                'pharmasan.recursos-humanos.configuracion'
              ],
              activeMenu: 'pharmasan.recursos-humanos.configuracion'
            }
          },
          {
            path: 'cargos',
            name: 'pharmasan.recursos-humanos.configuracion.cargos',
            component: () => import('../views/configuracion/pages/cargos/index.vue'),
            meta: {
              access: [
                'pharmasan.recursos-humanos.configuracion.cargos'
              ],
              activeMenu: 'pharmasan.recursos-humanos.configuracion'
            }
          },
          {
            path: 'cargos/editar-cargo/:id?',
            name: 'pharmasan.recursos-humanos.configuracion.cargos.editar-cargo',
            component: () => import('../views/configuracion/pages/cargos/editar/index.vue'),
            meta: {
              access: [
                'pharmasan.recursos-humanos.configuracion.cargos.access'
              ],
              activeMenu: 'pharmasan.recursos-humanos.configuracion'
            }
          },
          {
            path: 'cargos/crear-cargo',
            name: 'pharmasan.recursos-humanos.configuracion.cargos.crear-cargo',
            component: () => import('../views/configuracion/pages/cargos/editar/index.vue'),
            meta: {
              access: [
                'pharmasan.recursos-humanos.configuracion.cargos.access'
              ],
              activeMenu: 'pharmasan.recursos-humanos.configuracion'
            }
          },
          {
            path: 'areas',
            name: 'pharmasan.recursos-humanos.configuracion.areas',
            component: () => import('../views/configuracion/pages/areas/index.vue'),
            redirect: { name: 'pharmasan.recursos-humanos.configuracion.areas.listado' },
            meta: {
              access: [
                'pharmasan.recursos-humanos.configuracion.areas'
              ],
              activeMenu: 'pharmasan.recursos-humanos.configuracion'
            },
            children: [
              {
                path: '',
                name: 'pharmasan.recursos-humanos.configuracion.areas.listado',
                component: () => import('../views/configuracion/pages/areas/listar/index.vue'),
                meta: {
                  access: [
                    'pharmasan.recursos-humanos.configuracion.areas'
                  ],
                  activeMenu: 'pharmasan.recursos-humanos.configuracion'
                }
              },
              {
                path: 'crear-area',
                name: 'pharmasan.recursos-humanos.configuracion.areas.crear-area',
                component: () => import('../views/configuracion/pages/areas/crear/crear.vue'),
                meta: {
                  access: [
                    'pharmasan.recursos-humanos.configuracion.areas.access'
                  ],
                  activeMenu: 'pharmasan.recursos-humanos.configuracion'
                }
              },
              {
                path: 'editar-area/:id?',
                name: 'pharmasan.recursos-humanos.configuracion.areas.crear-area',
                component: () => import('../views/configuracion/pages/areas/crear/crear.vue'),
                meta: {
                  access: [
                    'pharmasan.recursos-humanos.configuracion.areas.access'
                  ],
                  activeMenu: 'pharmasan.recursos-humanos.configuracion'
                }
              }
            ]
          }
        ]
      }
    ]
  }
]

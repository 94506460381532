<template>
  <section class="intro-x">
    <div class="w-full mt-5 flex">
      <back-button />
      <router-link
      :to="{name: 'pharmasan.administrativa.compras-administrativas.ajustes.estados.form'}"
      class="btn btn-primary ml-2"
      >
        Agregar
      </router-link>
    </div>
    <div class="w-full my-5">
      <h1 class="font-bold text-xl">Estados de orden</h1>
    </div>
    <div
    class="w-full"
    >
      <DataTable :value="getStates">
        <Column field="id"  header="ID" headerStyle="width: 70px;" />
        <Column field="code" header="CODIGO" headerStyle="width: 170px;" />
        <Column field="name"  header="NOMBRE" />
        <Column field="state"  header="HABILITADO" >
          <template #body="{data}">
            <span>{{data.state?'Si':'No'}}</span>
          </template>
        </Column>
        <Column header="ACCIONES" headerStyle="width: 120px;">
          <template #body="{data}">
            <router-link
            :to="{name: 'pharmasan.administrativa.compras-administrativas.ajustes.estados.form', params: {id: data.id}}"
            class="btn btn-primary p-1"
            title="Editar"
            >
              <svg viewBox="0 0 24 24" style="width: 24px; height: 24px;"><path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"></path></svg>
            </router-link>
          </template>
        </Column>
      </DataTable>
    </div>
  </section>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import orderEstadoListar from '../../../../../services/orderEstadoListar'

export default {
  components: {
    DataTable,
    Column
  },
  setup () {
    const states = ref([])
    const getStates = computed(() => {
      return states.value
    })
    const fetchStates = () => {
      return orderEstadoListar().then(({ data }) => {
        states.value = data
      }).catch(err => {
        console.error(err.message)
      })
    }

    onMounted(() => {
      fetchStates()
    })

    return {
      getStates
    }
  }
}
</script>

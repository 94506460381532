import axios from 'axios'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_ENCARGA
const login = ({ user, pass }) => {
  return axios.get(`${baseUrl}/usuarios/login`, {
    headers: {
      user,
      pass,
      loading: true
    }
  })
}

const getServicios = (MyToken) => {
  return axios.get(`${baseUrl}/tablas-generales/tipo-servicios`, {
    headers: {
      MyToken,
      loading: true
    }
  })
}

const getEmpaques = (MyToken) => {
  return axios.get(`${baseUrl}/tablas-generales/empaques`, {
    headers: {
      MyToken,
      loading: true
    }
  })
}

const getCentrosCostos = (MyToken) => {
  return axios.get(`${baseUrl}/clientes/centros-costos`, {
    headers: {
      MyToken,
      loading: true
    }
  })
}

const getCiudades = (MyToken) => {
  return axios.get(`${baseUrl}/tablas-generales/ciudades`, {
    headers: {
      MyToken,
      loading: true
    }
  })
}

const getMensajeros = (MyToken) => {
  return axios.get(`${baseUrl}/clientes/mensajeros`, {
    headers: {
      MyToken,
      loading: true
    }
  })
}

const getClientesList = (MyToken) => {
  return axios.get(`${baseUrl}/clientes/misclientes`, {
    headers: {
      MyToken,
      loading: true
    }
  })
}

const crearGuia = (payload, MyToken) => {
  return axios.post(`${baseUrl}/guias/crear-guia`, payload, {
    headers: {
      MyToken,
      loading: true
    }
  })
}

export {
  login,
  getServicios,
  getEmpaques,
  getCentrosCostos,
  getCiudades,
  crearGuia,
  getMensajeros,
  getClientesList
}

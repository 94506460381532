import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'

export default [
  {
    path: '/capital-state/investments/',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: 'dashboard',
        name: 'InvestmentsIndex',
        component: () => import('./views/dashboard/index').then(m => m || m)
      }
    ]
  }
]

<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <MobileMenu />
    <div  class="flex">
      <!-- BEGIN: Side Menu -->
      <nav id="main-menu" class="side-nav relative">
        <!-- BEGIN: Logo -->
        <div class="grid pl-2 grid-flow-col auto-cols-max items-center">
          <img
          alt="Midone Tailwind HTML Admin Template"
          class="w-6 ml-3 cursor-pointer"
          src="@/assets/images/logo.svg"
          @click="desplegar"
          />
          <div>
            <router-link
            :to="{ name: 'home' }"
            tag="a"
            class="intro-x flex items-center pt-2 py-1"
            >
              <span class="hidden xl:block text-white text-lg ml-3 name-page">
                Pharmasan
              </span>
            </router-link>
          </div>
        </div>
        <!-- END: Logo -->
        <div class="side-nav__devider my-6"></div>
        <menuDisplay />
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div class="content">
        <TopBar />
        <router-view />
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, watch } from 'vue'
import { useStore } from '@/store'
// import { useRoute } from 'vue-router'
import TopBar from '@/components/top-bar/Main'
import MobileMenu from '@/components/mobile-menu/Main'
import menuDisplay from '../../components/menu-display'
import cash from 'cash-dom'

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    menuDisplay
  },
  setup () {
    const store = useStore()
    // const route = useRoute()
    const darkMode = computed(() => store.state.main.darkMode)
    const ov = computed(() => store.state.main.ov)
    watch(ov.value, (n, v) => {
      // console.log('this is n---->', n)
      // if (n) {
      //   var menu = document.getElementsByClassName('side-nav')
      //   var main = document.getElementsByClassName('main')
      //   var namePage = document.getElementsByClassName('name-page')
      //   var content = document.getElementsByClassName('content')
      //   menu[0].classList.toggle('hidden')
      //   main[0].classList.toggle('px-2')
      //   namePage[0].classList.toggle('hidden')
      //   content[0].classList.toggle('p-2')
      // }
    })
    onMounted(() => {
      cash('body')
        .removeClass('error-page')
        .removeClass('login')
        .addClass('main')
      darkMode.value ? cash('html').addClass('dark') : cash('html').removeClass('dark')
      // console.log('menu from main menu-->', route.name)
      // if (route.name === 'pharmasan.sap.ov.crear') {
      //   // var menu = document.getElementsByClassName('side-nav')
      //   // var main = document.getElementsByClassName('main')
      //   // var namePage = document.getElementsByClassName('name-page')
      //   // var content = document.getElementsByClassName('content')
      //   // menu[0].classList.toggle('hidden')
      //   // main[0].classList.toggle('px-2')
      //   // namePage[0].classList.toggle('hidden')
      //   // content[0].classList.toggle('p-2')
      // }
    })
    return { }
  },
  methods: {
    desplegar () {
      var menu = document.getElementsByClassName('side-nav')
      var namePage = document.getElementsByClassName('name-page')
      menu[0].classList.toggle('side-nav--simple')
      namePage[0].classList.toggle('invisible')
    }
  }
})
</script>

<style>
@media print {
  #main-menu{
    display: none;
  }
}
  #main-menu{
    transition-duration: 1s;
  }
  #main-menu:hover .content-menu{
    right: 0;
  }
.content-menu{
  position: absolute;
  top: 26px;
  right: -40px;
  transition-duration: 1s;
}

.side-menu__sub-icon i {
  font-size: 10px;
}
/* .main {
  padding-right: 10px;
  padding-left: 10px;
}
.side-nav {
  display: none;
} */
.swal2-container {z-index: 1000000 !important}
.content {
  min-height: 95vh !important;
}
</style>

import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '../../../../middleware/securityAccess'

export default [
  {
    path: '/pharmasan/reportes',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    redirect: { name: 'pharmasan.reportes.senspro.informes' },
    children: [
      {
        path: 'senspro',
        name: 'pharmasan.reportes.senspro',
        redirect: { name: 'pharmasan.reportes.senspro.informes' },
        beforeEnter: VueRouterMultiguard([auth, securityAccess]),
        children: [
          /* {
            path: 'dashboard',
            name: 'senspro.dashboard.access',
            component: () => import('@/apps/pharmasan/senspro/views/dashboard/realizados.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['senspro.dashboard.access']
            }
          }, */
          {
            path: 'informes',
            name: 'pharmasan.reportes.senspro.informes',
            component: () => import('@/apps/pharmasan/senspro/views/reports/index.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['pharmasan.reportes.senspro.informes'],
              activeMenu: 'pharmasan.reportes.senspro.informes'
            }
          }
        ]
      }
    ]
  }
]

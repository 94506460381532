<template>
  <section class="intro-x">
    <div class="w-full mt-3 mb-4 ">
      <div class="font-bold text-lg my-5">Unidades</div>
      <div class="w-full flex justify-between">
        <div class="flex">
          <back-button />
          <router-link
          :to="{name: 'pharmasan.administrativa.compras-administrativas.ajustes.unidades.formulario'}"
          class="btn btn-primary ml-2 "
          >
            Agregar
          </router-link>
        </div>
        <div>
          <Vistas-Tipo
          :route-list="{name: 'pharmasan.administrativa.compras-administrativas.ajustes.unidades.list'}"
          :route-grid="{name: 'pharmasan.administrativa.compras-administrativas.ajustes.unidades.grid'}"
          />
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 w-full mb-4">
      <div
      v-for="(row,k) in unidades"
      :key="k"
      class="w-full"
      >
        <div class="box p-4 w-full shadow-md">
          <div>
            <div class="font-bold">({{row.prefix}}) {{row.name}}</div>
            <div class="text-gray-600">Unidad de medida</div>
          </div>
          <div class="flex mt-2">
            <router-link
            :to="{ name: 'pharmasan.administrativa.compras-administrativas.ajustes.unidades.formulario' , params: { id: row.id} }"
            tag="button"
            class="bg-primary-1 rounded-md text-white px-3 py-1"
            title="Editar"
            >
              Editar
            </router-link>
            <!-- <button
            type="button"
            class="bg-red-600 rounded-md text-white px-3 py-1 ml-2"
            title="Eliminar"
            @click="handleRemove(row)"
            >
              Eliminar
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, ref } from 'vue'
import { confirm, error, success } from '../../../../../../../../libs/mensajes'
import deleteUnits from '../../../../../services/deleteUnits'
import getUnits from '../../../../../services/unitsGetAll'
import VistasTipo from '../../../../../../../../components/VistasTipo'
export default {
  components: { VistasTipo },
  setup () {
    const unidades = ref([])
    const handleRemove = ({ id }) => {
      confirm().then(({ isConfirmed }) => {
        if (isConfirmed) {
          deleteUnits({ id }).then(({ status }) => {
            if (status !== 200) return
            success('Unidad removida')
            fetchUnits()
          }).catch((err) => {
            console.error(err.message)
            error(err.response.data.message)
          })
        }
      })
    }
    const fetchUnits = () => {
      return getUnits().then(({ status, data }) => {
        unidades.value = data
      }).catch((err) => {
        console.error(err.response.data.message)
      })
    }
    onMounted(() => {
      fetchUnits()
    })
    return {
      unidades,
      handleRemove
    }
  }
}
</script>

<style lang="scss" scoped>
.caja{
  width: 100%;
}
</style>

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang'
import utils from './utils'
import './libs'
import globalComponents from './global-components'

// Midone Theme
import './assets/sass/app.scss'
import './assets/tailwind.css'

const pinia = createPinia()

const app = createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(pinia)

app.config.devtools = true

globalComponents(app)
utils(app)

app.mount('#app')

// import dayjs from 'dayjs'

const state = () => {
    return {
      storeSocios: [],
      storeEstadosFinancieros: []
    }
}

const mutations = {
  setSocios (state, payload) {
    state.storeSocios = payload
  },
  setEstadosFinancieros (state, payload) {
    state.storeEstadosFinancieros = payload
  }
}

const actions = {
  searchSocios ({ commit }, payload) {
    commit('setSocios', payload)
  },
  searchEstadosFinancieros ({ commit }, payload) {
    commit('setEstadosFinancieros', payload)
  }
}

const getters = {
  getStoreSocios: state => state.storeSocios,
  getStoreEstadosFinancieros: state => state.storeEstadosFinancieros
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

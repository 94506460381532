import dayjs from 'dayjs'
import http from '../../../../libs/http'

export default class ExpenseServices {
  urlPettyCash = process.env.VUE_APP_MICROSERVICE_API_PETTY_CASH

  async getExpenseByDate (date, query = {}) {
    try {
      return await http.get(this.urlPettyCash + '/expense/list-by-date/' + date, { params: { ...query } }).then(res => res.data)
    } catch (error) {
      console.error('Error getExpenseByDate Egreso', error)
      throw error
    }
  }

  async getExpenseByType (date, type) {
    try {
      return await http.get(this.urlPettyCash + '/expense/list-by-type/' + date + '/' + type).then(res => res.data)
    } catch (error) {
      console.error('Error getExpenseByType Egreso', error)
      throw error
    }
  }

  async getExpenseByStatus (date, status) {
    try {
      return await http.get(this.urlPettyCash + '/expense/list-by-status/' + date + '/' + status).then(res => res.data)
    } catch (error) {
      console.error('Error getExpenseByStatus Egreso', error)
      throw error
    }
  }

  async getExpenseByTypeAndStatus (date, type, status) {
    try {
      return await http.get(this.urlPettyCash + '/expense/list-by-type-status/' + date + '/' + type + '/' + status).then(res => res.data)
    } catch (error) {
      console.error('Error getExpenseByTypeAndStatus Egreso', error)
      throw error
    }
  }

  async createExpenseByCase (endpoint, data) {
    try {
      data.date = dayjs().locale('es').format('YYYY-MM-DD')
      return await http.post(this.urlPettyCash + endpoint, data).then(res => res.data)
    } catch (error) {
      console.error('Error createExpenseByCase', error)
      throw error
    }
  }

  async editExpenseByCase (endpoint, data) {
    try {
      data.userId = 1
      data.date = dayjs().locale('es').format('YYYY-MM-DD')
      return await http.put(this.urlPettyCash + endpoint, data).then(res => res.data)
    } catch (error) {
      console.error('Error editExpenseByCase', error)
      throw error
    }
  }

  async deleteExpenseById (endpoint) {
    try {
      return await http.delete(this.urlPettyCash + endpoint).then(res => res.data)
    } catch (error) {
      console.error('Error deleteExpenseById', error)
      throw error
    }
  }

  async expenseValidate () {
    return await http.get(this.urlPettyCash + '/balance-closing/validate').then(res => res.data)
  }
}

import http from '@/libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PETTY_CASH_NEWGASANDOIL

export default class ExpensesService {
  getExpenses (payload = {}) {
    return http.get(`${baseUrl}/expenses`, {
      params: {
        ...payload
      },
      headers: {
        loading: true
      }
    })
  }

  getTotalExpenses (payload = {}) {
    return http.get(`${baseUrl}/expenses/total-expenses`, {
      params: {
        ...payload
      },
      headers: {
        loading: true
      }
    })
  }

  getDifference (id) {
    return http.get(`${baseUrl}/expenses/get-difference/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  saveExpense (payload = {}) {
    return http.post(`${baseUrl}/expenses`, payload, {
      headers: {
        loading: true
      }
    })
  }

  saveExpenseFull (payload = {}) {
    return http.post(`${baseUrl}/expenses/full`, payload, {
      headers: {
        loading: true
      }
    })
  }

  deleteExpense (id) {
    return http.delete(`${baseUrl}/expenses/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}

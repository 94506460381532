import securityAccess from '../../../../../middleware/securityAccess'

export default [
  {
    path: 'ventas',
    name: 'pharmasan.informes.ventas',
    component: () => import('../views/home/index.vue'),
    beforeEnter: [securityAccess],
    meta: {
      access: [
        'pharmasan.informes.ventas'
      ],
      activeMenu: 'pharmasan.informes'
    }
  },
  {
    path: 'ventas/stock-mx',
    name: 'pharmasan.informes.ventas.ov-detalle',
    component: () => import('../views/ov/ovDetalle.vue'),
    meta: {
      access: [
        'pharmasan.informes.ventas.ov-detalle'
      ],
      activeMenu: 'pharmasan.informes'
    }
  }
]

import notFound from './views/404'
import notAutorize from './views/401'
import routerRedirect from '@/middleware/routerRedirect'
export default [
  {
    path: '/not-autorize',
    name: 'error.401',
    component: notAutorize,
    beforeEnter: [routerRedirect]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error.404',
    component: notFound
  }
]

import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import Home from './views/compras/home'
import HomeAdmin from './views/administrativo/home'

import AjustesCompras from './views/compras/ajustes/home'
import AjustesComprasSistema from './views/compras/ajustes/sistema'

import AjustesComprasUnidadesGrid from './views/compras/ajustes/unidades/grid'
import AjustesComprasUnidadesList from './views/compras/ajustes/unidades/list'

import AjustesComprasCategoriasList from './views/compras/ajustes/categorias/list'

import AjustesComprasEstadosList from './views/compras/ajustes/estados/list'
import AjustesComprasEstadosForm from './views/compras/ajustes/estados/form'

import AjustesComprasImpuestosList from './views/compras/ajustes/impuestos/list'
import AjustesComprasImpuestosForm from './views/compras/ajustes/impuestos/form'

import AjustesComprasCategoriasForm from './views/compras/ajustes/categorias/form'
import AjustesComprasUnidadesForm from './views/compras/ajustes/unidades/form'

import AjustesComprasPresentacionForm from './views/compras/ajustes/presentacion/form'

import HomeMinorBox from './views/minorBox/home'
import ConfiguracionMinorBox from './views/minorBox/configuracion'
import CreateMinorBox from './views/minorBox/configuracion/components/cajaMenor/create'
import MinorBoxList from './views/minorBox/configuracion/components/cajaMenor/list'
import securityAccess from '@/middleware/securityAccess'

export default [
  {
    path: '/pharmasan/administrativa',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    redirect: { name: 'pharmasan.administrativa.compras-administrativas' },
    children: [
      {
        path: 'compras-administrativas',
        component: Home,
        name: 'pharmasan.administrativa.compras-administrativas',
        redirect: { name: 'pharmasan.administrativa.compras-administrativas.ajustes' },
        children: [
          {
            path: 'ajustes',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes',
            component: AjustesCompras,
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.administrativa.compras-administrativas.ajustes'
              ],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'ajustes/sistema',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes.listas-sistema',
            component: AjustesComprasSistema,
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.ajustes.listas-sistema'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'ajustes/unidades/form/:id?',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes.unidades.formulario',
            component: AjustesComprasUnidadesForm,
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.ajustes.listas-sistema'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'ajustes/unidades/grid',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes.unidades.grid',
            component: AjustesComprasUnidadesGrid,
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.ajustes.listas-sistema'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'ajustes/unidades/list',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes.unidades.list',
            component: AjustesComprasUnidadesList,
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.ajustes.listas-sistema'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'ajustes/categorias/list/:id?',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes.list',
            component: AjustesComprasCategoriasList,
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.ajustes.listas-sistema'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'ajustes/categorias/form/:id?',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes.categorias.formulario',
            component: AjustesComprasCategoriasForm,
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.ajustes.listas-sistema'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'ajustes/categorias/subcategoria/form/:id?',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes.categorias.subcategorias.formulario',
            component: AjustesComprasCategoriasForm,
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.ajustes.listas-sistema'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'ajustes/impuestos/form/:id?',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes.impuestos.formulario',
            component: AjustesComprasImpuestosForm,
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.ajustes.listas-sistema'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'ajustes/impuestos/list',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes.impuestos.list',
            component: AjustesComprasImpuestosList,
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.ajustes.listas-sistema'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'ajustes/estados/list',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes.estados.list',
            component: AjustesComprasEstadosList,
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.ajustes.listas-sistema'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'ajustes/estados/form/:id?',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes.estados.form',
            component: AjustesComprasEstadosForm,
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.ajustes.listas-sistema'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'ajustes/productos/list',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes.productos',
            component: () => import('./views/compras/ajustes/productos/list').then(m => m.default || m),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.ajustes.productos'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'ajustes/facturacion',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes.facturacion',
            component: () => import('./views/compras/ajustes/facturacion').then(m => m.default || m),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.ajustes.facturacion'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'ajustes/productos/:id?',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes.productos.edit',
            component: () => import('./views/compras/inventario/ArticuloForm').then(m => m.default || m),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.ajustes.productos.access'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'ajustes/productos/carga-masiva',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes.productos.carga-masiva',
            component: () => import('./views/compras/ajustes/productos/cargaMasiva'),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.ajustes.productos.access'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'ajustes/general',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes.general',
            component: () => import('./views/compras/ajustes/general'),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.ajustes.general'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'inventario',
            name: 'pharmasan.administrativa.compras-administrativas.inventario',
            // component: InventarioCompras,
            component: () => import('./views/compras/inventario/home'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.inventario']
            }
          },
          {
            path: 'entradas',
            name: 'pharmasan.administrativa.compras-administrativas.inventario.entradas',
            component: () => import('./views/compras/inventario/entradas'),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.inventario.access'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.inventario'
            }
          },
          {
            path: 'salidas',
            name: 'pharmasan.administrativa.compras-administrativas.inventario.salidas',
            component: () => import('./views/compras/inventario/salidas'),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.inventario.access'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.inventario'
            }
          },
          {
            path: 'inventario/form/:id?',
            name: 'pharmasan.administrativa.compras-administrativas.inventario.form',
            // component: InventarioFormCompras
            component: () => import('./views/compras/inventario/ArticuloForm'),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.inventario.access'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.inventario'
            }
          },
          {
            path: 'inventario/carga-masiva',
            name: 'pharmasan.administrativa.compras-administrativas.inventario.carga-masiva',
            component: () => import('./views/compras/inventario/cargaMasiva'),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.inventario.access'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.inventario'
            }
          },
          {
            path: 'solicitudes/list',
            name: 'pharmasan.administrativa.compras-administrativas.solicitudes',
            component: () => import('./views/compras/solicitudes/list').then(m => m.default || m),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.solicitudes'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.solicitudes'
            }
          },
          {
            path: 'solicitudes/form/:id?',
            name: 'pharmasan.administrativa.compras-administrativas.solicitudes.formulario',
            component: () => import('./views/compras/solicitudes/form').then(m => m.default || m),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.solicitudes.creacion'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.solicitudes'
            }
          },
          {
            path: 'solicitudes/:id/detalle',
            name: 'pharmasan.administrativa.compras-administrativas.solicitudes.detail',
            component: () => import('./views/compras/solicitudes/detail').then(m => m.default || m),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.solicitudes'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.solicitudes'
            }
          },
          {
            path: 'solicitudes/:id/history',
            name: 'pharmasan.administrativa.compras-administrativas.solicitudes.historial',
            component: () => import('./views/compras/solicitudes/history').then(m => m.default || m),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.solicitudes'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.solicitudes'
            }
          },
          {
            path: 'solicitudes/carga-masiva',
            name: 'pharmasan.administrativa.compras-administrativas.solicitudes.carga-masiva',
            component: () => import('./views/compras/solicitudes/cargaMasiva').then(m => m.default || m),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.solicitudes.creacion'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.solicitudes'
            }
          },
          {
            path: 'pedidos/list',
            name: 'pharmasan.administrativa.compras-administrativas.pedidos',
            component: () => import('./views/compras/pedidos/list').then(m => m.default || m),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.pedidos'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.pedidos'
            }
          },
          {
            path: 'pedidos/:id/history',
            name: 'pharmasan.administrativa.compras-administrativas.pedidos.historial',
            component: () => import('./views/compras/pedidos/history').then(m => m.default || m),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.pedidos.access'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.pedidos'
            }
          },
          {
            path: 'pedidos/:id/detalle',
            name: 'pharmasan.administrativa.compras-administrativas.pedidos.detalle',
            component: () => import('./views/compras/pedidos/detail').then(m => m.default || m),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.pedidos.access'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.pedidos'
            }
          },
          {
            path: 'pedidos/form/:id?',
            name: 'pharmasan.administrativa.compras-administrativas.pedidos.formulario',
            component: () => import('./views/compras/pedidos/form').then(m => m.default || m),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.pedidos.access'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.pedidos'
            }
          },
          {
            path: 'ajustes/presentacion',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes.presentacion.list',
            component: () => import('./views/compras/ajustes/presentacion/list').then(m => m.default || m),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.ajustes'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'ajustes/presentacion/form/:id?',
            name: 'pharmasan.administrativa.compras-administrativas.ajustes.presentacion.formulario',
            component: AjustesComprasPresentacionForm,
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.ajustes'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.ajustes'
            }
          },
          {
            path: 'proveedores',
            name: 'pharmasan.administrativa.compras-administrativas.proveedores',
            component: () => import('./views/compras/proveedores/home').then(m => m.default || m),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.proveedores']
            }
          },
          {
            path: 'proveedores/carga-masiva',
            name: 'pharmasan.administrativa.compras-administrativas.proveedores.carga-masiva',
            component: () => import('./views/compras/proveedores/cargaMasiva').then(m => m.default || m),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.proveedores.access'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.proveedores'
            }
          },
          {
            path: 'consolidados',
            name: 'pharmasan.administrativa.compras-administrativas.consolidados',
            component: () => import('./views/compras/consolidados/home'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.consolidados'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.consolidados'
            }
          },
          {
            path: 'informes',
            name: 'pharmasan.administrativa.compras-administrativas.informes',
            component: () => import('./views/compras/informes'),
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.administrativa.compras-administrativas.informes'],
              activeMenu: 'pharmasan.administrativa.compras-administrativas.informes'
            }
          }
        ]
      },
      {
        path: 'administrativo',
        component: HomeAdmin,
        name: 'admin.administrativo',
        redirect: { name: 'admin.administrativo.facturacion' },
        children: [
          {
            path: 'facturacion',
            name: 'admin.administrativo.facturacion',
            component: () => import('./views/administrativo/facturacion2/home'),
            beforeEnter: [securityAccess],
            meta: {
              access: ['admin.administrativo.facturacion.access']
            }
          }
        ]
      },
      {
        path: 'caja-menor',
        component: HomeMinorBox,
        redirect: { name: 'admin.minor-box.settings.create-minor-bor' },
        children: [
          {
            path: 'configuracion',
            component: ConfiguracionMinorBox,
            children: [
              {
                path: 'creacion-caja-menor',
                component: CreateMinorBox,
                name: 'admin.minor-box.settings.create-minor-bor'
              },
              {
                path: 'listado',
                component: MinorBoxList,
                name: 'admin.minor-box.settings.list'
              }
            ]
          }
        ]
      }
    ]
  }
]

import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '@/middleware/securityAccess'
export default [
  {
    path: '/pharmasan/ventas/facturacion',
    name: 'pharmasan.ventas.facturacion',
    component: Layouts,
    redirect: { name: 'pharmasan.ventas.facturacion.facturacion-masiva' },
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      // {
      //   path: '',
      //   name: 'pharmasan.ventas.facturacion.facturacion-masiva',
      //   component: () => import('@/apps/pharmasan/facturacion/views/masiva/Menu.vue'),
      //   redirect: { name: 'pharmasan.ventas.facturacion.facturacion-masiva.evento' },
      //   children: [
      //     {
      //       path: '/facturacion-masiva/evento',
      //       name: 'pharmasan.ventas.facturacion.facturacion-masiva.evento',
      //       component: () => import('@/apps/pharmasan/facturacion/views/masiva/Evento.vue'),
      //       beforeEnter: VueRouterMultiguard([securityAccess]),
      //       meta: {
      //         access: [
      //           'pharmasan.ventas.facturacion.facturacion-masiva.evento'
      //         ]
      //       }
      //     },
      //     {
      //       path: '/facturacion-masiva/capitacion',
      //       name: 'pharmasan.ventas.facturacion.facturacion-masiva.capita',
      //       component: () => import('@/apps/pharmasan/facturacion/views/masiva/Capitacion.vue'),
      //       beforeEnter: VueRouterMultiguard([securityAccess]),
      //       meta: {
      //         access: [
      //           'pharmasan.ventas.facturacion.facturacion-masiva.capita'
      //         ]
      //       }
      //     },
      //     {
      //       path: '/facturacion-masiva/factura-con-valor',
      //       name: 'pharmasan.ventas.facturacion.facturacion-masiva.factura-valor',
      //       component: () => import('@/apps/pharmasan/facturacion/views/masiva/FacturaValor.vue'),
      //       beforeEnter: VueRouterMultiguard([securityAccess]),
      //       meta: {
      //         access: [
      //           'pharmasan.ventas.facturacion.facturacion-masiva.factura-valor'
      //         ]
      //       }
      //     }
      //   ]
      // },
      // {
      //   path: '/facturacion-masiva/historial',
      //   name: 'pharmasan.ventas.facturacion.historial',
      //   component: () => import('@/apps/pharmasan/facturacion/views/components/Historial.vue'),
      //   beforeEnter: VueRouterMultiguard([securityAccess]),
      //   meta: {
      //     access: [
      //       'pharmasan.ventas.facturacion.historial'
      //     ]
      //   }
      // },
      // {
      //   path: '/facturacion-masiva/historial-detalle-evento/:id',
      //   name: 'pharmasan.ventas.facturacion.historial-detalle',
      //   component: () => import('@/apps/pharmasan/facturacion/views/components/HistorialDetalle.vue'),
      //   beforeEnter: VueRouterMultiguard([securityAccess]),
      //   meta: {
      //     access: [
      //       'pharmasan.ventas.facturacion.historial-detalle'
      //     ]
      //   }
      // }

      {
        path: 'masiva',
        name: 'pharmasan.ventas.facturacion.facturacion-masiva',
        component: () => import('./views/home/index.vue'),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: [
            'pharmasan.ventas.facturacion.facturacion-masiva'
          ]
        },
        redirect: { name: 'pharmasan.ventas.facturacion.facturacion-masiva.evento' },
        children: [
          {
            path: 'evento',
            name: 'pharmasan.ventas.facturacion.facturacion-masiva.evento',
            component: () => import('./views/home/components/evento.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.ventas.facturacion.facturacion-masiva.evento'
              ],
              activeMenu: 'pharmasan.ventas.facturacion.facturacion-masiva'
            }
          },
          {
            path: 'capita',
            name: 'pharmasan.ventas.facturacion.facturacion-masiva.capita',
            component: () => import('./views/home/components/capita.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.ventas.facturacion.facturacion-masiva.capita'
              ],
              activeMenu: 'pharmasan.ventas.facturacion.facturacion-masiva'
            }
          },
          {
            path: 'valor',
            name: 'pharmasan.ventas.facturacion.facturacion-masiva.factura-valor',
            component: () => import('./views/home/components/valor.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.ventas.facturacion.facturacion-masiva.factura-valor'
              ],
              activeMenu: 'pharmasan.ventas.facturacion.facturacion-masiva'
            }
          }
        ]
      },
      {
        path: 'historial',
        name: 'pharmasan.ventas.facturacion.facturacion-masiva.historial',
        component: () => import('./views/historial/index.vue'),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: [
            'pharmasan.ventas.facturacion'
          ],
          activeMenu: 'pharmasan.ventas.facturacion.facturacion-masiva'
        }
      },
      {
        path: 'historial-detalle/:id',
        name: 'pharmasan.ventas.facturacion.facturacion-masiva.historial-detalle',
        component: () => import('./views/historial/components/historialDetalle.vue'),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: [
            'pharmasan.ventas.facturacion.facturacion-masiva'
          ],
          activeMenu: 'pharmasan.ventas.facturacion.facturacion-masiva'
        }
      }
    ]
  }
]

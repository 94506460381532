<template>
  <section class="intro-x my-5">
    <div class="w-full my-5">
      <div class="flex">
        <back-button />
      </div>
    </div>

    <div class="w-1/2 box p-4">
      <div class="w-full">
        <h1 class="font-bold mt-2 mb-4 text-xl">Formulario de impuestos</h1>
      </div>
      <form @submit="onSubmit">
        <!--Codigo impuesto -->
        <div class="w-full mb-2">
          <div class="">
            <label for="taxCode">Codigo</label>
          </div>
          <div>
            <input
            id="taxCode"
            v-model="model.code"
            type="text"
            class="form-control"
            maxlength="20"
            >
            <div class="text-red-400">
              {{errors.code}}
            </div>
          </div>
        </div>
        <!-- impuesto nombre -->
        <div class="w-full mb-2">
          <div class="">
            <label for="tax">Impuesto</label>
          </div>
          <div>
            <input
            id="tax"
            v-model="model.name"
            type="text"
            class="form-control"
            >
            <div class="text-red-400">
              {{errors.name}}
            </div>
          </div>
        </div>
        <!-- impuesto valor -->
        <div class="w-full mb-2">
          <div class="">
            <label for="taxValue">Valor</label>
          </div>
          <div>
            <input
            id="taxValue"
            v-model="model.value"
            type="text"
            class="form-control"
            >
            <div class="text-red-400">
              {{errors.value}}
            </div>
          </div>
        </div>
        <!-- impuesto estado -->
        <div class="form-check my-5">
          <input
          v-model="model.state"
          id="show-example-5"
          data-target="#select-options"
          class="show-code form-check-switch"
          type="checkbox"
          />
          <label
          class="form-check-label ml-0 sm:ml-2"
          for="show-example-5"
          >
            Estado
          </label >
        </div>
        <div class="w-full mt-4">
          <button type="submit" class="btn btn-primary">
            Guardar
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { useField, useForm } from 'vee-validate'
import { object, string, number, boolean } from 'yup'
import impuestosCrear from '../../../../../services/impuestosCrear'
import impuestosActualizar from '../../../../../services/impuestosActualizar'
import impuestosBuscar from '../../../../../services/impuestosBuscar'
import { messageConfirm, messageSuccess } from '../../../../../../../../libs/mensajes'
import { useRouter, useRoute } from 'vue-router'
import { computed, onMounted } from 'vue-demi'
export default {
  setup () {
    const router = useRouter()
    const route = useRoute()
    const isEdit = computed(() => {
      if (route.params.id) return true
      return false
    })
    const validationSchema = object().shape({
      code: string().max(20).required(),
      name: string().max(45).required(),
      value: number().min(0).max(100).required(),
      state: boolean().required()
    })

    const { values: model, errors, handleSubmit, handleReset } = useForm({
      validationSchema
    })

    useField('code', null, {
      initialValue: ''
    })
    useField('name', null, {
      initialValue: ''
    })

    useField('value', null, {
      initialValue: 0
    })

    useField('state', null, {
      initialValue: true
    })

    onMounted(() => {
      if (isEdit.value) {
        fetchImpuesto()
      }
    })

    const modelFill = (value) => {
      model.code = value.code
      model.name = value.name
      model.value = value.value
      model.state = value.state
    }

    const fetchImpuesto = () => {
      return impuestosBuscar(route.params.id).then(({ status, data }) => {
        modelFill(data)
      }).catch(err => {
        console.error(err.message)
      })
    }
    const onSubmit = handleSubmit((values) => {
      if (isEdit.value) {
        impuestosActualizar(route.params.id, values).then(({ status }) => {
          if (status === 200) {
            messageSuccess('Impuesto actualizado')
            router.back()
          }
        }).catch(err => {
          console.error(err.message)
        })
        return false
      }
      impuestosCrear(values).then(({ status, data }) => {
        if (status === 201) {
          messageConfirm('Desea crear otro impuesto', 'Impuesto creado', 'success').then(({ isConfirmed }) => {
            if (isConfirmed) {
              handleReset()
              return false
            }
            router.back()
          })
        }
      }).catch(err => {
        console.error(err.message)
      })
    })

    return {
      model,
      errors,
      onSubmit,
      isEdit
    }
  }
}
</script>

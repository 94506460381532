import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import home from './views/home'

import settings from './views/settings/home'
import settingsBanks from './views/settings/banks/list'
import settingsCreditLines from './views/settings/creditLines/crud'
import settingsTypePayments from './views/settings/typePayments/crud'
import settingsQuotesBank from './views/settings/quotesBank'
import settingsNotifications from './views/settings/notifications'
import settingsOthers from './views/settings/others'
import permissions from './views/settings/permissions'
import financialProducts from './views/financialProduct/home'
import financialProductsForm from './views/financialProduct/form'
import tableAmortizations from './views/financialProduct/tableAmortizations/home'
import listPaymentsMade from './views/financialProduct/payments/list/listPaymentsMade'
import loadTableAmortizations from './views/financialProduct/tableAmortizations/loadTemplate'
import generateAmortizations from './views/financialProduct/tableAmortizations/generateAmortizations'
import paymentsAmortizations from './views/financialProduct/payments/home'
import paymentsAmortizationsForm from './views/financialProduct/payments/form'
import reports from './views/reports/home'
import reportConsolidated from './views/reports/consolidated'
import reportGeneral from './views/reports/general'
import securityAccess from '../../../../middleware/securityAccess'

export default [
  {
    path: '/pharmasan/contabilidad',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    redirect: { name: 'pharmasan.contabilidad.amortizaciones' },
    children: [
      {
        path: 'amortizaciones',
        name: 'pharmasan.contabilidad.amortizaciones',
        component: home,
        redirect: { name: 'pharmasan.contabilidad.amortizaciones.dashboard' },
        children: [
          {
            path: 'configuracion',
            name: 'pharmasan.contabilidad.amortizaciones.configuracion',
            component: settings,
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.configuracion'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'configuracion/bancos',
            name: 'pharmasan.contabilidad.amortizaciones.configuracion.bancos',
            component: settingsBanks,
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.configuracion.bancos'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'configuracion/lineas-credito',
            name: 'pharmasan.contabilidad.amortizaciones.configuracion.lineas-credito',
            component: settingsCreditLines,
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.configuracion.lineas-credito'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'configuracion/tipo-pagos',
            name: 'pharmasan.contabilidad.amortizaciones.configuracion.tipos-pago',
            component: settingsTypePayments,
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.configuracion.tipos-pago'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'configuracion/cupos',
            name: 'pharmasan.contabilidad.amortizaciones.configuracion.listado-cupos',
            component: settingsQuotesBank,
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.configuracion.listado-cupos'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'configuracion/notificaciones',
            name: 'pharmasan.contabilidad.amortizaciones.configuracion.notificaciones',
            component: settingsNotifications,
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.configuracion.notificaciones'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'configuracion/otros',
            name: 'pharmasan.contabilidad.amortizaciones.configuracion.otros-permisos',
            component: settingsOthers,
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.configuracion.otros-permisos'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'configuracion/permisos',
            name: 'pharmasan.contabilidad.amortizaciones.configuracion.permisos',
            component: permissions,
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.configuracion.permisos'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'productos-financieros',
            name: 'pharmasan.contabilidad.amortizaciones.productos-financieros',
            component: financialProducts,
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.productos-financieros'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'productos-financieros/formulario/:id?',
            name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.formulario',
            component: financialProductsForm,
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.productos-financieros.access'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'productos-financieros/tabla-amortizaciones/:id',
            name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.tabla',
            component: tableAmortizations,
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.productos-financieros'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'productos-financieros/pagos/made/:id',
            name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.pagos.made',
            component: listPaymentsMade,
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.productos-financieros'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'productos-financieros/tabla/cargar/:id',
            name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.tabla.cargar',
            component: loadTableAmortizations,
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.productos-financieros'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'productos-financieros/tabla/generar/:id',
            name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.tabla.generar',
            component: generateAmortizations,
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.productos-financieros.access'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'productos-financieros/pagos',
            name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.pagos',
            component: paymentsAmortizations,
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.pagos'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'productos-financieros/pagos/formulario/:id?',
            name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.pagos.formulario',
            component: paymentsAmortizationsForm,
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.pagos.access'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'productos-financieros/pagos/formulario/:id?/:bankId?/:obligationNumber?',
            name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.pagos.formulario',
            component: paymentsAmortizationsForm,
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.pagos.access'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'productos-financieros/compras',
            name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.compras',
            component: () => import('./views/financialProduct/shopping/home'),
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.compras'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'productos-financieros/compras/:id?',
            name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.compras.formulario',
            component: () => import('./views/financialProduct/shopping/form'),
            beforeEnter: [securityAccess],
            meta: {
              access: ['pharmasan.contabilidad.amortizaciones.compras.access'],
              activeMenu: 'pharmasan.contabilidad.amortizaciones'
            }
          },
          {
            path: 'reportes',
            name: 'pharmasan.contabilidad.amortizaciones.reportes',
            component: reports,
            redirect: { name: 'pharmasan.contabilidad.amortizaciones.reportes.general' },
            children: [
              {
                path: 'consolidados',
                name: 'pharmasan.contabilidad.amortizaciones.reportes.consolidados',
                component: reportConsolidated,
                meta: {
                  access: ['pharmasan.contabilidad.amortizaciones.reportes'],
                  activeMenu: 'pharmasan.contabilidad.amortizaciones'
                }
              },
              {
                path: 'general',
                name: 'pharmasan.contabilidad.amortizaciones.reportes.general',
                component: reportGeneral,
                beforeEnter: [securityAccess],
                meta: {
                  access: ['pharmasan.contabilidad.amortizaciones.reportes'],
                  activeMenu: 'pharmasan.contabilidad.amortizaciones'
                }
              },
              {
                path: 'pagos-obligaciones',
                name: 'pharmasan.contabilidad.amortizaciones.reportes.pagos-obligaciones',
                component: () => import('./views/reports/payments-obligations'),
                beforeEnter: [securityAccess],
                meta: {
                  access: ['pharmasan.contabilidad.amortizaciones.reportes'],
                  activeMenu: 'pharmasan.contabilidad.amortizaciones'
                }
              },
              {
                path: 'gastos-financieros',
                name: 'pharmasan.contabilidad.amortizaciones.reportes.gastos-financieros',
                component: () => import('./views/reports/financial-expenses'),
                beforeEnter: [securityAccess],
                meta: {
                  access: ['pharmasan.contabilidad.amortizaciones.reportes'],
                  activeMenu: 'pharmasan.contabilidad.amortizaciones'
                }
              },
              {
                path: 'estados-creditos',
                name: 'pharmasan.contabilidad.amortizaciones.reportes.estados-creditos',
                component: () => import('./views/reports/credits-status'),
                beforeEnter: [securityAccess],
                meta: {
                  access: ['pharmasan.contabilidad.amortizaciones.reportes'],
                  activeMenu: 'pharmasan.contabilidad.amortizaciones'
                }
              }
            ]
          },
          {
            path: 'dashboard',
            name: 'pharmasan.contabilidad.amortizaciones.dashboard',
            redirect: { name: 'pharmasan.contabilidad.amortizaciones.dashboard.lineas-credito' },
            component: () => import('./views/dashboard/tabs'),
            access: [
              'pharmasan.contabilidad.amortizaciones.dashboard'
            ],
            children: [
              {
                path: 'principal',
                name: 'pharmasan.contabilidad.amortizaciones.dashboard.lineas-credito',
                component: () => import('./views/dashboard/principal'),
                meta: {
                  access: [
                    'pharmasan.contabilidad.amortizaciones.dashboard.lineas-credito'
                  ],
                  activeMenu: 'pharmasan.contabilidad.amortizaciones'
                }
              },
              {
                path: 'cupos-bancarios',
                name: 'pharmasan.contabilidad.amortizaciones.dashboard.cupos-bancarios',
                component: () => import('./views/dashboard/home'),
                meta: {
                  access: [
                    'pharmasan.contabilidad.amortizaciones.dashboard.cupos-bancarios'
                  ],
                  activeMenu: 'pharmasan.contabilidad.amortizaciones'
                }
              },
              {
                path: 'presupuesto',
                name: 'pharmasan.contabilidad.amortizaciones.dashboard.presupuestos',
                component: () => import('./views/dashboard/presupuesto'),
                meta: {
                  access: [
                    'pharmasan.contabilidad.amortizaciones.dashboard.presupuestos'
                  ],
                  activeMenu: 'pharmasan.contabilidad.amortizaciones'
                }
              }
            ]
          }
        ]
      }
    ]
  }
]

import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '@/middleware/securityAccess'
export default [
  {
    path: '/pharmasan/oportunidades',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: 'licitaciones',
        name: 'pharmasan.oportunidades.licitaciones',
        component: () => import('@/apps/pharmasan/licitaciones/views/licitaciones/LicMenu.vue'),
        redirect: { name: 'pharmasan.oportunidades.licitaciones.ver-licitaciones' },
        children: [
          {
            path: 'vizualizar',
            name: 'pharmasan.oportunidades.licitaciones.ver-licitaciones',
            component: () => import('@/apps/pharmasan/licitaciones/views/licitaciones/Licitaciones.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.oportunidades.licitaciones.ver-licitaciones'
              ],
              activeMenu: 'pharmasan.oportunidades.licitaciones.ver-licitaciones'
            }
          },
          {
            path: 'configuracion',
            name: 'pharmasan.oportunidades.licitaciones.herramientas',
            component: () => import('@/apps/pharmasan/licitaciones/views/licitaciones/Configuracion.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.oportunidades.licitaciones.herramientas'
              ],
              activeMenu: 'pharmasan.oportunidades.licitaciones.ver-licitaciones'
            }
          },
          {
            path: 'documentos',
            name: 'pharmasan.oportunidades.licitaciones.herramientas.documentos',
            component: () => import('@/apps/pharmasan/licitaciones/views/components/DocumentosListar.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.oportunidades.licitaciones.herramientas.documentos'
              ],
              activeMenu: 'pharmasan.oportunidades.licitaciones.ver-licitaciones'
            }
          },
          {
            path: 'licitacion',
            name: 'pharmasan.oportunidades.licitaciones.herramientas.licitaciones',
            component: () => import('@/apps/pharmasan/licitaciones/views/components/Licitacion.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.oportunidades.licitaciones.herramientas.licitaciones'
              ],
              activeMenu: 'pharmasan.oportunidades.licitaciones.ver-licitaciones'
            }
          },
          {
            path: 'modificar-licitacion/:id',
            name: 'pharmasan.oportunidades.licitaciones.editar',
            component: () => import('@/apps/pharmasan/licitaciones/views/components/editLicitacion.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.oportunidades.licitaciones.herramientas.licitaciones.access'
              ],
              activeMenu: 'pharmasan.oportunidades.licitaciones.ver-licitaciones'
            }
          },
          {
            path: 'cargar-documentos/:id',
            name: 'pharmasan.oportunidades.licitaciones.cargar-documentos',
            component: () => import('@/apps/pharmasan/licitaciones/views/components/cargarDocumentosLicitaciones.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.oportunidades.licitaciones.herramientas.licitaciones.access'
              ],
              activeMenu: 'pharmasan.oportunidades.licitaciones.ver-licitaciones'
            }
          },
          {
            path: 'modificar-indices/:id',
            name: 'pharmasan.oportunidades.licitaciones.modificar-indices',
            component: () => import('@/apps/pharmasan/licitaciones/views/components/modificarIndices.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.oportunidades.licitaciones.herramientas.licitaciones.access'
              ],
              activeMenu: 'pharmasan.oportunidades.licitaciones.ver-licitaciones'
            }
          },
          {
            path: 'tareas/:licCarpetasDocumentosId',
            name: 'pharmasan.oportunidades.licitaciones.herramientas.licitaciones.tareas',
            component: () => import('@/apps/pharmasan/licitaciones/views/components/tareasHome.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.oportunidades.licitaciones.herramientas.licitaciones'
              ],
              activeMenu: 'pharmasan.oportunidades.licitaciones.ver-licitaciones'
            }
          },
          {
            path: 'tareasCrear/:id',
            name: 'pharmasan.oportunidades.licitaciones.herramientas.licitaciones.tareas.crear',
            component: () => import('@/apps/pharmasan/licitaciones/views/components/tareasCrear'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.oportunidades.licitaciones.herramientas.licitaciones.access'
              ],
              activeMenu: 'pharmasan.oportunidades.licitaciones.ver-licitaciones'
            }
          },
          {
            path: 'tareasEditar/:id',
            name: 'pharmasan.oportunidades.licitaciones.herramientas.licitaciones.tareas.editar',
            component: () => import('@/apps/pharmasan/licitaciones/views/components/tareasEditar'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.oportunidades.licitaciones.herramientas.licitaciones.access'
              ],
              activeMenu: 'pharmasan.oportunidades.licitaciones.ver-licitaciones'
            }
          },
          {
            path: 'logs',
            name: 'pharmasan.oportunidades.licitaciones.herramientas.logs',
            component: () => import('@/apps/pharmasan/licitaciones/views/components/logs.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.oportunidades.licitaciones.herramientas.logs'
              ],
              activeMenu: 'pharmasan.oportunidades.licitaciones.ver-licitaciones'
            }
          },
          {
            path: 'indicadores-participacion/:id?',
            name: 'pharmasan.oportunidades.licitaciones.herramientas.indicadores-participacion',
            component: () => import('@/apps/pharmasan/licitaciones/views/components/Indicadores.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.oportunidades.licitaciones.herramientas.indicadores-participacion'
              ],
              activeMenu: 'pharmasan.oportunidades.licitaciones.ver-licitaciones'
            }
          },
          {
            path: 'indicadores/:id?',
            name: 'pharmasan.oportunidades.licitaciones.herramientas.licitacion-indicadores',
            component: () => import('@/apps/pharmasan/licitaciones/views/licIndicadoresValores/components/home.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.oportunidades.licitaciones.herramientas.indicadores-licitacion'
              ],
              activeMenu: 'pharmasan.oportunidades.licitaciones.ver-licitaciones'
            }
          },
          {
            path: 'socios',
            name: 'pharmasan.oportunidades.licitaciones.herramientas.socios',
            component: () => import('@/apps/pharmasan/licitaciones/views/socios/Main'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.oportunidades.licitaciones.herramientas.socios'
              ],
              activeMenu: 'pharmasan.oportunidades.licitaciones.ver-licitaciones'
            }
          },
          {
            path: 'estadosfinancieros/:id',
            name: 'pharmasan.oportunidades.licitaciones.herramientas.socios.estados-financieros',
            component: () => import('@/apps/pharmasan/licitaciones/views/estadosfinancieros/Main'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.oportunidades.licitaciones.herramientas.socios'
              ],
              activeMenu: 'pharmasan.oportunidades.licitaciones.ver-licitaciones'
            }
          }
        ]
      }
    ]
  }
]

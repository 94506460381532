import http from '../../../../libs/http'

export default class HeaderServices {
  getHeader (id) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_PETTY_CASH}/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  getCashBalance (date) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_PETTY_CASH}/balance-closing/${date}`, {
      headers: {
        loading: true
      }
    })
  }

  getInvoicesTypes () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_PETTY_CASH}/invoices-types`, {
      headers: {
        loading: true
      }
    })
  }

  getPrestamos () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_PETTY_CASH}/get-prestamos`, {
      headers: {
        loading: true
      }
    })
  }

  balanceClose (id) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_PETTY_CASH}/balance-closing/balance-close/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}

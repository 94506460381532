import { useStore } from '@/store'
import Cookies from 'js-cookie'

export default function (to, from, next) {
  const store = useStore()
  if (!store.getters['auth/getToken']) {
    Cookies.set('intended_url', to.path)
    next({ name: 'login' })
  } else {
    next()
  }
}

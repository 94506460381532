<template>
  <Toast />
  <Toolbar class="box mt-4 p-p-2">
    <template #start>
      <back-button />
    </template>
  </Toolbar>

  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">
        <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-lg mr-auto" :class="isEdit ? 'text-yellow-600' : 'text-green-800'">{{ isEdit ? 'Editar Producto Financiero' : 'Nuevo Producto Financiero' }}</h2>
        </div>
        <div class="p-5">
          <form action="" @submit.prevent="onSubmit">
            <h3 class="font-medium text-base mb-4">Información General</h3>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-4">
                <label for="banco">Banco</label>
                <Dropdown
                  id="banco"
                  v-model="modelo.bankId"
                  :options="banks"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Seleccione una opción"
                  :filter="true"
                  scrollHeight="auto"
                  filterPlaceholder="Buscar por nombre"
                  :class="{ 'p-invalid': errors.bankId }"
                  @change="changeBanks"
                />
                <MessageError :text="errors.bankId" />
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="obligacion">Linea de crédito</label>
                <Dropdown
                  id="obligacion"
                  v-model="modelo.bankQuotaId"
                  :options="creditLines"
                  @change="onChangeCreditLine"
                  optionLabel="creditLine.name"
                  optionValue="id"
                  placeholder="Seleccione una opción"
                  :filter="true"
                  filterPlaceholder="Buscar por nombre"
                  :class="{ 'p-invalid': errors.bankQuotaId }"
                />
                <MessageError :text="errors.bankQuotaId" />
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="banco">Cuenta Asociada SAP</label>
                <Dropdown
                  id="banco"
                  v-model="modelo.accountSap"
                  :options="accountsSap"
                  optionLabel="acctname"
                  optionValue="acctcode"
                  placeholder="Seleccione una opción"
                  :filter="true"
                  scrollHeight="auto"
                  filterPlaceholder="Buscar por nombre"
                  :class="{ 'p-invalid': errors.accountSap }"
                  @change="changeAccountSap"
                />
                <MessageError :text="errors.accountSap" />
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="obligationNumber">N° Obligación</label>
                <InputText
                  v-model="modelo.obligationNumber"
                  id="obligationNumber"
                  type="text"
                  :class="{ 'p-invalid': errors.obligationNumber }"
                />
                <MessageError :text="errors.obligationNumber" />
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="disbursement">Desembolso ($)</label>
                <InputNumber
                  id="disbursement"
                  v-model="modelo.disbursement"
                  mode="currency" currency="COP"
                  locale="es-CO"
                  minFractionDigits="0"
                  maxFractionDigits="0"
                />
                <MessageError :text="errors.disbursement" />
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="disbursementDate">Fecha Desembolso</label>
                <InputText
                  id="disbursementDate"
                  v-model="modelo.disbursementDate"
                  type="date"
                  :class="{ 'p-invalid': errors.disbursementDate }"
                />
                <MessageError :text="errors.disbursementDate" />
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="periodicity">Periodicidad</label>
                <Dropdown
                  id="periodicity"
                  v-model="modelo.periodicity"
                  :options="periodos"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Seleccione una opción"
                  :class="{ 'p-invalid': errors.periodicity }"
                />
                <MessageError :text="errors.periodicity" />
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="term">Plazo</label>
                <InputText
                  id="term"
                  type="number"
                  v-model="modelo.term"
                  :class="{ 'p-invalid': errors.term }"
                />
                <MessageError :text="errors.term" />
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="insurance">Seguro ($)</label>
                <InputNumber
                  id="insurance"
                  v-model="modelo.insurance"
                  mode="currency" currency="COP"
                  locale="es-CO"
                  minFractionDigits="0"
                  maxFractionDigits="0"
                />
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="otherValues">Otros valores ($)</label>
                <InputNumber
                  id="otherValues"
                  v-model="modelo.otherValues"
                  mode="currency" currency="COP"
                  locale="es-CO"
                  minFractionDigits="0"
                  maxFractionDigits="0"
                />
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="pointsAboutDTFTA">Puntos sobre DTF TA</label>
                <InputText v-model="modelo.pointsAboutDTFTA" id="pointsAboutDTFTA" type="number" />
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="currentDTFTA">DTF TA Vigente</label>
                <InputText v-model="modelo.currentDTFTA" id="currentDTFTA" type="number" />
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="rateEA">Tasa EA%</label>
                <div class="p-inputgroup">
                  <InputText
                    id="rateEA"
                    type="text"
                    v-model="modelo.rateEA"
                    :class="{ 'p-invalid': errors.rateEA }"
                  />
                  <span :class="{ 'p-invalid p-invalid-span': errors.rateEA }" class="p-inputgroup-addon" >%</span>
                </div>
                <MessageError :text="errors.rateEA" />
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="rateMMV">Tasa MMV%</label>
                <div class="p-inputgroup">
                  <InputText v-model="modelo.rateMMV" id="rateMMV" type="number" />
                  <span class="p-inputgroup-addon" >%</span>
                </div>
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="rateMV">Tasa MV%</label>
                <div class="p-inputgroup">
                  <InputText v-model="modelo.rateMV" id="rateMV" type="number" />
                  <span class="p-inputgroup-addon" >%</span>
                </div>
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="cutoffDate">Fecha de corte</label>
                <InputText
                  id="cutoffDate"
                  type="date"
                  v-model="modelo.cutoffDate"
                  :class="{ 'p-invalid': errors.cutoffDate }"
                />
                <MessageError :text="errors.cutoffDate" />
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="dueDate">Fecha de vencimiento</label>
                <InputText
                  id="dueDate"
                  type="date"
                  v-model="modelo.dueDate"
                  :class="{ 'p-invalid': errors.dueDate }"
                />
                <MessageError :text="errors.dueDate" />
              </div>
              <div class="p-field p-col-12 p-md-4" v-if="isCreditCard">
                <label for="disbursement">Cupo Tarjeta Crédito ($)</label>
                <InputNumber
                  id="disbursement"
                  v-model="modelo.creditCardQuota"
                  mode="currency" currency="COP"
                  locale="es-CO"
                  minFractionDigits="0"
                  maxFractionDigits="0"
                />
                <MessageError :text="errors.disbursement" />
              </div>
              <div class="p-field p-col-12 p-md-12">
                <label for="name">Descripción del producto</label>
                <Textarea
                  v-model="modelo.name"
                  id="name"
                  :class="{ 'p-invalid': errors.name }"
                  :autoResize="true"
                  rows="2"
                />
                <MessageError :text="errors.name" />
              </div>

              <div v-show="wasSelectAccountSap" class="p-field p-col-12 p-md-12">
                <h3 class="font-medium text-base mb-4">Información cuentas asociadas SAP</h3>
                <TableAccountsSap ref="tableAccountsSap" :isEdit="isEdit" :isDisabled="isDisabled" :listAccountsSap="modelo.accountsCodeSap" :errors="errors"/>
              </div>
            </div>
            <div class="p-field p-grid justify-end">
                <span class="p-buttonset">
                  <Button
                    :label="isEdit ? 'Editar' : 'Guardar'"
                    type="submit"
                    :icon="isEdit ? 'pi pi-pencil' : 'pi pi-send'"
                    class="p-button-sm"
                    :class="isEdit ? 'p-button-warning' : 'p-button-success'"
                  />
                </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import listBanks from '../../../services/listBanks'
import listBankQuota from '../../../services/listBankQuota'
import { array, number, object, string } from 'yup'
import { ErrorMessage, Field, useField, useForm } from 'vee-validate'
// import { useLoading } from 'vue3-loading-overlay'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'
import { error, success } from '../../../../../../../libs/mensajes'
import { useRoute, useRouter } from 'vue-router'
import createFinancialProduct from '../../../services/createFinancialProduct'
import updateFinancialProduct from '../../../services/updateFinancialProduct'
import findFinancialProduct from '../../../services/findFinancialProduct'
import listAccountsByBank from '../../../services/listAccountsByBank'
import createProductAccounts from '../../../services/createProductAccounts'
import TableAccountsSap from './tableAccountsSap'
import { useToast } from 'primevue/usetoast'
import listAccountsSapByProduct from '../../../services/listAccountsSapByProduct'

export default {
  name: 'formFinancialProduct',
  components: {
    TableAccountsSap
  },
  setup () {
    const periodos = ref([
      { name: 'Mensual', value: 'Mensual' },
      { name: 'Bimensual', value: 'Bimensual' },
      { name: 'Trimestral', value: 'Trimestral' },
      { name: 'Semestral', value: 'Semestral' },
      { name: 'Anual', value: 'Anual' },
      { name: '75 días', value: '75 dias' }
    ])
    const toast = useToast()
    const banks = ref()
    const creditLines = ref([])
    const accountsSap = ref([])
    const insurance = ref(0)
    const otherValues = ref(0)
    const fullPage = ref(true)
    const tableAccountsSap = ref()
    const wasSelectAccountSap = ref(false)
    const isCreditCard = ref(false)

    const router = useRouter()
    const route = useRoute()

    const isEdit = computed(() => {
      return !!route.params.id
    })

    const isDisabled = computed(() => {
      return !!route.params.id
    })
    /* Obtener el listado de bancos */
    const fetchBanks = () => {
      return listBanks({ options: true }).then(({ status, data }) => {
        banks.value = data.result
      }).catch(err => {
        console.error(err.message)
      })
    }

    /* Cambio del dropdown de la linea de credito */
    const onChangeCreditLine = (e) => {
      isCreditCard.value = e.originalEvent.target.innerText === 'Tarjeta de Crédito'
    }

    /* Obtener el listado de las lineas de credito */
    const fetchCreditLines = (params) => {
      return listBankQuota(params).then(({ status, data }) => {
        if (data.result.length > 0) {
          creditLines.value = data.result
        } else {
          toast.add({
            severity: 'warn',
            summary: 'Advertencia',
            detail: 'El banco seleccionado no tiene ninguna línea de credito',
            life: 3000
          })
        }
      }).catch(err => {
        console.error(err.message)
      })
    }

    /* Obtener el listado de las cuentas asociada de sap por banco */
    const fetchAccountsSap = ({ value: bankId }) => {
      return listAccountsByBank({ id: bankId }).then(({ status, data }) => {
        accountsSap.value = data.result
      }).catch(err => {
        console.error(err.message)
      })
    }

    /* Obtener el producto financiero para editar */
    const fetchFinancialProduct = (id) => {
      return findFinancialProduct({ id }).then(async ({ status, data: response }) => {
        if (status !== 200) return

        if (response.result.bankId) {
          await changeBanks({ value: response.result.bankId })
          isCreditCard.value = response.result.AmortizationsBankQuota.creditLine.name === 'Tarjeta de Crédito'
        }

        modelo.name = response.result.name
        modelo.bankId = response.result.bankId
        modelo.bankQuotaId = response.result.bankQuotaId
        modelo.nameCreditLine = response.result.AmortizationsBankQuota.creditLine.name
        modelo.accountSap = response.result.accountSap
        modelo.obligationNumber = response.result.obligationNumber
        modelo.disbursement = response.result.disbursement
        modelo.disbursementDate = response.result.disbursementDate
        modelo.periodicity = response.result.periodicity
        modelo.term = response.result.term
        modelo.insurance = response.result.insurance
        modelo.otherValues = response.result.otherValues
        modelo.pointsAboutDTFTA = response.result.pointsAboutDTFTA
        modelo.currentDTFTA = response.result.currentDTFTA
        modelo.rateEA = response.result.rateEA
        modelo.rateMMV = response.result.rateMMV
        modelo.rateMV = response.result.rateMV
        modelo.cutoffDate = response.result.cutoffDate
        modelo.dueDate = response.result.dueDate
        modelo.creditCardQuota = response.result.creditCardQuota
      })
    }

    const validationSchema = object({
      name: string().nullable().required().label('Descripción del producto'),
      bankId: string().nullable().required().label('Banco'),
      bankQuotaId: string().nullable().required().label('Línea de crédito'),
      accountSap: string().nullable().required().label('Cuenta asociada SAP'),
      obligationNumber: string().nullable().required().label('Número de la obligación'),
      disbursement: number().typeError('El campo es requerido').positive().label('Desembolso'),
      disbursementDate: string().nullable().required().label('Fecha desembolso'),
      periodicity: string().nullable().required().label('Periodicidad'),
      term: number().typeError('El campo es requerido').positive('').label('Plazo'),
      rateEA: number().typeError('El campo es requerido').positive('').label('Tasa EA%'),
      cutoffDate: string().nullable().required().label('Fecha de corte'),
      dueDate: string().nullable().required().label('Fecha de vencimiento'),
      accountsCodeSap: array().of(
        object({
          acctcode: string().nullable().required().label('Código cuenta SAP')
        })
      )
    })

    // Create a form context with the validation schema
    const { errors, handleSubmit, values: modelo, handleReset } = useForm({
      validationSchema
    })

    // Submit del envio de los datos
    const onSubmit = handleSubmit((values) => {
      // const loader = useLoading()
      // loader.show()

      if (isEdit.value) {
        updateFinancialProduct(route.params.id, values).then(({ data, status }) => {
          if (data.message === 'OK' && status === 200) {
            /* Obtener las cuentas ya guardadas del producto */
            listAccountsSapByProduct({ productId: route.params.id }).then(({ status, data: resp }) => {
              const newMapAccountSap = values.accountsCodeSap.map(account => {
                return {
                  accountSap: account.acctcode,
                  productId: route.params.id
                }
              })

              /* Obtenemos solo la diferencia de las cuentas nuevas si se agregaron */
              const propsAccounts = ['accountSap', 'productId']
              const result = newMapAccountSap.filter(function (accountNew) {
                return !resp.result.some(function (accountExist) {
                  return accountNew.accountSap === accountExist.accountSap
                })
              }).map(function (o) {
                return propsAccounts.reduce(function (newo, name) {
                  newo[name] = o[name]
                  return newo
                }, {})
              })

              createProductAccounts({ accounts: result }).then(({ data, status }) => {
                // loader.hide()
                success('Producto financiero actualizado exitosamente')
                router.back()
              })
            })
          }
        }).catch((err) => {
          // loader.hide()
          console.error(err.message)
          if (err.response.status) {
            error(err.response.data.message)
          }
        })
      } else {
        createFinancialProduct(values).then(({ data, status }) => {
          if (data.message === 'OK' && status === 201) {
            const newMapAccountSap = values.accountsCodeSap.map(account => {
              return {
                accountSap: account.acctcode,
                productId: data.result.id
              }
            })
            createProductAccounts({ accounts: newMapAccountSap }).then(({ data, status }) => {
              // loader.hide()
              success('Producto financiero creado exitosamente')
              router.back()
            })
          }
        }).catch((err) => {
          // loader.hide()
          console.error(err.message)
          if (err.response.status) {
            error(err.response.data.message)
          }
        })
      }
    })

    // esto es obligatorio
    useField('name', null, { initialValue: null })
    useField('bankId', null, { initialValue: null })
    useField('bankQuotaId', null, { initialValue: null })
    useField('accountSap', null, { initialValue: null })
    useField('obligationNumber', null, { initialValue: null })
    useField('disbursement', null, { initialValue: null })
    useField('disbursementDate', null, { initialValue: null })
    useField('periodicity', null, { initialValue: null })
    useField('term', null, { initialValue: null })
    useField('insurance', null, { initialValue: null })
    useField('otherValues', null, { initialValue: null })
    useField('pointsAboutDTFTA', null, { initialValue: null })
    useField('currentDTFTA', null, { initialValue: null })
    useField('rateEA', null, { initialValue: null })
    useField('rateMMV', null, { initialValue: null })
    useField('rateMV', null, { initialValue: null })
    useField('cutoffDate', null, { initialValue: null })
    useField('dueDate', null, { initialValue: null })
    useField('accountsCodeSap', null, {
      initialValue: [{ acctcode: null, acctname: null, nameaccount: null, _key: 0 }]
    })

    /* */
    const changeBanks = (e) => {
      console.log('E: ', e)
      fetchCreditLines({ bankId: e.value, status: true })
      fetchAccountsSap(e)
    }

    /* Evento cuando cambia la cuenta asociada de sap */
    const changeAccountSap = (e) => {
      const infoAccountSap = accountsSap.value.filter((account) => {
        return account.acctcode === e.value
      })

      /* Se agrega funcion que setea los valores en el componente hijo de la tabla */
      tableAccountsSap.value.setAccountCode([infoAccountSap[0]])

      modelo.accountsCodeSap = [
        { acctcode: e.value, acctname: infoAccountSap[0].acctname, nameaccount: 'ABONO A CAPITAL', _key: 0 }
      ]

      wasSelectAccountSap.value = true
    }

    /* Obtener las cuentas sap asociadas al producto */
    const fetchAccountsSapByProduct = (productId) => {
      return listAccountsSapByProduct({ productId }).then(({ status, data: resp }) => {
        const newResp = resp.result.map((r, index) => {
          return {
            acctcode: r.accountSap,
            acctname: r.nameAccount,
            nameaccount: r.nameAccount,
            _key: index,
            id: r.id
          }
        })

        tableAccountsSap.value.setAccountCode(newResp)
        modelo.accountsCodeSap = newResp
      }).catch(err => {
        console.error(err.message)
      })
    }

    onMounted(async () => {
      await fetchBanks()
      if (isEdit.value) {
        const { id } = route.params
        wasSelectAccountSap.value = true
        fetchFinancialProduct(id)
        fetchAccountsSapByProduct(id)
      } else {
        setTimeout(() => {
          handleReset()
        }, 200)
      }
    })

    return {
      banks,
      creditLines,
      accountsSap,
      periodos,
      insurance,
      otherValues,
      ErrorMessage,
      Field,
      errors,
      onSubmit,
      modelo,
      fullPage,
      isEdit,
      isDisabled,
      changeBanks,
      changeAccountSap,
      tableAccountsSap,
      wasSelectAccountSap,
      onChangeCreditLine,
      isCreditCard
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 489px) {
  .p-formgroup-inline {
    .p-field {
      margin-bottom: 1em !important;
    }
  }
}

.layout-content .content-section.implementation > h3 {
  font-weight: 600;
}

.p-invalid-span {
  background-color: #f4433638;
  border-color: #f44336;
}
.floating-button {
  position: fixed !important;
  bottom: 30px;
  right: 40px;
  z-index: 10050;
}
textarea {
  resize: none;
}
</style>

import securityMeService from '../apps/system/security/services/securityMeService'
import { useStore } from '../store'

const _transform = (values) => {
  const { serviceMenu } = values
  if (serviceMenu) {
    return serviceMenu.map(e => {
      return {
        title: e.name,
        icon: e.icon,
        pageName: e.action,
        nivel: e.nivel,
        id: e.id,
        parent_menu_id: e.parent_menu_id
      }
    })
  }
  return []
}

export default async (to, from, next) => {
  try {
    const store = useStore()
    if (!store.getters['auth/getToken']) return next()
    if (to.name === 'login') return next()
    const { data } = await securityMeService()
    // console.log(data)
    store.commit('auth/setPermissions', data.permissions || [])
    const menus = _transform(data)
    store.commit('sideMenu/setMenu', menus)
    store.commit('auth/setPeople', data.people)
  } catch (error) {
    console.error(error.message)
  }
  next()
}

<template>
  <section class="intro-x">
    <div class="w-full flex my-5">
      <back-button />
      <router-link
      :to="getRouteCreate"
      class="btn btn-primary ml-2"
      >
        Agregar
      </router-link>
    </div>
    <div class="my-5">
      <h1 class="font-bold text-xl">Categorías</h1>
    </div>
    <div class="w-full my-4">
      <DataTable :value="getCategories">
        <Column header="CODIGO" field="code" headerStyle="width:120px;" />
        <Column header="HABILITADO" field="state" headerStyle="width:120px;">
          <template #body="{data}">
            <span v-if="data.state">Si</span>
            <span v-else>No</span>
          </template>
        </Column>
        <Column header="NOMBRE" field="name" />
        <Column header="ACCIONES" bodyClass="flex justify-end " headerClass="flex justify-end ">
          <template #body="{data}">
            <router-link
            :to="{name:'pharmasan.administrativa.compras-administrativas.ajustes.list', params:{id: data.id}}"
            class="btn btn-primary p-1 mr-1"
            >
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" />
              </svg>
            </router-link>
            <router-link
            :to="{name: 'pharmasan.administrativa.compras-administrativas.ajustes.categorias.formulario',params:{id: data.id}}"
            class="btn btn-primary p-1"
            >
              <svg viewBox="0 0 24 24" style="width: 24px; height: 24px;"><path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"></path></svg>
            </router-link>
          </template>
        </Column>
      </DataTable>
    </div>
  </section>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import categoriasListar from '../../../../../services/categoriasListar'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

export default {
  components: {
    DataTable,
    Column
  },
  setup () {
    const route = useRoute()
    const categories = ref([])

    const getCategories = computed(() => {
      return categories.value
    })

    const isParent = computed(() => {
      if (route.params.id) return true
      return false
    })

    const categoriasFetch = () => {
      const { id } = route.params
      return categoriasListar(id).then(({ data }) => {
        if (id) {
          categories.value = data.categories
        } else {
          categories.value = data
        }
        return data
      }).catch(err => {
        console.error(err.message)
      })
    }

    const getRouteCreate = computed(() => {
      let routeName = { name: 'pharmasan.administrativa.compras-administrativas.ajustes.categorias.formulario' }
      if (isParent.value) routeName = { name: 'pharmasan.administrativa.compras-administrativas.ajustes.categorias.subcategorias.formulario', params: { id: route.params.id } }
      return routeName
    })

    onMounted(() => {
      categoriasFetch()
    })

    watch(() => route.params.id, (value) => {
      categoriasFetch()
    })
    return {
      getCategories,
      getRouteCreate
    }
  }
}
</script>

<style>

</style>

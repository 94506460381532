<template>
  <Toast />
  <Toolbar v-if="$can('pharmasan.contabilidad.amortizaciones.pagos.access')" class="box mt-4 p-p-2">
    <template #end>
      <router-link
        :to="{name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.pagos.formulario'}"
      >
        <Button label="Registrar Pago" icon="pi pi-plus" class="p-button-success p-button-sm" />
      </router-link>
    </template>
  </Toolbar>

  <listPayments />
</template>

<script>
import listPayments from '../list'

export default {
  name: 'AmortizationsPayments',
  components: { listPayments }
}
</script>

<style scoped>

</style>

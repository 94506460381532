import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import home from './views/home'
// import securityAccess from '../../../middleware/securityAccess'

export default [
  {
    path: '/capital-state/investment',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: '',
        name: 'investment',
        component: home,
        redirect: { name: 'investment.dashboard' },
        children: [
          {
            path: 'dashboard',
            name: 'investment.dashboard',
            component: () => import('./views/dashboard').then(m => m.default || m)
          },
          {
            path: 'reports',
            name: 'investment.reports',
            component: () => import('./views/reports/home')
          }
        ]
      }
    ]
  }
]

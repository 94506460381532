<template>
  <section class="w-full">
    <div v-if="loading" class="rounded-md icono-carga flex justify-center items-center">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)"/>
    </div>
    <div class="accordion-item" v-else>
      <div id="faq-accordion-content-1" class="accordion-header flex justify-between">
        <button
          class="accordion-button text-base font-medium focus:outline-none dark:text-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#faq-accordion-collapse-1"
          aria-expanded="true"
          aria-controls="faq-accordion-collapse-1"
        >
          Filtros de busqueda
        </button>
      </div>
      <div
        id="faq-accordion-collapse-1"
        class="accordion-collapse collapse show mt-2"
        aria-labelledby="faq-accordion-content-1"
        data-bs-parent="#faq-accordion-1"
      >
        <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-3">
              <label for="banks">Bancos:</label>
              <Dropdown
                id="banks"
                :options="banks"
                v-model="filters.bankId.value"
                class="p-inputtext-sm"
                optionLabel="name"
                optionValue="id"
                placeholder="Seleccione una opción"
                :filter="true"
                scrollHeight="auto"
                filterPlaceholder="Buscar por nombre"
              />
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label for="dateStart" class="block text-sm font-medium text-gray-700">Fecha Inicial:</label>
              <InputText
                id="dateStart"
                type="date"
                class="w-full p-inputtext-sm"
                v-model="filters.dateStart.value"
              />
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label for="dateEnd" class="block text-sm font-medium text-gray-700">Fecha Final:</label>
              <InputText
                id="dateEnd"
                type="date"
                class="w-full p-inputtext-sm"
                v-model="filters.dateEnd.value"
              />
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label for="dateEnd" class="block text-sm font-medium text-gray-700">&nbsp;</label>
              <span class="p-buttonset">
                <Button label="Buscar" icon="pi pi-search" @click="updateFilter(filters)" class="p-button-sm p-button-info"/>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import { onMounted, ref } from 'vue'
import listBanks from '../../../../services/listBanks'

export default {
  name: 'filters',
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  setup (props, context) {
    const loading = ref()
    const banks = ref()
    const modelo = ref({})

    /* Obtener el listado de bancos */
    const fetchBanks = () => {
      return listBanks({ options: true }).then(({ status, data }) => {
        banks.value = data.result
        banks.value.unshift({ id: '', name: 'Todos' })
      }).catch(err => {
        console.error(err.message)
      })
    }

    const updateFilter = (e) => {
      context.emit('update:modelValue', e)
      context.emit('search', { filters: e })
    }

    onMounted(() => {
      fetchBanks()
    })

    return {
      updateFilter,
      filters: props.modelValue,
      loading,
      banks,
      modelo
    }
  }
}
</script>

<style scoped>

</style>

import http from '@/libs/http'
import { useStore } from '@/store'

export default class LicitacionesService {
  saveLicitaciones (payload) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        loading: true
      }
    })
  }

  updateLicitaciones (id, payload) {
    return http.put(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones/${id}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        loading: true
      }
    })
  }

  getLicitaciones (limit = 10, offset = 0, search = {}, order = [{ field: 'fav', order: -1 }]) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones/paginate?limit=${limit}&offset=${offset}`, {
      params: { ...search, order },
      headers: { loading: true }
    })
  }

  getAllLicitaciones () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones`, {
      headers: { loading: true }
    })
  }

  getLicitacion (id) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  cargarDocumentos (id, payload) {
    return http.put(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-carpteas-documentos/upload/${id}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        loading: true
      }
    })
  }

  crearDocumento (payload) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-carpteas-documentos`, payload, {
      headers: {
        loading: true
      }
    })
  }

  actualizarDocumento (id, payload) {
    return http.put(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-carpteas-documentos/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  eliminarDocumento (id) {
    return http.delete(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-carpteas-documentos/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  crearCarpeta (payload) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-carpteas`, payload, {
      headers: {
        loading: true
      }
    })
  }

  actualizarCarpeta (id, payload) {
    return http.put(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-carpteas/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  eliminarCarpeta (id) {
    return http.delete(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-carpteas/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  reordenarCarpetas (payload) {
    return http.patch(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-carpteas/ordenar`, payload, {
      headers: {
        loading: true
      }
    })
  }

  getUsers () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-usuarios`)
  }

  setUser (id, payload) {
    return http.put(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-carpteas-documentos/add-responsable/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  reordenarDocumentos (payload) {
    return http.patch(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-carpteas-documentos/ordenar`, payload, {
      headers: {
        loading: true
      }
    })
  }

  generarIndices (id) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-indices`, { id }, {
      headers: {
        loading: true
      }
    })
  }

  getIndices (id) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-indices/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  updateIndices (id, payload) {
    return http.put(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-indices/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  refresh (id) {
    return http.put(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-indices/refresh/${id}`, {}, {
      headers: {
        loading: true
      }
    })
  }

  getPdf (ruta) {
    const store = useStore()
    return new Promise((resolve) => {
      const baseURL = process.env.VUE_APP_MICROSERVICE_API_LICITACIONES
      const url = `${baseURL}/visualizar-pdf`
      const token = store.getters['auth/getToken']
      const res = http.getUri({ url, params: { ruta, token } })
      resolve(res)
    })
  }

  getListaPdfs (id) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones/listapdfs/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  girarPdf (payload) {
    return http.put(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/visualizar-pdf/girar`, payload, {
      headers: {
        loading: true
      }
    })
  }

  reemplazarPdf (id, payload) {
    return http.put(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones/updatepdfs/${id}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        loading: true
      }
    })
  }

  eliminarPdf (id) {
    return http.delete(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones/deletepdfs/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  addPdf (payload) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones/addpdfs`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        loading: true
      }
    })
  }

  generarReporte (id) {
    const store = useStore()
    return new Promise((resolve) => {
      const baseURL = process.env.VUE_APP_MICROSERVICE_API_LICITACIONES
      const url = `${baseURL}/licitaciones/reporte-documentos-responsable/${id}`
      const token = store.getters['auth/getToken']
      const res = http.getUri({ url, params: { token } })
      resolve(res)
    })
  }

  licCopy (id) {
    return http.put(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones/copy/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  updateFav (params) {
    return http.put(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones/updateFav/${params.id}/${params.fav}`, {
      headers: {
        loading: true
      }
    })
  }

  getEstados () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitacion-estados/all`)
  }

  deleteLicitacion (id) {
    return http.delete(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones/${id}`)
  }
}

<template>
  <section class="box p-4 flex flex-col items-center justify-center">
    <h1 class="text-3xl font-bold mt-5">404</h1>
    <p>Pagina no encontrada</p>
    <button
    type="button"
    class="btn btn-primary my-5"
    @click="$router.back()"
    >
      Regresar
    </button>
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>

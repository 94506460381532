import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import currency from 'currency.js'
require('dayjs/locale/es')
const { useRouter } = require('vue-router')
const router = useRouter()

dayjs.extend(duration)

dayjs.locale('es')

const helpers = {
  goto (name) {
    router.push({ name })
  },
  cutText (text, length) {
    if (text.split(' ').length > 1) {
      const string = text.substring(0, length)
      const splitText = string.split(' ')
      splitText.pop()
      return splitText.join(' ') + '...'
    } else {
      return text
    }
  },
  formatDate (date = dayjs(), format = 'YYYY-MM-DD') {
    return dayjs(date).format(format)
  },
  capitalizeFirstLetter (string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ''
    }
  },
  onlyNumber (string) {
    if (string) {
      return parseInt(string.replace(/\D/g, ''))
    } else {
      return ''
    }
  },
  formatCurrency (number, precision = 0) {
    const cop = value => currency(value, { symbol: '$', separator: '.', decimal: ',', precision: precision })
    if (number) {
      return cop(number).format()
    } else {
      return 0
    }
  },
  formatNumber (number) {
    const cop = value => currency(value, { symbol: '', separator: '.', decimal: ',', precision: 0 })
    if (number) {
      return cop(number).format()
    } else {
      return 0
    }
  },
  timeAgo (time) {
    const date = new Date(
      (time || '').replace(/-/g, '/').replace(/[TZ]/g, ' ')
    )
    const diff = (new Date().getTime() - date.getTime()) / 1000
    const dayDiff = Math.floor(diff / 86400)

    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
      return dayjs(time).format('MMMM DD, YYYY')
    }

    return (
      (dayDiff === 0 &&
        ((diff < 60 && 'just now') ||
          (diff < 120 && '1 minute ago') ||
          (diff < 3600 && Math.floor(diff / 60) + ' minutes ago') ||
          (diff < 7200 && '1 hour ago') ||
          (diff < 86400 && Math.floor(diff / 3600) + ' hours ago'))) ||
      (dayDiff === 1 && 'Yesterday') ||
      (dayDiff < 7 && dayDiff + ' days ago') ||
      (dayDiff < 31 && Math.ceil(dayDiff / 7) + ' weeks ago')
    )
  },
  diffTimeByNow (time) {
    const startDate = dayjs(
      dayjs()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
    )
    const endDate = dayjs(
      dayjs(time)
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
    )

    const duration = dayjs.duration(endDate.diff(startDate))
    const milliseconds = Math.floor(duration.asMilliseconds())

    const days = Math.round(milliseconds / 86400000)
    const hours = Math.round((milliseconds % 86400000) / 3600000)
    let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000)
    const seconds = Math.round(
      (((milliseconds % 86400000) % 3600000) % 60000) / 1000
    )

    if (seconds < 30 && seconds >= 0) {
      minutes += 1
    }

    return {
      days: days.toString().length < 2 ? '0' + days : days,
      hours: hours.toString().length < 2 ? '0' + hours : hours,
      minutes: minutes.toString().length < 2 ? '0' + minutes : minutes,
      seconds: seconds.toString().length < 2 ? '0' + seconds : seconds
    }
  },
  isset (obj) {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === 'object' || Array.isArray(obj)) {
        return Object.keys(obj).length
      } else {
        return obj.toString().length
      }
    }

    return false
  },
  toRaw (obj) {
    return JSON.parse(JSON.stringify(obj))
  },
  randomNumbers (from, to, length) {
    const numbers = [0]
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to))
    }

    return numbers
  },
  liclogs (data) {
    const arrayName = data.tableName.split(/(?=[A-Z])/)
    let nombre = ''
    for (let i = 1; i < arrayName.length; i++) {
      nombre += arrayName[i]
    }
    if (data.BeforeTextJson === null) {
      return `Creación en ${nombre}`
    } else if (JSON.parse(data.AfterTextJson).deletedAt) {
      return `Eliminado en ${nombre}`
    } else {
      return `Actualización en ${nombre}`
    }
  },
  logsColumna (nombre) {
    switch (nombre) {
      case 'createdBy':
        return 'Creado por'
      case 'updatedBy':
        return 'Actualizado por'
      case 'createdAt':
        return 'Creado en'
      case 'updatedAt':
        return 'Actualizado en'
      case 'deletedAt':
        return 'Eliminado en'
      case 'deletedBy':
        return 'Eliminado por'
      case 'licitacionId':
        return 'Licitacion'
      case 'licCarpetaId':
        return 'Carpeta'
      case 'documentoId':
        return 'Documento'
      case 'path':
        return 'Ruta'
      case 'responsableId':
        return 'Responsable'
      case 'usercarga':
        return 'Usuario Carga'
      case 'fechacarga':
        return 'Fecha Carga'
      case 'pathConsolidado':
        return 'Ruta'
      default:
        return nombre
    }
  },
  capitalize (words) {
    const array = words.split(' ')
    const lowerArray = array.map(item => {
      item = item.toLowerCase()
      item = item.charAt(0).toUpperCase() + item.slice(1)
      return item
    })
    return lowerArray.join(' ')
  },
  base64String (f) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = (() => {
        return function (e) {
          resolve(window.btoa(e.target.result))
        }
      })(f)
      reader.readAsBinaryString(f)
    })
  },
   xxsInput () {
     setTimeout(() => {
       const obj = document.getElementsByClassName('p-dropdown-filter')
       for (let i = 0; i < obj.length; i++) {
         obj[i].classList.add('p-inputtext-xxs')
       }
     }, 80)
  },
  xxsMultiSelectInput () {
     setTimeout(() => {
       const obj = document.getElementsByClassName('p-multiselect-filter')
       for (let i = 0; i < obj.length; i++) {
         obj[i].classList.add('p-inputtext-xxs')
       }
     }, 50)
  }
}

const install = app => {
  app.config.globalProperties.$h = helpers
}

export { install as default, helpers as helper }

import { get } from 'lodash'
import Cookies from 'js-cookie'
const state = () => {
  return {
    user: JSON.parse(Cookies.get('user') || '{}'),
    cancelTokens: [],
    permissions: [],
    people: {
      id: null,
      type_document_id: null,
      number_document: null,
      firt_name: null,
      last_name: null,
      full_name: null,
      email: null
    }
  }
}

// getters
const getters = {
  getToken: state => get(state, 'user.token', false),
  isAdmin: state => get(state, 'user.admin', false),
  isConfig: state => get(state, 'user.config', false),
  user: state => get(state, 'user', false),
  getCancelTokens: state => state.cancelTokens,
  getPermissions: state => state.permissions,
  getPeople: state => state.people
}

// actions
const actions = {
  setUser ({ commit }, user) {
    commit('SET_USER', { user })
  },
  executeCancelTokens ({ state, commit }) {
    state.cancelTokens.forEach((request, i) => {
      if (request.cancel) {
        request.cancel('abortado por cambio de pagina')
      }
    })
    commit('clearCancelTokens')
  }
}

// mutations
const mutations = {
  SET_USER (state, { user }) {
    Cookies.set('user', user)
    state.user = user
  },
  LOGOUT (state) {
    state.user = null
    Cookies.remove('user')
  },
  pushCancelTokens (state, payload) {
    state.cancelTokens.push(payload)
  },
  clearCancelTokens (state) {
    state.cancelTokens = []
  },
  setPermissions (state, payload) {
    state.permissions = payload
  },
  setPeople (state, payload) {
    state.people = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<template>
  <div class="mt-2 mb-2">
    <h1 class="font-bold text-lg">Caja</h1>
  </div>

  <div class="box mb-4">
    <div class="flex pl-4 pt-4 gap-2">
      <font-awesome-icon icon="hand-holding-usd" style="width: 25px; height: 25px;color: #1C3FAA"/>
      <span class="text-base font-bold" style="color: #1C3FAA">Informacion caja</span>
    </div>
    <div class="grid lg:grid-cols-3 xl:grid-cols-5 grid-cols-1 gap-2 p-4">
      <span class="flex gap-2 items-center"><span class="font-bold">Saldo Caja: </span>{{$h.formatCurrency(getCashBalance,0)}}</span>
      <span class="flex gap-2 items-center"><span class="font-bold">Total Ingresos: </span>{{$h.formatCurrency(getTotalIncomes,0)}}</span>
<!--      <span class="flex gap-2 items-center"><span class="font-bold">Saldo Inicial: </span>{{$h.formatCurrency(getInitialBalance,0)}}</span>-->
      <span class="flex gap-2 items-center"><span class="font-bold">Total Egresos: </span>{{$h.formatCurrency(getTotalExpenses,0)}}</span>
      <!--
      <span class="flex gap-2 items-center"><span class="font-bold">Fecha: </span>
        <Calendar id="basic" v-model="date" autocomplete="off" @date-select="onChangeDate" />
      </span>
      -->
      <Button label="Reporte"
              icon="pi pi-download"
              class="mr-2 p-button-info"
              @click="downloadReport"
      />
    </div>
  </div>
</template>

<script>
import storeHeader from '../../stores/storeHeader'
import { computed, onMounted, ref } from 'vue'

export default {
  name: 'PettyCashHomeSummary',
  setup () {
    const getCashBalance = computed(() => storeHeader.getters.getCashBalance)
    const getTotalIncomes = computed(() => storeHeader.getters.getTotalIncomes)
    const getTotalExpenses = computed(() => storeHeader.getters.getTotalExpenses)
    const getFilterDate = computed(() => storeHeader.getters.getFilterDate)
    const date = ref(getFilterDate.value)

    const onChangeDate = (event) => {
      storeHeader.commit('setFilterDate', event.value)
      storeHeader.dispatch('gettingCashBalance')
      storeHeader.dispatch('gettingTotalIncomes')
      storeHeader.dispatch('gettingTotalExpenses')
    }

    const downloadReport = () => {
      storeHeader.dispatch('downloadReport').then((url) => {
        window.open(url, '_blank')
      })
    }

    onMounted(() => {
      storeHeader.dispatch('gettingCashBalance')
      storeHeader.dispatch('gettingTotalIncomes')
      storeHeader.dispatch('gettingTotalExpenses')
    })
    return {
      getCashBalance,
      getTotalIncomes,
      getTotalExpenses,
      date,
      onChangeDate,
      downloadReport
    }
  }
}
</script>

<style scoped>

</style>

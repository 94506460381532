import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '../../../middleware/securityAccess'
export default [
  {
    path: '/dailytech',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth, securityAccess]),
    meta: {
      access: ['daily-tech']
    },
    children: [
      {
        path: 'dashboard/operacion',
        name: 'daily-tech.operacion',
        component: () => import('./views/comercial').then(m => m || m)
      },
      {
        path: 'dashboard/informe-ejecucion',
        name: 'daily-tech.ejecucion',
        component: () => import('./views/ejecucion').then(m => m || m)
      }
    ]
  }
]

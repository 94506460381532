import { createStore } from 'vuex'
import storeSecurity from '@/apps/system/security/store'
import storePettyCash from '@/apps/pharmasan/pettyCash/store'
import storeLicitaciones from '@/apps/pharmasan/licitaciones/store'
import storelicitacionesIndicadoresValores from '@/apps/pharmasan/licitaciones/views/licIndicadoresValores/store/licIndVlrFilters.store'
import controlEnvioStorage from '@/apps/pharmasan/compras/logistica/store/controlEnviosStorage'
import modulesLocal from './modules'

const modules = {
  ...modulesLocal,
  ...storeSecurity,
  storePettyCash,
  storeLicitaciones,
  storelicitacionesIndicadoresValores,
  controlEnvioStorage
}

const store = createStore({
  modules
})

export function useStore () {
  return store
}

export default store

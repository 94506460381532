import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '../../../../../../middleware/securityAccess'
export default
[
  {
    path: 'catalogo/:id',
    name: 'pharmasan.compras.proveedores.catalogo',
    meta: {
      access: ['pharmasan.compras.proveedores.catalogo']
    },
    beforeEnter: VueRouterMultiguard([auth, securityAccess]),
    activeMenu: 'pharmasan.compras.proveedores',
    component: () => import('../views/index.vue').then(m => m || m)
  },
  {
    path: 'catalogo/producto/:id?',
    name: 'pharmasan.compras.proveedores.catalogo.producto',
    meta: {
      access: ['pharmasan.compras.proveedores.catalogo.producto']
    },
    beforeEnter: VueRouterMultiguard([auth, securityAccess]),
    activeMenu: 'pharmasan.compras.proveedores',
    component: () => import('../views/productCatalogo.vue').then(m => m || m)
  }
]

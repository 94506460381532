<template>
  <div>
    <!--    <DarkModeSwitcher />-->
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img
              alt=""
              class="w-6"
              :src="require(`@/assets/images/logo.svg`)"
            />
            <span class="text-white text-lg ml-3">
              Pharmasan
            </span>
          </a>
          <div class="my-auto">

          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->

        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <form
            class="validate-form my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
            @submit.prevent="login">

            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              Inicio de sesión
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              <!-- A few more clicks to sign in to your account. Manage all your
              e-commerce accounts in one place -->
            </div>
            <div class="intro-x mt-8">
              <input
                v-model.trim="validate.username.$model"
                type="text"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block"

                placeholder="Usuario"
              />
              <input
                v-model.trim="validate.password.$model"
                type="password"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                placeholder="Contraseña"
              />
            </div>
            <div
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
            >
              <div class="flex items-center mr-auto">
                <!-- <input
                  id="remember-me"
                  type="checkbox"
                  class="form-check-input border mr-2"
                /> -->
                <!-- <label class="cursor-pointer select-none" for="remember-me" >Remember me</label > -->
              </div>
              <!-- <a href="">Forgot Password?</a> -->
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button type="submit"
                class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
              >
                Iniciar sesión
              </button>
              <!-- <button
                class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top"
              >
                Sign up
              </button> -->
            </div>
            <div
              class="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left"
            >
              Al iniciar sesión se comprende que acepta todos los <a class="text-theme-1 dark:text-theme-10">términios y condiciones.</a>
              <br/>
              Este software es de uso privado
            </div>
          </form>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import cash from 'cash-dom'
import { auth } from './hooks'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup () {
    const router = useRouter()
    const store = useStore()
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })

    return { ...auth({ router, store }) }
  }
})
</script>

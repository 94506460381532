import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '../../../../middleware/securityAccess'
export default [
  {
    path: '/ut-segupharma/ventas',
    name: 'ut-segupharma.ventas',
    redirect: { name: 'ut-segupharma.ventas.ov' },
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: 'ov',
        name: 'ut-segupharma.ventas.ov',
        component: () => import('../views/ordenes/index.vue'),
        redirect: { name: 'ut-segupharma.ventas.ov.listado' },
        beforeEnter: [securityAccess],
        meta: {
          access: [
            'ut-segupharma.ventas.ov'
          ]
        },
        children: [
          {
            path: 'crear',
            name: 'ut-segupharma.ventas.ov.crear',
            component: () => import('../views/ordenes/crear/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.ov.crear'
              ]
            }
          },
          // PERMISO REEMPLAZAR MD EN EL EDITAR ---> ut-segupharma.ventas.ov.editar.reemplazar-md
          // PERMISO CREAR O EDITAR OV CON FECHAS FUTURAS DE CONTABILIDAD ---> ut-segupharma.ventas.ov.config_global.fecha_contabilidad
          {
            path: 'editar/:id?',
            name: 'ut-segupharma.ventas.ov.editar',
            component: () => import('../views/ordenes/editar/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.ov.editar'
              ]
            }
          },
          {
            path: 'listado',
            name: 'ut-segupharma.ventas.ov.listado',
            component: () => import('../views/ordenes/listado'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.ov.listado'
              ]
            }
          },
          {
            path: 'ver/:id?',
            name: 'ut-segupharma.ventas.ov.ver',
            component: () => import('../views/ordenes/ver/index.vue'),
            beforeEnter: [securityAccess],
            redirect: { name: 'ut-segupharma.ventas.ov.ver.tabla' },
            meta: {
              access: [
                'ut-segupharma.ventas.ov.ver'
              ],
              activeMenu: 'ut-segupharma.ventas.ov.listado'
            },
            children: [
              {
                path: 'tabla',
                name: 'ut-segupharma.ventas.ov.ver.tabla',
                beforeEnter: [securityAccess],
                component: () => import('../views/ordenes/ver/components/tabla.vue'),
                meta: {
                  access: [
                    'ut-segupharma.ventas.ov.ver'
                  ],
                  activeMenu: 'ut-segupharma.ventas.ov.listado'
                }
              },
              {
                path: 'mapa-relaciones',
                name: 'ut-segupharma.ventas.ov.ver.mapa-relaciones',
                beforeEnter: [securityAccess],
                component: () => import('../views/ordenes/ver/components/mapa-relaciones.vue'),
                meta: {
                  access: [
                    'ut-segupharma.ventas.ov.ver'
                  ],
                  activeMenu: 'ut-segupharma.ventas.ov.listado'
                }
              },
              {
                path: 'historial',
                name: 'ut-segupharma.ventas.ov.ver.historial',
                beforeEnter: [securityAccess],
                component: () => import('../views/ordenes/ver/components/historial.vue'),
                meta: {
                  access: [
                    'ut-segupharma.ventas.ov.ver'
                  ],
                  activeMenu: 'ut-segupharma.ventas.ov.listado'
                }
              },
              {
                path: 'devolucion',
                name: 'ut-segupharma.ventas.ov.ver.devolucion',
                beforeEnter: [securityAccess],
                component: () => import('../views/ordenes/ver/components/devolucion.vue'),
                meta: {
                  access: [
                    'ut-segupharma.ventas.ov.ver'
                  ],
                  activeMenu: 'ut-segupharma.ventas.ov.listado'
                }
              }
            ]
          },
          {
            path: 'sync-sap',
            name: 'ut-segupharma.ventas.ov.sync-sap',
            component: () => import('../views/ordenes/syncEntregasMasivas/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.ov.sync-sap'
              ]
            }
          }
        ]
      },
      {
        path: 'configuracion',
        name: 'ut-segupharma.ventas.configuracion',
        component: () => import('../views/ordenes/configuracion/index.vue'),
        redirect: { name: 'ut-segupharma.ventas.configuracion.home' },
        beforeEnter: [securityAccess],
        meta: {
          access: [
            'ut-segupharma.ventas.configuracion'
          ]
        },
        children: [
          {
            path: '',
            name: 'ut-segupharma.ventas.configuracion.home',
            component: () => import('../views/ordenes/configuracion/views/home.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'tabla-ov',
            name: 'ut-segupharma.ventas.configuracion.tabla-ov',
            component: () => import('../views/ordenes/configuracion/views/tabla_crear_ov.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.tabla-ov'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'bodegas-usuarios',
            name: 'ut-segupharma.ventas.configuracion.bodegas-usuarios',
            component: () => import('../views/ordenes/configuracion/views/bodegas_usuarios.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.bodegas-usuarios'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'articulos',
            name: 'ut-segupharma.ventas.configuracion.articulos',
            component: () => import('../views/ordenes/configuracion/articulos/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.articulos'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'articulos/crear',
            name: 'ut-segupharma.ventas.configuracion.articulos.crear',
            component: () => import('../views/ordenes/configuracion/articulos/views/crear.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.articulos.crear'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'articulos/editar/:id',
            name: 'ut-segupharma.ventas.configuracion.articulos.editar',
            component: () => import('../views/ordenes/configuracion/articulos/views/crear.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.articulos.editar'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'bodegas',
            name: 'ut-segupharma.ventas.configuracion.bodegas',
            component: () => import('../views/ordenes/configuracion/bodegas/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.bodegas'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'bodegas/crear',
            name: 'ut-segupharma.ventas.configuracion.bodegas.crear',
            component: () => import('../views/ordenes/configuracion/bodegas/views/crear.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.bodegas.crear'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'bodegas/editar/:id',
            name: 'ut-segupharma.ventas.configuracion.bodegas.editar',
            component: () => import('../views/ordenes/configuracion/bodegas/views/crear.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.bodegas.editar'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'modalidad-contrato',
            name: 'ut-segupharma.ventas.configuracion.modalidad-contrato',
            component: () => import('../views/ordenes/configuracion/modalidad-contrato/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.modalidad-contrato'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'modalidad-contrato/crear',
            name: 'ut-segupharma.ventas.configuracion.modalidad-contrato.crear',
            component: () => import('../views/ordenes/configuracion/modalidad-contrato/views/crear.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.modalidad-contrato.crear'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'modalidad-contrato/editar/:id',
            name: 'ut-segupharma.ventas.configuracion.modalidad-contrato.editar',
            component: () => import('../views/ordenes/configuracion/modalidad-contrato/views/crear.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.modalidad-contrato.editar'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'motivo-autorizacion',
            name: 'ut-segupharma.ventas.configuracion.motivo-autorizacion',
            component: () => import('../views/ordenes/configuracion/motivo-autorizacion/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.motivo-autorizacion'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'ov-cliente',
            name: 'ut-segupharma.ventas.configuracion.ov-cliente',
            component: () => import('../views/ordenes/configuracion/ov-cliente/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.ov-cliente'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'pacientes',
            name: 'ut-segupharma.ventas.configuracion.pacientes',
            component: () => import('../views/ordenes/configuracion/pacientes/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.pacientes'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'pacientes/crear',
            name: 'ut-segupharma.ventas.configuracion.pacientes.crear',
            component: () => import('../views/ordenes/configuracion/pacientes/views/crear.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.pacientes.crear'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'pacientes/editar/:id',
            name: 'ut-segupharma.ventas.configuracion.pacientes.editar',
            component: () => import('../views/ordenes/configuracion/pacientes/views/crear.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.pacientes.editar'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'ips',
            name: 'ut-segupharma.ventas.configuracion.ips',
            component: () => import('../views/ordenes/configuracion/ips/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.ips'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'localizacion',
            name: 'ut-segupharma.ventas.configuracion.localizacion',
            component: () => import('../views/ordenes/configuracion/localizacion/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.localizacion'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'medicos',
            name: 'ut-segupharma.ventas.configuracion.medicos',
            component: () => import('../views/ordenes/configuracion/medicos/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.medicos'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'diagnosticos',
            name: 'ut-segupharma.ventas.configuracion.diagnosticos',
            component: () => import('../views/ordenes/configuracion/diagnosticos/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.diagnosticos'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'frecuencias',
            name: 'ut-segupharma.ventas.configuracion.frecuencias',
            component: () => import('../views/ordenes/configuracion/frecuencias/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.frecuencias'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'duracion-tratamiento',
            name: 'ut-segupharma.ventas.configuracion.duracion-tratamiento',
            component: () => import('../views/ordenes/configuracion/duracion-tratamiento/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.duracion-tratamiento'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'seguimiento-entregas',
            name: 'ut-segupharma.ventas.configuracion.seguimiento-entregas',
            component: () => import('../views/ordenes/configuracion/seguimiento-entregas/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.seguimiento-entregas'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'regimen',
            name: 'ut-segupharma.ventas.configuracion.regimen',
            component: () => import('../views/ordenes/configuracion/regimen/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.regimen'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'calculo-cuota',
            name: 'ut-segupharma.ventas.configuracion.calculo-cuota',
            component: () => import('../views/ordenes/configuracion/calculo-cuota/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.calculo-cuota'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          },
          {
            path: 'config-sap',
            name: 'ut-segupharma.ventas.configuracion.config-sap',
            component: () => import('../views/ordenes/configuracion/config-sap/views/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'ut-segupharma.ventas.configuracion.calculo-cuota'
              ],
              activeMenu: 'ut-segupharma.ventas.configuracion'
            }
          }
        ]
      }
    ]
  }
]

<template>
  <div>
    <div class="flex justify-center">
      <span class="font-bold text-2xl"> Crear nuevo informe ejecutivo</span>
    </div>
    <div class="pt-4">
      <formCreateInformeEjecutivo />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'createInformeEjecutivo',
  components: {
    formCreateInformeEjecutivo: defineAsyncComponent(() => import('../../components/create/formCreate.vue'))
  }
}
</script>

<style scoped>

</style>

<template>
  <Toast />
  <Toolbar class="box mt-4 p-p-2 flex sm:flex-row sm:w-auto flex-col w-full">
    <template #start>
      <back-button />
    </template>
    <template #end>
      <div class="flex sm:flex-row flex-col">

      </div>
    </template>
  </Toolbar>

  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">
        <div class="flex flex-col sm:flex-row justify-between items-center p-3 border-b border-gray-200 dark:border-dark-5" style="border-top-left-radius: 6px; border-top-right-radius: 6px;  border-top-color: #037ABC; border-top-width: 8px;">
          <h2 class="font-medium text-base text-lg">Información Producto Financiero</h2>
          <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-dark-3 bg-gray-300 rounded">{{ financialProduct.name }}</span>
        </div>
        <div class="p-5">
          <div class="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-6">
            <div class="col-span-1 flex">
              <div class="symbol symbol-50 symbol-light-danger mr-5">
                <span class="symbol-label">
                  <font-awesome-icon class="text-2xl" icon="university" />
                </span>
              </div>
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3">
                <span class="text-gray-500 text-sm">Banco</span>
                <span class="text-gray-700 font-bold text-lg">{{ financialProduct.bank }}</span>
              </div>
            </div>

            <div class="col-span-1 flex">
              <div class="symbol symbol-50 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <font-awesome-icon class="text-2xl" icon="credit-card" />
                </span>
              </div>
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3">
                <span class="text-gray-500 text-sm">Tipo de Obligación</span>
                <span class="text-gray-700 font-bold text-lg">{{ financialProduct.typeObligation }}</span>
              </div>
            </div>
            <div class="col-span-1 flex">
              <div class="symbol symbol-50 symbol-light-dark mr-5">
                <span class="symbol-label">
                  <font-awesome-icon class="text-2xl" icon="hashtag" />
                </span>
              </div>
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3">
                <span class="text-gray-500 text-sm">Número de la Obligación</span>
                <span class="text-gray-700 font-bold text-lg">{{ financialProduct.obligationNumber }}</span>
              </div>
            </div>
            <div class="col-span-1 flex">
              <div class="symbol symbol-50 symbol-light-success mr-5">
                <span class="symbol-label">
                  <font-awesome-icon class="text-2xl" icon="money-bill" />
                </span>
              </div>
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3">
                <span class="text-gray-500 text-sm">Desembolso</span>
                <span class="text-gray-700 font-bold text-lg">{{ $h.formatCurrency(parseInt(financialProduct.disbursement)) }}</span>
              </div>
            </div>
            <div class="col-span-1 flex">
              <div class="symbol symbol-50 symbol-light-warning mr-5">
                <span class="symbol-label">
                  <font-awesome-icon class="text-2xl" icon="calendar-alt" />
                </span>
              </div>
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3">
                <span class="text-gray-500 text-sm">Fecha Desembolso</span>
                <span class="text-gray-700 font-bold text-lg">{{ financialProduct.disbursementDate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">
        <div class="intro-x">
          <DataTable
            showGridlines
            ref="dt"
            :lazy="true"
            :value="paymentsMade"
            class="p-datatable-sm p-2 text-sm z-50"
            :rows="10"
            :filters="filters"
            :scrollable="true"
            scrollHeight="520px"
            breakpoint="960px"
            responsiveLayout="stack"
            dataKey="virtualId"
            v-model:expandedRows="expandedRows"
            @rowExpand="onRowExpand"
            @rowCollapse="onRowCollapse"
          >
            <template #header>
              <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                <h3 class="font-medium p-mb-2 p-m-md-0 p-as-md-center text-lg">Listado de Pagos Realizados</h3>
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                </span>
              </div>
            </template>

            <!-- Columnas -->
            <Column :expander="true" headerStyle="width: 3rem" />
            <Column field="status" header="Estado" :sortable="false" style="text-align:center;">
              <template #body="{data}">
                <Badge :value="data.status" class="p-mr-2 text-center" :severity="data.status === 'Pendiente' ? 'warning' : 'success'"></Badge>
              </template>
            </Column>
            <Column field="nameBank" header="Banco" :sortable="false" style="text-align:center;"></Column>
            <Column field="obligationType" header="Tipo de Obligación" :sortable="false" style="text-align:center;"></Column>
            <Column field="obligationNumber" header="# Obligacion" :sortable="false" style="text-align:center;"></Column>
            <Column field="installmentNumber" header="# Cuota" :sortable="false" style="text-align:center;"/>
            <Column field="totalQuotas" header="# Total cuotas" :sortable="false" style="text-align:center;"/>
            <Column field="paymentDate" header="Fecha Cuota" :sortable="false" style="text-align:center;"></Column>
            <Column field="debit" header="Valor Cuota" :sortable="false" style="text-align:right;">
              <template #body="{data}">
                {{ $h.formatCurrency(parseInt(data.debit),2) }}
              </template>
            </Column>
            <Column field="createdAt" header="Fecha pago" :sortable="false" style="text-align:center;"></Column>
            <Column field="credit" header="Valor pago" :sortable="false" style="min-width:8rem; text-align:right;">
              <template #body="{data}">
                {{ $h.formatCurrency(parseInt(data.credit),2) }}
              </template>
            </Column>
            <Column field="registerBy" header="Pagado por" :sortable="false" style="min-width:8rem; text-align:right;"/>
            <Column :exportable="false" header="Acciones" style="text-align: center; width: 5rem">
              <template #body="{data}">
                <Button
                  icon="pi pi-file-pdf"
                  class="p-button-rounded p-button-danger p-mr-2"
                  v-tooltip.top="'PDF del Pago'"
                  @click="exportPaymentPdf(data.productId, data.key)"
                />
              </template>
            </Column>
            <template #expansion>
              <div class="orders-subtable w-full">
                <h5>Detalle del pago</h5>
                <DataTable :value="reportDetails" responsiveLayout="scroll" class="p-datatable-sm p-2 text-sm">
                  <Column field="acctname" header="Cuenta SAP"/>
                  <Column field="acctcode" header="N° Cuenta SAP"/>
                  <Column field="debit" header="Valor amortización" :sortable="false" style="min-width:8rem; text-align:right;">
                    <template #body="{data}">
                      {{ $h.formatCurrency(parseInt(data.debit),2) }}
                    </template>
                  </Column>
                  <Column field="credit" header="Valor cuota" :sortable="false" style="min-width:8rem; text-align:right;">
                    <template #body="{data}">
                      {{ $h.formatCurrency(parseInt(data.credit),2) }}
                    </template>
                  </Column>
                </DataTable>
              </div>
            </template>
          </DataTable>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { onMounted, reactive, ref } from 'vue'
import findFinancialProduct from '../../../../services/findFinancialProduct'
import { useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'
// import { useLoading } from 'vue3-loading-overlay'
import { FilterMatchMode } from 'primevue/api'
import listPaymentsMade from '../../../../services/listPaymentsMade'
import reporteGeneralDetails from '../../../../services/reporteGeneralDetails'
import axios from 'axios'
import { warning } from '../../../../../../../../libs/mensajes'

export default {
  name: 'tableAmortizations',
  setup () {
    const route = useRoute()
    const toast = useToast()
    // const loader = useLoading()
    const paymentsMade = ref()
    const financialProduct = reactive({
      name: '',
      bank: '',
      typeObligation: '',
      obligationNumber: '',
      disbursement: '',
      disbursementDate: ''
    })
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })
    const expandedRows = ref([])
    const reportDetails = ref([
      { id: '1' },
      { id: '2' }
    ])

    /* Obtener el producto financiero para editar */
    const fetchFinancialProduct = (id) => {
      return findFinancialProduct({ id }).then(({ status, data: response }) => {
        if (status !== 200) return
        financialProduct.id = response.result.id
        financialProduct.name = response.result.name
        financialProduct.bank = response.result.AmortizationsBank.name
        financialProduct.typeObligation = response.result.AmortizationsBankQuota.creditLine.name
        financialProduct.obligationNumber = response.result.obligationNumber
        financialProduct.disbursement = response.result.disbursement
        financialProduct.disbursementDate = response.result.disbursementDate
      })
    }

    /**/
    const fetchListPaymentMade = (productId) => {
      // loader.show()
      return listPaymentsMade({ productId }).then(({ status, data: response }) => {
        if (status !== 200) {
          // loader.hide()
          toast.add({ severity: 'error', summary: 'Error', detail: 'Problemas al listar los pagos realizados, consulte con el administrador del sistema', life: 3000 })
        } else {
          if (response.result.length > 0) {
            // loader.hide()
            paymentsMade.value = response.result.map((e, i) => { return { virtualId: i, ...e } }) ?? []
          } else {
            // loader.hide()
            toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'El producto no tiene obligaciones registradas con pago', life: 3000 })
          }
        }
      })
    }

    const getReporteDetails = async ({ productId, key, status }) => {
      const queryParams = { productId, key, status: status === 'Pendiente' ? 0 : 1 }
      const result = await reporteGeneralDetails(queryParams)
      reportDetails.value = result ?? []
    }

    const onRowExpand = ({ data }) => {
      getReporteDetails(data)
      expandedRows.value = [data]
    }
    const onRowCollapse = () => {
      expandedRows.value = []
    }

    /**/
    const exportPaymentPdf = (productId, key) => {
      const filters = reactive({
        productId,
        key
      })
      const url = `${process.env.VUE_APP_MICROSERVICE_API_AMORTIZATIONS}/export-payment-made-pdf`
      const res = axios.getUri({ url, params: filters })
      if (filters.productId !== null) {
        window.open(res, '_blank')
      } else {
        warning('No hay un producto')
      }
    }

    onMounted(() => {
      const { id } = route.params
      Promise.all([
        fetchFinancialProduct(id),
        fetchListPaymentMade(id)
      ])
    })

    return {
      financialProduct,
      filters,
      paymentsMade,
      onRowExpand,
      onRowCollapse,
      reportDetails,
      expandedRows,
      exportPaymentPdf
    }
  }

}
</script>

<style lang="scss">
.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.42rem;
}
.symbol .symbol-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #3F4254;
  background-color: #F3F6F9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.42rem;
}
.symbol .symbol-badge {
  position: absolute;
  border: 2px solid #ffffff;
  border-radius: 100%;
  top: 0;
  right: 0;
}
.symbol.symbol-circle {
  border-radius: 50%;
}
.symbol.symbol-circle > img {
  border-radius: 50%;
}
.symbol.symbol-circle .symbol-label {
  border-radius: 50%;
}
.symbol.symbol-light-primary .symbol-label {
  background-color: #E1F0FF;
  color: #3699FF;
}
.symbol.symbol-light-secondary .symbol-label {
  background-color: #EBEDF3;
  color: #E4E6EF;
}
.symbol.symbol-light-success .symbol-label {
  background-color: #C9F7F5;
  color: #1BC5BD;
}
.symbol.symbol-light-info .symbol-label {
  background-color: #EEE5FF;
  color: #8950FC;
}
.symbol.symbol-light-warning .symbol-label {
  background-color: #FFF4DE;
  color: #FFA800;
}
.symbol.symbol-light-danger .symbol-label {
  background-color: #FFE2E5;
  color: #F64E60;
}
.symbol.symbol-light-light .symbol-label {
  background-color: #F3F6F9;
  color: #F3F6F9;
}
.symbol.symbol-light-dark .symbol-label {
  background-color: #D1D3E0;
  color: #181C32;
}
.symbol.symbol-light-white .symbol-label {
  background-color: #ffffff;
  color: #ffffff;
}

.symbol.symbol-50 > img {
  width: 100%;
  max-width: 50px;
  height: 50px;
}
.symbol.symbol-50 .symbol-label {
  width: 50px;
  height: 50px;
}
.symbol.symbol-50 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}
.symbol.symbol-50 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}
.custom-style {
  .p-dialog-header {
    color: #5E8F32 !important;
  }
}
</style>

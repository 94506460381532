import Layouts from '../../../layouts/main-menu/Main.vue'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '../../../middleware/securityAccess'
import proveedores from './proveedores/router/index.js'
export default [
  {
    path: '/pharmasan/compras',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth, securityAccess]),
    children: [
      {
        path: 'ofertas',
        name: 'pharmasan.compras.ofertas',
        meta: {
          access: ['pharmasan.compras.ofertas']
        },
        activeMenu: 'pharmasan.compras.ofertas',
        component: () => import('./ofertas/views/index').then(m => m || m)
      },
      /* carta agotado rutas */
      {
        path: 'carta-agotado',
        name: 'pharmasan.compras.carta-agotado',
        meta: {
          access: [
            'pharmasan.compras.carta-agotado'
          ]
        },
        component: () => import('./cartaAgotadoCompras/views/listado/index.vue').then(m => m || m)
      },
      {
        path: 'crear-carta',
        name: 'pharmasan.compras.carta-agotado.crear-carta',
        meta: {
          access: [
            'pharmasan.compras.carta-agotado'
          ],
          activeMenu: 'pharmasan.compras.carta-agotado'
        },
        component: () => import('./cartaAgotadoCompras/views/crear/index.vue')
      },
      /* proveedores */
      {
        ...proveedores
      }
    ]
  },
  {
    path: '/pharmasan/compras/logistica',
    name: 'pharmasan.compras.logistica',
    component: Layouts,
    meta: {
      access: [
        'pharmasan.compras.logistica'
      ],
      activeMenu: 'pharmasan.compras.logistica'
    },
    beforeEnter: VueRouterMultiguard([auth]),
    redirect: { name: 'pharmasan.compras.logistica.recepcion-pedidos' },
    children: [
      {
        path: 'cargue-facturas',
        name: 'pharmasan.compras.logistica.cargue-facturas',
        redirect: { name: 'pharmasan.compras.logistica.cargue-facturas.listado' },
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          title: 'Cargue de facturas',
          activeMenu: 'pharmasan.compras.logistica.cargue-facturas',
          access: [
            'pharmasan.compras.logistica.cargue-facturas'
          ]
        },
        children: [
          {
            path: 'listado',
            name: 'pharmasan.compras.logistica.cargue-facturas.listado',
            component: () => import('./logistica/views/cargue-facturas/index.vue'),
            meta: {
              title: 'Cargue Facturas - Listado',
              activeMenu: 'pharmasan.compras.logistica.cargue-facturas',
              access: [
                'pharmasan.compras.logistica.cargue-facturas.listado'
              ]
            }
          },
          {
            path: 'xml',
            name: 'pharmasan.compras.logistica.cargue-facturas.xml',
            component: () => import('./logistica/views/cargue-facturas/xml/index.vue'),
            meta: {
              activeMenu: 'pharmasan.compras.logistica.cargue-facturas',
              access: [
                'pharmasan.compras.logistica.cargue-facturas.xml'
              ]
            }
          }
        ]
      },
      {
        path: 'novedades-proveedores-mx',
        name: 'pharmasan.compras.logistica.novedades-proveedores-mx',
        redirect: { name: 'pharmasan.compras.logistica.novedades-proveedores-mx.listado' },
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          title: 'Novedades Proveedores Mx',
          activeMenu: 'pharmasan.compras.logistica.novedades-proveedores-mx',
          access: [
            'pharmasan.compras.logistica.novedades-proveedores-mx'
          ]
        },
        children: [
          {
            path: 'listado',
            name: 'pharmasan.compras.logistica.novedades-proveedores-mx.listado',
            component: () => import('./logistica/views/novedades-provedores-mx/index.vue'),
            meta: {
              title: 'Novedades Proveedores Mx - Listado',
              activeMenu: 'pharmasan.compras.logistica.novedades-proveedores-mx',
              access: [
                'pharmasan.compras.logistica.novedades-proveedores-mx.listado'
              ]
            }
          },
          {
            path: 'crear',
            name: 'pharmasan.compras.logistica.novedades-proveedores-mx.crear',
            component: () => import('./logistica/views/novedades-provedores-mx/form/index.vue'),
            meta: {
              title: 'Novedades Proveedores Mx - Crear',
              activeMenu: 'pharmasan.compras.logistica.novedades-proveedores-mx',
              access: [
                'pharmasan.compras.logistica.novedades-proveedores-mx.crear'
              ]
            }
          },
          {
            path: 'editar/:id',
            name: 'pharmasan.compras.logistica.novedades-proveedores-mx.editar',
            component: () => import('./logistica/views/novedades-provedores-mx/form/index.vue'),
            meta: {
              title: 'Novedades Proveedores Mx - Editar',
              activeMenu: 'pharmasan.compras.logistica.novedades-proveedores-mx',
              access: [
                'pharmasan.compras.logistica.novedades-proveedores-mx.editar'
              ]
            }
          }
        ]
      },
      {
        path: 'recepcion-tecnica',
        name: 'pharmasan.compras.logistica.recepcion-tecnica',
        component: () => import('./logistica/views/recepcion/index.vue'),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        redirect: { name: 'pharmasan.compras.logistica.recepcion-tecnica.estado-recepcion' },
        meta: {
          access: [
            'pharmasan.compras.logistica.recepcion-tecnica'
          ]
        },
        children: [
          {
            path: 'estado-recepcion',
            name: 'pharmasan.compras.logistica.recepcion-tecnica.estado-recepcion',
            component: () => import('./logistica/views/recepcion/components/EstadoRecepcion/index.vue'),
            meta: {
              access: [
                'pharmasan.compras.logistica.recepcion-tecnica'
              ],
              activeMenu: 'pharmasan.compras.logistica.recepcion-tecnica'
            }
          },
          {
            path: 'recepcion/id:?',
            name: 'pharmasan.compras.logistica.recepcion-tecnica.recepcion',
            component: () => import('./logistica/views/recepcion/components/Recepcion/index.vue'),
            meta: {
              access: [
                'pharmasan.compras.logistica.recepcion-tecnica.access'
              ],
              activeMenu: 'pharmasan.compras.logistica.recepcion-tecnica'
            }
          },
          {
            path: 'novedades',
            name: 'pharmasan.compras.logistica.recepcion-tecnica.novedades',
            component: () => import('./logistica/views/recepcion/components/Novedades/index.vue'),
            meta: {
              activeMenu: 'pharmasan.compras.logistica.recepcion-tecnica'
            }
          }
        ]
      },
      {
        path: 'configuracion',
        name: 'pharmasan.compras.logistica.configuracion',
        component: () => import('./logistica/views/configuracion/index.vue'),
        redirect: { name: 'pharmasan.compras.logistica.configuracion.menu' },
        beforeEnter: VueRouterMultiguard([auth]),
        meta: {
          access: [
            'pharmasan.compras.logistica.configuracion'
          ],
          activeMenu: 'pharmasan.compras.logistica.configuracion'
        },
        children: [
          {
            path: 'menu',
            name: 'pharmasan.compras.logistica.configuracion.menu',
            component: () => import('./logistica/views/configuracion/components/Menu.vue'),
            meta: {
              access: [
                'pharmasan.compras.logistica.configuracion'
              ],
              activeMenu: 'pharmasan.compras.logistica.configuracion'
            }
          },
          {
            path: 'responsable-bodegas',
            name: 'pharmasan.compras.logistica.configuracion.bodegas',
            component: () => import('./logistica/views/configuracion/components/ResponsablesBodegas/index.vue'),
            meta: {
              access: [
                'pharmasan.compras.logistica.configuracion.bodegas'
              ],
              activeMenu: 'pharmasan.compras.logistica.configuracion'
            }
          }
        ]
      },
      {
        path: 'gestion-envios',
        name: 'pharmasan.compras.logistica.gestion-envios',
        component: () => import('./logistica/views/controlEnvios/index.vue').then(m => m || m),
        beforeEnter: VueRouterMultiguard([auth]),
        redirect: { name: 'pharmasan.compras.logistica.listar-control-envios' },
        meta: {
            access: 'pharmasan.compras.logistica.gestion-envios',
            activeMenu: 'pharmasan.compras.logistica.gestion-envios'
        },
        children: [
          {
            path: 'listar-control-envios-realizados',
            name: 'pharmasan.compras.logistica.listar-control-envios',
            component: () => import('./logistica/views/controlEnvios/realizados.vue').then(m => m || m),
            beforeEnter: VueRouterMultiguard([auth]),
            meta: {
              access: ['pharmasan.compras.logistica.listar-control-envios'],
              activeMenu: 'pharmasan.compras.logistica.listar-control-envios'
            }
          },
          {
            path: 'listar-control-envios-pendientes',
            name: 'pharmasan.compras.logistica.listar-control-envios-pendientes',
            component: () => import('./logistica/views/controlEnvios/pendientes.vue').then(m => m || m),
            beforeEnter: VueRouterMultiguard([auth]),
            meta: {
              access: ['pharmasan.compras.logistica.listar-control-envios-pendientes'],
              activeMenu: 'pharmasan.compras.logistica.listar-control-envios-pendientes'
            }
          }
        ]
      },
      {
        path: 'control-envios',
        name: 'pharmasan.compras.logistica.control-envios',
        component: () => import('./logistica/views/controlEnvios/steps.vue'),
        redirect: { name: 'pharmasan.compras.logistica.control-envios.informacion-principal' },
        beforeEnter: VueRouterMultiguard([auth]),
        meta: {
          access: [
            'pharmasan.compras.logistica.control-envio'
          ],
          activeMenu: 'pharmasan.compras.logistica.control-envio'
        },
        children: [
          {
            path: 'informacion-principal',
            name: 'pharmasan.compras.logistica.control-envios.informacion-principal',
            component: () => import('./logistica/views/controlEnvios/components/infoPrincipal.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.compras.logistica.control-envio'
              ],
              activeMenu: 'pharmasan.compras.logistica.control-envio'
            }
          },
          {
            path: 'informacion-logistica',
            name: 'pharmasan.compras.logistica.control-envios.informacion-logistica',
            component: () => import('./logistica/views/controlEnvios/components/InfoLogistica.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.compras.logistica.control-envio'
              ],
              activeMenu: 'pharmasan.compras.logistica.control-envio'
            }
          }
        ]
      },
      // }
      // {
      //   path: 'control-envio/crear',
      //   name: 'pharmasan.compras.logistica.control-envio.crear',
      //   component: () => import('./logistica/views/controlEnvio/crear.vue'),
      //   redirect: { name: 'pharmasan.compras.logistica.control-envio.crear.informacion-bodegas' },
      //   beforeEnter: VueRouterMultiguard([securityAccess]),
      //   meta: {
      //     access: [
      //       'pharmasan.compras.logistica.control-envio'
      //     ],
      //     activeMenu: 'pharmasan.compras.logistica.control-envio'
      //   },
      //   children: [
      //     {
      //       path: 'informacion-bodegas',
      //       name: 'pharmasan.compras.logistica.control-envio.crear.informacion-bodegas',
      //       component: () => import('./logistica/views/controlEnvio/components/infoBodegas.vue'),
      //       beforeEnter: VueRouterMultiguard([securityAccess]),
      //       meta: {
      //         access: [
      //           'pharmasan.compras.logistica.control-envio'
      //         ],
      //         activeMenu: 'pharmasan.compras.logistica.control-envio'
      //       }
      //     },
      //     {
      //       path: 'asignar-cajas',
      //       name: 'pharmasan.compras.compras.logistica.control-envio.crear.asignar-cajas',
      //       component: () => import('./logistica/views/controlEnvio/components/controlCajas.vue'),
      //       beforeEnter: VueRouterMultiguard([securityAccess]),
      //       meta: {
      //         access: [
      //           'pharmasan.compras.logistica.control-envio'
      //         ],
      //         activeMenu: 'pharmasan.logistica.control-envio'
      //       }
      //     },
      //     {
      //       path: 'cargar-medicamentos',
      //       name: 'pharmasan.compras.logistica.control-envio.crear.cargar-medicamentos',
      //       component: () => import('./logistica/views/controlEnvio/components/pickingMx.vue'),
      //       beforeEnter: VueRouterMultiguard([securityAccess]),
      //       meta: {
      //         access: [
      //           'pharmasan.compras.logistica.control-envio'
      //         ],
      //         activeMenu: 'pharmasan.compras.logistica.control-envio'
      //       }
      //     },
      //     {
      //       path: 'datos-envio',
      //       name: 'pharmasan.compras.logistica.control-envio.crear.datos-envio',
      //       component: () => import('./logistica/views/controlEnvio/components/datosEnvio.vue'),
      //       beforeEnter: VueRouterMultiguard([securityAccess]),
      //       meta: {
      //         access: [
      //           'pharmasan.compras.logistica.control-envio'
      //         ],
      //         activeMenu: 'pharmasan.compras.logistica.control-envio'
      //       }
      //     }
      //   ]
      // },
      {
        path: 'recepcion-pedidos',
        name: 'pharmasan.compras.logistica.recepcion-pedidos',
        component: () => import('./logistica/views/recepcionPedidos/index.vue').then(m => m || m),
        redirect: { name: 'pharmasan.compras.logistica.recepcion-pedidos.listar-pedidos' },
        meta: {
          access: 'pharmasan.compras.logistica.recepcion-pedidos',
          activeMenu: 'pharmasan.compras.logistica.recepcion-pedidos'
        },
        children: [
          {
            path: 'listar-pedidos',
            name: 'pharmasan.compras.logistica.recepcion-pedidos.listar-pedidos',
            beforeEnter: VueRouterMultiguard([auth]),
            component: () => import('./logistica/views/recepcionPedidos/components/listarPedidos.vue').then(m => m || m),
            meta: {
              access: ['pharmasan.compras.logistica.recepcion-pedidos.listar-pedidos'],
              activeMenu: 'pharmasan.compras.logistica.recepcion-pedidos'
            }
          },
          {
            path: 'listar-recepciones',
            name: 'pharmasan.compras.logistica.recepcion-pedidos.listar-recepciones',
            beforeEnter: VueRouterMultiguard([auth]),
            component: () => import('./logistica/views/recepcionPedidos/components/listarRecepciones.vue').then(m => m || m),
            meta: {
              access: ['pharmasan.compras.logistica.recepcion-pedidos.listar-recepciones'],
              activeMenu: 'pharmasan.compras.logistica.recepcion-pedidos'
            }
          },
          // esta ruta se creó para dividir lo que deben ver los usuarios de compras y los usuarios de bodega
          {
            path: 'listar-entradas-pendientes',
            name: 'pharmasan.compras.logistica.recepcion-pedidos.listar-entradas-pendientes',
            beforeEnter: VueRouterMultiguard([auth]),
            component: () => import('./logistica/views/recepcionPedidos/components/listarRecepciones.vue').then(m => m || m),
            meta: {
              access: ['pharmasan.compras.logistica.recepcion-pedidos.listar-entradas-pendientes'],
              activeMenu: 'pharmasan.compras.logistica.recepcion-pedidos'
            }
          },
          {
            path: 'listar-entradas-de-mercancia',
            name: 'pharmasan.compras.logistica.recepcion-pedidos.entradas-de-mercancia',
            beforeEnter: VueRouterMultiguard([auth]),
            component: () => import('./logistica/views/recepcionPedidos/components/listarEntradasMercancia.vue'),
            meta: {
              access: ['pharmasan.compras.logistica.recepcion-pedidos.entradas-de-mercancia'],
              activeMenu: 'pharmasan.compras.logistica.recepcion-pedidos'
            }
          },
          {
            path: 'listar-entradas-de-mercancia-devoluciones',
            name: 'pharmasan.compras.logistica.recepcion-pedidos.entradas-de-mercancia-devoluciones',
            beforeEnter: VueRouterMultiguard([auth]),
            component: () => import('./logistica/views/recepcionPedidos/components/listarEntradasMercancia.vue'),
            meta: {
              access: ['pharmasan.compras.logistica.recepcion-pedidos.entradas-de-mercancia-devoluciones'],
              activeMenu: 'pharmasan.compras.logistica.recepcion-pedidos'
            }
          },
          {
            path: 'recepcion-pedidos-xml',
            name: 'pharmasan.compras.logistica.recepcion-pedidos.xml',
            component: () => import('./logistica/views/recepcionPedidos/xml.vue').then(m => m || m),
            beforeEnter: VueRouterMultiguard([auth]),
            meta: {
              access: 'pharmasan.compras.logistica.recepcion-pedidos.xml',
              activeMenu: 'pharmasan.compras.logistica.recepcion-pedidos.xml'
            }
          },
          {
            path: 'listado-recepcion-pedidos-xml',
            name: 'pharmasan.compras.logistica.recepcion-pedidos.listado-xml',
            component: () => import('./logistica/views/recepcionPedidos/components/listarXml.vue').then(m => m || m),
            beforeEnter: VueRouterMultiguard([auth]),
            meta: {
              access: 'pharmasan.compras.logistica.recepcion-pedidos.listado-xml',
              activeMenu: 'pharmasan.compras.logistica.recepcion-pedidos.listado-xml'
            }
          },
          {
            path: 'configuracion',
            name: 'pharmasan.compras.logistica.recepcion-pedidos.configuracion',
            beforeEnter: VueRouterMultiguard([auth]),
            component: () => import('./logistica/views/recepcionPedidos/configuracion.vue'),
            meta: {
              access: ['pharmasan.compras.logistica.recepcion-pedidos.configuracion'],
              activeMenu: 'pharmasan.compras.logistica.recepcion-pedidos'
            }
          }
        ]
      },
      {
        path: 'crear-recepcion',
        name: 'pharmasan.compras.logistica.recepcion-pedidos.crear-recepcion',
        beforeEnter: VueRouterMultiguard([auth]),
        component: () => import('./logistica/views/recepcionPedidos/createRecepcion.vue'),
        meta: {
          access: ['pharmasan.compras.logistica.recepcion-pedidos.crear-recepcion'],
          activeMenu: 'pharmasan.compras.logistica.recepcion-pedidos'
        }
      },
      {
        path: 'editar-recepcion/:DocEntry',
        name: 'pharmasan.compras.logistica.recepcion-pedidos.editar-recepcion',
        beforeEnter: VueRouterMultiguard([auth]),
        component: () => import('./logistica/views/recepcionPedidos/editarRecepcion.vue'),
        meta: {
          access: ['pharmasan.compras.logistica.recepcion-pedidos.editar-recepcion'],
          activeMenu: 'pharmasan.compras.logistica.recepcion-pedidos'
        }
      },
      {
        /* DocEntry de entrada que toma como base */
        path: 'crear-devolucion/:DocEntry',
        name: 'pharmasan.compras.logistica.recepcion-pedidos.crear-devolucion',
        beforeEnter: VueRouterMultiguard([auth]),
        component: () => import('./logistica/views/recepcionPedidos/crearDevolucion.vue'),
        meta: {
          access: ['pharmasan.compras.logistica.recepcion-pedidos.editar-recepcion'],
          activeMenu: 'pharmasan.compras.logistica.recepcion-pedidos'
        }
      },
      {
        /* DocEntry del pedido que se toma como base */
        path: 'crear-oferta/:DocEntry',
        name: 'pharmasan.compras.logistica.recepcion-pedidos.crear-oferta',
        beforeEnter: VueRouterMultiguard([auth]),
        component: () => import('./ofertas/views/createOfertaCompra.vue'),
        meta: {
          activeMenu: 'pharmasan.compras.logistica.recepcion-pedidos'
        }
      }
    ]
  }
]

import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '../../../middleware/securityAccess'

export default [
  {
    path: '/utpharmedis',
    component: Layouts,
    name: 'ut-pharmedis',
    beforeEnter: VueRouterMultiguard([auth, securityAccess]),
    meta: {
      access: ['ut-pharmedis']
    },
    children: [
      {
        path: 'dashboard/operacion',
        name: 'ut-pharmedis.operacion',
        component: () => import('./views/operacion').then(m => m || m),
        meta: {
          access: ['ut-pharmedis.operacion']
        }
      },
      {
        path: 'dashboard/informe-ejecucion',
        name: 'ut-pharmedis.ejecucion',
        component: () => import('./views/ejecucion').then(m => m || m),
        meta: {
          access: ['ut-pharmedis.ejecucion']
        }
      }
    ]
  }
]

import Swal from 'sweetalert2'

const success = (text, title = 'Mensaje') => {
  Swal.fire({
    icon: 'success',
    title,
    text
  })
}
const messageSuccess = (text, title = 'Mensaje') => {
  Swal.fire({
    icon: 'success',
    title,
    text
  })
}

const error = (text, title = 'Error') => {
  Swal.fire({
    icon: 'error',
    title,
    text,
    backdrop: `
      rgba(0,0,123,0.4)
      url("/images/200.webp")
      left top
      no-repeat
    `
  })
}

const errorHtml = (title = 'Error', html) => {
  Swal.fire({
    icon: 'error',
    title,
    html
  })
}

const errorHtmlTabla = ({ title, text, table, width }) => {
  // Verificar si table es un array y tiene al menos un elemento
  if (Array.isArray(table) && table.length > 0) {
    // Crear el contenido HTML de la tabla
    const tableHtml = `
      <div>
        <p>${text}</p>
        <div class="w-full overflow-x-hidden overflow-y-auto">
          <table class="m-4 text-sm border" style="width: 44rem; border-collapse: collapse; border: 1px solid;">
            <thead class="font-bold" style="border: 1px solid;">
              <tr>
                ${Object.keys(table[0]).map(header => `<td class="border">${header}</td>`).join('')}
              </tr>
            </thead>
            ${table.map(row => `<tr class="text-left" style="border: 1px solid">${Object.values(row).map(cell => `<td class="p-1 border text-center">${cell}</td>`).join('')}</tr>`).join('')}
          </table>
        </div>
      </div>
    `

    Swal.fire({
      icon: 'error',
      title: title ?? 'Error',
      text: text ?? 'No llega',
      width: width ?? 'auto',
      html: tableHtml
    })
  } else {
    // Tratar el caso en que table no sea un array o esté vacío
    Swal.fire({
      icon: 'error',
      title: title ?? 'Error',
      text: text ?? 'Error en la estructura de la tabla'
    })
  }
}

const info = (text, title = 'Mensaje') => {
  Swal.fire({
    icon: 'info',
    title,
    text
  })
}

const warning = (text, title = 'Advertencia') => {
  Swal.fire({
    icon: 'warning',
    title,
    text
  })
}
const messageWarning = (text, title = 'Advertencia') => {
  Swal.fire({
    icon: 'warning',
    title,
    text
  })
}

const messageWarningDelete = (text = '', title = 'Estas seguro?', icon = 'question', html) => {
  return Swal.fire({
    icon,
    title,
    text,
    html,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Eliminar!',
    cancelButtonText: 'No, cancelar!'
  })
}

const confirm = (text = '', title = 'Estas seguro?', icon = 'question', html) => {
  return Swal.fire({
    icon,
    title,
    text,
    html,
    showCancelButton: true,
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'No'
  })
}
const messageConfirm = (text = '', title = 'Estas seguro?', icon = 'question', html) => {
  return Swal.fire({
    icon,
    title,
    text,
    html,
    showCancelButton: true,
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'No'
  })
}
const messageConfirmSave = (text = '', title = 'Estas seguro?', icon = 'question', html) => {
  return Swal.fire({
    icon,
    title,
    text,
    html,
    showCancelButton: true,
    confirmButtonColor: '#24963E',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Guardar!',
    cancelButtonText: 'No, cancelar!'
  })
}

export {
  success,
  confirm,
  error,
  errorHtml,
  info,
  warning,
  messageWarning,
  messageWarningDelete,
  messageSuccess,
  messageConfirm,
  messageConfirmSave,
  errorHtmlTabla
}

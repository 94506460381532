import { createI18n } from 'vue-i18n'
import store from '@/store'
import { isEmpty } from 'lodash'

const i18n = createI18n({
  globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE, // set locale
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE, // set fallback locale
  messages: {} // set locale messages
})

/**
 * @param {String} locale
 */
export async function loadMessages (locale) {
  const getLocaleMessage = i18n.global.getLocaleMessage(locale)
  if (isEmpty(getLocaleMessage)) {
    const messages = await import(`@/lang/${locale}.json`)
    i18n.global.setLocaleMessage(locale, messages)
  }

  if (i18n.global.locale !== locale) {
    i18n.global.locale = locale
  }
}

(async function () {
  await loadMessages(store.getters['lang/locale'])
})()

export default i18n

import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '../../../middleware/securityAccess'
import createInformeEjecutivo from './views/informeEjecucion/views/create/create.vue'

export default [
  {
    path: '/dashboard',
    component: Layouts,
    name: 'pharmasan.dashboard',
    beforeEnter: VueRouterMultiguard([auth, securityAccess]),
    meta: {
      access: ['pharmasan.dashboard']
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        redirect: { name: 'pharmasan.dashboard.operacion' }
      },
      {
        path: 'operacion',
        name: 'pharmasan.dashboard.operacion',
        component: () => import('./views/operacion').then(m => m || m),
        meta: {
          access: [
            'pharmasan.dashboard.operacion'
          ]
        }
      },
      {
        path: 'ejecucion',
        name: 'pharmasan.dashboard.ejecucion',
        component: () => import('./views/ejecucion').then(m => m || m),
        meta: {
          access: [
            'pharmasan.dashboard.ejecucion'
          ]
        }
      },
      {
        path: 'indicadores-clientes',
        name: 'pharmasan.dashboard.indicadores-clientes',
        component: () => import('./views/indicadoresClientes').then(m => m || m),
        meta: {
          access: [
            'pharmasan.dashboard.indicadores-clientes'
          ]
        }
      },
      {
        path: 'informe-ejecucion',
        name: 'pharmasan.dashboard.informe-ejecutivo',
        component: () => import('./views/informeEjecucion/index.vue'),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: ['pharmasan.dashboard.informe-ejecutivo']
        }
      },
      {
        path: 'informe-ejecucion/crear',
        name: 'dashboard.informe.ejecucion.create',
        component: createInformeEjecutivo,
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: ['pharmasan.dashboard.informe-ejecutivo.access'],
          activeMenu: 'pharmasan.dashboard.informe-ejecutivo'
        }
      },
      {
        path: 'informe-rips',
        name: 'pharmasan.dashboard.informe-rips.carge-rips',
        component: () => import('./views/informeRips/informeRips.vue').then(m => m || m),
        meta: {
          access: [
            'pharmasan.dashboard.informe-rips.carge-rips'
          ]
        }
      },
      {
        path: 'dashboard-rips',
        name: 'pharmasan.dashboard.informe-rips.dashboard',
        component: () => import('./views/informeRips/dashboardRips.vue'),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: [
            'pharmasan.dashboard.informe-rips.dashboard'
          ],
          activeMenu: 'pharmasan.dashboard.informe-rips.dashboard'
        }
      },
      {
        path: 'listado-rips',
        name: 'pharmasan.dashboard.informe-rips.listado',
        component: () => import('./views/informeRips/listado/index.vue'),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: [
            'pharmasan.dashboard.informe-rips.listado'
          ]
        }
      },
      {
        path: 'reportes',
        name: 'pharmasan.dashboard.reportes',
        component: () => import('./views/reportes/index.vue').then(m => m || m),
        meta: {
          access: [
            'pharmasan.dashboard.reportes'
          ]
        }
      }
    ]
  }
]

import ExpenseServices from '../services/expenseServices'
import dayjs from 'dayjs'
import { useStore } from 'vuex'

require('dayjs/locale/')

const useExpense = () => {
  const typesExpenses = [
    {
      value: null,
      name: 'Seleccione una opcion'
    },
    {
      value: 'Factura',
      name: 'Factura'
    },
    {
      value: 'Prestamos',
      name: 'Prestamos'
    },
    {
      value: 'Pendientes',
      name: 'Pendientes'
    },
    {
      value: 'Reembolso Caja Sede',
      name: 'Reembolso Caja Sede'
    },
    {
      value: 'Anticipo',
      name: 'Anticipo'
    }
  ]
  // Array status egresos
  const statusExpenses = [
    {
      value: 1,
      name: 'No Requiere'
    },
    {
      value: 2,
      name: 'Pendiente'
    },
    {
      value: 3,
      name: 'Aprobado'
    },
    {
      value: 4,
      name: 'Rechazado'
    }
  ]

  const expenseServices = new ExpenseServices()
  const store = useStore()

  const returnData = async (data) => {
    if (data.length > 0) {
      await data.map(expense => {
        expense.date = dayjs(expense.date).format('DD-MM-YYYY')
      })
    }
    store.dispatch('storePettyCash/storeExpenses', data)
  }

  const getExpenses = async (form) => {
    let dataExpenses = []
    try {
      if (form.expenseType && form.expenseStatus) {
        console.log('Case 1', form)
        dataExpenses = await expenseServices.getExpenseByTypeAndStatus(form.date, form.expenseType, form.expenseStatus)
        await returnData(dataExpenses)
        return true
      }
      if (form.expenseStatus) {
        console.log('case 2', form)
        dataExpenses = await expenseServices.getExpenseByStatus(form.date, form.expenseStatus)
        await returnData(dataExpenses)
        return true
      }
      if (form.expenseType) {
        console.log('case 3', form)
        dataExpenses = await expenseServices.getExpenseByType(form.date, form.expenseType)
        await returnData(dataExpenses)
        return true
      }
      if (form.date) {
        console.log('Case 4', form)
        dataExpenses = await expenseServices.getExpenseByDate(form.date, form.query)
        await returnData(dataExpenses)
        return true
      }
    } catch (error) {
      console.error('error getExpenses', error)
      throw error
    }
  }

  const createExpenses = async (form) => {
    form.userId = 1
    form.date = dayjs(new Date()).format('YYYY-MM-DD')
    form.amount = parseFloat(form.amount ?? 0)
    try {
      if (form.expenseType === 'Prestamos') {
        console.log('case Préstamos Expense', form)
        return await expenseServices.createExpenseByCase('/expense/create-by-loan', form)
      }
      if (form.expenseType === 'Anticipo') {
        console.log('case Anticipo Expense', form)
        return await expenseServices.createExpenseByCase('/expense/create-by-advance', form)
      }
      if (form.expenseType === 'Factura') {
        console.log('case Factura Expense', form)
        return await expenseServices.createExpenseByCase('/expense/create-by-invoice', form)
      }
      if (form.expenseType === 'Pendientes') {
        console.log('case Pendientes Expense', form)
        return await expenseServices.createExpenseByCase('/expense/create-by-pending', form)
      }
      if (form.expenseType === 'Reembolso Caja Sede') {
        console.log('case Reembolso Caja Sede Expense', form)
        return await expenseServices.createExpenseByCase('/expense/create-by-refund', form)
      }
    } catch (error) {
      console.error('error catch useExpense', error)
      throw error
    }
  }

  const updateExpenses = async (form, id) => {
    try {
      form.userId = 1
      form.date = dayjs(new Date()).format('YYYY-MM-DD')
      form.amount = parseFloat(form.amount ?? 0)
      if (form.expenseType === 'Factura') {
        console.log('Case Edit Factura')
        return await expenseServices.editExpenseByCase('/expense/edit/' + id, form)
      }
      if (form.expenseType === 'Prestamos') {
        console.log('Case Edit Prestamos', form)
        return await expenseServices.editExpenseByCase('/expense/edit/' + id, form)
      }
      if (form.expenseType === 'Pendientes') {
        console.log('Case Edit Pendientes', form)
        return await expenseServices.editExpenseByCase('/expense/edit/' + id, form)
      }
      if (form.expenseType === 'Reembolso Caja Sede') {
        console.log('Case Edit Reembolso Caja Sede', form)
        return await expenseServices.editExpenseByCase('/expense/edit/' + id, form)
      }
      if (form.expenseType === 'Anticipo') {
        console.log('Case Edit Anticipo', form)
        return await expenseServices.editExpenseByCase('/expense/edit/' + id, form)
      }
    } catch (error) {
      console.error('error updateExpenses', error)
      throw error
    }
  }

  const deleteExpense = async (id) => {
    try {
      return await expenseServices.deleteExpenseById('/expense/delete/' + id)
    } catch (error) {
      console.error('error deleteExpense', error)
      throw error
    }
  }

  return {
    typesExpenses,
    statusExpenses,
    createExpenses,
    updateExpenses,
    deleteExpense,
    getExpenses
  }
}
export default useExpense

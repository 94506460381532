import Layouts from '../../../layouts/main-menu/Main.vue'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '../../../middleware/securityAccess'

export default [
  {
    path: '/',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth, securityAccess]),
    children: [
      /* carta agotado rutas */
      {
        path: 'carta-agotado',
        name: 'asmetsalud.carta-agotado',
        meta: {
          access: [
            'asmetsalud.carta-agotado'
          ]
        },
        component: () => import('./cartaAgotadoCompras/views/listado/index.vue').then(m => m || m)
      }
      /* logistica  rutas */
    ]
  }
]

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPhone,
  faUsers,
  faUniversity,
  faCreditCard,
  faHashtag,
  faMoneyBill,
  faCalendarAlt,
  faDollarSign,
  faCoins,
  faFunnelDollar,
  faPercentage,
  faShareSquare,
  faTrashAlt,
  faPen,
  faReceipt,
  faTimes,
  faCheck,
  faClock,
  faClipboardCheck,
  faPlus,
  faHistory,
  faBook,
  faGavel,
  faFolder,
  faList,
  faClipboardList,
  faHandHoldingUsd,
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faChartLine,
  faHandshake,
  faChartBar,
  faChartPie,
  faFileInvoice,
  faMoneyBillAlt,
  faCheckCircle,
  faToolbox
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faPhone,
  faUsers,
  faUniversity,
  faCreditCard,
  faHashtag,
  faMoneyBill,
  faCalendarAlt,
  faDollarSign,
  faCoins,
  faFunnelDollar,
  faPercentage,
  faShareSquare,
  faHistory,
  faBook,
  faGavel,
  faFolder,
  faTrashAlt,
  faPen,
  faReceipt,
  faTimes,
  faCheck,
  faClock,
  faClipboardCheck,
  faPlus,
  faFolder,
  faList,
  faClipboardCheck,
  faClipboardList,
  faChartLine,
  faHandHoldingUsd,
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faHandshake,
  faChartBar,
  faChartPie,
  faFileInvoice,
  faMoneyBillAlt,
  faCheckCircle,
  faToolbox
)

const install = app => {
  app.component('font-awesome-icon', FontAwesomeIcon)
}

export { install as default }

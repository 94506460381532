import findFinancialProduct from '../../apps/pharmasan/accounting/amortizations/services/findFinancialProduct'

const state = () => {
  return {
    productInfo: {
      disbursement: 0,
      insurance: 0,
      otherValues: 0,
      pointsAboutDTFTA: 0,
      currentDTFTA: 0,
      rateEA: 0,
      rateMMV: 0,
      rateMV: null,
      id: null,
      name: '',
      bankId: null,
      bankQuotaId: null,
      obligationNumber: '',
      accountSap: '',
      disbursementDate: '',
      periodicity: '',
      term: null,
      cutoffDate: '',
      dueDate: '',
      AmortizationsBank: {
        name: ''
      },
      AmortizationsBankQuota: {
        creditLine: {
          name: ''
        }
      }
    }
  }
}

// getters
const getters = {
  getFinancialProduct: state => state.productInfo
}

// actions
const actions = {
  async financialProductAction (context, id) {
    return findFinancialProduct({ id }).then(({ status, data }) => {
      context.commit('financialProductMutation', data.result)
    })
  }
}

// mutations
const mutations = {
  financialProductMutation (state, payload) {
    state.productInfo = payload
  },
  setDisbursment (state, payload) {
    state.productInfo.disbursement = payload
  },
  setRateEA (state, payload) {
    state.productInfo.rateEA = parseFloat(payload)
  },
  setTerm (state, payload) {
    state.productInfo.term = parseInt(payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

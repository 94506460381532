import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
// import securityAccess from '@/middleware/securityAccess'
export default [
  {
    path: '/usuarios',
    component: Layouts,
    name: 'usuarios/listado',
    beforeEnter: VueRouterMultiguard([auth]),
    redirect: { name: 'usuarios.usuarios' }
    // children: [
    //   {
    //     path: '',
    //     name: 'usuarios',
    //     component: () => import('../views/usuarios/index.vue'),
    //     access: [
    //       'usuarios'
    //     ],
    //     children: [
    //       {
    //         path: '',
    //         name: 'usuarios.usuarios',
    //         component: () => import('../views/usuarios/pages/usuarios/listar/TablaUsuarios.vue'),
    //         beforeEnter: VueRouterMultiguard([securityAccess]),
    //         meta: {
    //           access: [
    //             'usuarios.usuarios'
    //           ],
    //           activeMenu: 'usuarios.usuarios'
    //         }
    //       },
    //       {
    //         path: 'grupos',
    //         name: 'usuarios.grupos',
    //         component: () => import('../views/usuarios/pages/grupos/listar/index.vue'),
    //         beforeEnter: VueRouterMultiguard([securityAccess]),
    //         meta: {
    //           access: [
    //             'usuarios.grupos'
    //           ]
    //         },
    //         children: [
    //         ]
    //       }
    //     ]
    //   },
    //   {
    //     path: 'ver/:id?',
    //     name: 'usuarios.usuarios.ver',
    //     component: () => import('../views/usuarios/pages/usuarios/ver'),
    //     redirect: { name: 'usuarios.usuarios.ver.informacion' },
    //     meta: {
    //       access: [
    //         'usuarios.usuarios'
    //       ],
    //       activeMenu: 'usuarios.usuarios'
    //     },
    //     children: [
    //       {
    //         path: 'informacion',
    //         name: 'usuarios.usuarios.ver.informacion',
    //         component: () => import('../views/usuarios/pages/usuarios/ver/pages/informacion'),
    //         beforeEnter: VueRouterMultiguard([securityAccess])
    //       },
    //       {
    //         path: 'permisos',
    //         name: 'usuarios.usuarios.ver.permisos',
    //         component: () => import('../views/usuarios/pages/usuarios/ver/pages/permisos'),
    //         beforeEnter: VueRouterMultiguard([securityAccess])
    //       }
    //     ]
    //   },
    //   {
    //     path: 'crear',
    //     name: 'usuarios.usuarios.crear',
    //     component: () => import('../views/usuarios/pages/usuarios/crear/index.vue'),
    //     beforeEnter: VueRouterMultiguard([securityAccess]),
    //     redirect: { name: 'usuarios.usuarios.informacion' },
    //     meta: {
    //       access: [
    //         'usuarios.usuarios.access'
    //       ]
    //     },
    //     children: [
    //       {
    //         path: 'informacion',
    //         name: 'usuarios.usuarios.informacion',
    //         component: () => import('../views/usuarios/pages/usuarios/crear/pages/informacion/index.vue'),
    //         beforeEnter: VueRouterMultiguard([securityAccess])
    //       },
    //       {
    //         path: 'permisos',
    //         name: 'usuarios.usuarios.permisos',
    //         component: () => import('../views/usuarios/pages/usuarios/crear/pages/permisos/index.vue'),
    //         beforeEnter: VueRouterMultiguard([securityAccess])
    //       }
    //     ]
    //   },
    //   {
    //     path: 'editar/:id?',
    //     name: 'usuarios.usuarios.editar',
    //     component: () => import('../views/usuarios/pages/usuarios/crear/editar.vue'),
    //     beforeEnter: VueRouterMultiguard([securityAccess]),
    //     redirect: { name: 'usuarios.usuarios.informacion' },
    //     meta: {
    //       access: [
    //         'usuarios.usuarios.access'
    //       ]
    //     },
    //     children: [
    //       {
    //         path: 'informacion',
    //         name: 'usuarios.usuarios.editar.informacion',
    //         component: () => import('../views/usuarios/pages/usuarios/crear/pages/informacion/index.vue'),
    //         beforeEnter: VueRouterMultiguard([securityAccess])
    //       },
    //       {
    //         path: 'permisos',
    //         name: 'usuarios.usuarios.editar.permisos',
    //         component: () => import('../views/usuarios/pages/usuarios/crear/pages/permisos/index.vue'),
    //         beforeEnter: VueRouterMultiguard([securityAccess])
    //       }
    //     ]
    //   },
    //   {
    //     path: 'grupos/autorizaciones',
    //     name: 'usuarios.grupos.autorizaciones',
    //     component: () => import('../views/usuarios/pages/grupos/autorizaciones/index.vue'),
    //     beforeEnter: VueRouterMultiguard([securityAccess]),
    //     meta: {
    //       access: [
    //         'usuarios.grupos.access'
    //       ]
    //     }
    //   },
    //   {
    //     path: 'empleados',
    //     name: 'usuarios.empleados',
    //     component: () => import('../views/empleados/index.vue'),
    //     beforeEnter: VueRouterMultiguard([securityAccess]),
    //     meta: {
    //       access: [
    //         'usuarios.empleados'
    //       ]
    //     }
    //   },
    //   {
    //     path: 'empleados/crear',
    //     name: 'usuarios.empleados.crear',
    //     component: () => import('../views/empleados/pages/crear/index.vue'),
    //     beforeEnter: VueRouterMultiguard([securityAccess]),
    //     meta: {
    //       access: [
    //         'usuarios.empleados.access'
    //       ],
    //       activeMenu: 'usuarios.empleados'
    //     }
    //   },
    //   {
    //     path: 'empleados/editar/:id?',
    //     name: 'usuarios.empleados.editar',
    //     component: () => import('../views/empleados/pages/crear/index.vue'),
    //     beforeEnter: VueRouterMultiguard([securityAccess]),
    //     meta: {
    //       access: [
    //         'usuarios.empleados.access'
    //       ],
    //       activeMenu: 'usuarios.empleados'
    //     }
    //   },
    //   {
    //     path: 'empleados/ver/:id?',
    //     name: 'usuarios.empleados.ver',
    //     component: () => import('../views/empleados/pages/ver'),
    //     beforeEnter: VueRouterMultiguard([securityAccess]),
    //     meta: {
    //       access: [
    //         'usuarios.empleados'
    //       ],
    //       activeMenu: 'usuarios.empleados'
    //     }
    //   }
    // ]
  }
]

// export default [
//   {
//     path: '/usuarios',
//     component: Layouts,
//     name: 'usuarios.access',
//     beforeEnter: VueRouterMultiguard([authStore]),
//     redirect: { name: 'usuarios.empleados.listar' },
//     children: [
//       {
//         path: '/usuarios/listado',
//         name: 'usuarios.listar',
//         component: () => import('../views/usuarios/index.vue'),
//         beforeEnter: VueRouterMultiguard([securityAccess]),
//         meta: {
//           access: [
//             'usuarios.listar'
//           ]
//         }
//       },
//       {
//         path: '/crear/:id?',
//         name: 'usuarios.crear',
//         component: () => import('../views/usuarios/pages/usuarios/crear/index.vue'),
//         beforeEnter: VueRouterMultiguard([securityAccess]),
//         redirect: { name: 'crear.informacion-usuario' },
//         meta: {
//           access: [
//             'usuarios.crear'
//           ],
//           activeMenu: 'usuarios.access'
//         },
//         children: [
//           // SALEN ERRORES EN LA CONSOLA EN TODO EL PROYECTO POR ESTAS RUTAS ( CORREGIR )
//           // {
//           //   path: '/crear/informacion-usuario',
//           //   name: 'crear.informacion-usuario',
//           //   component: () => import('../views/usuarios/pages/usuarios/crear/pages/informacion/index.vue'),
//           //   activeMenu: 'usuarios.access'
//           // },
//           // {
//           //   path: '/crear/permisos-usuario',
//           //   name: 'crear.permisos-usuario',
//           //   component: () => import('../views/usuarios/pages/usuarios/crear/pages/permisos/index.vue'),
//           //   activeMenu: 'usuarios.access'
//           // }
//         ]
//       },
//       {
//         path: '/ver/:id?',
//         name: 'usuarios.ver',
//         component: () => import('../views/usuarios/pages/usuarios/ver/index.vue'),
//         beforeEnter: VueRouterMultiguard([securityAccess]),
//         meta: {
//           access: [
//             'usuarios.ver'
//           ]
//         }
//       },
//       {
//         path: '/empleados/listado',
//         name: 'usuarios.empleados.listar',
//         component: () => import('../views/empleados/index.vue'),
//         beforeEnter: VueRouterMultiguard([securityAccess]),
//         meta: {
//           access: [
//             'usuarios.empleados.listar'
//           ],
//           activeMenu: 'usuarios.empleados.listar'
//         }
//       },
//       {
//         path: 'empleados/crear/:id?',
//         name: 'usuarios.empleados.crear',
//         component: () => import('../views/empleados/pages/crear/index.vue'),
//         beforeEnter: VueRouterMultiguard([securityAccess]),
//         meta: {
//           access: [
//             'usuarios.empleados.crear'
//           ],
//           activeMenu: 'usuarios.empleados.crear'
//         }
//       },
//       {
//         path: 'empleados/ver/:id',
//         name: 'usuarios.empleados.ver',
//         component: () => import('../views/empleados/pages/ver/index.vue'),
//         beforeEnter: VueRouterMultiguard([securityAccess]),
//         meta: {
//           access: [
//             'usuarios.empleados.ver'
//           ]
//         }
//       },
//       {
//         path: '/usuarios/grupos/autorizaciones',
//         name: 'usuarios.grupos.autorizaciones',
//         component: () => import('../views/usuarios/pages/grupos/autorizaciones'),
//         beforeEnter: VueRouterMultiguard([securityAccess]),
//         meta: {
//           access: [
//             'usuarios.listar'
//           ]
//         }
//       }
//     ]
//   }
// ]

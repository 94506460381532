<template>
 <section>
   <div class="w-full box p-2 px-5 mt-4">
     <div
      class="nav nav-tabs flex-col sm:flex-row  lg:justify-start"
      style="overflow-x:auto;"
      role="tablist"
      >
        <router-link
        :to="{name:'admin.administrativo.facturacion'}"
        active-class="active"
        data-toggle="tab"
        class="py-4 sm:mr-8 flex"
        role="tab"
        aria-selected="true"
        >
          <ArchiveIcon class="w-4 h-4 mr-2" />
          Facturación
        </router-link>
      </div>
   </div>
   <router-view />
 </section>
</template>

<script>

export default {
  setup () {
    return { }
  }
}
</script>

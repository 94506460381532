<template>
  <AutoComplete
  v-model="model"
  field="cardname"
  inputClass="form-control"
  :suggestions="values"
  :dropdown="false"
  placeholder="Ingrese la busqueda..."
  :class="classes"
  @item-select="onChange"
  @complete="onComplete($event)"
  >
    <template #item="{ item }">
      <div>
        <span>{{item.cardname}}</span>
      </div>
    </template>
  </AutoComplete>
</template>
<script>
import { defineComponent, onMounted, ref, watch } from 'vue'
import suppliersFindName from './service'
import AutoComplete from 'primevue/autocomplete'
export default defineComponent({
  name: 'SelectVendedores',
  props: {
    modelValue: {
      type: [String, Number],
      default: null
    },
    classes: {
      type: String,
      default: null
    }
  },
  components: {
    AutoComplete
  },
  setup (props, context) {
    const values = ref([])
    const model = ref({
      cardcode: '',
      cardname: ''
    })
    const onComplete = ({ query }) => {
      return suppliersFindName({ query: query.trim() }).then(({ data }) => {
        values.value = data.data
      }).catch(err => {
        console.error(err.message)
      })
    }
    const onChange = ({ value }) => {
      context.emit('update:modelValue', value.cardcode)
    }
    watch(() => props.modelValue, (value) => {
      console.log(value)
      setSupliers()
    })
    const setSupliers = () => {
      values.value = []
      model.value = { cardcode: '', cardname: '' }
      if (props.modelValue) {
        suppliersFindName({ cardcode: props.modelValue }).then(({ data }) => {
          // console.log(data)
          values.value = data.data
          model.value = data.data[0]
          context.emit('update:modelValue', props.modelValue)
        }).catch(err => {
          console.error(err.message)
        })
      }
    }
    onMounted(() => {
      setSupliers()
      /* onComplete({ query: 'DAV' }) */
    })
    return {
      model,
      values,
      onComplete,
      onChange
    }
  }
})
</script>

<template>
  <div class="p-grid p-mt-3" style="">
    <div class="p-col-12">
      <div class="p-grid">
         <div class="p-col-12" style="">
            <titleAndButtons ref="titleAndButtons"/>
         </div>
      </div>
      <div class="p-grid" style="">
        <div class="p-col-12">
          <dataTable ref="dataTable"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
  name: 'Permisos',
  components: {
    titleAndButtons: defineAsyncComponent(() => import('./components/titleAndButtons')),
    dataTable: defineAsyncComponent(() => import('./components/dataTable'))
  }
}
</script>
<style scoped>
</style>

<template>
  <section class="w-full">
    <HomeSummary />
    <HomeDetails />
  </section>
</template>

<script>
import HomeSummary from '../components/home/homeSummary'
import HomeDetails from '../components/home/homeDetails.vue'

export default {
  name: 'PettyCashHome',
  components: {
    HomeSummary,
    HomeDetails
  }
}
</script>

<style scoped>

</style>

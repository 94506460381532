<template>
    <div class="intro-y col-span-12">
      <ul class="flex list-reset border border-grey-light rounded w-auto font-sans">
        <li class="zoom-in" v-if="currentPage>1">
          <a class="block text-theme-1 border-r border-grey-light px-3 py-2" @click="onClickNumberPage(1)">
            Inicio
          </a>
        </li>
        <li class="zoom-in" v-if="currentPage>1">
          <a class="block text-theme-1 border-r border-grey-light px-3 py-2" @click="onClickNumberPage(currentPage-1)">
            Anterior
          </a>
        </li>
        <li v-for="(item,key) in paginatorLast" :key="key" class="zoom-in">
          <template v-if="item>=currentPage">
            <a class="block text-theme-1 border-r border-grey-light px-3 py-2" :class="`${currentPage===item?'font-bold':''}`" @click="onClickNumberPage(item)">
              {{ item }}
            </a>
          </template>
        </li>
        <li class="zoom-in hover:bg-theme-2" v-if="currentPage !== lastPage">
          <a class="block text-theme-1 border-r border-grey-light px-3 py-2" @click="onClickNumberPage(currentPage+1)">
            Siguiente
          </a>
        </li>
        <li class="zoom-in" v-if="currentPage !== lastPage">
          <a class="block text-theme-1 border-r border-grey-light px-3 py-2" @click="onClickNumberPage(lastPage)">
            Última
          </a>
        </li>
        <li>
          <p class="py-2 px-3 text-theme-1">
            Página {{currentPage}} de {{lastPage}} total registros {{total}}
          </p>
        </li>
      </ul>
    </div>
</template>
<script>
import { loadBusiness } from './hook'
export default {
  name: 'Paginator',
  props: {
    total: {
      type: [Number, String],
      default: 9
    },
    lastPage: {
      type: [Number, String],
      default: 6
    },
    currentPage: {
      type: [Number, String],
      default: 1
    }
  },
  setup (props, context) {
    return {
      ...loadBusiness({ props, context })
    }
  }
}
</script>

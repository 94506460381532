import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '../../../../middleware/securityAccess'

export default [
  {
    path: '/pharmasan/reportes',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    redirect: { name: 'pharmasan.reportes.sap' },
    children: [
      {
        path: 'sap',
        name: 'pharmasan.reportes.sap',
        beforeEnter: VueRouterMultiguard([auth, securityAccess]),
        component: () => import('./views/consultasSap'),
        meta: {
          access: ['pharmasan.reportes.sap'],
          activeMenu: 'pharmasan.reportes.sap'
        }
      }
    ]
  }
]

import dayjs from 'dayjs'
import HeaderServices from '../services/headerServices'
const _HeaderServices = new HeaderServices()

const state = () => {
    return {
      storeDate: dayjs().locale('es').format('YYYY-MM-DD'),
      storeIncomes: [],
      storeIncome: {},
      storeExpenses: [],
      storeExpense: {},
      storeTotalInc: 0,
      storeTotalExp: 0,
      storePending: 0,
      storePrestamos: 0,
      storeInitial: 0,
      storeBalance: 0,
      storeHeader: {},
      storeInvoicesTypes: []
    }
}
const mutations = {
  setDate (state, payload) {
    state.storeDate = payload
  },
  setIncomes (state, payload) {
    state.storeIncomes = payload
    state.storeTotalInc = payload.total
  },
  setIncome (state, payload) {
    state.storeIncome = payload
  },
  setExpenses (state, payload) {
    state.storeExpenses = payload
    state.storeTotalExp = payload.total
    state.storePending = payload.pending
  },
  setExpense (state, payload) {
    state.storeExpense = payload
  },
  setHeader (state, payload) {
    for (const [index, item] of Object.entries(payload)) {
      state.storeHeader[index] = item
    }
  },
  setInital (state, payload) {
    state.storeInitial = payload
  },
  setBalance (state, payload) {
    state.storeBalance = payload
  },
  setInvoicesTypes (state, payload) {
    state.storeInvoicesTypes = payload
  },
  setPrestamos (state, payload) {
    state.storePrestamos = payload
  }
}
const actions = {
  searchIncomesDate ({ commit }, payload) {
    commit('setDate', payload)
  },
  storeIncomes ({ commit }, payload = []) {
    commit('setIncomes', payload)
  },
  storeIncome ({ commit, state }, payload) {
    const objIncome = state.storeIncomes.value.rows.find(income => income.id === payload)
    commit('setIncome', objIncome)
  },
  searchExpensesDate ({ commit }, payload) {
    commit('setDate', payload)
  },
  storeExpenses ({ commit }, payload = []) {
    commit('setExpenses', payload)
  },
  storeExpense ({ commit }, payload = {}) {
    const objExpense = state.storeExpenses.value.rows.find(expense => expense.id === payload)
    commit('setExpense', objExpense)
  },
  storeHeader ({ commit }, payload = {}) {
    commit('setHeader', payload)
  },
  gettingCashBalance ({ commit }, { date }) {
    _HeaderServices.getCashBalance(date).then(({ data }) => {
      commit('setInital', parseFloat(data.initial))
      commit('setBalance', parseFloat(data.balance))
    })
  },
  storeInvoicesTypes ({ commit }) {
    _HeaderServices.getInvoicesTypes().then(({ data }) => {
      commit('setInvoicesTypes', data)
    })
  },
  storePrestamos ({ commit }) {
    _HeaderServices.getPrestamos().then(({ data }) => {
      commit('setPrestamos', data)
    })
  },
  storeBalanceClose ({ commit }, { id }) {
    return _HeaderServices.balanceClose(id).then(({ data }) => {
      return data
    })
  }
}

const getters = {
  getStoreDate: state => state.storeDate,
  getIncomes: state => state.storeIncomes,
  getIncome: state => state.storeIncome,
  getExpenses: state => state.storeExpenses,
  getExpense: state => state.storeExpense,
  getTotalInc: state => state.storeTotalInc,
  getTotalExp: state => state.storeTotalExp,
  getHeader: state => state.storeHeader,
  getPending: state => state.storePending,
  getInitial: state => state.storeInitial,
  getBalance: state => state.storeBalance,
  getInvoicesTypes: state => state.storeInvoicesTypes

}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

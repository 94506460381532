import Layouts from '@/layouts/main-menu/Main'
import VueRouterMultiguard from 'vue-router-multiguard'
import auth from '../../../middleware/auth'
// import Home from './index'

export default [
  {
    path: '/',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: '',
        name: 'home',
        // component: Home
        redirect: { name: 'pharmasan.dashboard.operacion' }
      }
    ]
  }
]

import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '../../../middleware/securityAccess'

export default [
  {
    path: '/utespecializada',
    component: Layouts,
    name: 'ut-especializada',
    beforeEnter: VueRouterMultiguard([auth, securityAccess]),
    redirect: { name: 'ut-especializada.operacion' },
    meta: {
      access: ['ut-especializada']
    },
    children: [
      {
        path: 'dashboard',
        name: 'ut-especializada.operacion',
        component: () => import('@/apps/utEspecializada/dashboard/views/operacion/index.vue'),
        redirect: { name: 'ut-especializada.operacion.ssd-norte-santander' },
        children: [
          {
            path: 'operacion/ut-especializada',
            name: 'ut-especializada.operacion.ut-especializada',
            component: () => import('@/apps/utEspecializada/dashboard/views/operacion/ut-especializada/index.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['ut-especializada.operacion.ut-especializada'],
              activeMenu: 'ut-especializada.operacion'
            }
          },
          {
            path: 'operacion/ssd-norte-santander',
            name: 'ut-especializada.operacion.ssd-norte-santander',
            component: () => import('@/apps/utEspecializada/dashboard/views/operacion/ssd-norte-santander/index.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['ut-especializada.operacion.ssd-norte-santander'],
              activeMenu: 'ut-especializada.operacion'
            }
          },
          {
            path: 'operacion/ssd-santander',
            name: 'ut-especializada.operacion.ssd-santander',
            component: () => import('@/apps/utEspecializada/dashboard/views/operacion/ssd-santander/index.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['ut-especializada.operacion.ssd-santander'],
              activeMenu: 'ut-especializada.operacion'
            }
          }
        ]
      }
    ]
  }
]

<template>
  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">

        <div class="accordion-item">
          <div id="faq-accordion-content-1" class="accordion-header flex justify-between">
            <div class="flex flex-col sm:flex-row w-full justify-between items-center p-3 border-b border-gray-200 dark:border-dark-5" style="border-top-left-radius: 6px; border-top-right-radius: 6px;  border-top-color: #037ABC; border-top-width: 8px;">
            <button
              class="accordion-button text-base font-medium focus:outline-none dark:text-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faq-accordion-collapse-1"
              aria-expanded="true"
              aria-controls="faq-accordion-collapse-1"
            >
              Información Producto Financiero
            </button>
            <div class="flex justify-end">
              <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-dark-3 bg-gray-300 rounded">{{ info.name }}</span>
            </div>
            </div>
          </div>
          <div
            id="faq-accordion-collapse-1"
            class="accordion-collapse collapse show"
            aria-labelledby="faq-accordion-content-1"
            data-bs-parent="#faq-accordion-1"
          >
            <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
              <div class="p-5">
                <div class="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-4">
                  <div class="col-span-1 flex">
                    <div class="symbol symbol-50 symbol-light-danger mr-5">
                      <span class="symbol-label">
                        <font-awesome-icon class="text-2xl" icon="university" />
                      </span>
                    </div>
                    <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                      <span class="text-gray-500 text-xs">Banco</span>
                      <span class="text-gray-700 font-bold text-sm">{{ info.bank }}</span>
                    </div>
                  </div>
                  <div class="col-span-1 flex">
                    <div class="symbol symbol-50 symbol-light-primary mr-5">
                      <span class="symbol-label">
                        <font-awesome-icon class="text-2xl" icon="credit-card" />
                      </span>
                    </div>
                    <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                      <span class="text-gray-500 text-xs">Linea de crédito</span>
                      <span class="text-gray-700 font-bold text-sm">{{ info.typeObligation }}</span>
                    </div>
                  </div>
                  <div class="col-span-1 flex">
                    <div class="symbol symbol-50 symbol-light-dark mr-5">
                      <span class="symbol-label">
                        <font-awesome-icon class="text-2xl" icon="hashtag" />
                      </span>
                    </div>
                    <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                      <span class="text-gray-500 text-xs">Número de la Obligación</span>
                      <span class="text-gray-700 font-bold text-sm">{{ info.obligationNumber }}</span>
                    </div>
                  </div>
                  <div class="col-span-1 flex">
                    <div class="symbol symbol-50 symbol-light-warning mr-5">
                      <span class="symbol-label">
                        <font-awesome-icon class="text-2xl" icon="calendar-alt" />
                      </span>
                    </div>
                    <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                      <span class="text-gray-500 text-xs">Fecha Desembolso</span>
                      <span class="text-gray-700 font-bold text-sm">{{ info.disbursementDate }}</span>
                    </div>
                  </div>
                  <div class="col-span-1 flex">
                    <div class="symbol symbol-50 symbol-light-success mr-5">
                      <span class="symbol-label">
                        <font-awesome-icon class="text-2xl" icon="money-bill" />
                      </span>
                    </div>
                    <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                      <span class="text-gray-500 text-xs">Desembolso</span>
                      <InputNumber
                        v-if="info.isGenerate"
                        id="disbursement"
                        class="p-inputtext-xs"
                        v-model="disbursement"
                        mode="currency"
                        currency="COP"
                        locale="es-CO"
                        minFractionDigits="0"
                        maxFractionDigits="0"
                        @input="changeDisbursment"
                      />
                      <span v-else class="text-gray-700 font-bold text-sm">{{ $h.formatCurrency(parseInt(info.disbursement)) }}</span>
                    </div>
                  </div>
                  <div class="col-span-1 flex">
                    <div class="symbol symbol-50 symbol-light-info mr-5">
                      <span class="symbol-label">
                        <font-awesome-icon class="text-2xl" icon="percentage" />
                      </span>
                    </div>
                    <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                      <span class="text-gray-500 text-xs">Tasa EA</span>
                      <InputText
                        v-if="info.isGenerate"
                        type="text"
                        placeholder="Small"
                        class="p-inputtext-xs"
                        v-model="rateEA"
                      />
                      <span v-else class="text-gray-700 font-bold text-xs">{{ info.rateEA }}%</span>
                    </div>
                  </div>
                  <div class="col-span-1 flex">
                    <div class="symbol symbol-50 symbol-light-danger mr-5">
                      <span class="symbol-label">
                        <font-awesome-icon class="text-2xl" icon="hashtag" />
                      </span>
                    </div>
                    <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                      <span class="text-gray-500 text-xs">Plazo</span>
                      <InputText
                        v-if="info.isGenerate"
                        id="term"
                        type="number"
                        class="p-inputtext-xs"
                        v-model="term"
                      />
                      <span v-else class="text-gray-700 font-bold text-sm">{{ info.term }}</span>
                    </div>
                  </div>
                  <div class="col-span-1 flex">
                    <div class="symbol symbol-50 symbol-light-dark mr-5">
                      <span class="symbol-label">
                        <font-awesome-icon class="text-2xl" icon="calendar-alt" />
                      </span>
                    </div>
                    <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                      <span class="text-gray-500 text-xs">Periodicidad</span>
                      <span class="text-gray-700 font-bold text-sm">{{ info.periodicity }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'infoFinancialProduct',
  props: {
    info: {
      type: Object
    }
  },
  setup (props, context) {
    const store = useStore()

    /**/
    const disbursement = computed({
      get () {
        return props.info.disbursement
      },
      set (value) {
        store.commit('infoFinancialProductAmortization/setDisbursment', value)
      }
    })
    /**/
    const rateEA = computed({
      get () {
        return props.info.rateEA
      },
      set (value) {
        let val = 0
        if (value.length > 0) {
          val = value
        }
        store.commit('infoFinancialProductAmortization/setRateEA', val)
      }
    })
    /**/
    const term = computed({
      get () {
        return props.info.term
      },
      set (value) {
        store.commit('infoFinancialProductAmortization/setTerm', value)
      }
    })

    return {
      disbursement,
      rateEA,
      term
    }
  }
}
</script>

<style scoped>

</style>

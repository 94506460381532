<template>
  <section class="intro-x">
    <div class="w-full my-5">
      <back-button />
    </div>
    <div class="w-full lg:w-1/2 box p-4">
      <div class="w-full">
        <h1 class="font-bold text-lg">Formulario estados de orden</h1>
      </div>
      <form
      class="w-full"
      @submit.enter="onSubmit"
      >
        <!-- nombre -->
        <div class="w-full mb-2">
          <label for="name">Nombre</label>
          <input
          id="name"
          v-model="model.name"
          name="name"
          type="text"
          class="form-control"
          >
          <MessageError :text="errors.name" />
        </div>
        <!-- codigo -->
        <div class="w-full mb-2">
          <label for="code">Codigo</label>
          <input
          id="code"
          v-model="model.code"
          name="code"
          type="text"
          class="form-control"
          >
          <MessageError :text="errors.code" />
        </div>
        <!-- impuesto estado -->
        <div class="form-check my-5">
          <input
          v-model="model.state"
          id="show-example-5"
          data-target="#select-options"
          class="show-code form-check-switch"
          type="checkbox"
          />
          <label
          class="form-check-label ml-0 sm:ml-2"
          for="show-example-5"
          >
            Estado
          </label >
        </div>
        <div class="w-full mt-4">
          <button type="submit" class="btn btn-primary">
            Guardar
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { useField, useForm } from 'vee-validate'
import { object, string, boolean } from 'yup'
import ordenEstadoCrear from '../../../../../services/ordenEstadoCrear'
import ordenEstadoBuscar from '../../../../../services/ordenEstadoBuscar'
import ordenEstadoActualizar from '../../../../../services/ordenEstadoActualizar'
import { messageConfirm, messageSuccess } from '../../../../../../../../libs/mensajes'
import { useRoute, useRouter } from 'vue-router'
import { computed, onMounted } from 'vue-demi'

export default {
  setup () {
    const router = useRouter()
    const route = useRoute()
    const validationSchema = object().shape({
      name: string().max(140).required(),
      code: string().max(10).required(),
      state: boolean().required()
    })
    const {
      values: model,
      errors,
      handleSubmit,
      handleReset
    } = useForm({
      validationSchema
    })

    useField('name', null, { initialValue: '' })
    useField('code', null, { initialValue: '' })
    useField('state', null, { initialValue: true })

    const onSubmit = handleSubmit((values) => {
      if (isEdit.value) { // editando un registro
        const { id } = route.params
        ordenEstadoActualizar(id, values).then(() => {
          messageSuccess('Orden Actualizada')
          router.back()
        }).catch(err => {
          console.log(err.message)
        })
      } else { // insertando un nuevo registro
        ordenEstadoCrear(values).then(({ data }) => {
          messageConfirm('Desea crear un nuevo estado', 'Estado creado', 'success').then(({ isConfirmed }) => {
            if (isConfirmed) {
              handleReset()
              return false
            }
            router.back()
          })
        }).catch(err => {
          console.error(err.message)
        })
      }
    })

    const fetchState = () => {
      const { id } = route.params
      return ordenEstadoBuscar(id).then(({ data }) => {
        stateFill(data)
        return data
      }).catch(err => {
        console.error(err.message)
      })
    }

    const stateFill = (obj) => {
      model.name = obj.name
      model.code = obj.code
      model.state = obj.state
    }

    onMounted(() => {
      if (isEdit.value) {
        fetchState()
      }
    })

    const isEdit = computed(() => {
      if (route.params.id) return true
      return false
    })

    return {
      model,
      errors,
      onSubmit
    }
  }
}
</script>

<style>

</style>

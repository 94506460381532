import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '@/middleware/securityAccess'
export default [
  {
    path: '/cartera',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: '/cargue-facturas',
        name: 'cartera.facturas',
        component: () => import('@/apps/pharmasan/cartera/views/cargue_facturas'),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: [
            'cartera.facturas'
          ]
        }
      },
      {
        path: '/estado-cartera',
        name: 'cartera.estado',
        component: () => import('@/apps/pharmasan/cartera/views/estado_cartera'),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: [
            'cartera.estado'
          ]
        }
      },
      {
        path: '/radicacion',
        name: 'cartera.radicaciones',
        component: () => import('@/apps/pharmasan/cartera/views/radicaciones/index.vue'),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: [
            'cartera.radicaciones'
          ]
        }
      },
      {
        path: '/devolucion-glosas',
        name: 'cartera.glosas',
        component: () => import('@/apps/pharmasan/cartera/views/glosas'),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: [
            'cartera.glosas'
          ]
        }
      },
      {
        path: '/conciliaciones',
        name: 'cartera.conciliaciones',
        component: () => import('@/apps/pharmasan/cartera/views/conciliaciones'),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: [
            'cartera.conciliaciones'
          ]
        }
      },
      {
        path: '/configuraciones',
        name: 'cartera.configuraciones',
        component: () => import('@/apps/pharmasan/cartera/views/configuraciones'),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: [
            'cartera.configuraciones'
          ]
        }
      }
    ]
  }
]

import { useStore } from 'vuex'

const can = (permission) => {
  const store = useStore()
  const user = store.getters['auth/user']

  if (user && user.admin) {
    // Si el usuario es un administrador, tiene todos los permisos
    return true
  }

  // Si el usuario no es un administrador, verificamos los permisos específicos
  const permissions = store.getters['auth/getPermissions']

  return !!(user && permissions.some(a => a === permission))
}

const install = app => {
  app.config.globalProperties.$can = can
}

export { install as default, can }

<template>
  <section class="my-5 intro-x">
    <div class="w-full flex items-center mb-5">
      <back-button />
      <h1 class="font-bold text-xl ml-2 ">Lista de sistema</h1>
    </div>
    <div class="w-full">
      <DataTable :value="rows">
        <!-- <Column
        header="ID"
        field="id"
        headerStyle="width:70px;"
        bodyClass="text-center"
        /> -->
        <Column
        header="-"
        v-if="$can('pharmasan.administrativa.compras-administrativas.ajustes.listas-sistema.access')"
        headerStyle="width:70px;"
        >
          <template #body="{data}">
            <router-link
            :to="data.route"
            class="btn btn-primary p-1"
            >
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
              </svg>
            </router-link>
          </template>
        </Column>
        <Column
        header="NOMBRE"
        field="name"
        />
      </DataTable>
    </div>
  </section>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { ref } from 'vue'
export default {
  components: {
    DataTable,
    Column
  },
  setup () {
    const rows = ref([
      // {
      //   id: 1,
      //   name: 'ListaEstadoOrden',
      //   edit: true,
      //   route: {
      //     name: 'admin.compras.ajustes.estados.list'
      //   }
      // },
      {
        id: 2,
        name: 'ListaUnidadesMedida',
        edit: true,
        route: {
          name: 'pharmasan.administrativa.compras-administrativas.ajustes.unidades.list'
        }
      },
      // {
      //   id: 7,
      //   name: 'ListaPresentacion',
      //   edit: true,
      //   route: {
      //     name: 'admin.compras.ajustes.presentacion.list'
      //   }
      // },
      {
        id: 3,
        name: 'ListaCategoriaProducto',
        edit: true,
        route: {
          name: 'pharmasan.administrativa.compras-administrativas.ajustes.list'
        }
      },
      // {
      //   id: 4,
      //   name: 'ListaSubCategoriaProducto',
      //   edit: true,
      //   route: {
      //     name: 'admin.compras.ajustes.unidades.list'
      //   }
      // },
      // {
      //   id: 5,
      //   name: 'ListaTansacciones',
      //   edit: true,
      //   route: {
      //     name: 'admin.compras.ajustes.unidades.list'
      //   }
      // },
      {
        id: 6,
        name: 'ListaImpuesto',
        edit: true,
        route: {
          name: 'pharmasan.administrativa.compras-administrativas.ajustes.impuestos.list'
        }
      }
    ])
    return {
      rows
    }
  }
}
</script>

<style>

</style>

import { useStore } from '@/store'

export default function (to, from, next) {
  const store = useStore()
  if (store.getters['auth/getToken']) {
    next({ name: 'dashboard' })
  } else {
    next()
  }
}

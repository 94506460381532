import VueRouterMultiguard from 'vue-router-multiguard'
import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import securityAccess from '../../../middleware/securityAccess'
import inventario from './inventario/route/index.js'
import ventas from './ventas/route/index.js'

const routes = [
  {
    path: '/pharmasan/informes',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: '',
        name: 'pharmasan.informes',
        component: () => import('./index.vue'),
        redirect: { name: 'pharmasan.informes.home' },
        beforeEnter: [securityAccess],
        meta: {
          access: [
            'pharmasan.informes'
          ],
          activeMenu: 'pharmasan.informes'
        },
        children: [
          {
            path: '',
            name: 'pharmasan.informes.home',
            component: () => import('./home/index.vue'),
            beforeEnter: [securityAccess],
            meta: {
              access: [
                'pharmasan.informes'
              ],
              activeMenu: 'pharmasan.informes'
            }
          },
          ...inventario,
          ...ventas
        ]
      }
    ]
  }
]

export default routes

<template>
  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">
        <div class="flex flex-col sm:flex-row items-center p-4 border-b border-gray-200 dark:border-dark-5">
          <h2 class="mr-auto text-lg font-bold">Listado de Pagos Pendientes</h2>
        </div>
        <ListPaymentsPending />
      </div>
    </div>
  </div>
</template>

<script>
// import FiltersPayments from './filters'
import ListPaymentsPending from './listPaymentsPending'

export default {
  name: 'listPayments',
  components: {
    // FiltersPayments,
    ListPaymentsPending
  }
}
</script>

<style scoped>

</style>

import { reactive } from 'vue'
import { success } from '../../../../../../libs/mensajes'
import * as Yup from 'yup'
import PermissionsCreate from '../services/create'

/*
* Reactives
*/
const model = reactive({
  name: '',
  description: '',
  action: ''
})

/*
* Methods
*/
const schema = Yup.object().shape({
  name: Yup.string().required().max(255).min(6),
  description: Yup.string().required().max(255).min(6),
  action: Yup.string().required().max(255).min(6)
})

const loadBusiness = (props, context) => {
  /*
  * Methods
  */
  const handleSubmit = (values) => {
    PermissionsCreate(model).then(({ status, data }) => {
      success()
    }).catch(err => {
      console.error(err.message)
    })
  }

  return {
    model,
    handleSubmit,
    schema
  }
}

export {
  loadBusiness
}

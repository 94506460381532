<template>
  <Toast />
  <Toolbar class="box mt-4 p-p-2">
    <template #start>
      <back-button />
    </template>
    <template #end>
      <Button v-if="$can('pharmasan.contabilidad.amortizaciones.configuracion.lineas-credito.access')" label="Agregar" icon="pi pi-plus" class="p-button-success p-button-sm" @click="openNew" />
    </template>
  </Toolbar>

  <div class="box mt-4 intro-x">
    <DataTable
      showGridlines
      ref="dt"
      :value="creditLines"
      class="p-datatable-sm p-2 text-sm"
      dataKey="id"
      :paginator="true" :rows="10" :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5,10,25]"
      currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
          <h3 class="p-mb-2 p-m-md-0 p-as-md-center text-lg">Listado de Líneas de Crédito</h3>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Buscar..." />
          </span>
        </div>
      </template>

      <!-- Columnas -->
      <Column field="name" header="Nombre" :sortable="true" style="min-width:16rem"></Column>
      <Column field="status" header="Estado" :sortable="true" style="min-width:12rem">
        <template #body="{ data }">
          <span :class="'creditLine-badge px-2 py-1 text-xs font-bold leading-none text-white rounded ' + (data.status === true ? 'bg-green-700' : 'bg-red-700')">{{data.status === true ? 'Activo' : 'Inactivo'}}</span>
        </template>
      </Column>
      <Column v-if="$can('pharmasan.contabilidad.amortizaciones.configuracion.lineas-credito.access')" :exportable="false" header="Acciones" style="text-align: center">
        <template #body="{ data }">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="editCreditLine(data)" />
          <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteCreditLine(data)" />
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog v-model:visible="creditLineDialog" :style="{width: '450px'}" header="Agregar Línea de crédito" :modal="true" class="p-fluid">
    <div class="p-field">
      <label for="name">Nombre</label>
      <InputText id="name" v-model="creditLine.name" required="true" autofocus :class="{'p-invalid': submitted && !creditLine.name}" />
      <small class="p-error" v-if="submitted && !creditLine.name">El nombre es requerido.</small>
    </div>
    <div class="p-field">
      <label for="status">Estado</label>
      <InputSwitch v-model="creditLine.status" id="status" class="p-d-block"/>
    </div>

    <template #footer class="p-text-bold">
      <Button label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-sm" @click="hideDialog"/>
      <Button label="Guardar" icon="pi pi-check" class="p-button-success p-button-sm" @click="saveCreditLine" />
    </template>
  </Dialog>

  <Dialog v-model:visible="deleteCreditLineDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3 text-red-600" style="font-size: 2rem" />
      <span v-if="creditLine">¿Está seguro de eliminar la línea de crédito <b>{{creditLine.name}}</b>?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text p-button-danger" @click="deleteCreditLineDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text p-button-success" @click="removeCreditLine" />
    </template>
  </Dialog>

</template>

<script>
import { ref, onMounted } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import { useToast } from 'primevue/usetoast'

import listCreditLines from '../../../../services/listCreditLines'
import createCreditLine from '../../../../services/createCreditLine'
import updateCreditLine from '../../../../services/updateCreditLine'
import { error } from '../../../../../../../../libs/mensajes'
import deleteCreditLine from '../../../../services/deleteCreditLine'

export default {
  name: 'CRUD Lineas Credito',
  setup () {
    /* Obtener el listado de lineas de credito */
    const fetchCreditLines = () => {
      return listCreditLines().then(({ status, data }) => {
        creditLines.value = data.result
      }).catch(err => {
        console.error(err.message)
      })
    }

    onMounted(() => {
      fetchCreditLines()
    })

    const toast = useToast()
    const creditLines = ref([])
    const creditLineDialog = ref(false)
    const deleteCreditLineDialog = ref(false)
    const deleteCreditLinesDialog = ref(false)
    const creditLine = ref({})
    const selectedCreditLines = ref()
    const submitted = ref(false)
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })
    const openNew = () => {
      creditLine.value = {
        id: null,
        name: '',
        status: true
      }
      submitted.value = false
      creditLineDialog.value = true
    }
    const hideDialog = () => {
      creditLineDialog.value = false
      submitted.value = false
    }
    const editCreditLine = (item) => {
      creditLine.value = { ...item }
      creditLineDialog.value = true
    }
    const confirmDeleteCreditLine = (item) => {
      creditLine.value = item
      deleteCreditLineDialog.value = true
    }

    const saveCreditLine = () => {
      submitted.value = true

      if (creditLine.value.name !== undefined) {
        if (creditLine.value.name.trim()) {
          if (creditLine.value.id) {
            updateCreditLine(creditLine.value.id, creditLine.value).then(() => {
              toast.add({ severity: 'success', summary: 'Exito', detail: 'Línea de crédito actualizada exitosamente', life: 3000 })
              fetchCreditLines()
            }).catch((err) => {
              console.error(err.message)
              error(err.response.data.message)
            })
          } else {
            createCreditLine(creditLine.value).then(() => {
              toast.add({ severity: 'success', summary: 'Exito', detail: 'Línea de crédito creada exitosamente', life: 3000 })
              fetchCreditLines()
            }).catch((err) => {
              console.error(err.message)
              error(err.response.data.message)
            })
          }

          creditLineDialog.value = false
          creditLine.value = {}
        }
      }
    }

    const removeCreditLine = () => {
      deleteCreditLine(creditLine.value.id, creditLine.value).then(() => {
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Línea de crédito eliminada correctamente', life: 3000 })
        fetchCreditLines()
        deleteCreditLineDialog.value = false
        creditLine.value = {}
      }).catch((err) => {
        console.error(err.message)
        error(err.response.data.message)
      })
      // creditLines.value = creditLines.value.filter(val => val.id !== creditLine.value.id)
    }

    return {
      creditLines,
      creditLineDialog,
      deleteCreditLineDialog,
      deleteCreditLinesDialog,
      creditLine,
      selectedCreditLines,
      filters,
      submitted,
      openNew,
      hideDialog,
      editCreditLine,
      confirmDeleteCreditLine,
      saveCreditLine,
      removeCreditLine
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>

<template>
  <div class="box mt-4 intro-x">
    <DataTable
      showGridlines
      ref="dt"
      :lazy="true"
      :value="financialProducts"
      class="p-datatable-sm p-2 text-sm"
      dataKey="id"
      :paginator="true"
      :rows="10"
      :totalRecords="totalRecords"
      :loading="loading"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5,10,25]"
      currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
      responsiveLayout="stack"
      breakpoint="960px"
    >
      <template #header>
        <FiltersFinancialProduct v-model="filters" @search="onFilter" />
      </template>

      <!-- Columnas -->
      <Column field="obligationNumber" header="# Obligación" :sortable="true"></Column>
      <Column field="name" header="Descripción" :sortable="true"></Column>
      <Column field="nameBank" header="Banco" :sortable="true"></Column>
      <Column field="nameObligation" header="Tipo Obligación" :sortable="true"></Column>
      <Column field="disbursement" header="Desembolso" :sortable="true" style="text-align: right">
        <template #body="{data}">
          {{ $h.formatCurrency(parseInt(data.disbursement)) }}
        </template>
      </Column>
      <Column field="disbursementDate" header="Fecha Desembolso" :sortable="true"></Column>
      <Column v-if="$can('pharmasan.contabilidad.amortizaciones.productos-financieros.access')" :exportable="false" header="Acciones" style="text-align: center; min-width:10rem">
        <template #body="{data}">
          <router-link
            :to="{name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.pagos.made', params: {id: data.id}}"
          >
            <Button icon="pi pi-check-circle" class="p-button-rounded p-button-info p-mr-2" v-tooltip.top="'Pagos realizados'"/>
          </router-link>
          <router-link
            v-if="$can('pharmasan.contabilidad.amortizaciones.productos-financieros.access')"
            :to="{name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.tabla', params: {id: data.id}}"
          >
            <Button icon="pi pi-table" class="p-button-rounded p-button-help p-mr-2" v-tooltip.top="'Detalle Amortizaciones'"/>
          </router-link>
          <router-link
            :to="{name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.formulario', params: {id: data.id}}"
          >
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" v-tooltip.top="'Editar'"/>
          </router-link>
          <Button
            icon="pi pi-file-pdf"
            class="p-button-rounded p-button-plain p-mr-2"
            v-tooltip.top="'PDF del producto financiero'"
            @click="exportProductPdf(data.id)"
          />
          <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" v-tooltip.top="'Eliminar'" @click="confirmDeleteFinancialProduct(data)" />
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog v-model:visible="deleteFinancialProductDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3 text-red-600" style="font-size: 2rem" />
      <span v-if="financialProduct">¿Está seguro de eliminar el producto financiero con # de obligacion <b>{{financialProduct.obligationNumber}}</b>?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text p-button-danger" @click="deleteFinancialProductDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text p-button-success" @click="removeFinancialProduct" />
    </template>
  </Dialog>
</template>

<script>
import { onMounted, reactive, ref } from 'vue'
import listFinancialProducts from '../../../services/listFinancialProducts'
import { error, warning } from '../../../../../../../libs/mensajes'
import { useToast } from 'primevue/usetoast'
import deleteFinancialProduct from '../../../services/deleteFinancialProduct'
import FiltersFinancialProduct from '../filters'
import axios from 'axios'

export default {
  name: 'listFinancialProduct',
  components: {
    FiltersFinancialProduct
  },
  setup () {
    const toast = useToast()
    const financialProducts = ref()
    const financialProduct = ref({})
    const deleteFinancialProductDialog = ref(false)
    const totalRecords = ref(0)
    const loading = ref(true)
    const lazyParams = ref({})
    const currentPage = ref(1)
    const sortAssign = ref('createdAt,ASC')
    const dt = ref()
    const filters = ref({
      bankId: { value: null, matchMode: 'contains' },
      obligationNumber: { value: '', matchMode: 'contains' },
      obligationId: { value: null, matchMode: 'contains' }
    })

    /* Obtener el listado de productos financieros */
    const fetchFinancialProducts = (event = false, type, filters) => {
      let queryParams = {}
      if (!event) {
        queryParams = {
          current_page: 1,
          per_page: 10,
          order: 'createdAt,ASC'
        }
      } else {
        if (type === 'sort') {
          sortAssign.value = `${event.sortField},${event.sortOrder === 1 ? 'ASC' : 'DESC'}`
        }
        queryParams = {
          current_page: `${type === 'sort' || type === 'filter' ? currentPage.value : event.page + 1}`,
          per_page: event.rows,
          order: sortAssign.value,
          bankId: filters.bankId.value ? filters.bankId.value : null,
          obligationNumber: filters.obligationNumber.value ? filters.obligationNumber.value : null,
          obligationId: filters.obligationId.value ? filters.obligationId.value : null
        }
      }

      loading.value = true
      return listFinancialProducts(queryParams).then(({ status, data: response }) => {
        loading.value = false
        financialProducts.value = response.result.data
        totalRecords.value = response.result.total
      }).catch(err => {
        loading.value = false
        console.error(err.message)
      })
    }

    const onPage = (event) => {
      lazyParams.value = event
      fetchFinancialProducts(event, 'page', filters.value)
    }

    const onSort = (event) => {
      lazyParams.value = event
      fetchFinancialProducts(event, 'sort', filters.value)
    }

    const onFilter = (event) => {
      fetchFinancialProducts(event, 'filter', filters.value)
    }

    const confirmDeleteFinancialProduct = (item) => {
      financialProduct.value = item
      deleteFinancialProductDialog.value = true
    }

    const removeFinancialProduct = () => {
      deleteFinancialProduct(financialProduct.value.id, financialProduct.value).then(() => {
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Producto financiero eliminado correctamente', life: 3000 })
        fetchFinancialProducts()
        deleteFinancialProductDialog.value = false
        financialProduct.value = {}
      }).catch((err) => {
        console.error(err.message)
        error(err.response.data.message)
      })
    }

    /**/
    const exportProductPdf = (productId, key) => {
      const filters = reactive({
        productId,
        key
      })
      const url = `${process.env.VUE_APP_MICROSERVICE_API_AMORTIZATIONS}/export-product-pdf`
      const res = axios.getUri({ url, params: filters })
      if (filters.productId !== null) {
        window.open(res, '_blank')
      } else {
        warning('No hay un producto')
      }
    }

    /* */
    onMounted(() => {
      loading.value = true

      lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
        filters: filters.value
      }

      fetchFinancialProducts()
    })

    return {
      dt,
      financialProducts,
      financialProduct,
      totalRecords,
      filters,
      loading,
      deleteFinancialProductDialog,
      onPage,
      onSort,
      onFilter,
      confirmDeleteFinancialProduct,
      removeFinancialProduct,
      exportProductPdf
    }
  }
}
</script>

<style scoped>

</style>

import http from '../../../../../../libs/http'
import pemissionsByGroup from './permissionsByGroup'

const api = `${process.env.VUE_APP_MICROSERVICE_API_SECURITY}`

const getGroup = (payload = {}) => http.get(`${api}/group`, { params: { ...payload } })
const removeGroup = (id) => http.delete(`${api}/group/${id}`)
const getUsersByGroup = (id) => http.get(`${api}/group/${id}/users`, { params: { paginate: 2000 } })
const removeUserGroup = (id) => http.delete(` ${api}/user/group/${id}`)

export {
  getUsersByGroup,
  getGroup,
  removeGroup,
  removeUserGroup,
  pemissionsByGroup
}

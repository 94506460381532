import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '@/middleware/securityAccess'
export default [
  {
    path: '/ut-segupharma',
    component: Layouts,
    name: 'ut-segupharma',
    beforeEnter: VueRouterMultiguard([auth]),
    redirect: { name: 'ut-segupharma.ventas' },
    children: [
      {
        path: 'facturacion',
        name: 'ut-segupharma.facturacion',
        component: () => import('@/apps/utsegupharma/facturacion/views/listado.vue'),
        meta: {
          access: [
            'ut-segupharma.facturacion'
          ],
          activeMenu: 'ut-segupharma.facturacion'
        }
      },
      {
        path: 'historial',
        name: 'ut-segupharma.facturacion.hitorial',
        component: () => import('@/apps/utsegupharma/facturacion/views/historial/index.vue'),
        // component: () => import('@/apps/utsegupharma/facturacion/views/Historial.vue'),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: [
            'ut-segupharma.facturacion.hitorial'
          ],
          activeMenu: 'ut-segupharma.facturacion'
        }
      },
      {
        path: 'historial-detalle-evento/:id',
        name: 'ut-segupharma.facturacion.hitorial-detalle',
        component: () => import('@/apps/utsegupharma/facturacion/views/HistorialDetalle.vue'),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: [
            'ut-segupharma.facturacion.hitorial-detalle'
          ],
          activeMenu: 'ut-segupharma.facturacion'
        }
      }
    ]
  }
]

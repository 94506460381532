<template>
  <div class="flex items-center text-primary-1">
    <router-link
    v-if="routeList"
    :to="routeList"
    class="mx-1 btn btn-primary"
    title="Ver tipo lista"
    >
      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M9,5V9H21V5M9,19H21V15H9M9,14H21V10H9M4,9H8V5H4M4,19H8V15H4M4,14H8V10H4V14Z" />
    </svg>
    </router-link>
    <router-link
    v-if="routeGrid"
    :to="routeGrid"
    class="btn btn-primary"
    title="Ver tipo grid"
    >
      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M3 11H11V3H3M5 5H9V9H5M13 21H21V13H13M15 15H19V19H15M3 21H11V13H3M5 15H9V19H5M13 3V11H21V3M19 9H15V5H19Z" />
      </svg>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    routeList: {
      type: [Object, String],
      default: ''
    },
    routeGrid: {
      type: [Object, String],
      default: ''
    }
  }
}
</script>

<style>

</style>

<template>
  <Toast />
  <Toolbar class="box mt-4 p-p-2">
    <template #start>
      <back-button />
    </template>
  </Toolbar>

  <InfoFinancialProduct :info="financialProduct" v-if="!newFormulario"/>

  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">
        <div
          class="flex flex-col sm:flex-row justify-between items-center p-3 border-b border-gray-200 dark:border-dark-5"
          :style="`border-top-left-radius: 6px; border-top-right-radius: 6px;  border-top-color: ${isEdit ? '#FAB711' : '#065f46'}; border-top-width: 8px;`"
        >
          <h2 class="font-medium text-lg mr-auto">{{ isEdit ? 'Pago de Obligación Financiera' : 'Nuevo Pago de Obligación Financiera' }}</h2>
        </div>
        <div class="p-5 custom-style">
          <form action="" @submit.prevent="onSubmit">

            <Fieldset legend="Encabezado" :toggleable="true" class="p-2">
              <div class="p-fluid p-formgrid p-grid" >
                <div class="p-field p-col-12 p-md-4">
                  <label>Tipo de pago</label>
                  <Dropdown
                    v-model="modelo.typesOfPaymentsId"
                    :options="typesOfPayments"
                    optionLabel="name"
                    option-value="id"
                    placeholder="Seleccione"
                    :filter="true"
                    filterPlaceholder="Buscar"
                    :class="{ 'p-invalid': errors.typesOfPaymentsId }"
                  />
                  <MessageError :text="errors[`typesOfPaymentsId`]" />
                </div>
                <div class="p-field p-col-12 p-md-8" v-if="newFormulario">
                  <label for="banks">Banco:</label>
                  <Dropdown
                    id="banks"
                    :options="banks"
                    v-model="modelo.bankId"
                    @change="fetchObligationNumbers"
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Seleccione una opción"
                    :filter="true"
                    scrollHeight="auto"
                    filterPlaceholder="Buscar por nombre"
                    :class="{ 'p-invalid': errors.bankId }"
                  />
                  <MessageError :text="errors[`bankId`]" />
                </div>
              </div>
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                  <label for="dueDate">Fecha de contabilización</label>
                  <InputText
                    id="dueDate"
                    type="date"
                    v-model="modelo.fechaContabilizacion"
                  />
                </div>
                <div class="p-field p-col-12 p-md-4">
                  <label for="dueDate">Fecha de vencimiento</label>
                  <InputText
                    id="dueDate"
                    type="date"
                    v-model="modelo.fechaVencimiento"
                  />
                </div>
                <div class="p-field p-col-12 p-md-4">
                  <label for="dueDate">Fecha de documento</label>
                  <InputText
                    id="dueDate"
                    type="date"
                    v-model="modelo.fechaDocumento"
                  />
                </div>
                <div class="p-field p-col-12 p-md-12">
                  <label for="name">Comentario</label>
                  <Textarea
                    v-model="modelo.commentary"
                    id="name"
                    :class="{ 'p-invalid': errors.commentary }"
                    :autoResize="true"
                    rows="2"
                    maxlength="50"
                  />
                  <MessageError :text="errors.commentary" />
                </div>
              </div>
            </Fieldset>

            <Fieldset legend="Detalle" :toggleable="true" class="p-2 mt-6">
              <div class="p-fluid" v-for="(detailsProduct, i) in modelo.detailsProduct" :key="i">
                <div class="p-field p-grid" v-if="newFormulario">
                  <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Número de la Obligación:</label>
                  <div class="p-col-12 p-md-10">
                    <div class="p-inputgroup">
                      <Dropdown
                        v-model="detailsProduct.productId"
                        :options="obligations"
                        optionLabel="obligationNumber"
                        option-value="id"
                        placeholder="Seleccione"
                        :filter="true"
                        filterPlaceholder="Buscar"
                        @change="onChangeProductosFinancieros($event, i)"
                        :class="{ 'p-invalid': errors[`detailsProduct[${i}].productId`] }"
                      />
                      <Button
                        icon="pi pi-info"
                        class="p-button-info"
                        v-tooltip.left="'Información del producto financiero'"
                        @click="toggleModal(detailsProduct.productId, false)"
                        :disabled="!detailsProduct.productId"
                      />
                    </div>
                    <!-- <pre>{{ JSON.stringify(detailsProduct, null, '\t') }}</pre>-->
                    <MessageError :text="errors[`detailsProduct[${i}].productId`]" />
                  </div>
                </div>
                <input v-else type="hidden" v-model="detailsProduct.productId">

                <div class="p-field p-grid">
                  <div class="p-col-12 p-md-12">
                    <DataTable
                      :value="modelo.detailsProduct[i].detailsAmortizations"
                      responsiveLayout="stack"
                      breakpoint="960px"
                      showGridlines
                      editMode="cell"
                      @cell-edit-complete="onCellEditComplete"
                      class="p-datatable-sm editable-cells-table"
                      :class="classNameDataTable"
                    >
                      <Column headerStyle="width: 5rem; text-align: center" header="Eliminar" bodyStyle="text-align: center; overflow: visible">
                        <template #body="{ data }">
                          <Button
                            type="button"
                            class="p-button-danger p-button-xs"
                            icon="pi pi-trash"
                            v-tooltip.top="'Eliminar fila'"
                            @click="removeRowAccountSap(data, i)"
                          ></Button>
                        </template>
                      </Column>
                      <Column header="Código cuenta SAP">
                        <template #body="{ data }">
                          <div v-if="data.accountSap" >
                            {{ data.accountSap }}
                          </div>
                          <div v-else class="p-inputgroup">
                            <AutocompleteAccountsSap
                              ref="autocompleteAccount"
                              v-model="data.accountSap"
                              @changeValues="onChangeValues($event, data._key, i)"
                              :class="{ 'p-invalid': errors[`detailsProduct[${i}].detailsAmortizations[${data._key}].accountSap`] }"
                            />
                          </div>
                          <MessageError :text="errors[`detailsProduct[${i}].detailsAmortizations[${data._key}].accountSap`]" />
                        </template>
                      </Column>
                      <Column field="nameAccount" header="Nombre cuenta"></Column>
                      <Column field="debit" header="Debito" bodyStyle="text-align: right">
                        <template #body="{data}">
                          {{ $h.formatCurrency(data.debit, 2) }}
                        </template>
                        <template #editor="{ data, field }">
                          <InputNumber
                            v-model="data[field]"
                            mode="currency"
                            currency="COP"
                            locale="es-CO"
                            :minFractionDigits="2"
                            maxFractionDigits="2"
                          />
                        </template>
                      </Column>
                      <Column header="Credito" bodyStyle="text-align: right">
                        <template #body="{ data }">
                          <div v-if="!data.isPayment" >
                            {{ data.credit }}
                          </div>
                          <div v-else class="p-inputgroup">
                            <InputNumber
                              v-model="modelo.detailsProduct[i].detailsAmortizations[data._key].credit"
                              mode="currency"
                              currency="COP"
                              locale="es-CO"
                              minFractionDigits="2"
                              maxFractionDigits="2"
                              id="credit"
                            />
                          </div>
                          <MessageError :text="errors[`detailsAmortizations[${data._key}].credit`]" />
                        </template>
                      </Column>
                      <ColumnGroup type="footer">
                        <Row>
                          <Column footer="Totales:" :colspan="3" footerStyle="text-align:right"/>
                          <Column :footer="debitTotalMethod(i)" footerStyle="text-align:right" />
                          <Column :footer="creditTotalMethod(i)" footerStyle="text-align:right" />
                        </Row>
                      </ColumnGroup>
                    </DataTable>
                  </div>
                </div>
                <div class="p-field p-grid">
                  <div class="p-col-12 p-md-4 p-lg-3">
                    <Button
                      label="Agregar Pago"
                      icon="pi pi-plus"
                      class="p-button-xs"
                      @click="addRowAccountSap(e, i)"
                      v-tooltip.top="'Agregar Pago'"
                    />
                  </div>
                  <div class="p-col-12 p-md-4 p-lg-3" v-if="newFormulario">
                    <Button
                      label="Eliminar Obligación"
                      icon="pi pi-minus"
                      class="p-button-danger p-button-xs"
                      @click="removeContainerObligation(detailsProduct._key)"
                      v-tooltip.top="'Eliminar Obligacion'"
                    />
                  </div>
                </div>
                <div style="display: block; border: 1px dashed gray; margin: 0 0 16px 0" v-show="(i + 1 !== modelo.detailsProduct.length)"></div>
              </div>
            </Fieldset>

          </form>
        </div>
      </div>
    </div>

    <Button
      label="Agregar Obligacion"
      icon="pi pi-plus"
      class="p-button-info floating-button mr-32"
      @click="addContainerObligation"
      v-tooltip.top="'Agregar Obligación'"
      v-if="newFormulario"
    />
    <Button
      icon="pi pi-send"
      label="Guardar"
      class="p-button-success floating-button"
      @click="onSubmit"
    />
  </div>

  <!-- Modal de informacion del producto financiero -->
  <modalInfo
    ref="modalInfo"
    :modalActive="modalActive"
    @updateModal="toggleModal(null, true)"
    :info="financialProduct"
  />

</template>

<script>
import { computed, defineAsyncComponent, onMounted, reactive, ref } from 'vue'
import { useField, useForm } from 'vee-validate'
import { array, number, object, string } from 'yup'
// import { useLoading } from 'vue3-loading-overlay'
import findFinancialProduct from '../../../../services/findFinancialProduct'
import { error, messageConfirmSave, success } from '../../../../../../../../libs/mensajes'
import { useRoute, useRouter } from 'vue-router'
import createPayment from '../../../../services/createPayment'
import InfoFinancialProduct from '../../info'
import getNextPayment from '../../../../services/getNextPayment'
import listTableAmortizationsDetails from '../../../../services/listTableAmortizationsDetails'
import AutocompleteAccountsSap from '../../../../../../../../components/select/accountsSap'
import { helper } from '../../../../../../../../utils/helper'
import { useToast } from 'primevue/usetoast'
import serviceFinancialProductos from '@/apps/pharmasan/accounting/amortizations/services/listFinancialProducts'
import serviceTypesOfPayments from '@/apps/pharmasan/accounting/amortizations/services/listTypePayments'
import listBanks from '../../../../services/listBanks'
import listOptionFinancialProducts from '../../../../services/listOptionFinancialProducts'

export default {
  name: 'formPayments',
  components: {
    InfoFinancialProduct,
    AutocompleteAccountsSap,
    modalInfo: defineAsyncComponent(() => import('../../info/modalInfo'))
  },
  setup () {
    const modalActive = ref(false)
    const financialProduct = reactive({
      name: '',
      bank: '',
      typeObligation: '',
      obligationNumber: '',
      disbursement: '',
      disbursementDate: ''
    })
    const toast = useToast()
    const router = useRouter()
    const route = useRoute()
    const banks = ref()
    const obligations = ref()
    const isEdit = computed(() => {
      return !!route.params.id
    })

    /* Obtener el listado de bancos */
    const fetchBanks = () => {
      return listBanks({ options: true }).then(({ status, data }) => {
        banks.value = data.result
      }).catch(err => {
        console.error(err.message)
      })
    }

    /* Obtener el listado de las obligaciones asociadas al banco */
    const fetchObligationNumbers = (e) => {
      const bankId = e.value
      if (bankId) {
        return listOptionFinancialProducts({ bankId }).then(({ status, data }) => {
          obligations.value = data.result
          console.log({ obligations })
        }).catch(err => {
          console.error(err.message)
        })
      }

      obligations.value = null
    }

    const toggleModal = async (productId, isClosed) => {
      modalActive.value = !modalActive.value
      if (!isClosed) {
        await fetchInfoFinancialProduct(productId)
      }
    }

    /* Obtener el producto financiero */
    const fetchFinancialProduct = (id, i) => {
      return findFinancialProduct({ id }).then(async ({ status, data: response }) => {
        if (status !== 200) return
        modelo.detailsProduct[i].productId = response.result.id
        modelo.bankId = response.result.bankId
        modelo.detailsProduct[i].obligationNumber = response.result.obligationNumber
        if (!newFormulario.value) {
          await fetchInfoFinancialProduct(id)
        }
        await fetchInfoNextPayment(response.result.id, i)
      })
    }

    const fetchInfoFinancialProduct = (id) => {
      return findFinancialProduct({ id }).then(async ({ status, data: response }) => {
        if (status !== 200) return
        financialProduct.id = response.result.id
        financialProduct.bankId = response.result.bankId
        financialProduct.name = response.result.name
        financialProduct.bank = response.result.AmortizationsBank.name
        financialProduct.typeObligation = response.result.AmortizationsBankQuota.creditLine.name
        financialProduct.obligationNumber = response.result.obligationNumber
        financialProduct.disbursement = response.result.disbursement
        financialProduct.disbursementDate = response.result.disbursementDate
        financialProduct.term = response.result.term
        financialProduct.rateEA = response.result.rateEA
        financialProduct.periodicity = response.result.periodicity
      })
    }

    /* Obtener la información de la ultima cuota pendiente por pagar de la obligacion */
    const fetchInfoNextPayment = (e, i) => {
      return getNextPayment({ productId: e }).then(({ status, data }) => {
        console.log('Data: ', data)
        fetchAmortizationDetails(data.result.id, i)
        modelo.detailsProduct[i].amortizatioId = data.result.id
        modelo.detailsProduct[i].numberInstallment = data.result.installmentNumber
      }).catch(err => {
        console.error(err.message)
      })
    }

    /* Obtener el detalle de la amortizacion para el pago */
    const fetchAmortizationDetails = async (amortizationId, i) => {
      const response = await listTableAmortizationsDetails({ amortizationId })
      console.log('Response: ', response)
      modelo.detailsProduct[i].detailsAmortizations = response.data.result.map((r, i) => {
        return {
          ...r,
          credit: 0,
          _key: i,
          isPayment: false
        }
      })
    }

    /* Agregar contenedor de la obligacion */
    const addContainerObligation = () => {
      modelo.detailsProduct.push({
        _key: modelo.detailsProduct.length,
        productId: null,
        amortizatioId: null,
        numberInstallment: null,
        obligationNumber: null,
        detailsAmortizations: [{
          accountSap: null,
          nameaccount: null,
          _key: 0,
          debit: 0,
          credit: 0
        }]
      })
    }

    /* Funcion para remover la fila (obligacion) */
    const removeContainerObligation = (_key) => {
      modelo.detailsProduct.splice(_key, 1)

      for (const key in modelo.detailsProduct) {
        modelo.detailsProduct[key]._key = key
      }
    }

    /* Funcion para agregar una nueva fila (pagos) */
    const addRowAccountSap = (event, i) => {
      modelo.detailsProduct[i].detailsAmortizations.push({ accountSap: null, debit: 0, credit: null, id: null, _key: modelo.detailsProduct[i].detailsAmortizations.length, nameAccount: null, isPayment: true })
    }

    /* Funcion para remover la fila (pagos) */
    const removeRowAccountSap = (data, i) => {
      modelo.detailsProduct[i].detailsAmortizations.splice(data._key, 1)

      for (const key in modelo.detailsProduct[i].detailsAmortizations) {
        modelo.detailsProduct[i].detailsAmortizations[key]._key = key
      }
    }

    const onChangeValues = (value, _key, i) => {
      modelo.detailsProduct[i].detailsAmortizations[_key] = { accountSap: value.acctcode, debit: 0, credit: null, nameAccount: value.acctname, _key, isPayment: true }
    }

    /* Esquema de validacion */
    let validationSchema = object({
      numberInstallment: number().min(1).typeError('El campo es requerido').positive().label('Número Cuota'),
      typesOfPaymentsId: string().nullable().required().label('Tipo de Pago'),
      bankId: string().nullable().required().label('Banco'),
      detailsProduct: array().of(
        object({
          productId: number().min(1).typeError('El campo es requerido').positive().label('Id de obligación'),
          amortizatioId: number().min(1).typeError('El campo es requerido').positive().label('Id de la amortización'),
          numberInstallment: number().min(1).typeError('El campo es requerido').positive().label('Número de la cuota'),
          detailsAmortizations: array().of(
            object({
              accountSap: number().typeError('El campo es requerido').positive().label('Cuenta SAP')
            })
          )
        })
      ),
      commentary: string().nullable().required().label('Comentario')
    })

    // Create a form context with the validation schema
    const { errors, handleSubmit, values: modelo, handleReset } = useForm({
      validationSchema
    })

    // No need to define rules for fields
    useField('fechaContabilizacion', null, { initialValue: new Date().toISOString().slice(0, 10) })
    useField('fechaVencimiento', null, { initialValue: new Date().toISOString().slice(0, 10) })
    useField('fechaDocumento', null, { initialValue: new Date().toISOString().slice(0, 10) })
    useField('commentary', null, { initialValue: null })
    useField('typesOfPaymentsId', null, { initialValue: null })
    useField('bankId', null, { initialValue: null })
    useField('detailsProduct', null, {
      initialValue: [{
        _key: 0,
        productId: null,
        amortizatioId: null,
        numberInstallment: null,
        obligationNumber: null,
        detailsAmortizations: [{
          accountSap: null,
          nameaccount: null,
          _key: 0,
          debit: 0,
          credit: 0
        }]
      }]
    })

    // Submit del envio de los datos
    const onSubmit = handleSubmit((values, actions) => {
      console.log('Values: ', values)
      console.log('Actions: ', actions)

      /* Validacion que no supere los 50 caracteres en el campo del comentario */
      if (values.commentary.length > 50) {
        toast.add({ severity: 'warn', summary: 'Error', detail: 'El comentario del pago excedió el limite de 50 caracteres.', life: 3000 })
        return true
      }

      /**/
      const sendResult = []
      let isValid = true
      values.detailsProduct.map((detail, i) => {
        /* Validar que se agrego pago para registrar */
        const validateAddPayment = detail.detailsAmortizations.filter((det) => {
          return det.credit > 0
        })
        if (validateAddPayment.length <= 0) {
          toast.add({ severity: 'warn', summary: 'Validación', detail: `Debe agregar al menos un pago y su valor correspondiente para registrar en SAP (# Obligación: ${detail.obligationNumber})`, life: 3000 })
          isValid = false
        }

        const paymentChildrens = detail.detailsAmortizations.map((payment, j) => {
          return {
            accountSap: payment.accountSap,
            debit: payment.debit,
            credit: payment.credit
          }
        })
        sendResult.push({
          productId: detail.productId,
          amortizatioId: detail.amortizatioId,
          typesOfPaymentsId: values.typesOfPaymentsId,
          installmentNumber: newFormulario.value ? null : detail.numberInstallment,
          commentary: values.commentary,
          refDate: values.fechaContabilizacion,
          taxDate: values.fechaDocumento,
          dueDate: values.fechaVencimiento,
          paymentChildrens
        })
      })
      console.log('sendResult: ', sendResult)

      if (!isValid) return true

      // const loader = useLoading()
      // loader.show()
      messageConfirmSave('', 'Registrar Pago', 'warning', '¿Esta seguro de enviar el registro del pago a SAP?').then(({ isConfirmed }) => {
        if (isConfirmed) {
          createPayment(sendResult).then((res) => {
            // loader.hide()
            success('Pago realizado exitosamente')
            router.back()
          }).catch((err) => {
            // loader.hide()
            console.error(err.message)
            if (err.response.status) {
              error(err.response.data.message)
            }
          })
        } else {
          // loader.hide()
        }
      })
    })

    const onCellEditComplete = (event) => {
      const { data, newValue, field } = event
      switch (field) {
        default:
          data[field] = newValue
          break
      }
    }

    /**/
    const debitTotalMethod = (i) => {
      const totalDebit = modelo.detailsProduct[i].detailsAmortizations.reduce((tot, arr) => {
        return tot + arr.debit
      }, 0)

      return helper.formatCurrency(totalDebit, 2)
    }

    const creditTotalMethod = (i) => {
      const totalCredit = modelo.detailsProduct[i].detailsAmortizations.reduce((tot, arr) => {
        return tot + arr.credit
      }, 0)

      return helper.formatCurrency(totalCredit, 2)
    }

    /* formulario para pogos */
    /* data */
    const newFormulario = ref(false)
    const productosFinancieros = ref([])
    const classNameDataTable = ref('')
    const typesOfPayments = ref([])
    /* methods */
    const loadInfoForNewPayment = async () => {
      const { data } = await serviceFinancialProductos({ per_page: 1000 })
      if (data) productosFinancieros.value = data.result.data ?? []
    }
    const onChangeProductosFinancieros = (e, i) => {
      if (e.value) fetchFinancialProduct(e.value, i)
    }
    const loadTypesOfPayments = async () => {
      const { data } = await serviceTypesOfPayments({ options: true })
      if (data) typesOfPayments.value = data.result ?? []
    }
    const newValidationSchema = () => {
      validationSchema = object({
        numberInstallment: number().min(1).typeError('El campo es requerido').positive().label('Número Cuota'),
        typesOfPaymentsId: number().min(1).typeError('El campo es requerido').positive().label('tipo de pago'),
        detailsProduct: array().of(
          object({
            productId: number().min(1).typeError('El campo es requerido').positive().label('Número de obligación'),
            detailsAmortizations: array().of(
              object({
                accountSap: number().typeError('El campo es requerido').positive().label('Cuenta SAP')
              })
            )
          })
        ),
        commentary: string().nullable().required().label('Comentario')
      })
    }
    /** hooks */
    onMounted(() => {
      const { id } = route.params
      if (id) {
        fetchFinancialProduct(id, 0)
      } else {
        newFormulario.value = true
        classNameDataTable.value = 'editable-cells-table'
        loadInfoForNewPayment()
        newValidationSchema()
        fetchBanks()
        useField('productId', null, { initialValue: null })
        setTimeout(() => {
          handleReset()
        }, 200)
      }
      loadTypesOfPayments()
    })

    return {
      onSubmit,
      errors,
      modelo,
      financialProduct,
      addRowAccountSap,
      removeRowAccountSap,
      onChangeValues,
      debitTotalMethod,
      creditTotalMethod,
      productosFinancieros,
      newFormulario,
      onChangeProductosFinancieros,
      classNameDataTable,
      typesOfPayments,
      fetchBanks,
      banks,
      fetchObligationNumbers,
      obligations,
      addContainerObligation,
      removeContainerObligation,
      modalActive,
      toggleModal,
      isEdit,
      onCellEditComplete
    }
  }
}
</script>

<style lang="scss" scoped>
.floating-button {
  position: fixed !important;
  bottom: 30px;
  right: 40px;
  z-index: 10050;
}
</style>

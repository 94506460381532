<template>
  <Toast />
  <Toolbar v-if="$can('pharmasan.contabilidad.amortizaciones.productos-financieros')" class="box mt-4 p-p-2">
    <template #end>
      <router-link
        :to="{name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.formulario'}"
      >
        <Button v-if="$can('pharmasan.contabilidad.amortizaciones.productos-financieros.access')" label="Agregar" icon="pi pi-plus" class="p-button-success p-button-xs" />
      </router-link>
    </template>
  </Toolbar>

  <listFinancialProduct />
</template>

<script>
import listFinancialProduct from '../list'

export default {
  name: 'homefinancialProducts',
  components: { listFinancialProduct }
}
</script>

<style scoped>

</style>

<template>
  <div
  ref="modalUnico"
  class="modal"
  tabindex="-1"
  aria-hidden="true"
  >
    <div
    class="modal-dialog "
    :class="[size]"
    >
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body p-10">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'Modal',
  props: {
    id: {
      type: [Number, String],
      default: () => +(new Date())
    },
    size: {
      type: String,
      default: 'modal-xl'
    }
  },

  setup () {
    const modalUnico = ref(null)
    const toggle = () => {
      cash(modalUnico.value).modal('toggle')
    }
    return {
      modalUnico,
      toggle
    }
  }
}
</script>

<template>
  <div class="box mb-4">
    <TabMenu :model="itemsGeneral" v-model:activeIndex="active"/>
    <router-view/>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default defineComponent({
  components: {},
  setup () {
    const route = useRoute()
    const router = useRouter()
    const active = ref(1)
    const items = ref([
      {
        label: 'Ingresos',
        icon: 'pi pi-fw pi-home',
        to: '/pettycash-newgas/incomes',
        class: ''
      },
      {
        label: 'Egresos',
        icon: 'pi pi-fw pi-calendar',
        to: '/pettycash-newgas/expenses',
        class: ''
      }
    ])
    const itemsGeneral = computed(() => {
      const store = useStore()
      const user = store.getters['auth/user']
      if (!user.admin) {
        const permissions = store.getters['auth/getPermissions']
        if (permissions.some(a => a === 'notIngresos')) {
          // eslint-disable-next-line
          setTimeout(() => {
            router.push('/pettycash-newgas/expenses')
          }, 0)
          return items.value.filter(x => x.label !== 'Ingresos')
        }
      }
      return items.value
    })

    watch(() => route.name, () => {
      if (route.name === 'pettycash-newgas.incomes') {
        items.value[0].class = 'p-highlight'
        items.value[1].class = ''
      } else if (route.name === 'pettycash-newgas.expenses' || route.name === 'pettycash-newgas.expensesFull') {
        items.value[0].class = ''
        items.value[1].class = 'p-highlight'
      }
    })

    onMounted(() => {
      if (route.name === 'pettycash-newgas.incomes') {
        items.value[0].class = 'p-highlight'
        items.value[1].class = ''
      } else if (route.name === 'pettycash-newgas.expenses' || route.name === 'pettycash-newgas.expensesFull') {
        items.value[0].class = ''
        items.value[1].class = 'p-highlight'
      }
      const store = useStore()
      const user = store.getters['auth/user']
      if (!user.admin) {
        const permissions = store.getters['auth/getPermissions']
        if (permissions.some(a => a === 'notIngresos')) {
          router.push('/pettycash-newgas/expenses')
        }
      }
    })

    return {
      active,
      items,
      itemsGeneral
    }
  }
})
</script>

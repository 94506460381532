<template>
  <section class="container">
    <div class="box flex items-center my-4">
      <div class="flex" style="width:70px;" >
        <div class="">
          <input
          :checked="darkMode"
          id="show-example-1"
          data-target="#basic-accordion"
          class="show-code form-check-switch mr-0 ml-3"
          type="checkbox"
          @change="handleChange"
          />
        </div>
      </div>
      <div class="w-2/3  py-3">
        Modo oscuro
      </div>
    </div>
  </section>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import cash from 'cash-dom'
export default defineComponent({
  setup () {
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)

    const handleChange = (e) => {
      console.log(' watchhh', e)
      store.dispatch('main/setDarkMode', e.target.checked)
      setDarkModeClass()
    }

    const setDarkModeClass = () => {
      darkMode.value
        ? cash('html').addClass('dark')
        : cash('html').removeClass('dark')
    }

    return {
      darkMode,
      handleChange
    }
  }
})
</script>

<style>

</style>

import { round } from 'lodash'
import Swal from 'sweetalert2'
const funciones = {
  cargando (toggle = 1) {
    if (toggle) {
      Swal.fire({
        title: 'Procesando...',
        // html: 'Please wait...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        }
      })
    } else {
      Swal.close()
    }
  },
  liquides (ACTIVO, PASIVO, INDICADOR) {
    let res = 0
    if (PASIVO) res = round(ACTIVO / PASIVO, 2)
    const str = `${res} ${INDICADOR.operation} ${INDICADOR.valor}`
    return {
      // eslint-disable-next-line no-eval
      message: eval(str) ? 'Cumple' : 'No cumple',
      valor: res
    }
  },
  endeudamiento (PASIVO, ACTIVO, INDICADOR) {
    let res = 0
    if (ACTIVO) res = round(PASIVO / ACTIVO, 2)
    const str = `${res} ${INDICADOR.operation} ${INDICADOR.valor}`
    return {
      // eslint-disable-next-line no-eval
      message: eval(str) ? 'Cumple' : 'No cumple',
      valor: res
    }
  },
  patrimonio (presupuesto, indice, vlrRequerido, patrimonio) {
    const res = presupuesto * vlrRequerido
    const str = `${patrimonio} ${indice} (${presupuesto}*${vlrRequerido})`
    return {
      // eslint-disable-next-line no-eval
      message: eval(str) ? 'Cumple' : 'No cumple',
      valor: res
    }
  },
  capitalTrabajo (activoC, pasivoC, indice, presupuesto, vlrRequerido) {
    const str = `(${activoC}-${pasivoC}) ${indice} ${presupuesto}*${vlrRequerido}`
    return {
      // eslint-disable-next-line no-eval
      message: eval(str) ? 'Cumple' : 'No cumple',
      valor: 'N/A'
    }
  },
  rentabilidadPatrimonio (utilOp, patrimonio, INDICADOR) {
    let res = 0
    if (patrimonio) res = round(utilOp / patrimonio, 2)
    const str = `${res} ${INDICADOR.operation} ${INDICADOR.valor}`
    return {
      // eslint-disable-next-line no-eval
      message: eval(str) ? 'Cumple' : 'No cumple',
      valor: res
    }
  },
  rentabilidadActivo (utilOp, activoT, INDICADOR) {
    let res = 0
    if (activoT) res = round(utilOp / activoT, 2)
    const str = `${res} ${INDICADOR.operation} ${INDICADOR.valor}`
    return {
      // eslint-disable-next-line no-eval
      message: eval(str) ? 'Cumple' : 'No cumple',
      valor: res
    }
  },
  razonCoberturaInteres (utilOp, gin, INDICADOR) {
    let res = 0
    if (gin) res = round(utilOp / gin, 2)
    const str = `${res} ${INDICADOR.operation} ${INDICADOR.valor}`
    return {
      // eslint-disable-next-line no-eval
      message: eval(str) ? 'Cumple' : 'No cumple',
      valor: res
    }
  }
}
const install = app => {
  app.config.globalProperties.$fn = funciones
}

export { install as default, funciones as funcion }

import { computed } from 'vue'

const loadBusiness = ({ props, context }) => {
  /*
  * Computdes
  */
  const paginatorLast = computed(() => {
    console.log('paginatorLast', props)
    if (props.lastPage > props.currentPage) {
      const sumPage = props.currentPage + 2
      return props.lastPage < sumPage ? props.lastPage : sumPage
    }
    return props.currentPage
  })

  /*
  * Methods
  */
  const onClickNumberPage = (page) => {
    context.emit('change-page', page)
  }

  return {
    paginatorLast,
    onClickNumberPage
  }
}

export {
  loadBusiness
}

<template>
  <Button label="Regresar" icon="pi pi-arrow-left" class="p-button-sm" @click="$router.back()"/>
</template>

<script>
export default {

}
</script>

<style>

</style>

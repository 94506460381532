import IncomeServices from '../services/incomeServices'
import dayjs from 'dayjs'
import { useStore } from 'vuex'

require('dayjs/locale/es')

const useIncome = () => {
  const typesIncomes = [
    'Factura',
    'Cuota',
    'Reembolso',
    'Nota Contable'
  ]
  const typesIncomesObject = [
    {
      value: 'Factura',
      name: 'Factura'
    },
    {
      value: 'Cuota',
      name: 'Cuota'
    },
    {
      value: 'Reembolso',
      name: 'Reembolso'
    },
    {
      value: 'Nota Contable',
      name: 'Nota Contable'
    }
  ]
  const incomeServices = new IncomeServices()
  const store = useStore()

  const returnData = async (data) => {
    if (data.length > 0) {
      await data.map(income => {
        income.date = dayjs(income.date).format('DD-MM-YYYY')
      })
    }
    store.dispatch('storePettyCash/storeIncomes', data)
  }

  const getIncomes = async (form) => {
    let dataIncomes = []
    try {
      if (form.incomeType && form.numberDoc) {
        console.log('case 1', form)
        dataIncomes = await incomeServices.getIncomeByTypeAndNumDoc(form.date, form.incomeType, form.numberDoc)
        await returnData(dataIncomes)
        return true
      }
      if (form.numberDoc) {
        console.log('case 2', form)
        dataIncomes = await incomeServices.getIncomeByNumDoc(form.date, form.numberDoc)
        await returnData(dataIncomes)
        return true
      }
      if (form.incomeType) {
        console.log('case 3', form)
        dataIncomes = await incomeServices.getIncomeByType(form.date, form.incomeType)
        await returnData(dataIncomes)
        return true
      }
      if (form.date) {
        console.log('case 4', form)
        dataIncomes = await incomeServices.getIncomeByDate(form.date, form.query)
        await returnData(dataIncomes)
        return true
      }
    } catch (error) {
      console.error('error getIncomes', error)
      throw error
    }
  }

  const createIncome = async (form) => {
    form.userId = 1
    form.amount = parseFloat(form.amount ?? 0)
    try {
      if (form.incomeType === 'Factura') {
        console.log('case Factura', form)
        return await incomeServices.createIncomeByCase('/income/create-by-factura', form)
      }
      if (form.incomeType === 'Cuota') {
        console.log('case Cuota', form)
        return await incomeServices.createIncomeByCase('/income/create-by-quota', form)
      }
      if (form.incomeType === 'Reembolso') {
        console.log('case Reembolso', form)
        return await incomeServices.createIncomeByCase('/income/create-by-refund', form)
      }
      if (form.incomeType === 'Nota Contable') {
        console.log('case Nota', form)
        return await incomeServices.createIncomeByCase('/income/create-by-nota', form)
      }
    } catch (error) {
      console.error('error createIncome', error)
      throw error
    }
  }

  const updateIncome = async (form, id) => {
    try {
      form.userId = 1
      form.date = dayjs(new Date()).format('YYYY-MM-DD')
      parseFloat(form.amount ?? 0)
      if (form.incomeType === 'Factura') {
        console.log('Case Edit Factura')
        return await incomeServices.editIncomeByCase('/income/edit/' + id, form)
      }
      if (form.incomeType === 'Cuota') {
        console.log('Case Edit Cuota')
        return await incomeServices.editIncomeByCase('/income/edit/' + id, form)
      }
      if (form.incomeType === 'Rembolso') {
        console.log('Case Edit Reembolso')
        return await incomeServices.editIncomeByCase('/income/edit/' + id, form)
      }
      if (form.incomeType === 'Nota Contable') {
        console.log('Case Edit Nota Contable')
        return await incomeServices.editIncomeByCase('/income/edit/' + id, form)
      }
    } catch (error) {
      console.error('Error update', error)
      throw error
    }
  }

  const deleteIncome = async (id) => {
    try {
      return await incomeServices.deleteIncomeById('/income/delete/' + id)
    } catch (error) {
      console.error('Error delete', error)
      throw error
    }
  }

  return {
    typesIncomes,
    typesIncomesObject,
    createIncome,
    updateIncome,
    deleteIncome,
    getIncomes
  }
}

export default useIncome

<template>
  <h2>Reporte General</h2>
</template>

<script>
export default {
  name: 'reportConsolidated'
}
</script>

<style scoped>

</style>

<template>
  <Toast />
  <Toolbar class="box mt-4 p-p-2 flex sm:flex-row sm:w-auto flex-col w-full">
    <template #start>
      <back-button />
    </template>
  </Toolbar>

  <div class="grid gap-4 grid-cols-12 mt-5">
    <div class="intro-y col-span-12 lg:col-span-4">
      <div class="intro-y box h-full">
        <div class="flex flex-col sm:flex-row justify-between items-center p-3 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-base">Información Plantilla</h2>
        </div>
        <div class="p-2 h-28 box flex items-center justify-center">
          <span class="text-sm">Lea detalladamente la información de cada campo de texto
            <a class="no-underline hover:underline text-indigo-600 cursor-pointer font-bold" @click="toggleAnimation">aquí</a>
          </span>
        </div>
      </div>
    </div>
    <div class="intro-y col-span-12 lg:col-span-4">
      <div class="intro-y box h-full">
        <div class="flex flex-col sm:flex-row justify-between items-center p-3 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-base">Descargar Plantilla Excel</h2>
        </div>
        <div class="p-2 h-28 box flex items-center justify-center">
          <Button label="Descargar Plantilla Excel" @click="handleDownloadXls(financialProduct)" icon="pi pi-table" class="p-button-warning p-button-sm" />
        </div>
      </div>
    </div>
    <div class="intro-y col-span-12 lg:col-span-4">
      <div class="intro-y box h-full">
        <div class="flex flex-col sm:flex-row justify-between items-center p-3 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-base">Cargar e importar plantilla excel</h2>
        </div>
        <div class="p-2 h-28 box flex items-center justify-center">
          <FileUpload
            mode="basic"
            name="demo[]"
            url="./upload.php"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            :maxFileSize="1000000"
            @upload="onUpload"
            @change="handleFilesUpload()"
            ref="inputFile"
          />
        </div>
      </div>
    </div>

    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">
        <div class="flex flex-col sm:flex-row justify-between items-center p-3 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-base">Información de los campos del archivo excel</h2>
        </div>
        <div class="p-3">
          <p class="pt-3 pb-5">
            La información cargada desde el archivo excel se visualizan a continuación:
          </p>

          <div class="flex flex-col">
            <div class="-my-2 sm:-mx-4 lg:-mx-4">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="overflow-hidden">

                  <div ref="tableExample" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">
        <div :class="{ 'test-animation': isVisible }" class="flex flex-col sm:flex-row justify-between items-center p-3 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-base">Información de los campos del archivo excel</h2>
        </div>
        <div class="p-3">
          <p class="pt-3 pb-5">
            En la siguiente tabla se muestran los campos incluidos en el archivo excel con su respectiva condición y descripción:
          </p>

          <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-600 uppercase tracking-wider">
                          Nombre del Campo
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-600 uppercase tracking-wider">
                          Condición
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-600 uppercase tracking-wider">
                          Descripción
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="(column, index) in infoAccountsSap" :key="index">
                        <td class="px-6 py-4 whitespace-nowrap"><code class="code">{{column.acctname}}</code></td>
                        <td class="px-6 py-4 whitespace-nowrap"><Badge :value="column.condition" severity="danger"></Badge></td>
                        <td class="px-6 py-4 whitespace-nowrap">{{column.description}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div >

  </div>

  <Button
    v-if="excelInfo.length !== 0"
    icon="pi pi-send"
    label="Guardar"
    class="p-button-success floating-button"
    @click="onSubmit"
  />
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import XLSX from 'xlsx'
import downloadTemplate from '../../../../services/downloadTemplate'
import dayjs from 'dayjs'
import { array, number, object, string } from 'yup'
import { useField, useForm } from 'vee-validate'
// import { useLoading } from 'vue3-loading-overlay'
import { useRoute, useRouter } from 'vue-router'
import createTableAmortization from '../../../../services/createTableAmortization'
import { error, errorHtml, messageSuccess } from '../../../../../../../../libs/mensajes'
import Handsontable from 'handsontable'
import 'handsontable/dist/handsontable.full.css'
import 'numbro/dist/languages.min'
import { useToast } from 'primevue/usetoast'
import listAccountsSapByProduct from '../../../../services/listAccountsSapByProduct'
import listTableAmortizations from '../../../../services/listTableAmortizations'
import { useStore } from 'vuex'
import { helper } from '../../../../../../../../utils/helper'

export default {
  name: 'loadTemplateAmortizations',
  setup () {
    const store = useStore()
    const toast = useToast()
    // const loader = useLoading()
    const isVisible = ref(false)
    const inputFile = ref()
    const tableExample = ref()
    const hotInstance = ref()
    const excelInfo = ref([])
    const router = useRouter()
    const route = useRoute()
    const financialProduct = ref('')
    const columnsNameExcel = ref([])
    const onlyInfoAccountsSap = ref([])
    const tableAmortizations = ref([])
    const tableAmortizationsDetails = ref([])
    const infoAccountsSap = ref([
      {
        acctcode: null,
        acctname: '# CUOTA',
        condition: 'Obligatorio y debe ser un número',
        description: '# de la cuota del registro de la amortización'
      },
      {
        acctcode: null,
        acctname: 'FECHA CUOTA',
        condition: 'Obligatorio',
        description: 'Fecha de la cuota (El formato debe ser AAAA-MM-DD)'
      }
    ])

    const objColumnsHot = ref([
      {
        data: '# CUOTA',
        title: '<span title="Número de la cuota de la amortización"># CUOTA</span>',
        type: 'numeric',
        allowEmpty: false
      },
      {
        data: 'FECHA CUOTA',
        title: '<span title="Fecha de la cuota de la amortización">FECHA CUOTA</span>',
        type: 'date',
        dateFormat: 'YYYY-MM-DD',
        correctFormat: true,
        allowEmpty: false,
        datePickerConfig: {
          i18n: {
            previousMonth: 'Mes Anterior',
            nextMonth: 'Próximo Mes',
            months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            weekdays: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
            weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']
          }
        }
      }
    ])

    /* Obtener el listado de la tabla de amortizaciones */
    const fetchTableAmortizations = (id) => {
      return listTableAmortizations({ id }).then(({ status, data }) => {
        tableAmortizations.value = data.result.map((tblAmortization) => {
          const totalDebit = tblAmortization.AmortizationsTablesdetail.reduce((tot, arr) => {
            return tot + arr.debit
          }, 0)

          return {
            ...tblAmortization,
            valorCuota: totalDebit
          }
        })

        tableAmortizationsDetails.value = data.result.map((tblAmortization) => {
          return tblAmortization.AmortizationsTablesdetail.map((detailAmortization) => {
            return {
              ...detailAmortization
            }
          })
        })
      }).catch(err => {
        console.error(err.message)
      })
    }

    /* Obtener las cuentas sap asociadas al producto */
    const fetchAccountsSapByProduct = (productId) => {
      return listAccountsSapByProduct({ productId }).then(({ status, data: resp }) => {
        onlyInfoAccountsSap.value = resp.result.map((r) => {
          return {
            acctcode: r.accountSap,
            acctname: r.nameAccount,
            condition: 'Obligatorio y debe ser un número',
            description: `Valor ${r.nameAccount} del registro de la amortización (si no hay valores colocar cero)`
          }
        })

        onlyInfoAccountsSap.value.forEach((item) => {
          infoAccountsSap.value.push(item)
        })

        columnsNameExcel.value = infoAccountsSap.value.map((col) => {
          return col.acctname
        })

        getArrayColumnsHot(columnsNameExcel.value)
      }).catch(err => {
        console.error(err.message)
      })
    }

    /* Funcion para obtener las columnas del handsontable */
    const getArrayColumnsHot = (columns) => {
      const newColumns = []
      for (let i = 2; i < columns.length; i++) {
        const col = columns[i]
        const variable = {
          data: col,
          title: `<span title="VALOR ${col}">${col}</span>`,
          type: 'numeric',
          numericFormat: {
            pattern: '$0.0,00',
            culture: 'en-US' // this is the default culture, set up for USD
          },
          allowEmpty: false
        }
        newColumns.push(variable)
      }

      newColumns.forEach((item) => {
        objColumnsHot.value.push(item)
      })

      objColumnsHot.value.push(
        {
          data: 'fee',
          title: '<span title="VALOR DE LA CUOTA">VALOR CUOTA</span>',
          allowEmpty: false,
          readOnly: true
        }
      )
    }

    /* Funcion que obtiene la informacion de la data cargada en excel */
    const handleFilesUpload = () => {
      // loader.show()
      const file = inputFile.value.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (event) => {
          const result = event.target.result
          const dataOne = new Uint8Array(result)
          const workbook = XLSX.read(dataOne, { type: 'array', cellDates: true })
          const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]]
          const data = XLSX.utils.sheet_to_json(firstWorksheet, { header: 2, raw: false, dateNf: dayjs('DD/MM/YYYY').format('YYYY-MM-DD') })
          const objColumns = columnsNameExcel.value.reduce(function (acc, curr) {
            acc[curr] = ''
            return acc
          }, {})
          modelo.amortization = data.map((info, key) => {
            for (const col in objColumns) {
              if (col === 'FECHA CUOTA') {
                objColumns[col] = info[col] ? info[col].split('/').reverse().join('-') : ''
              } else {
                objColumns[col] = isNaN(info[col]) ? '' : info[col]
              }
            }

            return {
              ...objColumns,
              _key: key,
              productId: route.params.id
            }
          })
          loadHandsontable(modelo.amortization)
          modeloAmortization(excelInfo.value)
        }
        reader.readAsArrayBuffer(file)
      }
    }

    const modeloAmortization = (item) => {
      excelInfo.value = { ...item }
      // loader.hide()
    }

    const validationSchema = object({
      amortization: array().of(
        object({
          installmentNumber: number().min(1).typeError('El campo es requerido').positive().label('Número Cuota'),
          fee: number().typeError('El campo es requerido').label('Valor Cuota'),
          paymentDate: string().nullable().required().label('Fecha Cuota'),
          interest: number().typeError('El campo es requerido').label('Valor intereses'),
          capital: number().typeError('El campo es requerido').label('Valor Capital'),
          residue: number().typeError('El campo es requerido').label('Valor Saldo')
        })
      )
    })

    // Create a form context with the validation schema
    const { errors, values: modelo } = useForm({
      validationSchema
    })

    useField('amortization', null, {
      initialValue: [{ installmentNumber: 0, fee: 0, paymentDate: null, interest: 0, capital: 0, residue: 0, _key: 0 }]
    })

    const getterProductFinancial = computed(() => store.getters['infoFinancialProductAmortization/getFinancialProduct'])

    /* Obtener el total del capital */
    const getTotalCapital = computed(() => {
      let tt = 0
      tableAmortizationsDetails.value.forEach((item, i) => {
        const ttFind = item.find((it) => {
          return it.OACT.acctcode === getterProductFinancial.value.accountSap
        })
        tt += ttFind.debit
      })

      return tt
    })

    // Funcion para validar el capital con el desembolso
    const validateCapital = (values) => {
      let dd = 0
      values.forEach((item, i) => {
        const findDetail = item.details.find((val) => {
          return val.accountSap === getterProductFinancial.value.accountSap
        })
        dd += findDetail.debit
      })

      const totalCapital = getTotalCapital.value + dd

      if (getterProductFinancial.value.disbursement < +totalCapital.toFixed(2)) {
        errorHtml(
          'Validación',
          `<br>El total del capital <strong>(${helper.formatCurrency(totalCapital, 2)})</strong> no debe superar el desembolso <strong>(${helper.formatCurrency(getterProductFinancial.value.disbursement, 2)})</strong>`
        )
        // loader.hide()
        return true
      }
      return false
    }

    /* Funcion para validar el numero de amortizaciones dependiendo los plazos */
    const validateNumAmortizations = (term, numAmortizations) => {
      if (numAmortizations > term) {
        errorHtml(
          'Validación',
          `<br>No es posible agregar otro registro debido a que el número de amortizaciones <strong>(${numAmortizations})</strong> es mayor al plazo máximo <strong>(${term})</strong>`
        )
        // loader.hide()
        return true
      }

      return false
    }

    const onSubmit = () => {
      // loader.show()
      hotInstance.value.validateCells((result, obj) => {
        if (result === true) {
          const firstResult = hotInstance.value.getSourceData()

          const lengthAmortizations = tableAmortizations.value.length === 0 ? firstResult.length : tableAmortizations.value.length + firstResult.length
          if (validateNumAmortizations(getterProductFinancial.value.term, lengthAmortizations)) {
            return
          }

          const secondResult = firstResult.map((item, i) => {
            const findAccount = onlyInfoAccountsSap.value.map((element) => {
              return {
                accountSap: element.acctcode,
                debit: item[element.acctname]
              }
            })
            return {
              installmentNumber: Number(item['# CUOTA']),
              productId: Number(item.productId),
              paymentDate: item['FECHA CUOTA'],
              details: findAccount
            }
          })

          if (validateCapital(secondResult)) {
            return
          }

          createTableAmortization({ amortization: secondResult }).then((res) => {
            // loader.hide()
            messageSuccess('Registros guardados con exito')
            router.back()
          }).catch((err) => {
            // loader.hide()
            console.error(err.message)
            if (err.response.status) {
              error(err.response.data.message)
            }
          })
        } else {
          // loader.hide()
          console.log('bad form data')
        }
      })
    }

    /* Animacion del contenedo con los campos */
    const toggleAnimation = () => {
      isVisible.value = true
      setTimeout(() => {
        isVisible.value = false
      }, 2000)
    }

    /* Exportar la plantilla excel */
    const handleDownloadXls = (id) => {
      return downloadTemplate({ id })
    }

    const optionsHandsontable = ref({
      rowHeaders: true,
      colHeaders: columnsNameExcel,
      licenseKey: 'non-commercial-and-evaluation',
      height: 426,
      rowHeights: 27,
      copyPaste: true,
      outsideClickDeselects: false,
      autoWrapRow: false,
      autoWrapCol: true,
      renderAllRows: false,
      width: '100%',
      stretchH: 'all',
      viewportColumnRenderingOffset: 100,
      viewportRowRenderingOffset: 100,
      undo: false,
      comments: true,
      fillHandle: {
        direction: 'vertical',
        autoInsertRow: false
      },
      cells: function (row, col, prop) {
        const cellProperties = {}

        if (col === 0 || col === 1) {
          cellProperties.className = 'htMiddle htCenter text-11'
        } else {
          cellProperties.className = 'htMiddle htRight text-11'
        }

        return cellProperties
      },
      columns: objColumnsHot.value,
      currentRowClassName: 'currentRow',
      currentColClassName: 'currentCol',
      afterGetColHeader (col, TH) {
        TH.style.fontWeight = 'bold'
        TH.style.fontSize = '12px'
        TH.style.verticalAlign = 'middle'
      },
      afterGetRowHeader (row, TH) {
        TH.style.fontWeight = 'bold'
        TH.style.fontSize = '12px'
        TH.style.verticalAlign = 'middle'
      },
      afterValidate (isValid, value, row, prop, source) {
        const comments = hotInstance.value.getPlugin('comments')
        columnsNameExcel.value.forEach((itemCol, i) => {
          if (itemCol === prop) {
            const index = hotInstance.value.propToCol(i)
            const cellCol = hotInstance.value.getCellMeta(row, index)
            if (!isValid) {
              comments.updateCommentMeta(row, index, {
                value: `- El ${itemCol} es un campo requerido. \n - Debe ser un número.`,
                readOnly: true
              })
              cellCol.valid = false
            } else {
              comments.removeCommentAtCell(row, index, true)
              cellCol.valid = true
            }
          }
        })
      },
      /* Hook cuando carga la data en el handsontable */
      afterLoadData (sourceData, initialLoad) {
        if (initialLoad === false) {
          const onlyColumnsAccountsName = onlyInfoAccountsSap.value.map(function (obj) {
            return obj.acctname
          })
          const objOnlyColumns = onlyColumnsAccountsName.reduce(function (acc, curr) {
            acc[curr] = ''
            return acc
          }, {})
          sourceData.forEach((src, i) => {
            let sum = 0
            for (const col in objOnlyColumns) {
              if (col !== 'FECHA CUOTA') {
                src[col] = Number(src[col])
                sum += src[col]
              } else {
                src[col] = src['FECHA CUOTA']
              }
            }
            src['VALOR CUOTA'] = sum

            const indexColumnFee = hotInstance.value.propToCol('fee')
            hotInstance.value.setDataAtCell(i, indexColumnFee, src['VALOR CUOTA'])
          })
        }
      },
      /* Cambios en celdas */
       afterChange (src, changes) {
        /*
         * Tipo de cambio diferente a loadData y columna fee (cuota)
         * Realizar suma para todas las columnas
         */
        if (changes !== 'loadData' && src[0][1] !== 'fee') {
          let sumQuote = 0
          columnsNameExcel.value.forEach((element1, index) => {
            if (index > 1) {
              src.forEach((element2, index) => {
                sumQuote += parseInt(hotInstance.value.getSourceDataAtCell(element2[0], element1))

                const indexColumnFee = hotInstance.value.propToCol('fee')
                hotInstance.value.setDataAtCell(element2[0], indexColumnFee, sumQuote)
              })
            }
          })
        }
      },
      beforeRenderer (td, r, c, p, pv, cp) {
        if (p === 'fee') {
          td.style.background = '#EEF1F5'
        }
        td.style.color = 'black'
      },
      beforeCopy: (data, coords) => {
        toast.add({ severity: 'info', summary: 'Exito', detail: 'Información copiada al portapapeles', life: 3000 })
      },
      contextMenu: { // Menu contextual - Opcion para borrar linea
        callback (key, selection, clickEvent) {
        },
        items: {
          copy: {
            name: 'Copiar'
          },
          remove_row: {
            name: 'Remover Linea'
          }
        }
      }
    })

    // Función para obtener la data en el tabla handsontable
    const loadHandsontable = (data) => {
      hotInstance.value = new Handsontable(tableExample.value, optionsHandsontable.value)
      hotInstance.value.loadData(data)
    }

    onMounted(() => {
      const { id } = route.params
      financialProduct.value = id
      fetchAccountsSapByProduct(id)
      fetchTableAmortizations(id)
      // store.dispatch('infoFinancialProductAmortization/financialProductAction', id)
    })

    return {
      tableExample,
      toggleAnimation,
      isVisible,
      handleDownloadXls,
      financialProduct,
      handleFilesUpload,
      inputFile,
      excelInfo,
      infoAccountsSap,
      columnsNameExcel,
      onlyInfoAccountsSap,
      objColumnsHot,
      modelo,
      onSubmit,
      errors,
      tableAmortizations,
      tableAmortizationsDetails,
      getTotalCapital
    }
  }
}
</script>

<style scoped>
@keyframes morph {
  0% {
    background-color: #5E8F32;
    color: white;
  }
  50% {
    color: white;
    background-color: #5E8F32;
  }
  100% {
    background-color: white;
    color: black;
  }
}
.test-animation {
  animation: morph 1s linear;
}
.code {
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  box-shadow: 0 1px 4px rgba(0 0 0 0.5);
  padding: 2px 4px;
  font-size: 90%;
}
.floating-button {
  position: fixed !important;
  bottom: 30px;
  right: 40px;
  z-index: 10050;
}
</style>

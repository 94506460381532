import Login from './views/login/Main'
import Dashboard from './views/dashboard/Main'
import Layouts from '@/layouts/main-menu/Main'
import VueRouterMultiguard from 'vue-router-multiguard'
import { auth, guest } from '@/middleware'

/*
* Users
*/
// import usersList from './views/users/list'
// import UsersForm from './views/users/form'
// import crear from './views/users/crear_usuario'
// import detalle from './views/users/detalle_usuario/index.vue'

/*
* Groups
*/
// import GroupsList from './views/groups/list'
// import GroupsForm from './views/groups/form'

/*
* Permissions
*/
import PermissionsList from './views/permissions/list'
import PermissionsCreate from './views/permissions/create'

export default [
  {
    path: '/common',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: '/',
        name: 'side-menu-dashboard-overview-1',
        component: Dashboard
      },
      {
        path: '/demo',
        name: 'side-menu-dashboard-overview-1',
        component: Dashboard
      }
    ]
  },
  // {
  //   path: '/usuarios',
  //   component: Layouts,
  //   beforeEnter: VueRouterMultiguard([auth]),
  //   children: [
  //     {
  //       path: '',
  //       name: 'usuarios',
  //       component: usersList
  //     },
  //     {
  //       path: 'crear-usuario',
  //       name: 'creacion.usuario',
  //       component: crear
  //     },
  //     {
  //       path: 'detalle-usuario/:id',
  //       name: 'detalle.usuario',
  //       component: detalle
  //     },
  //     {
  //       path: 'formulario/:id?',
  //       name: 'usuarios.formulario',
  //       component: UsersForm
  //     },
  //     {
  //       path: 'grupos',
  //       name: 'usuarios.grupos',
  //       component: GroupsList
  //     },
  //     {
  //       path: 'grupos/formulario/:id?',
  //       name: 'usuarios.grupos.formulario',
  //       component: GroupsForm
  //     }
  //   ]
  // },
  {
    path: '/permissions',
    name: 'permissions',
    beforeEnter: VueRouterMultiguard([auth]),
    component: Layouts,
    children: [
      {
        path: '',
        name: 'permissions.list',
        component: PermissionsList
      },
      {
        path: 'create',
        name: 'permissions.create',
        component: PermissionsCreate
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: VueRouterMultiguard([guest])
  }
]

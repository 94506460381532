<template>
  <section class="intro-x">
    <div class="w-full my-4">
      <back-button />
    </div>
    <Form
    :validation-schema="schema"
    @submit="handleSubmit"
    >
      <div class="w-full md:w-1/2 box p-4">
        <div class="w-full mb-2">
          <div class="fotn-bold text-lg font-bold">Formulario</div>
        </div>
        <div class="mb-2">
          <label for="codigo">Codigo</label>
          <Field
          v-model="model.code"
          id="codigo"
          type="text"
          class="form-control"
          name="code"
          maxlength="140"
          />
          <ErrorMessage name="coed" class="text-red-600" />
        </div>
        <div class="mb-2">
          <label for="nombre">Valor</label>
          <Field
          v-model="model.name"
          id="nombre"
          type="text"
          class="form-control"
          name="name"
          maxlength="140"
          />
          <ErrorMessage name="name" class="text-red-600" />
        </div>

        <div class="form-check my-5">
          <input
          v-model="model.state"
          id="show-example-5"
          data-target="#select-options"
          class="show-code form-check-switch"
          type="checkbox"
          />
          <label
          class="form-check-label ml-0 sm:ml-2"
          for="show-example-5"
          >
            Estado
          </label >
        </div>
        <div class="w-full mb-2">
          <button
          type="submit"
          class="bg-primary-1 text-white px-3 py-2 rounded mt-2"
          :disabled="isLoading"
          >
            Guardar
          </button>
        </div>
      </div>
    </Form>
  </section>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as Yup from 'yup'
import createUnits from '../../../../../services/createUnits'
import { success } from '../../../../../../../../libs/mensajes'
import { useRoute, useRouter } from 'vue-router'
import updateUnits from '../../../../../services/updateUnits'
import findUnits from '../../../../../services/findUnits'

export default {
  name: 'UnitForm',
  components: {
    Form,
    Field,
    ErrorMessage
  },
  setup () {
    const model = ref({
      name: '',
      code: '',
      state: true
    })
    const isLoading = ref(false)
    const schema = Yup.object().shape({
      name: Yup.string().required().min(3),
      code: Yup.string().required().min(3)
    })
    const toggleLoading = () => {
      isLoading.value = !isLoading.value
    }
    const router = useRouter()
    const route = useRoute()

    const esEditar = computed(() => {
      if (route.params.id) return true
      return false
    })

    const handleSubmit = (values) => {
      toggleLoading()
      if (esEditar.value) {
        updateUnits(route.params.id, model.value).then(({ status }) => {
          toggleLoading()
          if (status !== 200) return
          success('Unidad actualizada')
          router.back()
        }).catch((err) => {
          toggleLoading()
          console.error(err.message)
        })
      } else {
        createUnits(model.value).then(({ status }) => {
          toggleLoading()
          if (status !== 201) return
          success('Unidad creada')
          router.back()
        }).catch((err) => {
          toggleLoading()
          console.error(err.message)
        })
      }
    }
    onMounted(() => {
      if (esEditar.value) {
        const { id } = route.params
        findUnits({ id }).then(({ data }) => {
          model.value.name = data.name
          model.value.code = data.code
          model.value.state = data.state
        })
      }
    })
    return {
      esEditar,
      isLoading,
      model,
      schema,
      handleSubmit
    }
  }
}
</script>

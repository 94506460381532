<template>
  <section class="intro-x my-5">
    <div class="w-full my-5">
      <div class="flex">
        <back-button />
      </div>
    </div>

    <div class="w-1/2 box p-4">
      <div class="w-full">
        <h1 class="font-bold mt-2 mb-4 text-xl">Formulario de presentación</h1>
      </div>
      <form @submit="onSubmit">
        <!-- Unidad de medida -->
        <div class="w-full ">
          <div>
            <label for="descripcion" class="capitalize">Unidad de medida base</label>
          </div>
          <div>
            <select name="unitId"
            v-model="model.unitId"
            class="form-control capitalize"
            :class="{'border-red-400': errors.unitId}"
            >
              <option :value="null">Seleccionar</option>
              <option
              v-for="(row,k) in getUnidades"
              :key="k"
              :value="row.id"
              class="capitalize"
              >
                {{row.name}}
              </option>
            </select>
            <div class="text-red-400">
              {{errors.unitId}}
            </div>
          </div>
        </div>
        <!-- presentacion nombre -->
        <div class="w-full my-2">
          <div class="">
            <label for="tax">Presentación</label>
          </div>
          <div>
            <input
            id="tax"
            v-model="model.name"
            type="text"
            class="form-control"
            >
            <div class="text-red-400">
              {{errors.name}}
            </div>
          </div>
        </div>
        <!-- presentacion factor -->
        <div class="w-full mb-2">
          <div class="">
            <label for="taxFactor">Factor de conversion</label>
          </div>
          <div>
            <input
            id="taxFactor"
            v-model="model.factor"
            type="text"
            class="form-control"
            >
            <div class="text-red-400">
              {{errors.factor}}
            </div>
          </div>
        </div>
        <!-- Presentación estado -->
        <div class="form-check my-5">
          <input
          v-model="model.state"
          id="show-example-5"
          data-target="#select-options"
          class="show-code form-check-switch"
          type="checkbox"
          />
          <label
          class="form-check-label ml-0 sm:ml-2"
          for="show-example-5"
          >
            Estado
          </label >
        </div>
        <div class="w-full mt-4">
          <button type="submit" class="btn btn-primary">
            Guardar
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { useField, useForm } from 'vee-validate'
import { object, string, number, boolean } from 'yup'
import getUnits from '../../../../../services/unitsGetAll'
import presentacionCrear from '../../../../../services/presentacionCrear'
import presentacionActualizar from '../../../../../services/presentacionActualizar'
import presentacionBuscar from '../../../../../services/presentacionBuscar'
import { messageConfirm, messageSuccess } from '../../../../../../../../libs/mensajes'
import { useRouter, useRoute } from 'vue-router'
import { computed, onMounted, ref } from 'vue-demi'
export default {
  name: 'formPresentacion',
  setup () {
    const router = useRouter()
    const route = useRoute()
    const unidades = ref([])
    const isEdit = computed(() => {
      if (route.params.id) return true
      return false
    })
    const getUnidades = computed(() => {
      return unidades.value.filter(a => a.state)
    })
    const validationSchema = object().shape({
      unitId: number().required().label('unidad de medida'),
      name: string().max(45).required().label('presentación'),
      factor: number().min(0).required(),
      state: boolean().required()
    })

    const { values: model, errors, handleSubmit, handleReset } = useForm({
      validationSchema
    })

    useField('unitId', null, {
      initialValue: null
    })
    useField('name', null, {
      initialValue: ''
    })

    useField('factor', null, {
      initialValue: ''
    })

    useField('state', null, {
      initialValue: true
    })

    onMounted(() => {
      fetchUnidades()
      if (isEdit.value) {
        fetchPresentacion()
      }
    })

    const modelFind = (value) => {
      model.name = value.name
      model.factor = value.factor
      model.state = value.state
      model.unitId = value.unitId
    }

    const fetchUnidades = () => {
      return getUnits().then(({ status, data }) => {
        if (status !== 200) return
        unidades.value = data
        return data
      }).catch((err) => {
        console.error(err.message)
      })
    }

    const fetchPresentacion = () => {
      return presentacionBuscar(route.params.id).then(({ status, data }) => {
        modelFind(data)
      }).catch(err => {
        console.error(err.message)
      })
    }
    const onSubmit = handleSubmit((values) => {
      if (isEdit.value) {
        presentacionActualizar(route.params.id, values).then(({ status }) => {
          if (status === 200) {
            messageSuccess('Presentación actualizado')
            router.back()
          }
        }).catch(err => {
          console.error(err.message)
        })
        return false
      }
      presentacionCrear(values).then(({ status, data }) => {
        if (status === 201) {
          messageConfirm('Desea crear otra presentación', 'Presentación creada', 'success').then(({ isConfirmed }) => {
            if (isConfirmed) {
              handleReset()
              return false
            }
            router.back()
          })
        }
      }).catch(err => {
        console.error(err.message)
      })
    })

    return {
      model,
      errors,
      onSubmit,
      isEdit,
      getUnidades

    }
  }
}
</script>

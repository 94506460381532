import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '@/middleware/securityAccess'
export default [
  {
    path: '/pharmasan/administrativa',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    redirect: { name: 'pharmasan.administrativa.juridica' },
    children: [
      {
        path: 'juridica',
        name: 'pharmasan.administrativa.juridica',
        beforeEnter: VueRouterMultiguard([auth]),
        redirect: { name: 'pharmasan.administrativa.juridica.dashboard' },
        children: [
          {
            path: 'dashboard',
            name: 'pharmasan.administrativa.juridica.dashboard',
            component: () => import('../views/home'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.administrativa.juridica.dashboard'
              ]
            },
            children: []
          },
          {
            path: 'tutelas',
            name: 'pharmasan.administrativa.juridica.tutelas',
            redirect: { name: 'pharmasan.administrativa.juridica.tutelas.listado' },
            component: () => import('../views/tutelas/index.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: [
                'pharmasan.administrativa.juridica.tutelas'
              ]
            },
            children: [
              {
                path: 'listado',
                name: 'pharmasan.administrativa.juridica.tutelas.listado',
                component: () => import('../views/tutelas/pages/listado/index.vue'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.tutelas'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                }
              },
              {
                path: 'ver/:id',
                name: 'pharmasan.administrativa.juridica.tutelas.ver',
                component: () => import('../views/tutelas/pages/ver/Main.vue'),
                redirect: { name: 'pharmasan.administrativa.juridica.tutelas.ver.tutela' },
                children: [
                  {
                    path: 'tutela',
                    name: 'pharmasan.administrativa.juridica.tutelas.ver.tutela',
                    component: () => import('../views/tutelas/pages/ver/componentsMain/tutela.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.tutelas'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                    }
                  },
                  {
                    path: 'fallos',
                    name: 'pharmasan.administrativa.juridica.tutelas.ver.fallos',
                    component: () => import('../views/tutelas/pages/ver/componentsMain/fallo.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.tutelas'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                    }
                  },
                  {
                    path: 'incidentes',
                    name: 'pharmasan.administrativa.juridica.tutelas.ver.incidentes',
                    component: () => import('../views/tutelas/pages/ver/componentsMain/incidente.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.tutelas'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                    }
                  },
                  {
                    path: 'solicitudes',
                    name: 'pharmasan.administrativa.juridica.tutelas.ver.solicitudes',
                    component: () => import('../views/tutelas/pages/ver/componentsMain/solicitud.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.tutelas'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                    }
                  },
                  {
                    path: 'desacatos',
                    name: 'pharmasan.administrativa.juridica.tutelas.ver.desacatos',
                    component: () => import('../views/tutelas/pages/ver/componentsMain/desacato.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.tutelas'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                    }
                  }
                ]
              },
              {
                path: 'formulario',
                name: 'pharmasan.administrativa.juridica.tutelas.formulario',
                component: () => import('../views/tutelas/pages/formulario/index-2.vue'),
                redirect: { name: 'pharmasan.administrativa.juridica.tutelas.formulario.buscar-accionante' },
                children: [
                  {
                    path: 'buscar-accionante',
                    name: 'pharmasan.administrativa.juridica.tutelas.formulario.buscar-accionante',
                    component: () => import('../views/tutelas/pages/formulario/components/BuscarAccionante.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.tutelas.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                    }
                  },
                  {
                    path: 'datos-accionante',
                    name: 'pharmasan.administrativa.juridica.tutelas.formulario.datos-accionante',
                    component: () => import('../views/tutelas/pages/formulario/components/DatosAccionante.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.tutelas.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                    }
                  },
                  {
                    path: 'buscar-juzgado',
                    name: 'pharmasan.administrativa.juridica.tutelas.formulario.buscar-juzgado',
                    component: () => import('../views/tutelas/pages/formulario/components/BuscarJuzgado.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.tutelas.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                    }
                  },
                  {
                    path: 'detalle-tutela',
                    name: 'pharmasan.administrativa.juridica.tutelas.formulario.detalle-tutela',
                    component: () => import('../views/tutelas/pages/formulario/components/DetalleTutela.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.tutelas.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                    }
                  }
                ]
              },
              {
                path: 'editar-tutela/:id',
                name: 'pharmasan.administrativa.juridica.tutelas.formulario.editar',
                component: () => import('../views/tutelas/pages/formularioEditar/index.vue'),
                redirect: { name: 'pharmasan.administrativa.juridica.tutelas.formulario.editar.buscar-accionante' },
                children: [
                  {
                    path: 'buscar-accionante',
                    name: 'pharmasan.administrativa.juridica.tutelas.formulario.editar.buscar-accionante',
                    component: () => import('../views/tutelas/pages/formularioEditar/components/BuscarAccionante.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.tutelas.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                    }
                  },
                  {
                    path: 'datos-accionante',
                    name: 'pharmasan.administrativa.juridica.tutelas.formulario.editar.datos-accionante',
                    component: () => import('../views/tutelas/pages/formularioEditar/components/DatosAccionante.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.tutelas.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                    }
                  },
                  {
                    path: 'buscar-juzgado',
                    name: 'pharmasan.administrativa.juridica.tutelas.formulario.editar.buscar-juzgado',
                    component: () => import('../views/tutelas/pages/formularioEditar/components/BuscarJuzgado.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.tutelas.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                    }
                  },
                  {
                    path: 'detalle-tutela',
                    name: 'pharmasan.administrativa.juridica.tutelas.formulario.editar.detalle-tutela',
                    component: () => import('../views/tutelas/pages/formularioEditar/components/DetalleTutela.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.tutelas.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'solicitudes',
            name: 'pharmasan.administrativa.juridica.solicitudes',
            redirect: { name: 'pharmasan.administrativa.juridica.solicitudes.listado' },
            component: () => import('../views/solicitudes/index.vue'),
            children: [
              {
                path: 'listado/:id?',
                name: 'pharmasan.administrativa.juridica.solicitudes.listado',
                component: () => import('../views/solicitudes/pages/listado/index.vue'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.solicitudes'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.solicitudes'
                }
              },
              {
                path: 'crear/:id?',
                name: 'pharmasan.administrativa.juridica.solicitudes.crear',
                component: () => import('../views/solicitudes/pages/crear/index.vue'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.solicitudes.access'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.solicitudes'
                }
              },
              {
                path: 'responder/:id',
                name: 'pharmasan.administrativa.juridica.solicitudes.responder',
                component: () => import('../views/solicitudes/pages/responder/index.vue'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.solicitudes.access'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.solicitudes'
                }
              },
              {
                path: 'ver-respuesta/:id',
                name: 'pharmasan.administrativa.juridica.solicitudes.ver-respuesta',
                component: () => import('../views/solicitudes/pages/verRespuesta/index.vue'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.solicitudes'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.solicitudes'
                }
              }
            ]
          },
          {
            path: 'incidencias',
            name: 'procesos.judiciales.incidencias',
            redirect: { name: 'procesos.judiciales.incidencias.lista' },
            component: () => import('../views/incidencias/index.vue'),
            children: [
              {
                path: 'editar/:id',
                name: 'pharmasan.administrativa.juridica.tutelas.incidentes.editar',
                component: () => import('../views/incidencias/pages/editar/index.vue'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.tutelas.access'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                }
              },
              {
                path: 'crear',
                name: 'pharmasan.administrativa.juridica.tutelas.incidentes.crear',
                component: () => import('../views/incidencias/pages/crear/index.vue'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.tutelas.access'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                }
              }
            ]
          },
          {
            path: 'fallos',
            name: 'pharmasan.administrativa.juridica.tutelas.fallos',
            component: () => import('../views/fallos'),
            children: [
              {
                path: 'crear',
                name: 'pharmasan.administrativa.juridica.tutelas.fallos.crear',
                component: () => import('../views/fallos/pages/crear'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.tutelas.access'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                }
              },
              {
                path: 'editar/:id',
                name: 'pharmasan.administrativa.juridica.tutelas.fallos.editar',
                component: () => import('../views/fallos/pages/editar'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.tutelas.access'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                }
              }
            ]
          },
          {
            path: 'impugnaciones',
            name: 'pharmasan.administrativa.juridica.tutelas.fallos.impugnaciones',
            redirect: { name: 'pharmasan.administrativa.juridica.tutelas.fallos.impugnaciones.editar' },
            component: () => import('../views/impugnaciones'),
            children: [
              {
                path: 'editar/:id',
                name: 'pharmasan.administrativa.juridica.tutelas.fallos.impugnaciones.editar',
                component: () => import('../views/impugnaciones/pages/editar'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.tutelas.access'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.tutelas'
                }
              }
            ]
          },
          {
            path: 'respuestas',
            name: 'pharmasan.administrativa.juridica.respuestas',
            redirect: { name: 'pharmasan.administrativa.juridica.respuestas.listado' },
            component: () => import('../views/respuestas/index.vue'),
            children: [
              {
                path: 'listado',
                name: 'pharmasan.administrativa.juridica.respuestas.listado',
                component: () => import('../views/respuestas/pages/listado/index.vue'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.respuestas'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.respuestas'
                }
              },
              {
                path: 'crear',
                name: 'pharmasan.administrativa.juridica.respuestas.crear',
                component: () => import('../views/respuestas/pages/crear/index.vue'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.respuestas.access'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.respuestas'
                }
              },
              {
                path: 'actualizar/:id',
                name: 'pharmasan.administrativa.juridica.respuestas.actualizar',
                component: () => import('../views/respuestas/pages/actualizar/index.vue'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.respuestas.access'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.respuestas'
                }
              }
            ]
          },
          {
            path: 'sanciones',
            name: 'pharmasan.administrativa.juridica.sanciones',
            redirect: { name: 'pharmasan.administrativa.juridica.sanciones.listado' },
            component: () => import('../views/desacatos'),
            children: [
              {
                path: 'listado',
                name: 'pharmasan.administrativa.juridica.sanciones.listado',
                component: () => import('../views/desacatos/pages/listado'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.sanciones'
                  ]
                }
              },
              {
                path: 'crear',
                name: 'pharmasan.administrativa.juridica.sanciones.crear',
                component: () => import('../views/desacatos/pages/crear'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.sanciones.access'
                  ]
                }
              },
              {
                path: 'editar/:id',
                name: 'pharmasan.administrativa.juridica.sanciones.editar',
                component: () => import('../views/desacatos/pages/editar'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.sanciones.access'
                  ]
                }
              }
            ]
          },
          {
            path: 'juzgados',
            name: 'pharmasan.administrativa.juridica.juzgados',
            redirect: { name: 'pharmasan.administrativa.juridica.juzgados.listado' },
            component: () => import('../views/juzgados/index.vue'),
            children: [
              {
                path: 'listado',
                name: 'pharmasan.administrativa.juridica.juzgados.listado',
                component: () => import('../views/juzgados/pages/listado/index.vue'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.juzgados'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.juzgados'
                }
              },
              {
                path: 'crear',
                name: 'pharmasan.administrativa.juridica.juzgados.crear',
                component: () => import('../views/juzgados/pages/crear/index.vue'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.juzgados.access'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.juzgados'
                }
              },
              {
                path: 'editar/:id',
                name: 'pharmasan.administrativa.juridica.juzgados.editar',
                component: () => import('../views/juzgados/pages/editar/index.vue'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.juzgados.access'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.juzgados'
                }
              }
            ]
          },
          {
            path: 'personas',
            name: 'pharmasan.administrativa.juridica.personas',
            redirect: { name: 'pharmasan.administrativa.juridica.personas.listado' },
            component: () => import('../views/personas/Main.vue'),
            children: [
              {
                path: 'listado',
                name: 'pharmasan.administrativa.juridica.personas.listado',
                component: () => import('../views/personas/pages/listado/Main.vue'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.personas'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.personas'
                }
              },
              {
                path: 'crear',
                name: 'pharmasan.administrativa.juridica.personas.formulario',
                component: () => import('../views/personas/pages/formulario/Main.vue'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.personas.access'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.personas'
                }
              },
              {
                path: 'editar/:id',
                name: 'pharmasan.administrativa.juridica.personas.formulario-editar',
                component: () => import('../views/personas/pages/formularioEdit/Main.vue'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.personas.access'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.personas'
                }
              }
            ]
          },
          {
            path: 'procesos-administrativos',
            name: 'pharmasan.administrativa.juridica.procesos-administrativos',
            redirect: { name: 'pharmasan.administrativa.juridica.procesos-administrativos.listado' },
            component: () => import('../views/derechos-peticion/index.vue'),
            children: [
              {
                path: 'listado',
                name: 'pharmasan.administrativa.juridica.procesos-administrativos.listado',
                component: () => import('../views/derechos-peticion/pages/listado/index.vue'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.procesos-administrativos'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.procesos-administrativos'
                }
              },
              {
                path: 'formulario',
                name: 'pharmasan.administrativa.juridica.procesos-administrativos.formulario',
                component: () => import('../views/derechos-peticion/pages/formulario/index.vue'),
                redirect: { name: 'pharmasan.administrativa.juridica.procesos-administrativos.buscar-persona' },
                children: [
                  {
                    path: 'buscar-persona',
                    name: 'pharmasan.administrativa.juridica.procesos-administrativos.formulario.buscar-persona',
                    component: () => import('../views/derechos-peticion/pages/formulario/components/BuscarPersona.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-administrativos.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-administrativos'
                    }
                  },
                  {
                    path: 'datos-persona',
                    name: 'pharmasan.administrativa.juridica.procesos-administrativos.formulario.datos-persona',
                    component: () => import('../views/derechos-peticion/pages/formulario/components/DatosPersona.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-administrativos.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-administrativos'
                    }
                  },
                  {
                    path: 'datos-derecho-peticion',
                    name: 'pharmasan.administrativa.juridica.procesos-administrativos.formulario.datos-derecho-peticion',
                    component: () => import('../views/derechos-peticion/pages/formulario/components/DatosDerecho.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-administrativos.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-administrativos'
                    }
                  }
                ]
              },
              {
                path: 'formularioEditar/:id',
                name: 'pharmasan.administrativa.juridica.procesos-administrativos.formulario-editar',
                component: () => import('../views/derechos-peticion/pages/formularioEditar/index.vue'),
                redirect: { name: 'pharmasan.administrativa.juridica.procesos-administrativos.formulario-editar.buscar-persona' },
                children: [
                  {
                    path: 'buscar-persona',
                    name: 'pharmasan.administrativa.juridica.procesos-administrativos.formulario-editar.buscar-persona',
                    component: () => import('../views/derechos-peticion/pages/formularioEditar/components/BuscarPersona.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-administrativos.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-administrativos'
                    }
                  },
                  {
                    path: 'datos-persona',
                    name: 'pharmasan.administrativa.juridica.procesos-administrativos.formulario-editar.datos-persona',
                    component: () => import('../views/derechos-peticion/pages/formularioEditar/components/DatosPersona.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-administrativos.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-administrativos'
                    }
                  },
                  {
                    path: 'datos-derecho-peticion',
                    name: 'pharmasan.administrativa.juridica.procesos-administrativos.formulario-editar.datos-derecho-peticion',
                    component: () => import('../views/derechos-peticion/pages/formularioEditar/components/DatosDerecho.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-administrativos.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-administrativos'
                    }
                  }
                ]
              },
              {
                path: 'ver/:id',
                name: 'pharmasan.administrativa.juridica.procesos-administrativos.ver',
                component: () => import('../views/derechos-peticion/pages/ver/index.vue'),
                redirect: { name: 'pharmasan.administrativa.juridica.procesos-administrativos.ver.proceso' },
                children: [
                  {
                    path: 'proceso-administrativo',
                    name: 'pharmasan.administrativa.juridica.procesos-administrativos.ver.proceso',
                    component: () => import('../views/derechos-peticion/pages/ver/pages/derecho/index.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-administrativos'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-administrativos'
                    }
                  },
                  {
                    path: 'solicitudes',
                    name: 'pharmasan.administrativa.juridica.procesos-administrativos.ver.solicitudes',
                    component: () => import('../views/derechos-peticion/pages/ver/pages/solicitudes/index.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-administrativos'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-administrativos'
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'procesosjudiciales',
            name: 'pharmasan.administrativa.juridica.procesos-judiciales',
            redirect: { name: 'pharmasan.administrativa.juridica.procesos-judiciales.listado' },
            component: () => import('../views/procesosjudiciales/index.vue'),
            children: [
              {
                path: 'listado',
                name: 'pharmasan.administrativa.juridica.procesos-judiciales.listado',
                component: () => import('../views/procesosjudiciales/pages/listado/index.vue'),
                beforeEnter: VueRouterMultiguard([securityAccess]),
                meta: {
                  access: [
                    'pharmasan.administrativa.juridica.procesos-judiciales'
                  ],
                  activeMenu: 'pharmasan.administrativa.juridica.procesos-judiciales'
                }
              },
              {
                path: 'crear',
                name: 'pharmasan.administrativa.juridica.procesos-judiciales.crear',
                redirect: { name: 'pharmasan.administrativa.juridica.procesos-judiciales.crear.buscar-accionado' },
                component: () => import('../views/procesosjudiciales/pages/crear/index.vue'),
                children: [
                  {
                    path: 'buscar-accionado',
                    name: 'pharmasan.administrativa.juridica.procesos-judiciales.crear.buscar-accionado',
                    component: () => import('../views/procesosjudiciales/pages/crear/pages/buscar-accionado.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-judiciales.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-judiciales'
                    }
                  },
                  {
                    path: 'buscar-accionante',
                    name: 'pharmasan.administrativa.juridica.procesos-judiciales.crear.buscar-accionante',
                    component: () => import('../views/procesosjudiciales/pages/crear/pages/buscar-accionante.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-judiciales.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-judiciales'
                    }
                  },
                  {
                    path: 'informacion-juzgado',
                    name: 'pharmasan.administrativa.juridica.procesos-judiciales.crear.informacion-juzgado',
                    component: () => import('../views/procesosjudiciales/pages/crear/pages/informacion-juzgado.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-judiciales.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-judiciales'
                    }
                  },
                  {
                    path: 'detalle-proceso',
                    name: 'pharmasan.administrativa.juridica.procesos-judiciales.crear.detalle-proceso',
                    component: () => import('../views/procesosjudiciales/pages/crear/pages/detalle-proceso.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-judiciales.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-judiciales'
                    }
                  }
                ]
              },
              {
                path: 'ver/:id',
                name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver',
                redirect: { name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.info-proceso' },
                component: () => import('../views/procesosjudiciales/pages/ver/Main.vue'),
                children: [
                  {
                    path: 'info-proceso',
                    name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.info-proceso',
                    component: () => import('../views/procesosjudiciales/pages/ver/componentsMain/info-proceso.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-judiciales'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-judiciales'
                    }
                  },
                  {
                    path: 'info-sujetos',
                    name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.info-sujetos',
                    component: () => import('../views/procesosjudiciales/pages/ver/componentsMain/info-sujetos.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-judiciales'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-judiciales'
                    }
                  },
                  {
                    path: 'info-sujetos/agregar-demandante',
                    name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.info-sujetos.agregar-demandante',
                    component: () => import('../views/procesosjudiciales/pages/ver/componentsMain/componentsInfoSujetos/agregarDemandante.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-judiciales.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-judiciales'
                    }
                  },
                  {
                    path: 'info-sujetos/agregar-demandado',
                    name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.info-sujetos.agregar-demandado',
                    component: () => import('../views/procesosjudiciales/pages/ver/componentsMain/componentsInfoSujetos/agregarDemandado.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-judiciales.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-judiciales'
                    }
                  },
                  {
                    path: 'actuaciones',
                    name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.actuaciones',
                    component: () => import('../views/procesosjudiciales/pages/ver/componentsMain/actuaciones.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-judiciales'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-judiciales'
                    }
                  },
                  {
                    path: 'actuaciones/crear',
                    name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.actuaciones.crear',
                    component: () => import('../views/procesosjudiciales/pages/ver/componentsMain/componentsActuaciones/crear.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-judiciales.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-judiciales'
                    }
                  },
                  {
                    path: 'actuaciones/actualizar/:idActuacion',
                    name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.actuaciones.actualizar',
                    component: () => import('../views/procesosjudiciales/pages/ver/componentsMain/componentsActuaciones/update.vue'),
                    beforeEnter: VueRouterMultiguard([securityAccess]),
                    meta: {
                      access: [
                        'pharmasan.administrativa.juridica.procesos-judiciales.access'
                      ],
                      activeMenu: 'pharmasan.administrativa.juridica.procesos-judiciales'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
]

<template>
  <Toast />
  <Toolbar class="box mt-4 p-p-2">
    <template #start>
      <back-button />
    </template>
    <template #end>
      <Button v-if="$can('pharmasan.contabilidad.amortizaciones.configuracion.bancos.access')" label="Agregar" icon="pi pi-plus" class="p-button-success p-button-sm" @click="openDialogBank(null)" />
    </template>
  </Toolbar>

  <div class="box mt-4 intro-x">
    <DataTable
      showGridlines
      ref="dt"
      :value="banks"
      class="p-datatable-sm p-2 text-sm"
      dataKey="id"
      :paginator="true" :rows="10" :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5,10,25]"
      currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
          <h3 class="p-mb-2 p-m-md-0 p-as-md-center text-lg">Listado de Bancos</h3>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Buscar..." />
          </span>
        </div>
      </template>

      <!-- Columnas -->
      <Column field="name" header="Nombre" :sortable="true" style="min-width:16rem"></Column>
      <Column field="accountSap" header="Cuenta asociada SAP" :sortable="true" style="min-width:16rem">
        <template #body="{ data }">
          <div>
            {{data.accountSap.join()}}
          </div>
        </template>
      </Column>
      <Column field="cardCodeSap" header="Código SAP" :sortable="true" style="min-width:16rem"></Column>
      <Column field="status" header="Estado" :sortable="true" style="min-width:12rem">
        <template #body="{ data }">
          <span :class="'bank-badge px-2 py-1 text-xs font-bold leading-none text-white rounded ' + (data.status === true ? 'bg-green-700' : 'bg-red-700')">{{data.status === true ? 'Activo' : 'Inactivo'}}</span>
        </template>
      </Column>
      <Column v-if="$can('pharmasan.contabilidad.amortizaciones.configuracion.bancos.access')" :exportable="false" header="Acciones" style="text-align: center">
        <template #body="{ data }">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="openDialogBank(data)" />
          <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteBank(data)" />
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog v-model:visible="bankDialog" :style="{width: '450px'}" header="Agregar Banco" :modal="true" class="p-fluid">
    <div class="p-field">
      <label for="name">Nombre</label>
      <InputText
        id="name"
        type="text"
        v-model="modelo.name"
        :class="{ 'p-invalid': errors.name }"
      />
      <MessageError :text="errors.name" />
    </div>
    <div class="p-field">
      <label for="banco">Cuenta contable SAP</label>
      <MultiSelect
        v-model="modelo.accountSap"
        :options="accountsBankSap"
        :filter="true"
        optionLabel="labelSelect"
        optionValue="acctcode"
        placeholder="Seleccione una opción"
        :class="{ 'p-invalid': errors.accountSap }"
      />
      <MessageError :text="errors.accountSap" />
    </div>
    <div class="p-field">
      <label for="banco">Código SAP</label>
      <AutocompleteProveedores v-model="modelo.cardCodeSap" :classes="{ 'p-invalid': errors.cardCodeSap }"/>
      <MessageError :text="errors.cardCodeSap" />
    </div>
    <div class="p-field">
      <label for="status">Estado</label>
      <InputSwitch v-model="modelo.status" id="status" class="p-d-block"/>
    </div>

    <template #footer class="p-text-bold">
      <Button label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-sm" @click="hideDialog"/>
      <Button label="Guardar" icon="pi pi-check" class="p-button-success p-button-sm" @click="onSubmit" />
    </template>
  </Dialog>

  <Dialog v-model:visible="deleteBankDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3 text-red-600" style="font-size: 2rem" />
      <span v-if="bank">¿Está seguro de eliminar el Banco <b>{{bank.name}}</b>?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text p-button-danger" @click="deleteBankDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text p-button-success" @click="removeBank" />
    </template>
  </Dialog>

</template>

<script>
import { ref, onMounted } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import { useToast } from 'primevue/usetoast'

import listBanks from '../../../../services/listBanks'
import createBank from '../../../../services/createBank'
import updateBank from '../../../../services/updateBank'
import { error } from '../../../../../../../../libs/mensajes'
import deleteBank from '../../../../services/deleteBank'
import listBanksAccountSap from '../../../../services/listBanksAccountSap'
import AutocompleteProveedores from '../../../../../../../../components/select/proveedores'
import { object, string, array } from 'yup'
import { useField, useForm } from 'vee-validate'

export default {
  name: 'CRUD Bancos',
  components: {
    AutocompleteProveedores
  },
  setup () {
    /* Obtener el listado de bancos */
    const fetchBanks = () => {
      return listBanks().then(({ status, data }) => {
        banks.value = data.result.map(e => {
          e.accountSap = e.accountSap.map(x => x.account)
          return {
            ...e
          }
        })
        console.log(banks.value)
      }).catch(err => {
        console.error(err.message)
      })
    }

    const toast = useToast()
    const banks = ref()
    const bankDialog = ref(false)
    const deleteBankDialog = ref(false)
    const deleteBanksDialog = ref(false)
    const bank = ref({})
    const accountsBankSap = ref({})
    const selectedBanks = ref()
    const isEdit = ref(false)
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })

    const openDialogBank = (item) => {
      if (item) {
        for (const [key, value] of Object.entries(item)) {
          modelo[key] = value
        }
        isEdit.value = true
      } else {
        handleReset()
        isEdit.value = false
      }

      bankDialog.value = true
    }

    const hideDialog = () => {
      bankDialog.value = false
    }

    const confirmDeleteBank = (item) => {
      bank.value = item
      deleteBankDialog.value = true
    }

    const validationSchema = object({
      name: string().nullable().required().label('Nombre'),
      accountSap: array().min(1).required().label('Cuenta asociada SAP'),
      cardCodeSap: string().nullable().required().label('Código SAP')
    })

    // Create a form context with the validation schema
    const { errors, handleSubmit, values: modelo, handleReset } = useForm({
      validationSchema
    })

    // No need to define rules for fields
    useField('name', null, { initialValue: null })
    useField('accountSap', [], { initialValue: [] })
    useField('cardCodeSap', null, { initialValue: null })
    useField('status', null, { initialValue: true })
    useField('id', null, { initialValue: null })

    // Submit del envio de los datos
    const onSubmit = handleSubmit((values) => {
      if (isEdit.value && modelo.id) {
        updateBank(modelo.id, values).then(() => {
          toast.add({ severity: 'success', summary: 'Exito', detail: 'Banco actualizado exitosamente', life: 3000 })
          fetchBanks()
        }).catch((err) => {
          console.error(err.message)
          error(err.response.data.message)
        })
      } else {
        createBank(values).then(() => {
          toast.add({ severity: 'success', summary: 'Exito', detail: 'Banco creado exitosamente', life: 3000 })
          fetchBanks()
        }).catch((err) => {
          console.error(err.message)
          error(err.response.data.message)
        })
      }

      bankDialog.value = false
      bank.value = {}
    })

    const removeBank = () => {
      deleteBank(bank.value.id, bank.value).then(() => {
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Banco eliminado correctamente', life: 3000 })
        fetchBanks()
        deleteBankDialog.value = false
        bank.value = {}
      }).catch((err) => {
        console.error(err.message)
        error(err.response.data.message)
      })
    }

    const fetchAccountsBank = () => {
      listBanksAccountSap({}).then(({ status, data }) => {
        accountsBankSap.value = data.result.map(v => ({ ...v, labelSelect: `${v.acctcode} - ${v.acctname}` }))
        // accountsBankSap.value.unshift({ acctcode: null, labelSelect: '--- Seleccione una opción ---' })
      }).catch((err) => {
        console.error(err.message)
        error(err.response.data.message)
      })
    }

    onMounted(() => {
      fetchBanks()
      fetchAccountsBank()
    })

    return {
      banks,
      bankDialog,
      deleteBankDialog,
      deleteBanksDialog,
      bank,
      selectedBanks,
      filters,
      errors,
      modelo,
      onSubmit,
      openDialogBank,
      hideDialog,
      confirmDeleteBank,
      removeBank,
      accountsBankSap,
      AutocompleteProveedores
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>

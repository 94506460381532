<template>
  <section class="box p-4 flex flex-col items-center justify-center">
    <h1 class="text-3xl font-bold mt-5">401</h1>
    <p>Acceso no autorizado</p>
    <button
    type="button"
    class="btn btn-primary my-5"
    @click="$router.back()"
    >
      Regresar
    </button>
  </section>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default {
  setup () {
    const store = useStore()
    const router = useRouter()
    const user = computed(() => {
      return store.getters['auth/user']
    })
    onMounted(() => {
      setTimeout(() => {
        if (!user.value.group.routeName) return router.go(-1)
        router.push({ name: user.value.group.routeName })
      }, 3000)
    })
    return {
    }
  }
}
</script>

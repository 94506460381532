<template>
  <Toast />
  <Toolbar class="box mt-4 p-p-2">
    <template #start>
      <back-button />
    </template>
    <template #end>
      <Button v-if="$can('pharmasan.contabilidad.amortizaciones.configuracion.tipos-pago.access')" label="Agregar" icon="pi pi-plus" class="p-button-success p-button-sm" @click="openNew" />
    </template>
  </Toolbar>

  <div class="box mt-4 intro-x">
    <DataTable
      showGridlines
      ref="dt"
      :value="payments"
      class="p-datatable-sm p-2 text-sm"
      dataKey="id"
      :paginator="true" :rows="10" :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5,10,25]"
      currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
          <h3 class="p-mb-2 p-m-md-0 p-as-md-center text-lg">Listado de tipos de pagos</h3>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Buscar..." />
          </span>
        </div>
      </template>

      <!-- Columnas -->
      <Column field="name" header="Nombre" :sortable="true" style="min-width:16rem"></Column>
      <Column field="status" header="Estado" :sortable="true" style="min-width:12rem">
        <template #body="{ data }">
          <span :class="'payment-badge px-2 py-1 text-xs font-bold leading-none text-white rounded ' + (data.status === true ? 'bg-green-700' : 'bg-red-700')">{{data.status === true ? 'Activo' : 'Inactivo'}}</span>
        </template>
      </Column>
      <Column v-if="$can('pharmasan.contabilidad.amortizaciones.configuracion.tipos-pago.access')" :exportable="false" header="Acciones" style="text-align: center">
        <template #body="{ data }">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="editPayment(data)" />
          <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeletePayment(data)" />
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog v-model:visible="paymentDialog" :style="{width: '450px'}" header="Agregar Tipo de pago" :modal="true" class="p-fluid">
    <div class="p-field">
      <label for="name">Nombre</label>
      <InputText id="name" v-model="payment.name" required="true" autofocus :class="{'p-invalid': submitted && !payment.name}" />
      <small class="p-error" v-if="submitted && !payment.name">El nombre es requerido.</small>
    </div>
    <div class="p-field">
      <label for="status">Estado</label>
      <InputSwitch v-model="payment.status" id="status" class="p-d-block"/>
    </div>

    <template #footer class="p-text-bold">
      <Button label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-sm" @click="hideDialog"/>
      <Button label="Guardar" icon="pi pi-check" class="p-button-success p-button-sm" @click="savePayment" />
    </template>
  </Dialog>

  <Dialog v-model:visible="deletePaymentDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3 text-red-600" style="font-size: 2rem" />
      <span v-if="payment">¿Está seguro de eliminar el tipo de pago <b>{{payment.name}}</b>?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text p-button-danger" @click="deletePaymentDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text p-button-success" @click="removePayment" />
    </template>
  </Dialog>

</template>

<script>
import { ref, onMounted } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import { useToast } from 'primevue/usetoast'

import listPayments from '../../../../services/listTypePayments'
import createPayment from '../../../../services/createTypePayment'
import updatePayment from '../../../../services/updateTypePayment'
import { error } from '../../../../../../../../libs/mensajes'
import deletePayment from '../../../../services/deleteTypePayment'

export default {
  name: 'CRUD Tipo de Pagos',
  setup () {
    /* Obtener el listado de los tipos de pagos */
    const fetchPayments = () => {
      return listPayments().then(({ status, data }) => {
        payments.value = data.result
      }).catch(err => {
        console.error(err.message)
      })
    }

    onMounted(() => {
      fetchPayments()
    })

    const toast = useToast()
    const payments = ref([])
    const paymentDialog = ref(false)
    const deletePaymentDialog = ref(false)
    const deletePaymentsDialog = ref(false)
    const payment = ref({})
    const selectedPayments = ref()
    const submitted = ref(false)
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })
    const openNew = () => {
      payment.value = {
        id: null,
        name: '',
        status: true
      }
      submitted.value = false
      paymentDialog.value = true
    }
    const hideDialog = () => {
      paymentDialog.value = false
      submitted.value = false
    }
    const editPayment = (item) => {
      payment.value = { ...item }
      paymentDialog.value = true
    }
    const confirmDeletePayment = (item) => {
      payment.value = item
      deletePaymentDialog.value = true
    }

    const savePayment = () => {
      submitted.value = true

      if (payment.value.name !== undefined) {
        if (payment.value.name.trim()) {
          if (payment.value.id) {
            updatePayment(payment.value.id, payment.value).then(() => {
              toast.add({ severity: 'success', summary: 'Exito', detail: 'Tipo de pago actualizado exitosamente', life: 3000 })
              fetchPayments()
            }).catch((err) => {
              console.error(err.message)
              error(err.response.data.message)
            })
          } else {
            createPayment(payment.value).then(() => {
              toast.add({ severity: 'success', summary: 'Exito', detail: 'Tipo de pago creado exitosamente', life: 3000 })
              fetchPayments()
            }).catch((err) => {
              console.error(err.message)
              error(err.response.data.message)
            })
          }

          paymentDialog.value = false
          payment.value = {}
        }
      }
    }

    const removePayment = () => {
      deletePayment(payment.value.id, payment.value).then(() => {
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Tipo de pago eliminado correctamente', life: 3000 })
        fetchPayments()
        deletePaymentDialog.value = false
        payment.value = {}
      }).catch((err) => {
        console.error(err.message)
        error(err.response.data.message)
      })
      // payments.value = payments.value.filter(val => val.id !== payment.value.id)
    }

    return {
      payments,
      paymentDialog,
      deletePaymentDialog,
      deletePaymentsDialog,
      payment,
      selectedPayments,
      filters,
      submitted,
      openNew,
      hideDialog,
      editPayment,
      confirmDeletePayment,
      savePayment,
      removePayment
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>

<template>
  <div>
    <h1>hola mundo</h1>
  <h1>{{ $i18n.locale }} {{$t('message')}}</h1>
  </div>
</template>

<script>
export default {
  name: 'Main'
}
</script>

<style scoped>

</style>

// import securityMeService from '../../apps/security/services/securityMeService'

const state = () => {
  return {
    menu: [
      {
        icon: 'HomeIcon',
        pageName: 'side-menu-dashboard',
        title: 'Dashboard',
        subMenu: [
          {
            icon: 'TagIcon',
            pageName: 'dashboard',
            title: 'Operación'
          },
          {
            icon: 'TrendingUpIcon',
            pageName: 'dashboard.ejecucion',
            title: 'Ejecucion'
          }
        ]
      },
      {
        icon: 'UsersIcon',
        pageName: 'side-menu-users',
        title: 'Usuarios',
        subMenu: [
          {
            icon: 'CircleIcon',
            pageName: '',
            title: 'Mod Usuarios',
            subMenu: [
              {
                // icon: 'CircleIcon',
                pageName: 'usuarios',
                title: 'Listar Usuarios'
              },
              {
                // icon: '',
                pageName: 'usuarios.formulario',
                title: 'Crear Usuario'
              }
            ]
          },
          {
            icon: 'CircleIcon',
            pageName: '',
            title: 'Mod Grupos',
            subMenu: [
              {
                // icon: '',
                path: 'listar',
                title: 'Listar grupos',
                pageName: 'usuarios.grupos'
              },
              {
                // icon: '',
                path: 'formulario',
                title: 'Crear grupo',
                pageName: 'usuarios.grupos.formulario'
              }
            ]
          },
          {
            icon: 'CircleIcon',
            pageName: 'permissions.list',
            title: 'Mod Permisos'
          }
        ]
      },
      {
        icon: 'MapPinIcon',
        pageName: 'side-menu-users',
        title: 'Ubicaciones',
        subMenu: [
          {
            icon: 'CircleIcon',
            pageName: '',
            title: 'Mod departamentos',
            subMenu: [
              {
                icon: 'DiscIcon',
                pageName: 'departments',
                title: 'Listar departamentos'
              }
            ]
          },
          {
            icon: 'CircleIcon',
            pageName: '',
            title: 'Mod ciudades',
            subMenu: [
              {
                icon: 'DiscIcon',
                pageName: 'citys',
                title: 'Listar ciudades'
              }
            ]
          }
        ]
      },
      {
        icon: 'GlobeIcon',
        pageName: 'administrativo',
        title: 'Administrativo',
        subMenu: [
          {
            icon: 'DollarSignIcon',
            pageName: 'admin.compras',
            title: 'Compras',
            subMenu: [
              {
                icon: 'ToolIcon',
                pageName: 'pharmasan.administrativa.compras-administrativas.ajustes',
                title: 'Ajustes'
              },
              {
                icon: 'UsersIcon',
                pageName: 'admin.compras.preciosProveedores',
                title: 'Precios y proveedores'
              },
              {
                icon: 'PackageIcon',
                pageName: 'pharmasan.administrativa.compras-administrativas.inventario',
                title: 'Inventario'
              },
              {
                icon: 'ShoppingBagIcon',
                pageName: 'admin.compras.compras',
                title: 'Compras'
              },
              {
                icon: 'TruckIcon',
                pageName: 'admin.compras.entregas',
                title: 'Entregas'
              }
            ]
          },
          {
            icon: 'BoxIcon',
            pageName: 'admin.minor-box',
            title: 'Caja menor',
            subMenu: [
              {
                icon: 'SettingsIcon',
                pageName: 'admin.minor-box.settings.create-minor-bor',
                title: 'configuración'
              }
            ]
          }
        ]
      },
      {
        icon: 'SettingsIcon',
        pageName: '',
        title: 'Ajustes',
        subMenu: [
          {
            icon: 'BookOpenIcon',
            pageName: 'ajustes.tema',
            title: 'Tema'
          }
        ]
      }
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// mutations
const mutations = {
  setMenu (state, payload) {
    state.menu = payload
  }
}

// actions
const actions = { }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

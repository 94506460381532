
import Layouts from '../../../layouts/main-menu/Main.vue'
import auth from '../../../middleware/auth'
import VueRouterMultiguard from 'vue-router-multiguard'

export default [
  {
    path: '/pharmasan/general',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: 'general',
        name: 'pharmasan.general',
        redirect: { name: 'pharmasan.general.publicaciones' }
      },
      {
        path: 'publicaciones',
        name: 'pharmasan.general.publicaciones',
        component: () => import('../general/publicaciones/views/index.vue').then(m => m || m),
        meta: {
          access: [
            'pharmasan.general.publicaciones'
          ]
        }
      },
      {
        path: 'administrar-publicaciones',
        name: 'pharmasan.general.publicaciones.administrar',
        props: true,
        component: () => import('../general/publicaciones/views/administrar.vue').then(m => m || m),
        meta: {
          access: [
            'pharmasan.general.publicaciones.access'
          ],
          activeMenu: 'pharmasan.general.publicaciones'
        }
      }
    ]
  }
]

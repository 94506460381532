<template>
  <section>
    <router-view/>
  </section>
</template>
<script>
export default {
  name: 'home'
}
</script>

<style scoped>

</style>

<template>
  <!-- BEGIN: Top Bar -->
  <div id="top-menu" class="top-bar">
    <!-- BEGIN: Breadcrumb -->
    <div class="-intro-x breadcrumb mr-auto hidden sm:flex">
      <a href="">Intranet V2</a>
      <template v-for="(miga,index) in minasPan" :key="miga">
        <ChevronRightIcon class="breadcrumb__icon"  v-show="miga !== ''"/>
        <a class="breadcrumb--active cursor-pointer" v-show="miga !== ''" @click="goto(index)">{{ miga }}</a>
      </template>
    </div>
    <p>{{ userData.full_name }}</p>
    <a @click="salir" class="flex items-center p-2 transition duration-300 ease-in-out rounded-md">
      <UserIcon class="w-4 h-4 mr-2" /> Salir
    </a>
<!--    <a @click="clearCache" class="flex cursor-pointer">-->
<!--       F5-->
<!--    </a>-->
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from '@/store'
import chunk from 'lodash/chunk'

export default defineComponent({
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const userData = store.getters['auth/getPeople']
    const ruta = computed(() => {
      return route.name.replace(/[.]/g, ' ')
    })
    const minasPan = computed(() => {
      const fullPath = route.fullPath.split('?')[0]
      return chunk(fullPath.split('/'), 8)[0]
    })

    const searchDropdown = ref(false)

    const showSearchDropdown = () => {
      searchDropdown.value = true
    }

    const hideSearchDropdown = () => {
      searchDropdown.value = false
    }
    const salir = () => {
      store.commit('auth/LOGOUT')
      router.push({ name: 'login' })
    }
    const goto = (index) => {
      const ruta = []
      for (let i = 0; i <= index; i++) {
        ruta.push(minasPan.value[i])
      }
      router.push(ruta.join('/'))
    }
    const clearCache = () => {
      if ('caches' in window) {
        alert('limpiar el cache')
        caches.keys().then(function (cacheNames) {
          console.log('Borrando: ' + cacheNames)
          cacheNames.forEach(function (cacheName) {
            console.log('cache: ' + cacheName)
            caches.delete(cacheName)
          })
        })
      } else {
        alert('No se pudo limpiar el cache')
      }
    }

    return {
      userData,
      salir,
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      ruta,
      minasPan,
      goto,
      clearCache
    }
  }
})
</script>

<style>
@media print {
  #top-menu{
    display: none;
  }
}
</style>

<template>
  <Toast />
  <Toolbar class="box mt-4 p-p-2 flex sm:flex-row sm:w-auto flex-col w-full">
    <template #start>
      <back-button />
    </template>
    <template #end>
      <div class="flex sm:flex-row flex-col">
        <router-link
          :to="{name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.tabla.generar'}"
        >
          <Button label="Generar Amortizaciones" icon="pi pi-inbox" class="p-button-secondary p-button-sm mr-1"/>
        </router-link>
        <router-link
          :to="{name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.tabla.cargar'}"
        >
          <Button label="Cargue tabla Amortizaciones" icon="pi pi-table" class="p-button-info p-button-sm mr-1"/>
        </router-link>
        <Button label="Nuevo Registro" icon="pi pi-send" class="p-button-success p-button-sm" @click="openDialogAmortization(null)"/>
      </div>
    </template>
  </Toolbar>

  <InfoFinancialProduct :info="financialProduct" />

  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">
        <div class="intro-x">
          <DataTable
            showGridlines
            ref="dt"
            :value="tableAmortizations"
            v-model:expandedRows="expandedRows"
            class="p-datatable-sm p-2 text-sm"
            dataKey="id"
            :paginator="true"
            :rows="10"
            :filters="filters"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5,10,25,50,100,150,200]"
            currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
            responsiveLayout="stack"
            breakpoint="960px"
            @rowExpand="onRowExpand"
            @rowCollapse="onRowCollapse"
          >
            <template #header>
              <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                <h3 class="font-medium p-mb-2 p-m-md-0 p-as-md-center text-lg">Listado de Amortizaciones</h3>
                <div class="table-header-container">
                  <Button icon="pi pi-plus" label="Expandir" @click="expandAll" class="p-mr-2 p-button-sm" />
                  <Button icon="pi pi-minus" label="Colapsar" @click="collapseAll" class="p-button-sm" />
                </div>
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                </span>
              </div>
            </template>

            <!-- Columnas -->
            <Column :expander="true" headerStyle="width: 3rem"/>
            <Column field="installmentNumber" header="# Cuota" :sortable="true" style="min-width:3rem; text-align:center;">
              <template #body="{data}">
                <Badge :value="data.installmentNumber" class="p-mr-2 text-center" severity="info"></Badge>
              </template>
            </Column>
            <Column field="paymentDate" header="Fecha Cuota" :sortable="true" style="min-width:8rem; text-align:center;">
            </Column>
            <Column field="fee" header="Valor Cuota" :sortable="true" style="min-width:12rem; text-align:right;">
              <template #body="{data}">
                {{ $h.formatCurrency(data.valorCuota) }}
              </template>
            </Column>
            <Column :exportable="false" header="Acciones" style="text-align: center">
              <template #body="{ data }">
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-warning p-mr-2"
                  @click="openDialogEdit(data, 'editHeader')"
                  v-tooltip.top="'Editar'"
                />
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger"
                  @click="confirmDeleteAmortization(data)"
                  v-tooltip.top="'Eliminar'"/>
              </template>
            </Column>
            <template #expansion="slotProps">
              <div class="orders-subtable">
                <h5>Detalle de los valores de la amortización y cuota # {{ slotProps.data.installmentNumber }}</h5>
                <DataTable
                  :value="slotProps.data.AmortizationsTablesdetail"
                  showGridlines
                  responsiveLayout="scroll"
                  class="p-datatable-sm p-2 text-sm"
                >
                  <Column header="Cuenta">
                    <template #body="{data}">
                      {{ data.OACT.acctcode }}
                    </template>
                  </Column>
                  <Column field="nameAccount" header="Nombre">
                    <template #body="{data}">
                      {{ data.OACT.acctname }}
                    </template>
                  </Column>
                  <Column header="Valor" >
                    <template #body="{data}">
                      {{ $h.formatCurrency(parseInt(data.debit)) }}
                    </template>
                  </Column>
                  <Column :exportable="false" header="Acciones" style="text-align: center" >
                    <template #body="{ data }">
                      <Button
                        icon="pi pi-pencil"
                        class="p-button-rounded p-button-warning p-mr-2"
                        @click="openDialogEdit(data, 'editDetails')"
                        v-tooltip.top="'Editar'"
                      />
                      <Button
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger"
                        @click="removeAmortizationDetail(data)"
                        v-tooltip.top="'Eliminar Detalle'"/>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </template>
          </DataTable>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal nuevo registro amortizacion  -->
  <Dialog
    v-model:visible="displayDialogAmortization"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '50vw'}"
    :modal="true"
    class="p-fluid custom-style"
    @keyup.enter="onSubmit"
  >
    <template #header>
      <span class="text-lg p-text-bold" :class="isEdit ? 'text-yellow-600' : 'text-green-800'">{{ isEdit ? 'Editar Registro Amortización' : 'Nuevo Registro Amortización' }}</span>
    </template>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12">
        <label for="installmentNumber">Número Cuota:</label>
        <InputText
          id="installmentNumber"
          type="number"
          v-model="modelo.installmentNumber"
          :class="{ 'p-invalid': errors.installmentNumber }"
          autofocus
        />
        <MessageError :text="errors.installmentNumber" />
      </div>
      <div class="p-field p-col-12 p-md-12">
        <label for="paymentDate">Fecha Cuota:</label>
        <InputText
          id="paymentDate"
          type="date"
          v-model="modelo.paymentDate"
          :class="{ 'p-invalid': errors.paymentDate }"
        />
        <MessageError :text="errors.paymentDate" />
      </div>
      <div v-for="(account, key) in modelo.details" :key="key" class="p-field p-col-12 p-md-12">
        <label :for="account.acctcode">Valor <span class="font-medium capitalize">{{ account.nameaccount }}</span>:</label>
        <InputNumber
          :id="account.acctcode"
          v-model="account.debit"
          mode="currency"
          currency="COP"
          locale="es-CO"
          minFractionDigits="0"
          maxFractionDigits="0"
          :class="{ 'p-invalid': errors[`details[${account._key}].debit`] }"
        />
        <MessageError :text="errors[`details[${account._key}].debit`]" />
      </div>
      <div class="p-field p-col-12 p-md-12">
        <label for="fee">Valor Cuota ($):</label>
        <InputNumber
          id="fee"
          v-model="modelo.fee"
          mode="currency"
          currency="COP"
          locale="es-CO"
          minFractionDigits="0"
          maxFractionDigits="0"
          :class="{ 'p-invalid': errors.fee }"
          disabled
        />
        <MessageError :text="errors.fee" />
      </div>
      <input type="hidden" v-model="financialProduct.id">
    </div>
    <template #footer>
      <Button label="Cancelar" icon="pi pi-times" @click="closeResponsive" class="p-button-text p-button-danger"/>
      <Button label="Guardar" icon="pi pi-check" @click="onSubmit" class="p-button-sm p-button-success"/>
    </template>
  </Dialog>

  <!-- Modal para editar amortizaciones -->
  <Dialog
    v-model:visible="displayDialogEdit"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '50vw'}"
    :modal="true"
    class="p-fluid custom-style"
  >
    <template #header>
      <span class="text-lg p-text-bold text-yellow-600">Editar Registro Amortización</span>
    </template>
    <div class="p-fluid p-formgrid p-grid">
      <div v-if="isEditHeader" class="p-field p-col-12 p-md-12">
        <label for="installmentNumber">Número Cuota:</label>
        <InputText
          id="installmentNumber"
          type="number"
          v-model="modelo.installmentNumber"
          :class="{ 'p-invalid': errors.installmentNumber }"
          autofocus
        />
        <MessageError :text="errors.installmentNumber" />
      </div>
      <div v-if="isEditHeader" class="p-field p-col-12 p-md-12">
        <label for="paymentDate">Fecha Cuota:</label>
        <InputText
          id="paymentDate"
          type="date"
          v-model="modelo.paymentDate"
          :class="{ 'p-invalid': errors.paymentDate }"
          :disabled="!!isEditDetails"
        />
        <MessageError :text="errors.paymentDate" />
      </div>
      <div v-if="isEditDetails" class="p-field p-col-12 p-md-12">
        <label for="fee">Valor <strong>{{ modelo.OACT.acctname }}</strong>:</label>
        <InputNumber
          id="fee"
          v-model="modelo.debit"
          mode="currency"
          currency="COP"
          locale="es-CO"
          minFractionDigits="0"
          maxFractionDigits="0"
          :class="{ 'p-invalid': errors.debit }"
        />
        <MessageError :text="errors.valorCuota" />
      </div>
      <div v-if="isEditHeader" class="p-field p-col-12 p-md-12">
        <label for="fee">Valor Cuota ($):</label>
        <InputNumber
          id="fee"
          v-model="modelo.valorCuota"
          mode="currency"
          currency="COP"
          locale="es-CO"
          minFractionDigits="0"
          maxFractionDigits="0"
          :class="{ 'p-invalid': errors.valorCuota }"
          disabled
        />
        <MessageError :text="errors.valorCuota" />
      </div>
    </div>
    <template #footer>
      <Button label="Cancelar" icon="pi pi-times" @click="closeResponsive" class="p-button-text p-button-danger"/>
      <Button label="Editar" icon="pi pi-pencil" @click="onSubmitEdit(submitTypeEdit)" class="p-button-sm p-button-success"/>
    </template>
  </Dialog>

  <!-- Modal para eliminar un registro de la tabla de amortizaciones -->
  <Dialog
    v-model:visible="deleteAmortizationDialog"
    :style="{width: '450px'}"
    header="Confirmar"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3 text-red-600" style="font-size: 2rem" />
      <span v-if="financialProduct">
        ¿Está seguro de eliminar el registro con # de cuota <b>{{ modelo.installmentNumber }}</b>
        y # de obligacion <b>{{financialProduct.obligationNumber}}</b>?
      </span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text p-button-danger" @click="deleteAmortizationDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text p-button-success" @click="removeAmortization" />
    </template>
  </Dialog>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import findFinancialProduct from '../../../../services/findFinancialProduct'
import { useRoute } from 'vue-router'
import { array, number, object, string } from 'yup'
import { useField, useForm } from 'vee-validate'
import { useToast } from 'primevue/usetoast'
// import { useLoading } from 'vue3-loading-overlay'
import { error, errorHtml, messageWarningDelete } from '../../../../../../../../libs/mensajes'
import createTableAmortization from '../../../../services/createTableAmortization'
import listTableAmortizations from '../../../../services/listTableAmortizations'
import { FilterMatchMode } from 'primevue/api'
import updateTableAmortizations from '../../../../services/updateTableAmortizations'
import deleteTableAmortization from '../../../../services/deleteTableAmortization'
import listAccountsSapByProduct from '../../../../services/listAccountsSapByProduct'
import listTableAmortizationsDetails from '../../../../services/listTableAmortizationsDetails'
import { helper } from '../../../../../../../../utils/helper'
import InfoFinancialProduct from '../../info'
import updateTableAmortizationsDetails from '../../../../services/updateTableAmortizationsDetails'
import { useStore } from 'vuex'
import deleteDetailAmortization from '../../../../services/deleteTableDetailAmortization'

export default {
  name: 'tableAmortizations',
  components: {
    InfoFinancialProduct
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const toast = useToast()
    const displayDialogAmortization = ref(false)
    const displayDialogEdit = ref(false)
    const deleteAmortizationDialog = ref(false)
    // const loader = useLoading()
    const isEdit = ref(false)
    const isEditHeader = ref(false)
    const isEditDetails = ref(false)
    const capitalValue = ref(0)
    const submitTypeEdit = ref('')
    const dataInfoAccountsSap = ref([])
    const financialProduct = reactive({
      name: '',
      bank: '',
      typeObligation: '',
      obligationNumber: '',
      disbursement: '',
      disbursementDate: ''
    })
    const tableAmortizations = ref()
    const tableAmortizationsDetails = ref([])
    const expandedRows = ref([])
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })
    const maxInstallmentNumber = ref(0)

    /* Obtener el valor de la cuota */
    const getValueFee = computed(() => {
      return dataInfoAccountsSap.value.reduce((sum, val) => (
        typeof val.debit === 'number' ? sum + val.debit : sum
      ), 0)
    })

    /* Obtener el total del capital */
    const getTotalCapital = computed(() => {
      let tt = 0
      tableAmortizationsDetails.value.forEach((item, i) => {
        const ttFind = item.find((it) => {
          return it.OACT.acctcode === financialProduct.accountSap
        })

        tt += ttFind.debit
      })

      return tt
    })

    /* Obtener el listado de la tabla de amortizaciones */
    const fetchTableAmortizations = (id) => {
      return listTableAmortizations({ id }).then(({ status, data }) => {
        tableAmortizations.value = data.result.map((tblAmortization) => {
          const totalDebit = tblAmortization.AmortizationsTablesdetail.reduce((tot, arr) => {
            return tot + arr.debit
          }, 0)

          return {
            ...tblAmortization,
            valorCuota: totalDebit
          }
        })

        tableAmortizationsDetails.value = data.result.map((tblAmortization) => {
          return tblAmortization.AmortizationsTablesdetail.map((detailAmortization) => {
            return {
              ...detailAmortization
            }
          })
        })
      }).catch(err => {
        console.error(err.message)
      })
    }

    const onRowCollapse = () => {
      tableAmortizationsDetails.value = []
    }
    const onRowExpand = (event) => {
      console.log('Event expand: ', event)
    }
    const expandAll = async () => {
      expandedRows.value = tableAmortizations.value.filter(async (tableReg) => {
        const response = await listTableAmortizationsDetails({ amortizationId: tableReg.id })
        tableAmortizationsDetails.value = response.data.result ?? []
      })
    }
    const collapseAll = () => {
      expandedRows.value = null
    }

    /* Obtener el producto financiero */
    const fetchFinancialProduct = (id) => {
      return findFinancialProduct({ id }).then(({ status, data: response }) => {
        if (status !== 200) return
        modelo.productId = response.result.id
        financialProduct.id = response.result.id
        financialProduct.bankId = response.result.bankId
        financialProduct.name = response.result.name
        financialProduct.bank = response.result.AmortizationsBank.name
        financialProduct.typeObligation = response.result.AmortizationsBankQuota.creditLine.name
        financialProduct.obligationNumber = response.result.obligationNumber
        financialProduct.disbursement = response.result.disbursement
        financialProduct.disbursementDate = response.result.disbursementDate
        financialProduct.accountSap = response.result.accountSap
        financialProduct.term = response.result.term
        financialProduct.rateEA = response.result.rateEA
        financialProduct.periodicity = response.result.periodicity
      })
    }

    /* Obtener las cuentas sap asociadas al producto */
    const fetchAccountsSapByProduct = (productId) => {
      return listAccountsSapByProduct({ productId }).then(({ status, data: resp }) => {
        modelo.details = (dataInfoAccountsSap.value = resp.result.map((r, index) => {
          return {
            accountSap: r.accountSap,
            debit: 0,
            nameaccount: r.nameAccount,
            _key: index,
            id: r.id
          }
        }))
      }).catch(err => {
        console.error(err.message)
      })
    }

    /* Evento cuando se abre el modal */
    const openDialogAmortization = async (item) => {
      const maxFee = Math.max.apply(Math, tableAmortizations.value.map(function (o) { return o.installmentNumber }))

      isEdit.value = false
      /* Asignar el numero de la cuota */
      const lengthAmortizations = tableAmortizations.value.length
      if (lengthAmortizations > 0) {
        modelo.installmentNumber = maxFee + 1
      } else {
        modelo.installmentNumber = 1
      }

      await fetchAccountsSapByProduct(financialProduct.id)
      displayDialogAmortization.value = !validateNumAmortizations(financialProduct.term, lengthAmortizations + 1)

      modelo.productId = route.params.id
    }

    /* Evento de funcion cuando se cierra el modal */
    const closeResponsive = () => {
      displayDialogAmortization.value = false
      displayDialogEdit.value = false
    }

    /**/
    const openDialogEdit = (editData, typeEdit) => {
      if (typeEdit === 'editHeader') {
        isEditHeader.value = true
        isEditDetails.value = false
        submitTypeEdit.value = 'editHeader'
      } else {
        isEditHeader.value = false
        isEditDetails.value = true
        submitTypeEdit.value = 'editDetails'
        capitalValue.value = editData.debit
      }
      for (const [key, value] of Object.entries(editData)) {
        modelo[key] = value
      }
      isEdit.value = true
      displayDialogEdit.value = true
    }

    /* Confirmar el borrado de la amortizacion */
    const confirmDeleteAmortization = (item) => {
      for (const [key, value] of Object.entries(item)) {
        modelo[key] = value
      }
      deleteAmortizationDialog.value = true
    }

    /* Eliminar el detalle de la amortizacion */
    const removeAmortizationDetail = (data) => {
      const { id, OACT } = data

      messageWarningDelete(
        '',
        'Eliminar Detalle Amortización',
        'warning',
        `¿Esta seguro de eliminar el detalle de la amortización <strong>${OACT.acctcode} - ${OACT.acctname}</strong>, esta acción es irreversible?`
      ).then(({ isConfirmed }) => {
        if (isConfirmed) {
          deleteDetailAmortization(id).then(() => {
            toast.add({ severity: 'success', summary: 'Exito', detail: 'Registro del detalle de la amortización eliminado correctamente', life: 3000 })
            fetchTableAmortizations(modelo.productId)
          }).catch((err) => {
            console.error(err.message)
            error(err.response.data.message)
          })
        }
      })
    }

    /* Eliminar registro de amortizacion */
    const removeAmortization = () => {
      deleteTableAmortization(modelo.id).then(() => {
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Registro de amortización eliminado correctamente', life: 3000 })
        fetchTableAmortizations(modelo.productId)
        deleteAmortizationDialog.value = false
      }).catch((err) => {
        console.error(err.message)
        error(err.response.data.message)
      })
    }

    /* Declarando las reglas de validacion con esquema */
    const validationSchema = object({
      installmentNumber: number().min(1).typeError('El campo es requerido').positive().label('Número cuota'),
      fee: number().typeError('El campo es requerido').positive().label('Valor cuota'),
      paymentDate: string().nullable().required().label('Fecha cuota'),
      details: array().of(
        object({
          debit: number().typeError('El campo es requerido').positive().min(0).label('El campo del valor')
        })
      )
    })

    // Campos del modelo reactivo
    useField('installmentNumber', null, { initialValue: 0 })
    useField('fee', null, { initialValue: 0 })
    useField('paymentDate', null, { initialValue: null })
    useField('details', null, {
      initialValue: [{ accountSap: null, nameaccount: null, _key: 0, debit: 0 }]
    })

    // Create a form context with the validation schema
    const { errors, handleSubmit, values: modelo, handleReset } = useForm({
      validationSchema
    })

    // Funcion para validar el capital con el desembolso
    const validateCapital = (values) => {
      const findDetail = values.details.find((val) => {
        return val.accountSap === financialProduct.accountSap
      })

      const totalCapital = getTotalCapital.value + findDetail.debit

      if (financialProduct.disbursement < totalCapital) {
        errorHtml(
          'Validación',
          `<br>El total del capital <strong>(${helper.formatCurrency(totalCapital)})</strong> no debe superar el desembolso <strong>(${helper.formatCurrency(financialProduct.disbursement)})</strong>`
        )
        // loader.hide()
        return true
      }
      return false
    }

    /* Funcion para validar el numero de amortizaciones dependiendo los plazos */
    const validateNumAmortizations = (term, numAmortizations) => {
      if (numAmortizations > term) {
        errorHtml(
          'Validación',
          `<br>No es posible agregar otro registro debido a que el número de amortizaciones <strong>(${numAmortizations})</strong> supera el plazo máximo <strong>(${term})</strong>`
        )
        // loader.hide()
        return true
      }

      return false
    }

    // Submit del envio de los datos
    const onSubmit = handleSubmit((values) => {
      // loader.show()

      if (validateCapital(values)) {
        return
      }

      // Se eliminan los valores innecesario a no guardar
      values.details = values.details.map(({ _key, nameaccount, id, ...rest }) => ({ ...rest }))

      if (isEdit.value) {
        updateTableAmortizations(values.id, values).then((res) => {
          // loader.hide()
          toast.add({ severity: 'success', summary: 'Exito', detail: 'Registro de amortización actualizado exitosamente', life: 3000 })
          fetchTableAmortizations(values.productId)
        }).catch((err) => {
          // loader.hide()
          console.error(err.message)
          if (err.response.status) {
            error(err.response.data.message)
          }
        })
      } else {
        createTableAmortization({ amortization: [values] }).then((res) => {
          // loader.hide()
          toast.add({ severity: 'success', summary: 'Exito', detail: 'Registro de amortización creado exitosamente', life: 3000 })
          fetchTableAmortizations(values.productId)
        }).catch((err) => {
          // loader.hide()
          console.error(err.message)
          if (err.response.status) {
            error(err.response.data.message)
          }
        })
      }

      displayDialogAmortization.value = false
      handleReset()
    })

    /* Edicion de las amortizaciones */
    const onSubmitEdit = (submitTypeEdit) => {
      // loader.show()

      if (submitTypeEdit === 'editHeader') {
        const editHeaderSubmit = {
          installmentNumber: modelo.installmentNumber,
          productId: modelo.productId,
          paymentDate: modelo.paymentDate
        }

        if (['', null, undefined].includes(editHeaderSubmit.paymentDate)) {
          toast.add({ severity: 'error', summary: 'Error', detail: 'El campo es obligatorio', life: 3000 })
          // loader.hide()
          return
        }

        updateTableAmortizations(modelo.id, editHeaderSubmit).then(({ data, status }) => {
          if (data.message === 'OK' && status === 200) {
            // loader.hide()
            toast.add({ severity: 'success', summary: 'Exito', detail: 'Registro de amortizacion actualizado exitosamente', life: 3000 })
            fetchTableAmortizations(financialProduct.id)
            displayDialogEdit.value = false
          }
        }).catch((err) => {
          // loader.hide()
          console.error(err.message)
          if (err.response.status) {
            error(err.response.data.message)
          }
        })
      } else {
        const editDetailsSubmit = {
          debit: modelo.debit
        }

        if (['', null, undefined, 0, '0'].includes(editDetailsSubmit.debit)) {
          toast.add({ severity: 'error', summary: 'Error', detail: 'El campo es obligatorio', life: 3000 })
          // loader.hide()
          return
        }

        if (financialProduct.accountSap === modelo.OACT.acctcode) {
          const totalCapital = getTotalCapital.value + editDetailsSubmit.debit - capitalValue.value

          if (financialProduct.disbursement < totalCapital) {
            errorHtml(
              'Validación',
              `<br>El total del capital <strong>(${helper.formatCurrency(totalCapital)})</strong> no debe superar el desembolso <strong>(${helper.formatCurrency(financialProduct.disbursement)})</strong>`
            )
            // loader.hide()
            return true
          }
        }

        updateTableAmortizationsDetails(modelo.id, editDetailsSubmit).then(({ data, status }) => {
          if (data.message === 'OK' && status === 200) {
            // loader.hide()
            toast.add({ severity: 'success', summary: 'Exito', detail: 'Registro del detalle de la amortizacion actualizado exitosamente', life: 3000 })
            fetchTableAmortizations(financialProduct.id)
            displayDialogEdit.value = false
          }
        }).catch((err) => {
          // loader.hide()
          console.error(err.message)
          if (err.response.status) {
            error(err.response.data.message)
          }
        })
      }
    }

    /* */
    watch(() => getValueFee.value, (newValue, oldValue) => {
      modelo.fee = newValue
    })

    onMounted(() => {
      const { id } = route.params
      Promise.all([
        fetchFinancialProduct(id),
        fetchTableAmortizations(id)
      ])

      store.dispatch('infoFinancialProductAmortization/financialProductAction', id)
    })

    return {
      financialProduct,
      displayDialogAmortization,
      openDialogAmortization,
      closeResponsive,
      onSubmit,
      errors,
      modelo,
      tableAmortizations,
      tableAmortizationsDetails,
      expandedRows,
      onRowCollapse,
      onRowExpand,
      expandAll,
      collapseAll,
      filters,
      isEdit,
      isEditHeader,
      isEditDetails,
      deleteAmortizationDialog,
      confirmDeleteAmortization,
      removeAmortizationDetail,
      removeAmortization,
      maxInstallmentNumber,
      dataInfoAccountsSap,
      getTotalCapital,
      validateNumAmortizations,
      openDialogEdit,
      displayDialogEdit,
      onSubmitEdit,
      submitTypeEdit
    }
  }

}
</script>

<style lang="scss">
.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.42rem;
}
.symbol .symbol-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #3F4254;
  background-color: #F3F6F9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.42rem;
}
.symbol .symbol-badge {
  position: absolute;
  border: 2px solid #ffffff;
  border-radius: 100%;
  top: 0;
  right: 0;
}
.symbol.symbol-circle {
  border-radius: 50%;
}
.symbol.symbol-circle > img {
  border-radius: 50%;
}
.symbol.symbol-circle .symbol-label {
  border-radius: 50%;
}
.symbol.symbol-light-primary .symbol-label {
  background-color: #E1F0FF;
  color: #3699FF;
}
.symbol.symbol-light-secondary .symbol-label {
  background-color: #EBEDF3;
  color: #E4E6EF;
}
.symbol.symbol-light-success .symbol-label {
  background-color: #C9F7F5;
  color: #1BC5BD;
}
.symbol.symbol-light-info .symbol-label {
  background-color: #EEE5FF;
  color: #8950FC;
}
.symbol.symbol-light-warning .symbol-label {
  background-color: #FFF4DE;
  color: #FFA800;
}
.symbol.symbol-light-danger .symbol-label {
  background-color: #FFE2E5;
  color: #F64E60;
}
.symbol.symbol-light-light .symbol-label {
  background-color: #F3F6F9;
  color: #F3F6F9;
}
.symbol.symbol-light-dark .symbol-label {
  background-color: #D1D3E0;
  color: #181C32;
}
.symbol.symbol-light-white .symbol-label {
  background-color: #ffffff;
  color: #ffffff;
}

.symbol.symbol-50 > img {
  width: 100%;
  max-width: 50px;
  height: 50px;
}
.symbol.symbol-50 .symbol-label {
  width: 50px;
  height: 50px;
}
.symbol.symbol-50 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}
.symbol.symbol-50 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}
.custom-style {
  .p-dialog-header {
    color: #5E8F32 !important;
  }
}
.swal2-container {
  z-index: 9999;
}
</style>

import * as types from '../mutation-types'

const state = () => {
  return {
    darkMode:
      window.localStorage.getItem('is-dark')
      ? JSON.parse(window.localStorage.getItem('is-dark'))
      : false,
      ov: false
  }
}

// getters
const getters = {
  darkMode: state => state.darkMode,
  ov: state => state.ov
}

// actions
const actions = {
  setDarkMode ({ commit }, darkMode) {
    commit(types.SET_DARK_MODE, { darkMode })
  }
}

// mutations
const mutations = {
  [types.SET_DARK_MODE] (state, { darkMode }) {
    window.localStorage.setItem('is-dark', darkMode)
    state.darkMode = darkMode
  },
  setOv (state, payload) {
    // console.log('this is payload-->', payload)
    state.ov = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

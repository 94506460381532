<template>
  <section class="intro-x">
    <div class="w-full my-4">
      <back-button />
    </div>
    <div class="w-full md:w-1/2 box p-4">
      <form @submit="onSubmit">
        <div class="w-full my-2">
          <p class="font-bold">
            Formulario de categorías
          </p>
        </div>
        <div class="mb-2">
          <label for="catName">Nombre</label>
          <input
          id="catName"
          v-model="model.name"
          type="text"
          class="form-control"
          >
          <div class="text-red-400">
            {{errors.name}}
          </div>
        </div>
        <div class="mb-2">
          <label for="catCode">Codigo</label>
          <input
          id="catCode"
          v-model="model.code"
          type="text"
          class="form-control"
          >
          <div class="text-red-400">
            {{errors.code}}
          </div>
        </div>

        <div class="form-check  mb-2">
          <input
          v-model="model.state"
          id="show-example-5"
          class="show-code form-check-switch"
          type="checkbox"
          />
          <label
          class="form-check-label ml-0 sm:ml-2"
          for="show-example-5"
          >
            Estado
          </label >
        </div>

        <div class="mb-2">
          <button
          type="submit"
          class="bg-primary-1 px-3 py-2 text-white rounded-md mt-1"
          title="Guardar"
          :disabled="!isLoading"
          >
            Guardar
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { useField, useForm } from 'vee-validate'
import { computed, onMounted, ref } from 'vue'
import { object, string, boolean } from 'yup'
import createCategory from '../../../../../services/createCategory'
import findCategory from '../../../../../services/findCategory'
import updateCategory from '../../../../../services/updateCategory'
import { messageSuccess, success } from '../../../../../../../../libs/mensajes'
import { useRoute, useRouter } from 'vue-router'
export default {
  name: 'CategoryForm',
  setup () {
    /*
    * Instances
    */
    const router = useRouter()
    const route = useRoute()

    /*
    * Methods
    */
    const validationSchema = object().shape({
      name: string().required().min(3),
      code: string().max(10).required(),
      state: boolean().required()
    })

    const { values: model, errors, handleSubmit, handleReset } = useForm({
      validationSchema
    })

    useField('name', null, {
      initialValue: ''
    })

    useField('code', null, {
      initialValue: ''
    })

    useField('state', null, {
      initialValue: true
    })

    const isLoading = ref(true)

    const viewPadre = 'pharmasan.administrativa.compras-administrativas.ajustes.categorias.subcategorias.formulario'

    /*
    * Computeds
    */
    const esEditar = computed(() => {
      if (route.params.id && route.name !== viewPadre) return true
      return false
    })
    const esPadre = computed(() => {
      if (route.name === viewPadre) return true
      return false
    })

    const toggleLoading = () => {
      isLoading.value = !isLoading.value
    }
    const onSubmit = handleSubmit((values) => {
      toggleLoading()
      if (esEditar.value) {
        const { id } = route.params
        updateCategory(id, model).then(() => {
          toggleLoading()
          messageSuccess('Categoria actualizada')
          router.back()
        }).catch(err => {
          toggleLoading()
          console.error(err.message)
        })
      } else {
        const payload = { ...model }
        if (esPadre.value) {
          const { id } = route.params
          payload.parent_category_id = id
        }
        createCategory(payload).then(() => {
          toggleLoading()
          success('Categoria creada')
          handleReset()
          router.back()
        }).catch((err) => {
          toggleLoading()
          console.error(err.message)
        })
      }
    })

    /*
    * Hooks
    */
    onMounted(() => {
      if (esEditar.value) {
        const { id } = route.params
        findCategory({ id }).then(({ status, data }) => {
          model.name = data.name
          model.state = data.state
          model.code = data.code
        }).catch((err) => {
          console.error(err.message)
        })
      }
    })

    return {
      model,
      errors,
      esEditar,
      isLoading,
      onSubmit,
      esPadre
    }
  }

}
</script>

<template>
  <div class="h-10">
    <h1 class="font-bold text-lg capitalize">Caja</h1>
  </div>

  <div class="box mb-4">
    <div class="flex pl-4 pt-4 gap-2">
      <font-awesome-icon icon="hand-holding-usd" style="width: 25px; height: 25px;color: #1C3FAA"/>
      <span class="text-base font-bold" style="color: #1C3FAA">Informacion caja</span>
    </div>
    <div class="grid grid-cols-5 gap-2 p-4">
      <span class="flex gap-2 items-center"><span class="font-bold">Saldo Caja:</span>{{ $h.formatCurrency(storeBalance,2) }}</span>
      <span class="flex gap-2 items-center"><span class="font-bold">Total Ingresos: </span>{{ $h.formatCurrency(storeTotalInc,2) }}</span>
      <span class="flex gap-2 items-center"><span class="font-bold">Total Pendientes + Préstamos: </span>{{ $h.formatCurrency(storePending,2) }}</span>
      <span class="flex gap-2 items-center"><span class="font-bold">Fecha: </span>
<!--        <Calendar id="basic" v-model="txtDate" autocomplete="off" dateFormat="yy-mm-dd" @date-select="onChangeDate" />-->
        <input type="date" v-model="txtDate" class="w-full p-inputtext" @change="onChangeDate" />
      </span>
      <div>
        <Button icon="pi pi-file-pdf"
                class="mr-2 p-button-info"
                @click="$router.push({ name: 'pharmasan.contabilidad.caja-menor.ver-caja-menor.informe-caja' })"
                v-tooltip.top="'Informes'"
        />
        <Button label="Cierre / Arqueo Caja"
                icon="pi pi-th-large"
                class="mr-2"
                @click="arqueoCaja"
        />
<!--        <Button label="Cierre Caja"-->
<!--                icon="pi pi-money-bill"-->
<!--                class="mr-2"-->
<!--                @click="balanceClosing"-->
<!--        />-->
      </div>
      <span class="flex gap-2 items-center"><span class="font-bold">Saldo Inicial: </span>{{ $h.formatCurrency(storeInitial,2) }}</span>
      <span class="flex gap-2 items-center"><span class="font-bold">Total Egresos: </span>{{ $h.formatCurrency(storeTotalExp,2) }}</span>
    </div>
  </div>
</template>
<script>
import { computed, ref, onMounted } from 'vue'
import dayjs from 'dayjs'
import iconCaja from '@/assets/images/icon_caja.svg'
import useIncome from '../hooks/useIncome'
import useExpense from '../hooks/useExpense'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { error } from '@/libs/mensajes'
import IncomeService from '@/apps/pharmasan/pettyCash/services/incomeServices'

export default {
  name: 'Home Summary',
  setup () {
    const router = useRouter()
    const _incomeService = new IncomeService()
    const { getIncomes } = useIncome()
    const { getExpenses } = useExpense()
    const today = dayjs().locale('es').format('YYYY-MM-DD')
    const txtDate = ref(today)
    const store = useStore()
    const storeTotalExp = computed(() => store.getters['storePettyCash/getTotalExp'])
    const storeTotalInc = computed(() => store.getters['storePettyCash/getTotalInc'])
    const storePending = computed(() => store.getters['storePettyCash/getPending'])
    const storeInitial = computed(() => store.getters['storePettyCash/getInitial'])
    const storeBalance = computed(() => store.getters['storePettyCash/getBalance'])
    const storeDate = computed(() => store.getters['storePettyCash/getStoreDate'])
    const txtDateComputed = computed(() => {
      return {
        date: dayjs(txtDate.value).format('YYYY-MM-DD')
      }
    })

    const onChangeDate = async (e) => {
      store.commit('storePettyCash/setDate', txtDateComputed.value.date)
      await getIncomes({ date: txtDateComputed.value.date })
      await getExpenses({ date: txtDateComputed.value.date })
      await store.dispatch('storePettyCash/gettingCashBalance', {
        date: txtDateComputed.value.date
      })
    }

    const balanceClosing = () => {
      console.log('balanceClosing')
    }

    const arqueoCaja = async () => {
      const validate = await _incomeService.incomeValidate()
      // if (!validate) return await router.push({ name: 'income-create' })
      if (validate.status && validate.date === dayjs(storeDate.value).format('YYYY-MM-DD')) {
        await router.push({ name: 'pharmasan.contabilidad.caja-menor.ver-caja-menor.arqueo-caja', params: { id: validate.id } })
      } else {
        error(`La caja del dia ${validate.date} no se ha cerrado`)
      }
    }

    onMounted(() => {
      getExpenses({ date: storeDate.value, query: { limit: 10, offset: 0 } })
      getIncomes({ date: storeDate.value, query: { limit: 10, offset: 0 } })
      store.dispatch('storePettyCash/gettingCashBalance', {
        date: storeDate.value
      })
    })

    return {
      iconCaja,
      txtDate,
      storeTotalExp,
      storeTotalInc,
      storePending,
      storeInitial,
      storeBalance,
      onChangeDate,
      balanceClosing,
      arqueoCaja
    }
  }
}
</script>

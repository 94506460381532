<template>
  <Toast />
  <Toolbar class="box mt-4 p-p-2">
    <template #start>
      <back-button />
    </template>
    <template #end>
      <Button v-if="$can('pharmasan.contabilidad.amortizaciones.configuracion.listado-cupos.access')" label="Agregar" icon="pi pi-plus" class="p-button-success p-button-sm" @click="openNew" />
    </template>
  </Toolbar>

  <div class="box mt-4 intro-x">
    <DataTable
      showGridlines
      ref="dt"
      :value="listQuotes"
      class="p-datatable-sm p-2 text-sm"
      dataKey="id"
      :paginator="true" :rows="10" :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5,10,25]"
      currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
          <h3 class="p-mb-2 p-m-md-0 p-as-md-center text-lg">Listado de Cupos</h3>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Buscar..." />
          </span>
        </div>
      </template>

      <!-- Columnas -->
      <Column field="bank.name" header="Banco" :sortable="true" style="min-width:16rem"></Column>
      <Column field="creditLine.name" header="Línea de Crédito" :sortable="true" style="min-width:12rem"></Column>
      <Column field="quota" header="Valor Cupo" :sortable="true" style="min-width:12rem; text-align:right;">
        <template #body="{ data }">
          {{ $h.formatCurrency(parseInt(data.quota)) }}
        </template>
      </Column>
      <Column v-if="$can('pharmasan.contabilidad.amortizaciones.configuracion.listado-cupos.access')" :exportable="false" header="Acciones" style="text-align: center">
        <template #body="{ data }">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="editQuote(data)" />
          <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteQuote(data)" />
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog v-model:visible="quoteDialog" :style="{width: '450px'}" :header="titleDialog" :modal="true" class="p-fluid">
    <div class="p-field">
      <label for="banco">Banco</label>
      <Dropdown
        id="banco"
        :options="banks"
        v-model="modelo.bankId"
        optionLabel="name"
        optionValue="id"
        placeholder="Seleccione una opción"
        :filter="true"
        scrollHeight="auto"
        filterPlaceholder="Buscar por nombre"
        :class="{ 'p-invalid': errors.bankId }"
      />
      <MessageError :text="errors.bankId" />
    </div>
    <div class="p-field">
      <label for="creditLines">Líneas de Crédito</label>
      <Dropdown
        id="creditLines"
        :options="creditLines"
        v-model="modelo.obligationId"
        optionLabel="name"
        optionValue="id"
        placeholder="Seleccione una opción"
        :filter="true"
        scrollHeight="auto"
        filterPlaceholder="Buscar por nombre"
        :class="{ 'p-invalid': errors.obligationId }"
      />
      <MessageError :text="errors.obligationId" />
    </div>
    <div class="p-field">
      <label for="quota">Valor del cupo ($):</label>
      <InputNumber
        id="quota"
        v-model="modelo.quota"
        mode="currency"
        currency="COP"
        locale="es-CO"
        minFractionDigits="0"
        maxFractionDigits="0"
        :class="{ 'p-invalid': errors.quota }"
      />
      <MessageError :text="errors.quota" />
    </div>

    <div class="p-field">
      <label for="status">Estado</label>
      <InputSwitch v-model="modelo.status" id="status" class="p-d-block"/>
    </div>

    <template #footer class="p-text-bold">
      <Button label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-sm" @click="hideDialog"/>
      <Button label="Guardar" icon="pi pi-check" class="p-button-success p-button-sm" @click="onSubmit" />
    </template>
  </Dialog>

  <Dialog v-model:visible="deleteQuoteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3 text-red-600" style="font-size: 2rem" />
      <span v-if="quote">¿Está seguro de eliminar el cupo del Banco <b>{{modelo.bank.name}}</b> y la línea de crédito <b>{{modelo.creditLine.name}}</b>?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text p-button-danger" @click="deleteQuoteDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text p-button-success" @click="removeQuote" />
    </template>
  </Dialog>

</template>

<script>
import { ref, onMounted } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import { useToast } from 'primevue/usetoast'
import listBanks from '../../../services/listBanks'
import { number, object, string } from 'yup'
import { useField, useForm } from 'vee-validate'
import { error } from '../../../../../../../libs/mensajes'
import listObligations from '../../../services/listCreditLines'
import listQuotesBank from '../../../services/listQuotesBank'
import updateQuoteBank from '../../../services/updateQuoteBank'
import createQuoteBank from '../../../services/createQuoteBank'
import deleteQuoteBank from '../../../services/deleteQuoteBank'
// import { useLoading } from 'vue3-loading-overlay'

export default {
  name: 'CRUD Cupos',
  setup () {
    const toast = useToast()
    const listQuotes = ref()
    const banks = ref()
    const creditLines = ref()
    const quoteDialog = ref(false)
    const titleDialog = ref('')
    const deleteQuoteDialog = ref(false)
    const deleteQuotesDialog = ref(false)
    const quote = ref({})
    const selectedQuotes = ref()
    const submitted = ref(false)
    // const loader = useLoading()
    const isEdit = ref(false)
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })

    const openNew = () => {
      submitted.value = false
      quoteDialog.value = true
      isEdit.value = false
      titleDialog.value = 'Agregar Cupo'
      handleReset()
    }

    const hideDialog = () => {
      quoteDialog.value = false
      submitted.value = false
    }

    const editQuote = (item) => {
      quote.value = { ...item }
      quoteDialog.value = true
      for (const [key, value] of Object.entries(item)) {
        modelo[key] = value
      }

      delete modelo.bank
      delete modelo.creditLine

      isEdit.value = true
      titleDialog.value = 'Editar Cupo'
    }

    const confirmDeleteQuote = (item) => {
      for (const [key, value] of Object.entries(item)) {
        modelo[key] = value
      }
      deleteQuoteDialog.value = true
    }

    const validationSchema = object({
      bankId: string().nullable().required().label('Banco'),
      obligationId: string().nullable().required().label('Línea de Crédito'),
      quota: number().typeError('El campo es requerido').positive().label('Valor del cupo')
    })

    // Create a form context with the validation schema
    const { errors, handleSubmit, values: modelo, handleReset } = useForm({
      validationSchema
    })

    // No need to define rules for fields
    useField('bankId', null, { initialValue: null })
    useField('obligationId', null, { initialValue: null })
    useField('quota', null, { initialValue: null })
    useField('status', null, { initialValue: true })
    useField('id', null, { initialValue: null })

    // Submit del envio de los datos
    const onSubmit = handleSubmit((values) => {
      // loader.show()

      if (isEdit.value) {
        updateQuoteBank(values.id, values).then((res) => {
          // loader.hide()
          toast.add({
            severity: 'success',
            summary: 'Exito',
            detail: 'Cupo del banco actualizado exitosamente',
            life: 3000
          })
          fetchQuotes()
        }).catch((err) => {
          // loader.hide()
          console.error(err.message)
          if (err.response.status) {
            error(err.response.data.message)
          }
        })
      } else {
        createQuoteBank(values).then((res) => {
          // loader.hide()
          toast.add({ severity: 'success', summary: 'Exito', detail: 'Cupo del banco creado exitosamente', life: 3000 })
          fetchQuotes()
        }).catch((err) => {
          // loader.hide()
          console.error(err.message)
          if (err.response.status) {
            error(err.response.data.message)
          }
        })
      }

      quoteDialog.value = false
      handleReset()
    })

    /* Obtener el listado de cupos */
    const fetchQuotes = () => {
      return listQuotesBank({}).then(({ status, data }) => {
        listQuotes.value = data.result
      }).catch(err => {
        console.error(err.message)
      })
    }

    /* Obtener el listado de bancos */
    const fetchBanks = () => {
      return listBanks().then(({ status, data }) => {
        banks.value = data.result
      }).catch(err => {
        console.error(err.message)
      })
    }

    /* Obtener el listado de lineas de credito */
    const fetchCreditLines = () => {
      return listObligations({}).then(({ status, data }) => {
        creditLines.value = data.result
      }).catch(err => {
        console.error(err.message)
      })
    }

    /* Eliminar cupo de banco */
    const removeQuote = () => {
      deleteQuoteBank(modelo.id).then(() => {
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Cupo del banco eliminado correctamente', life: 3000 })
        fetchQuotes()
        deleteQuoteDialog.value = false
      }).catch((err) => {
        console.error(err.message)
        error(err.response.data.message)
      })
    }

    onMounted(() => {
      fetchBanks()
      fetchCreditLines()
      fetchQuotes()
    })

    return {
      banks,
      listQuotes,
      quoteDialog,
      deleteQuoteDialog,
      deleteQuotesDialog,
      quote,
      selectedQuotes,
      filters,
      submitted,
      openNew,
      hideDialog,
      editQuote,
      confirmDeleteQuote,
      errors,
      modelo,
      onSubmit,
      creditLines,
      titleDialog,
      removeQuote
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>

import { useStore } from '../store'
import Swal from 'sweetalert2'
export default async (to, from, next) => {
  const store = useStore()
  const user = store.getters['auth/user']
  if (!user.admin) {
    const permissions = store.getters['auth/getPermissions']
    const { access } = to.meta
    if (!access) return next()

    if (access.length) {
      for (const permission of access) {
        if (permissions.some(a => a === permission)) {
          return next()
        }
      }
    }
    await Swal.fire({
      icon: 'warning',
      title: 'Permiso no autorizado',
      text: 'Usted sera redireccionado'
    }).then(() => {
      if (!user.id) {
        return next({ name: 'login' })
      } else {
        if (user.group.routeName) {
          return next({ name: user.group.routeName })
        } else {
          if (!user.group.routeName) return next({ name: 'error.401' })
          return next({ name: 'pharmasan.general' })
        }
      }
    })
  }

  next()
}

import LicitacionesService from '../../../services/LicitacionesService'
import LicIndicadoresService from '../../../services/LicIndicadoresService'
import LicIndicadoresValores from '../../../services/LicIndicadoresValores'
const _LicitacionesService = new LicitacionesService()
const _LicIndicadoresService = new LicIndicadoresService()
const _LicIndicadoresValores = new LicIndicadoresValores()
const state = () => {
  return {
    listLicitaciones: [],
    selectedLicitacion: null,
    listIndicadores: []
  }
}
const getters = {
  getLicitaciones: state => state.listLicitaciones,
  getSelectedLicitacion: state => state.selectedLicitacion,
  getListIndicadores: state => state.listIndicadores
}
const mutations = {
  setListLicitaciones (state, payload) {
    state.listLicitaciones = payload
  },
  setSelectedLicitacion (state, payload) {
    state.selectedLicitacion = payload
  },
  setListIndicadores (state, payload) {
    state.listIndicadores = payload
  }
}
const actions = {
  storeListLicitaciones ({ commit }) {
    _LicitacionesService.getAllLicitaciones().then(({ data }) => {
      commit('setListLicitaciones', data)
    })
  },
  storeListIndicadoresTypes ({ commit, state }) {
    _LicIndicadoresService.getIndicadoresTypes().then(({ data }) => {
      if (data.length > 0) {
        const payload = data.map(item => {
          return {
            nombre: item.nombre,
            code: item.code,
            licitacionId: state.selectedLicitacion,
            valor: 0,
            operation: '==='
          }
        })
        commit('setListIndicadores', payload)
      } else {
        commit('setListIndicadores', [])
      }
    })
  },
  storeIndicadoresValores ({ getters }) {
    const payload = getters.getListIndicadores.map(i => {
      return {
        licitacionId: getters.getSelectedLicitacion,
        code: i.code,
        valor: i.valor,
        operation: i.operation
      }
    })
    return _LicIndicadoresValores.store(payload).then(({ data }) => {
      if (data) {
        return true
      }
      return false
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

<template>
  <Toast />

  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">

        <div class="flex flex-col sm:flex-row justify-between items-center p-3 border-b border-gray-200 dark:border-dark-5" style="border-top-left-radius: 6px; border-top-right-radius: 6px;  border-top-color: #037ABC; border-top-width: 6px;">
          <h2 class="mr-auto text-lg font-bold">Reporte General</h2>
          <span class="inline-flex items-center justify-center px-2 py-1 text-sm font-bold leading-none text-dark-3 bg-gray-300 rounded">Total registros: {{ totalRecords }}</span>
        </div>

        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 lg:col-span-12">
            <DataTable
              :value="dataReportGeneral"
              :paginator="true"
              :rows="10"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              :rowsPerPageOptions="[10,20,50]"
              responsiveLayout="scroll"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              showGridlines
              ref="dt"
              class="p-datatable-sm p-2 text-sm"
              v-model:expandedRows="expandedRows"
              @rowExpand="onRowExpand"
              @rowCollapse="onRowCollapse"
            >
              <template #header>
                <FiltersGeneral v-model="filters" @search="onSearch" @downloadExcel="handleDownloadXls"/>
              </template>

              <!-- Columnas -->
              <Column :expander="true" headerStyle="min-width: 50px"  />
              <Column field="status" header="Estado" :sortable="false" style="min-width:8rem; text-align:center;">
                <template #body="{data}">
                  <Badge :value="data.status" class="p-mr-2 text-center" :severity="data.status === 'Pendiente' ? 'warning' : 'success'"></Badge>
                </template>
              </Column>
              <Column field="nameBank" header="Banco" :sortable="false" style="min-width:8rem; text-align:center;"></Column>
              <Column field="obligationType" header="Tipo de Obligación" :sortable="false" style="min-width:8rem; text-align:center;"></Column>
              <Column field="obligationNumber" header="# Obligacion" :sortable="false" style="min-width:8rem; text-align:center;"></Column>
              <Column field="installmentNumber" header="# Cuota" :sortable="false" style="min-width:10rem; text-align:center;"/>
              <Column field="totalQuotas" header="# Total cuotas" :sortable="false" style="min-width:10rem; text-align:center;"/>
              <Column field="paymentDate" header="Fecha Cuota" :sortable="false" style="min-width:7rem; text-align:center;"></Column>
              <Column field="debit" header="Valor Cuota" :sortable="false" style="min-width:8rem; text-align:right;">
                <template #body="{data}">
                  {{ $h.formatCurrency(parseInt(data.debit),2) }}
                </template>
              </Column>
              <Column field="createdAt" header="Fecha pago" :sortable="false" style="min-width:7rem; text-align:center;"></Column>
              <Column field="credit" header="Valor pago" :sortable="false" style="min-width:8rem; text-align:right;">
                <template #body="{data}">
                  {{ $h.formatCurrency(parseInt(data.credit),2) }}
                </template>
              </Column>
              <Column field="registerBy" header="Pagado por" :sortable="false" style="min-width:8rem; text-align:right;"/>
              <template #expansion="{data}">
                <div class="orders-subtable w-full">
                  <h5>detella de amortización {{data.obligationNumber}}</h5>
                  <DataTable :value="reportDetails" responsiveLayout="scroll" class="p-datatable-sm p-2 text-sm">
                    <Column field="acctname" header="Cuenta SAP"/>
                    <Column field="accountSap" header="N° Cuenta SAP"/>
                    <Column field="debit" header="Valor amortización" :sortable="false" style="min-width:8rem; text-align:right;">
                      <template #body="{data}">
                        {{ $h.formatCurrency(parseInt(data.debit),2) }}
                      </template>
                    </Column>
                    <Column field="credit" header="Valor cuota" :sortable="false" style="min-width:8rem; text-align:right;">
                      <template #body="{data}">
                        {{ $h.formatCurrency(parseInt(data.credit),2) }}
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </template>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FiltersGeneral from './filters'
// import { useLoading } from 'vue3-loading-overlay'
import { useToast } from 'primevue/usetoast'
import reportGeneral from '../../../services/reportGeneral'
import reporteGeneralDetails from '../../../services/reporteGeneralDetails'
import http from '../../../../../../../libs/http'
import { ref, onMounted } from 'vue'

export default {
  name: 'reportGeneral',
  components: {
    FiltersGeneral
  },
  setup () {
    const dt = ref()
    const toast = useToast()
    // const loader = useLoading()
    const totalRecords = ref(0)
    const dataReportGeneral = ref()
    const minPayment = ref([])
    const lazyParams = ref({})
    const filters = ref({
      bankId: { value: null, matchMode: 'contains' },
      obligationNumber: { value: '', matchMode: 'contains' },
      typeDate: { value: '', matchMode: 'contains' },
      status: { value: null, matchMode: 'contains' },
      dateStart: { value: '', matchMode: 'contains' },
      dateEnd: { value: '', matchMode: 'contains' }
    })
    const expandedRows = ref([])
    const reportDetails = ref([
      { id: '1' },
      { id: '2' }
    ])

    const onSearch = (event) => {
      lazyParams.value.filters = filters.value
      fetchReportGeneral(event, 'filter')
    }

    /* Obtener la lista de pagos pendientes */
    const fetchReportGeneral = (event = false, typeVar) => {
      let queryParams = {
        current_page: 1,
        per_page: 10,
        order: 'paymentDate,ASC'
      }
      // // loader.show()

      if (typeVar === 'filter') {
        queryParams = {
          ...queryParams,
          bankId: event.filters.bankId.value ? parseInt(event.filters.bankId.value) : null,
          obligationNumber: event.filters.obligationNumber.value ? event.filters.obligationNumber.value : null,
          typeDate: event.filters.typeDate.value ? event.filters.typeDate.value : null,
          status: event.filters.status.value ? parseInt(event.filters.status.value) : null,
          date: event.filters.dateStart.value && event.filters.dateEnd.value ? `${event.filters.dateStart.value ? event.filters.dateStart.value : ''},${event.filters.dateEnd.value ? event.filters.dateEnd.value : ''}` : null
        }
      }

      return reportGeneral(queryParams).then(({ status, data }) => {
        // loader.hide()
        if (status !== 200) {
          toast.add({ severity: 'error', summary: 'Error', detail: 'Problemas al listar los pagos pendientes, consulte con el administrador del sistema', life: 3000 })
        } else {
          dataReportGeneral.value = data.result.map((e, i) => { return { virtualId: i, ...e } }) ?? []
          totalRecords.value = dataReportGeneral.value.length
        }
      })
    }

    const handleDownloadXls = (event) => {
      const queryParams = {
        bankId: event.filters.bankId.value ? parseInt(event.filters.bankId.value) : null,
        obligationNumber: event.filters.obligationNumber.value ? event.filters.obligationNumber.value : null,
        typeDate: event.filters.typeDate.value ? event.filters.typeDate.value : null,
        status: event.filters.status.value ? parseInt(event.filters.status.value) : null,
        date: event.filters.dateStart.value && event.filters.dateEnd.value ? `${event.filters.dateStart.value ? event.filters.dateStart.value : ''},${event.filters.dateEnd.value ? event.filters.dateEnd.value : ''}` : null
      }
      const url = `${process.env.VUE_APP_MICROSERVICE_API_AMORTIZATIONS}/reports/general/download`
      const res = http.getUri({ url, params: queryParams })
      window.open(res, '_blank')
    }

    onMounted(() => {
      lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
        filters: filters.value
      }

      fetchReportGeneral()
    })

    const onRowExpand = ({ data }) => {
      getReporteDetails(data)
      expandedRows.value = [data]
    }
    const onRowCollapse = () => {
      expandedRows.value = []
    }

    const getReporteDetails = async ({ productId, key, status }) => {
      const queryParams = { productId, key, status: status === 'Pendiente' ? 0 : 1 }
      const result = await reporteGeneralDetails(queryParams)
      // console.log(res)
      reportDetails.value = result ?? []
    }

    return {
      dt,
      filters,
      onSearch,
      fetchReportGeneral,
      dataReportGeneral,
      minPayment,
      handleDownloadXls,
      expandedRows,
      onRowExpand,
      onRowCollapse,
      reportDetails,
      totalRecords
    }
  }
}
</script>

<style lang="scss">
.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.42rem;
}
.symbol .symbol-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #3F4254;
  background-color: #F3F6F9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.42rem;
}
.symbol .symbol-badge {
  position: absolute;
  border: 2px solid #ffffff;
  border-radius: 100%;
  top: 0;
  right: 0;
}
.symbol.symbol-circle {
  border-radius: 50%;
}
.symbol.symbol-circle > img {
  border-radius: 50%;
}
.symbol.symbol-circle .symbol-label {
  border-radius: 50%;
}
.symbol.symbol-light-primary .symbol-label {
  background-color: #E1F0FF;
  color: #3699FF;
}
.symbol.symbol-light-secondary .symbol-label {
  background-color: #EBEDF3;
  color: #E4E6EF;
}
.symbol.symbol-light-success .symbol-label {
  background-color: #C9F7F5;
  color: #1BC5BD;
}
.symbol.symbol-light-info .symbol-label {
  background-color: #EEE5FF;
  color: #8950FC;
}
.symbol.symbol-light-warning .symbol-label {
  background-color: #FFF4DE;
  color: #FFA800;
}
.symbol.symbol-light-danger .symbol-label {
  background-color: #FFE2E5;
  color: #F64E60;
}
.symbol.symbol-light-light .symbol-label {
  background-color: #F3F6F9;
  color: #F3F6F9;
}
.symbol.symbol-light-dark .symbol-label {
  background-color: #D1D3E0;
  color: #181C32;
}
.symbol.symbol-light-white .symbol-label {
  background-color: #ffffff;
  color: #ffffff;
}
.symbol.symbol-30 > img {
  width: 100%;
  max-width: 40px;
  height: 40px;
}
.symbol.symbol-30 .symbol-label {
  width: 40px;
  height: 40px;
}
.symbol.symbol-30 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}
.symbol.symbol-30 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-50 > img {
  width: 100%;
  max-width: 50px;
  height: 50px;
}
.symbol.symbol-50 .symbol-label {
  width: 50px;
  height: 50px;
}
.symbol.symbol-50 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}
.symbol.symbol-50 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}
.custom-style {
  .p-dialog-header {
    color: #5E8F32 !important;
  }
}

.custom-style {
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 0.75rem !important;
  }
  .p-fieldset-content {
    padding: 0 !important;
  }
}
</style>

<template>
  <section class="w-full">
    <HomeSummary />
    <HomeDetails />
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import HomeDetails from '../../components/homeDetails.vue'
import HomeSummary from '../../components/homeSummary.vue'
export default defineComponent({
  components: {
    HomeSummary,
    HomeDetails
  }
})
</script>

import http from '@/libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PETTY_CASH_NEWGASANDOIL

export default class IncomesService {
  getIncomes (payload = {}) {
    return http.get(`${baseUrl}/incomes`, {
      params: {
        ...payload
      },
      headers: {
        loading: true
      }
    })
  }

  getCashBalance (payload = {}) {
    return http.get(`${baseUrl}/incomes/cash-balance`, {
      params: {
        ...payload
      },
      headers: {
        loading: true
      }
    })
  }

  getTotalIncomes (payload = {}) {
    return http.get(`${baseUrl}/incomes/total-incomes`, {
      params: {
        ...payload
      },
      headers: {
        loading: true
      }
    })
  }

  saveIncome (payload = {}) {
    return http.post(`${baseUrl}/incomes`, payload, {
      headers: {
        loading: true
      }
    })
  }

  deleteIncome (id) {
    return http.delete(`${baseUrl}/incomes/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}

<template>
  <ul id="conten_menus"></ul>
</template>
<script>
import { defineComponent, onMounted, computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
export default defineComponent({
  emits: ['redirect'],
  setup (props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const formattedMenu = ref([])
    const drawMenu = () => {
      document.getElementById('conten_menus').innerHTML = ''
      const grop = map(groupBy(formattedMenu.value, 'nivel'))
      let i = 1
      for (const niveles of grop) {
        for (const menu of niveles) {
          const childs = formattedMenu.value.find(f => f.parent_menu_id === menu.id)
          const div = document.getElementById(i === 1 ? 'conten_menus' : `nivel_${menu.parent_menu_id}`)
          if (div) {
            const item = `<li>
              <a href="javascript:;"
              id="link_${menu.id}"
              data-title="${menu.title}"
              data-pageName="${childs ? '' : menu.pageName}"
              data-nivelId="nivel_${menu.id}"
              class="side-menu ${menu.pageName === (route.meta.activeMenu ? route.meta.activeMenu : route.name) ? 'side-menu--active' : ''}">
                <div class="side-menu__icon">
                  ${menu.icon ? `<i class="pi pi-${menu.icon}" ></i>` : ''}
                </div>
                <div class="side-menu__title">
                  <span>${menu.title.startsWith('link-') ? startCase(lowerCase(menu.title.substring(5))) : startCase(lowerCase(menu.title))}</span>
                  <div class="side-menu__sub-icon">
                    <i class="${childs ? 'pi pi-chevron-down' : ''}" id="icon_nivel_${menu.id}"></i>
                  </div>
                </div>
              </a>
              <ul class="v-enter-active v-enter-to ul-childs"
              id="nivel_${menu.id}"
              style="display: none;"> </ul>
            </li>`
            div.insertAdjacentHTML('beforeend', item)
          }
          tippy(`#link_${menu.id}`, {
            placement: 'right',
            content: `${startCase(lowerCase(menu.title))}`
          })
        }
        i++
      }
      // evento click menu
      const classname = document.getElementsByClassName('side-menu')
      for (let i = 0; i < classname.length; i++) {
        classname[i].addEventListener('click', eventClickMenu, false)
      }
      activeMenu()
    }
    const activeMenu = () => {
      // menu active
      let routeFind = {}
      if (route.meta.activeMenu) {
        routeFind = formattedMenu.value.find(f => f.pageName === route.meta.activeMenu)
      } else {
        routeFind = formattedMenu.value.find(f => f.pageName === route.name)
      }
      if (routeFind) {
        let menuId = routeFind.id
        for (let index = routeFind.nivel; index > 1; index--) {
          const menuFind = formattedMenu.value.find(f => f.id === menuId)
          menuId = menuFind.parent_menu_id
          const ele = document.getElementById(`nivel_${menuId}`)
          if (ele) ele.style.display = 'block'
          // document.getElementById(`nivel_${menuId}`).style.display = 'block'
          const elementLink = document.getElementById(`link_${menuId}`)
          if (elementLink) {
            elementLink.classList.remove('side-menu--active')
            elementLink.classList.add('side-menu--active')
          }
          const elementIcon = document.getElementById(`icon_nivel_${menuId}`)
          if (elementIcon) {
            elementIcon.classList.remove('pi-chevron-up')
            elementIcon.classList.remove('pi-chevron-down')
            elementIcon.classList.add('pi-chevron-up')
          }
        }
      }
    }
    const eventClickMenu = function () {
      // redirect
      const pageName = this.getAttribute('data-pageName')
      if (pageName) {
        const title = this.getAttribute('data-title')
        if (title.startsWith('link')) {
          window.open(pageName, '_blank')
          return
        }
        emit('redirect')
        router.push({ name: pageName })
        return
      }

      const dataNivelId = this.getAttribute('data-nivelId')
      const element = document.getElementById(dataNivelId).style
      const display = element.display === 'none' ? 'block' : 'none'
      element.display = display
      element.animation = display === 'none' ? 'fade-in 1s' : 'fade-out 1s'
      // icon desplegue
      const elementIcon = document.getElementById(`icon_${dataNivelId}`)
      elementIcon.classList.remove('pi-chevron-up')
      elementIcon.classList.remove('pi-chevron-down')
      elementIcon.classList.add(display === 'none' ? 'pi-chevron-down' : 'pi-chevron-up')
    }

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = store.state.sideMenu.menu
        drawMenu()
      }
    )

    onMounted(() => {
      formattedMenu.value = store.state.sideMenu.menu
      drawMenu()
    })

    return { }
  }
})
</script>

<style>
  .tippy-box[data-theme~='tomato'] > .tippy-svg-arrow > svg:first-child {
  fill: yellow;
}
</style>

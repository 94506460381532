import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import homeCentro from './views/centro-salud/home'
import homeBodega from './views/bodegas-pharmasan/home'
import securityAccess from '../../../middleware/securityAccess'

export default [
  {
    path: '/capital-state',
    component: Layouts,
    name: 'capital-state.presupuesto-obra',
    redirect: { name: 'capital-state.presupuesto-obra.centro-salud' },
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: 'presupuesto-obra/centro-salud',
        name: 'capital-state.presupuesto-obra.centro-salud',
        component: homeCentro,
        redirect: { name: 'capital-state.presupuesto-obra.centro-salud.dashboard' },
        children: [
          {
            path: 'dashboard',
            name: 'capital-state.presupuesto-obra.centro-salud.dashboard',
            component: () => import('./views/centro-salud/dashboard').then(m => m.default || m),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['capital-state.presupuesto-obra.centro-salud.dashboard']
            }
          },
          {
            path: 'supports',
            name: 'construction-budget.centro-salud.supports',
            component: () => import('./views/centro-salud/supports/home')
            /* beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['construction-budget.reports.access']
            } */
          },
          {
            path: 'reports',
            name: 'construction-budget.centro-salud.reports',
            component: () => import('./views/centro-salud/reports/home')
            /* beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['construction-budget.reports.access']
            } */
          }
        ]
      },
      {
        path: '/capital-state/construction-budget/bodegas-pharmasan',
        name: 'capital-state.presupuesto-obra.bodegas-pharmasan',
        component: homeBodega,
        redirect: { name: 'capital-state.presupuesto-obra.bodegas-phramasan.dashboard' },
        children: [
          {
            path: 'dashboard',
            name: 'capital-state.presupuesto-obra.bodegas-phramasan.dashboard',
            component: () => import('./views/bodegas-pharmasan/dashboard').then(m => m.default || m),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['capital-state.presupuesto-obra.bodegas-phramasan.dashboard']
            }
          },
          {
            path: 'supports',
            name: 'construction-budget.bodegas-pharmasan.supports',
            component: () => import('./views/bodegas-pharmasan/supports/home')
            /* beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['construction-budget.reports.access']
            } */
          },
          {
            path: 'reports',
            name: 'construction-budget.bodegas-pharmasan.reports',
            component: () => import('./views/bodegas-pharmasan/reports/home')
            /* beforeEnter: VueRouterMultiguard([securityAccess]),
            meta: {
              access: ['construction-budget.reports.access']
            } */
          }
        ]
      }
    ]
  }
]

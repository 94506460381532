<template>
  <small class="p-error">
    {{text}}
  </small>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    text: {
      type: String,
      default: () => ''
    }
  }
})
</script>

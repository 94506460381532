import Chart from './chart/Main.vue'
import Tippy from './tippy/Main.vue'
import TippyContent from './tippy-content/Main.vue'
import LoadingIcon from './loading-icon/Main.vue'
import TinySlider from './tiny-slider/Main.vue'
import * as featherIcons from '@zhuowenli/vue-feather-icons'
import Modal from './modal'
import Paginator from './paginator'
import BackButton from './back-button'
import Multiselect from '@vueform/multiselect'
import MessageError from './Messages/Error'
import InputCurrencys from './InputMaskCurrency'

export default app => {
  app.component('Chart', Chart)
  app.component('Tippy', Tippy)
  app.component('TippyContent', TippyContent)
  app.component('LoadingIcon', LoadingIcon)
  app.component('TinySlider', TinySlider)
  app.component('Modal', Modal)
  app.component('Paginator', Paginator)
  app.component('BackButton', BackButton)
  app.component('Multiselect', Multiselect)
  app.component('MessageError', MessageError)
  app.component('InputCurrency', InputCurrencys)

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = '24'
    app.component(key, icon)
  }
}

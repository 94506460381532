import {
  login,
  getServicios,
  getEmpaques,
  getCentrosCostos,
  getCiudades,
  getMensajeros,
  getClientesList
} from '../services/encargaTuDomi.service'

const state = () => {
  return {
    datosEnvio: {
      gestionEnvio: {
        escala: false,
        boxes: [],
        datosEnvio: [],
        datosLogistica: {
          codBodOrigen: '',
          nomBodOrigen: '',
          dirBodOrigen: '',
          codBodDestino: '',
          nomBodDestino: '',
          dirBodDestino: '',
          responsableBodDestino: '',
          Comments: ''
        }
      },
      infoLogistica: {
        tipoTransportista: null,
        destino: [],
        guia: {},
        origen: {}
      }
    },
    infoEncarga: {
      user: '',
      tokenEncarga: null
    },
    tiposServicio: [],
    empaques: [],
    centrosCostos: [],
    ciudades: [],
    mensajeros: [],
    tiposDocumentos: [
      { tipdoc: 1, name: 'Cédula de Ciudadania' },
      { tipdoc: 2, name: 'NIT' },
      { tipdoc: 3, name: 'Cédula de Extranjería' },
      { tipdoc: 4, name: 'Registro Civil' },
      { tipdoc: 5, name: 'Tarjeta de Identidad' },
      { tipdoc: 6, name: 'Identificación de extranjeros dif a NIT' },
      { tipdoc: 7, name: 'Pasaporte' }
    ],
    clientes: [],
    selected: []
  }
}
const getters = {
  getTokenEncarga: state => state.infoEncarga.tokenEncarga,
  getUserEncarga: state => state.infoEncarga.user,
  getTiposServicios: state => state.tiposServicio,
  getEmpaques: state => state.empaques,
  getCentrosCostos: state => state.centrosCostos,
  getCiudades: state => state.ciudades,
  getMensajeros: state => state.mensajeros,
  getTiposDocumentos: state => state.tiposDocumentos,
  getClientes: state => state.clientes,
  getDatosEnvio: state => state.datosEnvio,
  getGestionEnvio: state => state.datosEnvio.gestionEnvio,
  getInfoLogistica: state => state.datosEnvio.infoLogistica,
  getInfoSeleccionados: state => state.selected
}
const mutations = {
  setTokenEncarga (state, payload) {
    state.infoEncarga.tokenEncarga = payload
  },
  setUserEncarga (state, payload) {
    state.infoEncarga.user = payload
  },
  setTiposServicio (state, payload) {
    state.tiposServicio = payload
  },
  setEmpaques (state, payload) {
    state.empaques = payload
  },
  setCentrosCostos (state, payload) {
    state.centrosCostos = payload
  },
  setCiudades (state, payload) {
    state.ciudades = payload
  },
  setMensajeros (state, payload) {
    state.mensajeros = payload
  },
  setClientes (state, payload) {
    state.clientes = payload
  },
  setSeleccionados (state, payload) {
    state.selected = payload
  },
  setDatosEnvio (state, payload) {
    state.datosEnvio = payload
  },
  setGestionEnvio (state, payload) {
    state.datosEnvio.gestionEnvio = payload
  },
  setInfoLogistica (state, { tipoTransportista, destino, guia, origen }) {
    if (![undefined, null].includes(tipoTransportista)) {
      state.datosEnvio.infoLogistica.tipoTransportista = tipoTransportista
    }
    if (![undefined, null].includes(destino)) {
      state.datosEnvio.infoLogistica.destino = destino
    }
    if (![undefined, null].includes(guia)) {
      state.datosEnvio.infoLogistica.guia = guia
    }
    if (![undefined, null].includes(origen)) {
      state.datosEnvio.infoLogistica.origen = origen
    }
  }
}
const actions = {
  async loginIntoEncarga (state, payload) {
    return login(payload).then(({ data }) => {
      if (data.MyToken) {
        state.commit('setTokenEncarga', data.MyToken)
        state.commit('setUserEncarga', payload.user)
      }
    })
  },
  loadInfoEncarga (state) {
    const tokenEnc = state.getters.getTokenEncarga
    if (tokenEnc) {
      getServicios(tokenEnc).then(({ data }) => {
        if (data.state) state.commit('setTiposServicio', data.table)
      })
      getEmpaques(tokenEnc).then(({ data }) => {
        if (data.state) state.commit('setEmpaques', data.table)
      })
      getCiudades(tokenEnc).then(({ data }) => {
        if (data.state) state.commit('setCiudades', data.table)
      })
      getCentrosCostos(tokenEnc).then(({ data }) => {
        if (data.status) state.commit('setCentrosCostos', data.centros)
      })
      getMensajeros(tokenEnc).then(({ data }) => {
        if (data.status) state.commit('setMensajeros', data.mensajeros)
      })
      getClientesList(tokenEnc).then(({ data }) => {
        if (data.status) state.commit('setClientes', data.clientes)
      })
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

<template>
 <section>
   <div class="w-full box p-2 px-5 mt-4">
     <div
      class="nav nav-tabs flex-col sm:flex-row  lg:justify-start"
      style="overflow-x:auto;"
      role="tablist"
      >
        <!-- <router-link
        v-if="$can('admin.compras.facturacion.access')"
        :to="{name:'admin.compras.facturacion'}"
        active-class="active"
        data-toggle="tab"
        class="py-4 sm:mr-8 flex"
        role="tab"
        aria-selected="true"
        >
          <ArchiveIcon class="w-4 h-4 mr-2" />
          Facturación
        </router-link> -->

        <router-link
        v-if="$can('pharmasan.administrativa.compras-administrativas.ajustes')"
        :to="{name:'pharmasan.administrativa.compras-administrativas.ajustes'}"
        active-class="active"
        data-toggle="tab"
        class="py-4 sm:mr-8 flex"
        role="tab"
        aria-selected="true"
        >
          <SettingsIcon class="w-4 h-4 mr-2" />
          Ajustes
        </router-link>

        <router-link
        v-if="$can('pharmasan.administrativa.compras-administrativas.inventario')"
        :to="{name:'pharmasan.administrativa.compras-administrativas.inventario'}"
        active-class="active"
        id="activities-tab"
        data-toggle="tab"
        data-target="#activities"
        class="py-4 sm:mr-8 flex"
        role="tab"
        aria-selected="false"
        >
          <PackageIcon class="w-4 h-4 mr-2" />
          Inventario
        </router-link>

        <router-link
        v-if="$can('pharmasan.administrativa.compras-administrativas.solicitudes')"
        :to="{name:'pharmasan.administrativa.compras-administrativas.solicitudes'}"
        active-class="active"
        id="tasks-tab"
        data-toggle="tab"
        data-target="#tasks"
        class="py-4 sm:mr-8 flex"
        role="tab"
        aria-selected="false"
        >
          <FileMinusIcon class="w-4 h-4 mr-2" />
          Solicitudes
        </router-link>
        <router-link
        v-if="$can('pharmasan.administrativa.compras-administrativas.pedidos')"
        :to="{name:'pharmasan.administrativa.compras-administrativas.pedidos'}"
        active-class="active"
        id="activities-tab"
        data-toggle="tab"
        data-target="#activities"
        class="py-4 sm:mr-8 flex"
        role="tab"
        aria-selected="false"
        >
          <ShoppingBagIcon class="w-4 h-4 mr-2" />
          Pedidos
        </router-link>

        <!-- <router-link
        :to="{name:'admin.compras.ajustes'}"
        active-class="active"
        data-toggle="tab"
        class="py-4 sm:mr-8 flex"
        role="tab"
        aria-selected="true"
        >
          <SettingsIcon class="w-4 h-4 mr-2" />
          Aprobar solicitudes
        </router-link> -->

        <!-- <router-link
        :to="{name:'admin.compras.preciosProveedores'}"
        active-class="active"
        id="dashboard-tab"
        data-toggle="tab"
        data-target="#dashboard"
        class="py-4 sm:mr-8 flex"
        role="tab"
        aria-controls="dashboard"
        aria-selected="true"
        >
          <UsersIcon class="w-4 h-4 mr-2" />
          Consolidado
        </router-link> -->

        <router-link
        v-if="$can('pharmasan.administrativa.compras-administrativas.consolidados')"
        :to="{name:'pharmasan.administrativa.compras-administrativas.consolidados'}"
        active-class="active"
        id="activities-tab"
        data-toggle="tab"
        data-target="#activities"
        class="py-4 sm:mr-8 flex"
        role="tab"
        aria-selected="false"
        >
          <Minimize2Icon class="w-4 h-4 mr-2" />
          Consolidados
        </router-link>
        <!-- <router-link
        :to="{name:'admin.compras.consolidados'}"
        active-class="active"
        id="activities-tab"
        data-toggle="tab"
        data-target="#activities"
        class="py-4 sm:mr-8 flex"
        role="tab"
        aria-selected="false"
        >
          <TruckIcon class="w-4 h-4 mr-2" />
          Envios / Recepcion
        </router-link> -->

        <router-link
        v-if="$can('pharmasan.administrativa.compras-administrativas.proveedores')"
        :to="{name:'pharmasan.administrativa.compras-administrativas.proveedores'}"
        active-class="active"
        id="activities-tab"
        data-toggle="tab"
        data-target="#activities"
        class="py-4 sm:mr-8 flex"
        role="tab"
        aria-selected="false"
        >
          <BriefcaseIcon class="w-4 h-4 mr-2" />
          Proveedores
        </router-link>

        <!-- <router-link
        :to="{name: 'admin.compras.entregas'}"
        active-class="active"
        id="tasks-tab"
        data-toggle="tab"
        data-target="#tasks"
        class="py-4 sm:mr-8 flex"
        role="tab"
        aria-selected="false"
        >
          <TruckIcon class="w-4 h-4 mr-2" />
          Transacciones
        </router-link> -->
        <router-link
        v-if="$can('pharmasan.administrativa.compras-administrativas.informes')"
        :to="{name:'pharmasan.administrativa.compras-administrativas.informes'}"
        active-class="active"
        id="informes-tab"
        data-toggle="tab"
        data-target="#informes"
        class="py-4 sm:mr-8 flex"
        role="tab"
        aria-selected="false"
        >
          <FileTextIcon class="w-4 h-4 mr-2" />
          Informes
        </router-link>
      </div>
   </div>
   <router-view />
 </section>
</template>

<script>

export default {
  setup () {
    return { }
  }
}
</script>

import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '@/middleware/securityAccess'

import AjustesTema from './views/tema/Main'

export default [
  {
    path: '/ajustes',
    component: Layouts,
    name: 'ajustes',
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: 'tema',
        name: 'ajustes.tema',
        component: AjustesTema,
        meta: {
          access: [
            'ajustes.tema'
          ]
        }
      },
      {
        path: '',
        component: () => import('./views/usuarios/index.vue'),
        redirect: { name: 'ajustes.usuarios' },
        name: 'ajustes.usuario',
        children: [
          {
            path: 'usuarios',
            name: 'ajustes.usuarios',
            component: () => import('./views/usuarios/pages/usuarios/listar/TablaUsuarios.vue'),
            meta: {
              access: [
                'ajustes.usuarios'
              ]
            }
          },
          {
            path: 'grupos',
            name: 'ajustes.grupos',
            component: () => import('./views/usuarios/pages/grupos/listar/index.vue'),
            meta: {
              access: [
                'ajustes.grupos'
              ]
            }
          }
        ]
      },
      {
        path: 'usuarios/crear',
        name: 'ajustes.usuarios.crear',
        component: () => import('./views/usuarios/pages/usuarios/crear/index.vue'),
        redirect: { name: 'ajustes.usuarios.crear.informacion' },
        meta: {
          access: [
            'ajustes.usuarios.access'
          ],
          activeMenu: 'ajustes.usuarios'
        },
        children: [
          {
            path: 'informacion',
            name: 'ajustes.usuarios.crear.informacion',
            component: () => import('./views/usuarios/pages/usuarios/crear/pages/informacion/index.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            activeMenu: 'ajustes.usuarios'
          },
          {
            path: 'permisos',
            name: 'ajustes.usuarios.crear.permisos',
            component: () => import('./views/usuarios/pages/usuarios/crear/pages/permisos/index.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            activeMenu: 'ajustes.usuarios'
          }
        ]
      },
      {
        path: 'usuarios/editar/:id?',
        name: 'ajustes.usuarios.editar',
        component: () => import('./views/usuarios/pages/usuarios/crear/editar.vue'),
        redirect: { name: 'ajustes.usuarios.editar.informacion' },
        meta: {
          access: [
            'ajustes.usuarios.access'
          ],
          activeMenu: 'ajustes.usuarios'
        },
        children: [
          {
            path: 'informacion',
            name: 'ajustes.usuarios.editar.informacion',
            component: () => import('./views/usuarios/pages/usuarios/crear/pages/informacion/index.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            activeMenu: 'ajustes.usuarios'
          },
          {
            path: 'permisos',
            name: 'ajustes.usuarios.editar.permisos',
            component: () => import('./views/usuarios/pages/usuarios/crear/pages/permisos/index.vue'),
            beforeEnter: VueRouterMultiguard([securityAccess]),
            activeMenu: 'ajustes.usuarios'
          }
        ]
      },
      {
        path: 'ver/:id?',
        name: 'ajustes.usuarios.ver',
        component: () => import('./views/usuarios/pages/usuarios/ver'),
        meta: {
          access: [
            'ajustes.usuarios'
          ],
          activeMenu: 'ajustes.usuarios'
        }
      },
      {
        path: 'grupos/autorizaciones',
        name: 'ajustes.grupos.autorizaciones',
        component: () => import('./views/usuarios/pages/grupos/autorizaciones/index.vue'),
        meta: {
          access: [
            'ajustes.grupos.access'
          ],
          activeMenu: 'ajustes.usuarios'
        }
      },
      {
        path: 'permisos',
        name: 'ajustes.permisos',
        component: () => import('./views/permisos_nuevo/index.vue'),
        redirect: { name: 'ajustes.permisos.listado-permisos' },
        meta: {
          access: [
            'ajustes.permisos'
          ]
        },
        children: [
          {
            path: 'listado-permisos',
            name: 'ajustes.permisos.listado-permisos',
            component: () => import('./views/permisos_nuevo/pages/permisos.vue'),
            meta: {
              access: [
                'ajustes.permisos.listado-permisos'
              ],
              activeMenu: 'ajustes.permisos'
            }
          },
          {
            path: 'listado-menus',
            name: 'ajustes.permisos.listado-menus',
            component: () => import('./views/permisos_nuevo/pages/menus.vue'),
            meta: {
              access: [
                'ajustes.permisos.listado-menus'
              ],
              activeMenu: 'ajustes.permisos'
            }
          }
        ]
      }
    ]
  }
]

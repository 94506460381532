<template>
  <Toast />
  <div class="grid grid-cols-12 gap-6">
    <div class="intro-y col-span-12 lg:col-span-12">
      <DataTable
        showGridlines
        ref="dt"
        :lazy="true"
        :value="paymentsPending"
        v-model:expandedRows="expandedRows"
        class="p-datatable-sm p-2 text-sm"
        :paginator="false"
        :rows="10"
        :filters="filters"
        dataKey="amortizationId"
        responsiveLayout="scroll"
        @rowExpand="onRowExpand"
        @rowCollapse="onRowCollapse"
      >
        <template #header>
          <FiltersPayments v-model="filters" @search="onSearch"/>
        </template>
        <Column :expander="true" headerStyle="width: 3rem"/>
        <Column field="paymentDate" header="Fecha de pago"/>
        <Column field="obligationNumber" header="# obligación"/>
        <Column field="description" header="Descripción"/>
        <Column field="installmentNumber" header="# Cuota"/>
        <Column field="Quote" header="Valor a pagar">
          <template #body="{data}">
            {{ $h.formatCurrency(parseInt(data.Quote)) }}
          </template>
        </Column>
        <Column field="nameBank" header="Banco"/>
        <Column field="creditLine" header="Linea de credito"/>
        <Column :exportable="false" header="Acciones" style="text-align: center">
          <template #body="{data}">
            <router-link
              v-if="minPayment.includes(data.amortizationId)"
              :to="{
                name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.pagos.formulario',
                params: {
                  id: data.productId,
                  bankId: data.bankId,
                  obligationNumber: data.obligationNumber
                }
              }"
            >
              <Button v-if="$can('pharmasan.contabilidad.amortizaciones.pagos.access')" icon="pi pi-money-bill" class="p-button-rounded p-button-info p-mr-2" v-tooltip.top="'Registrar Pago'"/>
            </router-link>
            <Button v-else disabled icon="pi pi-money-bill" class="p-button-rounded p-button-info p-mr-2" v-tooltip.top="'Registrar Pago'"/>
          </template>
        </Column>
        <template #expansion="slotProps">
          <div class="orders-subtable">
            <h5>Detalle del pago para la obligación N° {{ slotProps.data.obligationNumber }} y cuota # {{ slotProps.data.installmentNumber }}</h5>
            <DataTable
              :value="slotProps.data.pendingDetails"
              showGridlines
              responsiveLayout="scroll"
              class="p-datatable-sm p-2 text-sm"
            >
              <Column field="accountSap" header="Cuenta" />
              <Column header="Nombre">
                <template #body="{data}">
                  {{ data.OACT.acctname }}
                </template>
              </Column>
              <Column field="debit" header="Valor" >
                <template #body="{data}">
                  {{ $h.formatCurrency(parseInt(data.debit)) }}
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
        <template #footer>
          <Paginator
            v-model:first="pagineitor['offset']"
            :rows="1"
            :totalRecords="pagineitor['totalItemsCount']"
            @page="onPage"
          >
          </Paginator>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue'
import FiltersPayments from './filters'
import listPaymentsPending from '../../../../services/listPaymentsPending'
// import { useLoading } from 'vue3-loading-overlay'
import { useToast } from 'primevue/usetoast'
// const loader = useLoading()
const groupBy = require('lodash/groupBy')
const head = require('lodash/head')
const mapLodash = require('lodash/map')

export default {
  name: 'listPaymentsPending',
  components: {
    FiltersPayments
  },
  setup () {
    const dt = ref()
    const toast = useToast()
    const paymentsPending = ref()
    const pendingDetails = ref([])
    const expandedRows = ref([])
    const minPayment = ref([])
    const lazyParams = ref({})
    const pagineitor = reactive({
      offset: 0,
      rows: 1,
      totalItemsCount: 82
    })
    // const date = new Date()
    const filters = ref({
      bankId: { value: null, matchMode: 'contains' },
      dateStart: { value: null, matchMode: 'contains' },
      dateEnd: { value: null, matchMode: 'contains' }
    })

    const onSort = (event) => {
      lazyParams.value = event
      fetchListPaymentPending(event, 'sort')
    }

    const onSearch = (event) => {
      lazyParams.value.filters = filters.value
      fetchListPaymentPending(event, 'filter')
    }

    /* Obtener la lista de pagos pendientes */
    const fetchListPaymentPending = (event = false, type, queryParams = { current_page: 1, per_page: 10, order: 'paymentDate,ASC' }) => {
      if (type === 'filter') {
        // Se agrega paymentdate
        if (!['', null, undefined].includes(event.filters.dateStart.value) && !['', null, undefined].includes(event.filters.dateEnd.value)) {
          queryParams.paymentDate = `${filters.value.dateStart.value},${filters.value.dateEnd.value}`
        }

        // Se agrega bankId
        if (!['', null, undefined].includes(event.filters.bankId.value)) {
          queryParams.bankId = event.filters.bankId.value ? parseInt(event.filters.bankId.value) : null
        }
      }
      return listPaymentsPending(queryParams).then(({ status, data }) => {
        // loader.hide()
        if (status !== 200) {
          toast.add({ severity: 'error', summary: 'Error', detail: 'Problemas al listar los pagos pendientes, consulte con el administrador del sistema', life: 3000 })
        } else {
          const temp = groupBy(data.result.data, 'obligationNumber')
          minPayment.value = mapLodash(temp, e => {
            return head(e).amortizationId
          })
          pagineitor.totalItemsCount = data.result.last_page
          pagineitor.offset = parseInt(data.result.current_page) - 1
          paymentsPending.value = data.result.data
        }
      }).catch((error) => {
        // loader.hide()
        console.log('Error: ', error)
      })
    }

    onMounted(() => {
      lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
        filters: filters.value
      }

      fetchListPaymentPending()
    })

    const onRowCollapse = () => {
      pendingDetails.value = []
    }
    const onRowExpand = (event) => {
      // console.log(event)
    }

    const onPage = ({ first, page }) => {
      const p = { current_page: (1 + page), per_page: 10, order: 'paymentDate,ASC' }
      fetchListPaymentPending(false, '', p)
    }

    return {
      dt,
      filters,
      onSearch,
      onSort,
      fetchListPaymentPending,
      paymentsPending,
      minPayment,
      expandedRows,
      pendingDetails,
      onRowCollapse,
      onRowExpand,
      pagineitor,
      onPage
    }
  }
}
</script>

<style scoped>

</style>

import http from '@/libs/http'
export default class LicIndicadoresService {
  getIndicadoresTypes () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/indicadores-types`)
  }

  getParametrosParticipacion () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/parametros-participacion`)
  }
}

import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
// import securityAccess from '@/middleware/securityAccess'
export default [
  {
    path: '/utpharmedicas',
    name: 'utpharmedicas',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    redirect: { name: 'ut-pharmedicas.entrada-mercancia' },
    children: [
      {
        path: 'entradas-mercancia',
        name: 'ut-pharmedicas.entrada-mercancia',
        component: () => import('@/apps/utpharmedicas/entradaMercancia/views/Home.vue'),
        meta: {
          access: ['ut-pharmedicas.entrada-mercancia']
        }
      },
      {
        path: 'entradas-mercancia/agregar',
        name: 'ut-pharmedicas.entrada-mercancia.access',
        component: () => import('@/apps/utpharmedicas/entradaMercancia/views/forms/create/index'),
        meta: {
          access: ['ut-pharmedicas.entrada-mercancia']
        }
      }
    ]
  }
]

import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'

export default [
  {
    path: '/common',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: 'departments',
        name: 'departments',
        component: () => import('./views/departments/Main').then(m => m || m)
      },
      {
        path: 'citys',
        name: 'citys',
        component: () => import('./views/citys').then(m => m || m)
      }
    ]
  }
]

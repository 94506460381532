import http from '@/libs/http'
import { createStore } from 'vuex'
import dayjs from 'dayjs'
import IncomesService from '../services/IncomesService'
import ExpensesService from '../services/ExpensesService'

const _IncomesService = new IncomesService()
const _ExpensesService = new ExpensesService()

const storeHeader = {
  state: {
    cashBalance: 0,
    totalIncomes: 0,
    initialBalance: 0,
    totalExpenses: 0,
    expensesDetail: 0,
    filterDate: dayjs().format('DD-MM-YYYY')
  },
  mutations: {
    setCashBalance (state, payload) {
      state.cashBalance = payload
    },
    setTotalIncomes (state, payload) {
      state.totalIncomes = payload
    },
    setInitialBalance (state, payload) {
      state.initialBalance = payload
    },
    setTotalExpenses (state, payload) {
      state.totalExpenses = payload
    },
    setFilterDate (state, payload) {
      state.filterDate = payload
    },
    setExpensesDetail (state, payload) {
      state.expensesDetail = payload
    }
  },
  actions: {
    gettingCashBalance ({ commit }, payload) {
      _IncomesService.getCashBalance(payload).then(({ data }) => {
        commit('setCashBalance', data.cashBalance)
      }).catch(error => {
        console.log(error)
      })
    },
    gettingTotalIncomes ({ commit }, payload) {
      _IncomesService.getTotalIncomes(payload).then(({ data }) => {
        commit('setTotalIncomes', data.totalIncomes)
      }).catch(error => {
        console.log(error)
      })
    },
    gettingInitialBalance ({ commit }, payload) {
      _IncomesService.getCashBalance(payload).then(({ data }) => {
        commit('setInitialBalance', data.cashBalance)
      }).catch(error => {
        console.log(error)
      })
    },
    gettingTotalExpenses ({ commit }, payload) {
      _ExpensesService.getTotalExpenses(payload).then(({ data }) => {
        commit('setTotalExpenses', data.totalExpenses)
      }).catch(error => {
        console.log(error)
      })
    },
    gettingFilterDate ({ commit }, payload) {
      commit('setFilterDate', payload)
    },
    gettingExpensesDetail ({ commit }, payload) {
      _ExpensesService.getTotalExpenses(payload).then(({ data }) => {
        commit('setExpensesDetail', data.totalExpenses)
      }).catch(error => {
        console.log(error)
      })
    },
    downloadReport ({ commit }, payload) {
      return new Promise((resolve) => {
        const url = `${process.env.VUE_APP_MICROSERVICE_API_PETTY_CASH_NEWGASANDOIL}/reports/general-report`
        const res = http.getUri({ url })
        resolve(res)
      })
    }
  },
  getters: {
    getCashBalance: state => state.cashBalance,
    getTotalIncomes: state => state.totalIncomes,
    getInitialBalance: state => state.initialBalance,
    getTotalExpenses: state => state.totalExpenses,
    getFilterDate: state => state.filterDate,
    getDetailExp: state => state.expensesDetail
  }
}
const store = createStore(storeHeader)

export default {
  namespaced: true,
  ...store
}

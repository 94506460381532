import Layouts from '../../../layouts/main-menu/Main.vue'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '../../../middleware/securityAccess'
export default [
  {
    path: '/pharmasan/ventas',
    component: Layouts,
    name: 'pharmasan.ventas.mipres',
    beforeEnter: VueRouterMultiguard([auth, securityAccess]),
    redirect: { name: 'pharmasan.ventas.mipres.token' },
    meta: {
      access: ['pharmasan.ventas.mipres']
    },
    children: [
      {
        path: 'mipres',
        name: 'pharmasan.ventas.mipres.listado',
        component: () => import('./views/mipres.vue').then(m => m || m),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: [
            'pharmasan.ventas.mipres.listado'
          ]
        }
      },
      {
        path: 'configurar-mipres',
        name: 'pharmasan.ventas.mipres.token',
        component: () => import('./views/configurar-mipres.vue').then(m => m || m),
        beforeEnter: VueRouterMultiguard([securityAccess]),
        meta: {
          access: [
            'pharmasan.ventas.mipres.token'
          ]
        }
      }
    ]
  }
]

<template>
  <section class="w-full">
    <Home />
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import Home from './home'
export default defineComponent({
  components: {
      Home
  },
  setup () {

  }
})
</script>

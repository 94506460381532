import { costFilters } from '../../apps/pharmasan/dashboard/components/cardCostos/service'

const state = () => {
  return {
    filters: {
      month_start: null,
      month_end: null,
      year: new Date().getFullYear(),
      customer: null,
      bodega: null,
      municUsers: null,
      modalidad: null,
      dptoBodega: null,
      dptoUser: null
    },
    optionsFilters: {
      Mes: [],
      Year: [],
      Cliente: [],
      Bodega: [],
      MunicUser: [],
      Contrato: [],
      DptoBodega: [],
      DptoUser: []
    },
    loadingSearch: false,
    loadingFilters: false
  }
}

// getters
const getters = {
  getFilters: state => state.filters,
  getOptionsFilters: state => state.optionsFilters,
  getLoadingSearch: state => state.loadingSearch,
  getLoadingFilters: state => state.loadingFilters
}

// actions
const actions = {
  getFiltersAction (context, payload) {
    console.log('Context', context)
    const { commit, getters } = context
    if (!payload) {
      commit('loadingSearchMutation', true)
    }
    commit('loadingFiltersMutation', true)

    return costFilters(getters.getFilters).then(async ({ status, data }) => {
      await commit('optionsFiltersMutation', data)
      await commit('loadingSearchMutation', false)
      await commit('loadingFiltersMutation', false)
      // await commit('setStartEndMonthsMutation')
    })
  },
  async getClearAction (context, payload) {
    const { commit, dispatch } = context
    await commit('filtersMutation', payload)
    await dispatch('getFiltersAction')
  }
}

// mutations
const mutations = {
  filtersMutation (state, payload) {
    state.filters = payload
  },
  optionsFiltersMutation (state, payload) {
    state.optionsFilters = payload
  },
  loadingSearchMutation (state, payload) {
    state.loadingSearch = payload
  },
  loadingFiltersMutation (state, payload) {
    state.loadingFilters = payload
  },
  setStartEndMonthsMutation (state) {
    state.filters.month_start = state.optionsFilters.Mes[0]
    state.filters.month_end = state.optionsFilters.Mes[state.optionsFilters.Mes.length - 1]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<template>
  <section class="intro-x">
    <div class="w-full mt-3 mb-4 ">
      <div class="font-bold text-lg my-5">Unidades</div>
      <div class="w-full flex justify-between">
        <div class="flex">
          <back-button />
          <router-link
          :to="{name:'pharmasan.administrativa.compras-administrativas.ajustes.unidades.formulario'}"
          class="btn btn-primary ml-2 "
          >
            Agregar
          </router-link>
        </div>
        <div>
          <Vistas-Tipo
          :route-list="{name: 'pharmasan.administrativa.compras-administrativas.ajustes.unidades.list'}"
          :route-grid="{name: 'pharmasan.administrativa.compras-administrativas.ajustes.unidades.grid'}"
          />
        </div>
      </div>
    </div>
    <div class="w-full">
      <DataTable :value="getUnits">
        <Column header="CODIGO" field="code" headerStyle="width:120px;" />
        <Column header="VALOR" field="name" />
        <Column header="HABILITADO" field="state" headerStyle="width:120px;">
          <template #body="{ data }">
            <span v-if="data.state">Si</span>
            <span v-else>No</span>
          </template>
        </Column>
        <Column header="ACCIONES" headerStyle="width:120px;">
          <template #body="{ data }">
            <router-link
            :to="{name: 'pharmasan.administrativa.compras-administrativas.ajustes.unidades.formulario',params: {id: data.id}}"
            class="btn btn-primary p-1"
            :title="`Editar ${data.name}`"
            >
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
              </svg>
            </router-link>
          </template>
        </Column>
      </DataTable>
    </div>
  </section>
</template>

<script>
import VistasTipo from '@/components/VistasTipo'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { ref, computed, onMounted } from 'vue'
import unitsGetAll from '../../../../../services/unitsGetAll'
export default {
  components: {
    VistasTipo,
    DataTable,
    Column
  },
  setup () {
    const units = ref([])
    const getUnits = computed(() => {
      return units.value
    })
    const fetchUnits = () => {
      return unitsGetAll().then(({ status, data }) => {
        units.value = data
      }).catch(err => {
        console.error(err.message)
      })
    }
    onMounted(fetchUnits)
    return {
      getUnits
    }
  }

}
</script>

import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '@/middleware/securityAccess'

export default [
  {
    path: '/pharmasan/inventario',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    meta: {
      access: [
        'pharmasan.inventario'
      ]
    },
    name: 'pharmasan.inventario',
    redirect: { name: 'pharmasan.inventario.operaciones-stock' },
    children: [
      {
        path: 'operaciones-stock',
        beforeEnter: VueRouterMultiguard([auth, securityAccess]),
        meta: {
          access: [
            'pharmasan.inventario.operaciones-stock'
          ]
        },
        name: 'pharmasan.inventario.operaciones-stock',
        redirect: { name: 'pharmasan.inventario.operaciones-stock.recepcion-tecnica' },
        children: [
          {
            path: 'recepcion-tecnica',
            name: 'pharmasan.inventario.operaciones-stock.recepcion-tecnica',
            component: () => import('../views/pages/controlEnvios/listar.page.vue'),
            beforeEnter: VueRouterMultiguard([auth, securityAccess]),
            meta: {
              access: [
                'pharmasan.inventario.operaciones-stock.recepcion-tecnica'
              ]
            }
          },
          {
            path: 'recepcion-tecnica/:EnvioId',
            component: () => import('../views/pages/recepcionTenicaSede/show.page.vue'),
            beforeEnter: VueRouterMultiguard([auth, securityAccess]),
            meta: {
              access: [
                'pharmasan.inventario.operaciones-stock.recepcion-tecnica.access'
              ]
            },
            name: 'pharmasan.inventario.operaciones-stock.recepcion-tecnica.enivio'
          },
          {
            path: 'registro-apartado',
            name: 'pharmasan.inventario.operaciones-stock.recepcion-tecnica.apartado',
            component: () => import('../views/pages/apartados/registro.page.vue'),
            beforeEnter: VueRouterMultiguard([auth, securityAccess]),
            meta: {
              access: [
                'pharmasan.inventario.operaciones-stock.recepcion-tecnica.access'
              ]
            }
          },
          {
            path: 'recepcion-tecnica-historial',
            name: 'pharmasan.inventario.operaciones-stock.recepcion-tecnica.historial',
            component: () => import('../views/pages/recepcionTenicaSede/historial.page.vue'),
            beforeEnter: VueRouterMultiguard([auth, securityAccess]),
            meta: {
              access: [
                'pharmasan.inventario.operaciones-stock.recepcion-tecnica.historial'
              ]
            }
          },
          {
            path: 'ajuste-inventario',
            name: 'pharmasan.inventario.operaciones-stock.ajuste-inventario',
            component: () => import('../views/pages/ajustesInventario'),
            beforeEnter: VueRouterMultiguard([auth, securityAccess]),
            meta: {
              access: [
                'pharmasan.inventario.operaciones-stock.ajuste-inventario'
              ]
            },
            redirect: { name: 'pharmasan.inventario.operaciones-stock.ajuste-inventario.entradas' },
            children: [
              {
                path: 'entradas',
                name: 'pharmasan.inventario.operaciones-stock.ajuste-inventario.entradas',
                component: () => import('../views/pages/ajustesInventario/entradas.vue'),
                beforeEnter: VueRouterMultiguard([auth, securityAccess]),
                meta: {
                  access: [
                    'pharmasan.inventario.operaciones-stock.ajuste-inventario.entradas'
                  ],
                  activeMenu: 'pharmasan.inventario.operaciones-stock.ajuste-inventario.entradas'
                }
              },
              {
                path: 'entradas/crear',
                name: 'pharmasan.inventario.operaciones-stock.ajuste-inventario.entradas.crear',
                component: () => import('../views/components/ajustesInventario/CrearEntradaComponent.vue'),
                beforeEnter: VueRouterMultiguard([auth, securityAccess]),
                meta: {
                  access: [
                    'pharmasan.inventario.operaciones-stock.ajuste-inventario.entradas.crear'
                  ],
                  activeMenu: 'pharmasan.inventario.operaciones-stock.ajuste-inventario.entradas'
                }
              },
              {
                path: 'ver/:Id',
                name: 'pharmasan.inventario.operaciones-stock.ajuste-inventario.ver',
                component: () => import('../views/pages/ajustesInventario/ver.vue'),
                beforeEnter: VueRouterMultiguard([auth, securityAccess]),
                meta: {
                  access: [
                    'pharmasan.inventario.operaciones-stock.ajuste-inventario'
                  ],
                  activeMenu: 'pharmasan.inventario.operaciones-stock.ajuste-inventario'
                }
              },
              {
                path: 'salidas',
                name: 'pharmasan.inventario.operaciones-stock.ajuste-inventario.salidas',
                component: () => import('../views/pages/ajustesInventario/salidas.vue'),
                beforeEnter: VueRouterMultiguard([auth, securityAccess]),
                meta: {
                  access: [
                    'pharmasan.inventario.operaciones-stock.ajuste-inventario.salidas'
                  ],
                  activeMenu: 'pharmasan.inventario.operaciones-stock.ajuste-inventario.salidas'
                }
              },
              {
                path: 'salidas/crear',
                name: 'pharmasan.inventario.operaciones-stock.ajuste-inventario.salidas.crear',
                component: () => import('../views/pages/ajustesInventario/salidas.crear.vue'),
                beforeEnter: VueRouterMultiguard([auth, securityAccess]),
                meta: {
                  access: [
                    'pharmasan.inventario.operaciones-stock.ajuste-inventario.salidas.crear'
                  ],
                  activeMenu: 'pharmasan.inventario.operaciones-stock.ajuste-inventario.salidas.crear'
                }
              }
            ]
          }
        ]
      }
    ]
  }
]

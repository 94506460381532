import dayjs from 'dayjs'
import http from '../../../../libs/http'

export default class incomeService {
  urlPettyCash = process.env.VUE_APP_MICROSERVICE_API_PETTY_CASH

  async getIncomeByDate (date, query = {}) {
    try {
      return await http.get(`${this.urlPettyCash}/income/list-by-date/${date}`, { params: { ...query } }).then(res => res.data)
    } catch (error) {
      console.error('Error getIncomeByDate ingreso', error)
      throw error
    }
  }

  async getIncomeByType (date, type) {
    try {
      return await http.get(`${this.urlPettyCash}/income/list-by-type/${date}/${type}`).then(res => res.data)
    } catch (error) {
      console.error('Error getIncomeByType ingreso', error)
      throw error
    }
  }

  async getIncomeByNumDoc (date, num) {
    try {
      return await http.get(`${this.urlPettyCash}/income/list-by-numdoc/${date}/${num}`).then(res => res.data)
    } catch (error) {
      console.error('Error getIncomeByNumDoc ingreso', error)
      throw error
    }
  }

  async getIncomeByTypeAndNumDoc (date, type, num) {
    try {
      return await http.get(`${this.urlPettyCash}/income/list-by-num-type/${date}/${num}/${type}`).then(res => res.data)
    } catch (error) {
      console.error('Error getIncomeByTypeAndNumDoc ingreso', error)
      throw error
    }
  }

  async createIncomeByCase (endpoint, data) {
    try {
      data.date = dayjs().locale('es').format('YYYY-MM-DD')
      return await http.post(this.urlPettyCash + endpoint, data).then(res => res.data)
    } catch (error) {
      console.error('Error createIncomeByCase ingreso', error)
      throw error
    }
  }

  async editIncomeByCase (endpoint, data) {
    try {
      data.userId = 1
      data.date = dayjs().locale('es').format('YYYY-MM-DD')
      return await http.put(this.urlPettyCash + endpoint, data).then(res => res.data)
    } catch (error) {
      console.error('Error editIncomeByCase ingreso', error)
      throw error
    }
  }

  async deleteIncomeById (endpoint) {
    try {
      return await http.delete(this.urlPettyCash + endpoint).then(res => res.data)
    } catch (error) {
      console.error('Error deleteIncomeById ingreso', error)
      throw error
    }
  }

  async incomeValidate () {
    return await http.get(this.urlPettyCash + '/balance-closing/validate').then(res => res.data)
  }
}

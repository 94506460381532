<template>
<!--  <div class="box mb-4">-->
<!--    <TabView>-->
<!--      <TabPanel>-->
<!--        <template #header>-->
<!--          <font-awesome-icon icon="arrow-alt-circle-down" style="width: 20px; height: 20px"/>-->
<!--          <span class="ml-2">Ingresos</span>-->
<!--        </template>-->
<!--        <tabIngresos/>-->
<!--      </TabPanel>-->
<!--      <TabPanel>-->
<!--        <template #header>-->
<!--          <font-awesome-icon icon="arrow-alt-circle-up" style="width: 20px; height: 20px"/>-->
<!--          <span class="ml-2">Egresos</span>-->
<!--        </template>-->
<!--        <tabEgresos/>-->
<!--      </TabPanel>-->
<!--    </TabView>-->
<!--  </div>-->
  <div class="box mb-4">
    <TabMenu :model="items" v-model:activeIndex="active"/>
    <div class="p-4">
      <router-view/>
    </div>
  </div>

<!--  <section class='w-full'>-->
<!--    <div class='w-full box p-4 mt-5'>-->
<!--      <TabGroup as='div' class='mt-2'>-->
<!--        <div class='border-b border-gray-200'>-->
<!--          <TabList class='-mb-px flex px-4 space-x-8'>-->
<!--            <Tab class="flex">-->
<!--              <img :src="iconIngreso" class="mr-2">-->
<!--              <span class="text-base font-medium text-primary-1 dark:text-white">Ingresos</span>-->
<!--            </Tab>-->
<!--            <Tab class="flex">-->
<!--              <img :src="iconEgreso" class="mr-2">-->
<!--              <span class="text-base font-medium text-primary-1 dark:text-white">Egresos</span>-->
<!--            </Tab>-->
<!--          </TabList>-->
<!--        </div>-->

<!--        <TabPanels as='template'>-->
<!--          <TabPanel class='pt-5'>-->
<!--            <IncomeIndex />-->
<!--          </TabPanel>-->

<!--          <TabPanel class='pt-5'>-->
<!--            <ExpenseIndex />-->
<!--          </TabPanel>-->
<!--        </TabPanels>-->
<!--      </TabGroup>-->
<!--    </div>-->
<!--  </section>-->

</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue'
// import useExpense from '../hooks/useExpense'
import { useStore } from 'vuex'
// import tabIngresos from './homeTabs/tabIngresos/main'
// import tabEgresos from './homeTabs/tabEgresos/main'
// import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
// import IncomeIndex from '../views/incomes/main.vue'
// import ExpenseIndex from '../views/expenses/main.vue'
// import iconEgreso from '@/assets/images/icon_egreso.svg'
// import iconIngreso from '@/assets/images/icon_ingreso.svg'

export default defineComponent({
  components: {
    // Tab,
    // TabGroup,
    // TabList,
    // TabPanel,
    // TabPanels,
    // IncomeIndex,
    // ExpenseIndex,
    // tabIngresos,
    // tabEgresos
  },
  setup () {
    const store = useStore()
    const storeExpenses = computed(() => store.getters['storePettyCash/getExpenses'])
    const active = ref(1)
    const items = ref([
      {
        label: 'Ingresos',
        icon: 'pi pi-fw pi-home',
        to: '/caja-menor/ingresos',
        class: ''
      },
      {
        label: 'Egresos',
        icon: 'pi pi-fw pi-calendar',
        to: '/caja-menor/egresos',
        class: ''
      }
    ])
    // const { getExpenses } = useExpense()
    // const getExpenseSearch = async (filter) => {
    //   try {
    //     filter.date = storeDate.value
    //     await getExpenses(filter)
    //   } catch (error) {
    //     //  errorHtml(error)
    //     console.error('Error al buscar egreso', error)
    //   }
    // }
    // const storeDate = computed(() => store.getters['storePettyCash/getStoreDate'])

    onMounted(async () => {
    })

    return {
      items,
      active,
      storeExpenses
      // iconIngreso,
      // iconEgreso
    }
  }
})
</script>

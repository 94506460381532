<template>
  <div>
    <section>
      <div class="box p-2 mt-2">
        <div
          class="boxed-tabs nav nav-tabs text-xs justify-center w-full bg-gray-200 text-gray-600 dark:bg-dark-1 dark:text-gray-500 rounded-md mx-auto flex-col sm:flex-row"
          role="tablist"
        >
          <router-link
            id="pagos-tab"
            :to="{ name: 'pharmasan.contabilidad.amortizaciones.dashboard' }"
            :class="getActive([
              'pharmasan.contabilidad.amortizaciones.dashboard',
              'pharmasan.contabilidad.amortizaciones.dashboard.lineas-credito',
              'pharmasan.contabilidad.amortizaciones.dashboard.cupos-bancarios',
              'pharmasan.contabilidad.amortizaciones.dashboard.presupuestos'
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <TabletIcon class="w-4 h-4 mr-2" />
            Dashboard
          </router-link>
          <router-link
            id="pagos-tab"
            :to="{ name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.pagos' }"
            :class="getActive([
              'pharmasan.contabilidad.amortizaciones.productos-financieros.pagos',
              'pharmasan.contabilidad.amortizaciones.productos-financieros.pagos.formulario'
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <ShoppingBagIcon class="w-4 h-4 mr-2" />
            Pagos
          </router-link>
          <router-link
            id="pagos-tab"
            :to="{ name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.compras' }"
            :class="getActive([
              'pharmasan.contabilidad.amortizaciones.productos-financieros.compras',
              'pharmasan.contabilidad.amortizaciones.productos-financieros.compras.formulario'
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <ShoppingBagIcon class="w-4 h-4 mr-2" />
            Compras
          </router-link>
          <router-link
            id="productos-tab"
            :to="{ name: 'pharmasan.contabilidad.amortizaciones.productos-financieros' }"
            :class="getActive([
              'pharmasan.contabilidad.amortizaciones.productos-financieros',
              'pharmasan.contabilidad.amortizaciones.productos-financieros.formulario',
              'pharmasan.contabilidad.amortizaciones.productos-financieros.tabla',
              'pharmasan.contabilidad.amortizaciones.productos-financieros.tabla.cargar',
              'pharmasan.contabilidad.amortizaciones.productos-financieros.tabla.generar',
              'pharmasan.contabilidad.amortizaciones.productos-financieros.pagos.made'
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <BoxIcon class="w-4 h-4 mr-2" />
            Prod. Financieros
          </router-link>
          <router-link
            id="reportes-tab"
            :to="{ name: 'pharmasan.contabilidad.amortizaciones.reportes' }"
            :class="getActive([
              'pharmasan.contabilidad.amortizaciones.reportes',
              'pharmasan.contabilidad.amortizaciones.reportes.general',
              'pharmasan.contabilidad.amortizaciones.reportes.pagos-obligaciones',
              'pharmasan.contabilidad.amortizaciones.reportes.gastos-financieros',
              'pharmasan.contabilidad.amortizaciones.reportes.estados-creditos'
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <BarChartIcon class="w-4 h-4 mr-2" />
            Reportes
          </router-link>
          <router-link
            id="configuracion-tab"
            :to="{ name: 'pharmasan.contabilidad.amortizaciones.configuracion' }"
            :class="getActive([
              'pharmasan.contabilidad.amortizaciones.configuracion',
              'pharmasan.contabilidad.amortizaciones.configuracion.bancos',
              'pharmasan.contabilidad.amortizaciones.configuracion.lineas-credito',
              'pharmasan.contabilidad.amortizaciones.configuracion.notificaciones',
              'pharmasan.contabilidad.amortizaciones.configuracion.otros-permisos'
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <SettingsIcon class="w-4 h-4 mr-2" />
            Configuración
          </router-link>
        </div>
      </div>
    </section>
    <router-view />
  </div>
</template>

<script>
import { useRoute } from 'vue-router'

export default {
  name: 'amortizationsHome',
  setup () {
    const route = useRoute()

    const getActive = (routeNames = []) => {
      if (routeNames.includes(route.name)) {
        return 'active'
      }
      return ''
    }

    return {
      getActive
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <AutoComplete
  v-model="model"
  field="acctname"
  inputClass="form-control"
  :suggestions="values"
  :dropdown="false"
  placeholder="Ingrese 3 o más caracteres para buscar..."
  :class="classes"
  @item-select="onChange"
  @complete="onComplete($event)"
  :disabled="disabled"
  forceSelection
  >
    <template #item="{ item }">
      <div>
        <span>{{item.acctcode}}</span> - <span>{{item.acctname}}</span>
      </div>
    </template>
  </AutoComplete>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import findAccountsSap from './service'
import AutoComplete from 'primevue/autocomplete'
export default defineComponent({
  name: 'SelectCuentasSap',
  props: {
    modelValue: {
      type: [String, Number],
      default: null
    },
    classes: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AutoComplete
  },
  setup (props, context) {
    const values = ref([])
    const keyModel = ref(0)
    const model = ref({
      acctcode: '',
      acctname: ''
    })
    const onComplete = ({ query }) => {
      if (!query.trim() || query.length < 3) {
        values.value = []
      } else {
        return findAccountsSap({ search: query.trim() }).then(({ data }) => {
          values.value = data.result
        }).catch(err => {
          console.error(err.message)
        })
      }
    }

    /* */
    const onChange = (event) => {
      context.emit('changeValues', event.value)
    }

    const setCode = (objectAccount) => {
      values.value = objectAccount
      model.value = objectAccount[0]
      keyModel.value = objectAccount[0]._key
    }

    onMounted(() => {
      if (props.modelValue) {
        findAccountsSap({ search: props.modelValue }).then(({ data }) => {
          values.value = data.result
          model.value = data.result[0]
          context.emit('update:modelValue', props.modelValue)
        }).catch(err => {
          console.error(err.message)
        })
      }
    })
    return {
      model,
      values,
      onComplete,
      onChange,
      setCode,
      keyModel
    }
  }
})
</script>

import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import Index from './views/Main.vue'
import securityAccess from '@/middleware/securityAccess'
const Incomes = () => import('./components/home/homeTabs/tabIngresos/main')
const Expenses = () => import('./components/home/homeTabs/tabEgresos/main')
const ExpensesFull = () => import('./components/home/homeTabs/egresosFull/main')

export default [
  {
    path: '/pettycash-newgas',
    component: Layouts,
    redirect: { name: 'new-gas-and-oil.caja-menor' },
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: '',
        component: Index,
        name: 'new-gas-and-oil.caja-menor',
        redirect: { name: 'new-gas-and-oil.caja-menor.ver-caja-menor' },
        children: [
          {
            path: 'incomes',
            component: Incomes,
            name: 'new-gas-and-oil.caja-menor.ver-caja-menor',
            beforeEnter: [securityAccess],
            meta: {
              access: ['new-gas-and-oil.caja-menor.ver-caja-menor']
            }
          },
          {
            path: 'expenses/:id',
            component: Expenses,
            name: 'pettycash-newgas.expenses',
            beforeEnter: [securityAccess],
            meta: {
              access: ['new-gas-and-oil.caja-menor.ver-caja-menor']
            }
          },
          {
            path: 'expenses',
            component: ExpensesFull,
            name: 'pettycash-newgas.expensesFull',
            beforeEnter: [securityAccess],
            meta: {
              access: ['new-gas-and-oil.caja-menor.ver-caja-menor']
            }
          }
        ]
      }
      // {
      //   path: 'income/edit/:id',
      //   component: IncomeCreate,
      //   name: 'income-edit'
      // },
      // {
      //   path: 'expenses/create',
      //   component: ExpensesCreate,
      //   name: 'expenses-create'
      // },
      // {
      //   path: 'expenses/edit/:id',
      //   component: ExpensesCreate,
      //   name: 'expenses-edit'
      // }
    ]
  }
]

<template>
  <section class="w-full">
    <div
    class="flex bg-blue my-5"
    >
      <div class="bg-blue-20">
        <div style="height: 96px;"></div>
        <div
        v-for="(per,k) in getPermissions"
        :key="k"
        class="bg-blue-300 flex flex-col items-center border-t border-b pl-2 capitalize rounded-l-lg shadow-md intro-y"
        style="width:170px;"
        >
            <div
            class="flex items-center w-full cursor-pointer"
            style="height:130px;"
            @click="per.visible = !per.visible"
            >
              <div class="p-2 w-full flex items-center text-blue-900 font-bold">
                <div style="width: 24px;">
                  <svg v-if="!per.visible" style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                  </svg>
                  <svg v-else style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
                  </svg>
                </div>
                <div style="position: relative; top: 1px;">{{per.name}}</div>
              </div>
            </div>
            <div
            v-for="(row,m) in per.hijos"
            :key="m"
            class="w-full flex flex-col pl-2 bg-blue-200 border-t border-b rounded-l-lg h-full"
            >
              <template v-if="per.visible">
                <div
                class="flex items-center w-full cursor-pointer"
                style="height:130px;"
                @click="row.visible = !row.visible"
                >
                  <div class="p-2 flex items-center font-bold text-blue-900">
                    <div style="width: 24px;">
                      <svg v-if="!row.visible" style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                      </svg>
                      <svg v-else style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
                      </svg>
                    </div>
                    <div>{{row.name}}</div>
                  </div>
                </div>
                <div
                v-for="(roww,o) in row.hijos"
                :key="o"
                class="bg-blue-100 flex flex-col pl-2 border-t border-b rounded-l-lg h-full"
                >
                  <template v-if="row.visible">
                    <div
                    v-if="row.visible"
                    class="flex items-center cursor-pointer"
                    style="height:130px;"
                    @click="roww.visible = !roww.visible"
                    >
                      <div class="p-2 flex items-center font-bold text-blue-900">
                        <div style="width: 24px;">
                          <svg v-if="!roww.visible" style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                          </svg>
                          <svg v-else style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
                          </svg>
                        </div>
                        <div>{{roww.name}}</div>
                      </div>
                    </div>
                    <div
                    v-for="(rowww,p) in roww.hijos"
                    :key="p"
                    class="pl-2 w-full flex bg-blue-50 border-t border-b rounded-l-lg"
                    >
                      <div
                      v-if="roww.visible"
                      class="flex items-center"
                      style="height:130px;"
                      >
                        <div class="p-2">{{rowww.name}}</div>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
        </div>
      </div>
      <div
      style="width: calc(100% - 150px); overflow-x: scroll;"
      >
        <div class="flex">
          <div
          v-for="(grupo,p) in getGroups"
          :key="p"
          class="w-full flex  items-center justify-center bg-red intro-y"
          style="height:45x; width:202px;"
          >
            <div
            class="text-center capitalize bg-red"
            style="width: 202px;"
            >
              <div class="box py-5 my-2 mx-1 shadow-md">
                <div class=""> {{grupo.name}} </div>
                <div class="text-sm">Grupo</div>
              </div>
            </div>
          </div>
        </div>
        <!-- aqui los grupos -->
        <div
        v-for="(row,m) in getPermissions"
        :key="m"
        class="flex flex-col border-t border-b intro-y"
        >
          <!-- paso 1 -> {{row.name}} -->
          <div class="flex">
            <div
            v-for="(gr,n) in getGroups"
            :key="`${row.id}-${gr.id}-${n}`"
            class="flex bg-blue-300 border-r border-gray-50  items-center justify-center h-full"
            >
              <div
              class="form-check text-center w-full flex justify-center"
              style="width:200px; height:130px;"
              >
                <!-- <input
                :checked="estadoRef"
                type="checkbox"
                class="show-code form-check-switch"
                @change="handleCheckChange(row.action,gr.id,$event)"
                > -->
              </div>
            </div>
          </div>
          <div
          v-for="(roww,n) in row.hijos"
          :key="n"
          class="flex flex-col border-t border-b intro-y"
          >
            <!-- paso 2 -> {{roww.name}} -->
            <template v-if="row.visible">
              <div
              class="flex"
              >
                <div
                v-for="(gr,n) in getGroups"
                :key="`${row.id}-${gr.id}-${n}`"
                class="flex bg-blue-200 border-r border-gray-50 items-center justify-center"
                >
                  <div
                  class="form-check text-center w-full flex justify-center  "
                  style="width:200px; height:130px;"
                  >
                    <input
                    :checked="estadoRef"
                    type="checkbox"
                    class="show-code form-check-switch bg-white"
                    @change="handleCheckChange(row.action,gr.id,$event)"
                    >
                  </div>
                </div>
              </div>
              <div
              v-for="(rowww,o) in roww.hijos"
              :key="o"
              class="flex flex-col border-t border-b"
              >
                <template v-if="roww.visible">
                  <!-- paso 3 -> {{rowww.name}} -->
                  <div class="flex">
                    <div
                    v-for="(gr,n) in getGroups"
                    :key="`${row.id}-${gr.id}-${n}`"
                    class="flex bg-blue-100 border-r border-gray-50 items-center justify-center"
                    >
                      <div
                      class="form-check text-center w-full flex justify-center"
                      style="width:200px; height:130px;"
                      >
                        <input
                        :checked="estadoRef"
                        type="checkbox"
                        class="show-code form-check-switch"
                        @change="handleCheckChange(row.action,gr.id,$event)"
                        >
                      </div>
                    </div>
                  </div>
                  <div
                  v-for="(rowwww,p) in rowww.hijos"
                  :key="p"
                  class="flex flex-col border-t border-b"
                  >
                    <template v-if="rowww.visible">
                      <!-- paso 4 -> {{rowwww.name}} -->
                      <div class="flex">
                        <div
                        v-for="(gr,n) in getGroups"
                        :key="`${row.id}-${gr.id}-${n}`"
                        class="flex bg-blue-50 border-r border-gray-50 items-center justify-center"
                        >
                          <div
                          class="form-check text-center w-full flex justify-center"
                          style="width:200px; height:130px;"
                          >
                            <input
                            :checked="estadoRef"
                            type="checkbox"
                            class="show-code form-check-switch"
                            @change="handleCheckChange(row.action,gr.id,$event)"
                            >
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </div>

            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, onMounted, reactive } from 'vue'
import PermissionsList from '../services/list'
import { getGroup as groupsList } from '../../groups/services'
import updatePermission from '../services/updatePermission'
export default {
  setup () {
    /*
    * Reactives
    */
    const permissions = reactive([
       {
        name: 'Ventas',
        hijos: [
          {
            name: 'modulo',
            hijos: [
              {
                name: 'control de ov',
                hijos: [
                  {
                    name: 'visualizar control de ov para farmacia'
                  },
                  {
                    name: 'agregar control ov '
                  },
                  {
                    name: 'retomar control de ov'
                  },
                  {
                    name: 'ver control ov'
                  }
                ]
              },
              {
                name: 'control de soporte sedes',
                hijos: [
                  {
                    name: 'Aprobar detalles de soporte'
                  },
                  {
                    name: 'Visualizar todos los soportes'
                  },
                  {
                    name: 'Ver todos los soportes de la sede'
                  },
                  {
                    name: 'Ver reporte de farmacia'
                  },
                  {
                    name: 'eliminar detalles'
                  }
                ]
              },
              {
                name: 'Generacion de RIPS',
                hijos: []
              }
            ]
          }
        ]
      },
      {
        name: 'clientes',
        hijos: [
          {
            name: 'Facturas',
            hijos: [
              {
                name: 'listado facturas',
                hijos: [
                  {
                    name: 'ver detalle '
                  },
                  {
                    name: 'eliminar detalle '
                  },
                  {
                    name: 'agregar detalle '
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        name: 'Ventas',
        hijos: [
          {
            name: 'Facturas',
            hijos: [
              {
                name: 'listado facturas',
                hijos: [
                  {
                    name: 'ver detalle '
                  },
                  {
                    name: 'eliminar detalle '
                  },
                  {
                    name: 'agregar detalle '
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        name: 'gestion'
      },
      {
        name: 'SIAU'
      },
      {
        name: 'ADMINISTRATIVO'
      },
      {
        name: 'Reportes',
        hijos: [
          {
            name: 'usuarios',
            hijos: [
              {
                name: 'Ver y generar reportes',
                hijos: [
                  {
                    name: 'categorias'
                  },
                  {
                    name: 'categorias'
                  },
                  {
                    name: 'Mostrat ganancias en todos los informes'
                  },
                  {
                    name: 'mostrar precio de costo en todos los informes'
                  }
                ]
              }
            ]
          }
        ]
      }
    ])
    const groups = reactive([])
    const estadoRef = reactive(true)

    /*
    * Computeds
    */
    const getPermissions = computed(() => {
      return permissions
    })
    const getGroups = computed(() => {
      return groups
    })

    /*
    * Hooks
    */
    onMounted(() => {
      Promise.all([fetchGroups(), fetchPermissions()]).then((results) => {
        console.log(results)
      }).catch(err => {
        console.error(err.message)
      })
    })

    /*
    * Methods
    */
    const fetchPermissions = () => {
      return PermissionsList().then(({ status, data }) => {
        if (status !== 200) return false
        // for (const [key, value] of Object.entries(data)) {
        //   permissions[key] = value
        // }
      })
    }
    const fetchGroups = () => {
      groupsList({ paginate: 2000 }).then(({ status, data }) => {
        if (status !== 200) return false
        for (const [key, value] of Object.entries(data)) {
          groups[key] = value
        }
      }).catch(err => {
        console.error(err.message)
      })
    }
    const handleCheckChange = (action, groupId, evt) => {
      console.log({ action, groupId }, evt.target.checked)
      const state = evt.target.checked
      updatePermission(groupId, { state, action }).then(({ status, data }) => {
        console.log(222, { status, data })
      }).catch(err => {
        console.error(err.menssage)
      })
    }
    return {
      handleCheckChange,
      estadoRef,
      getGroups,
      getPermissions
    }
  }
}
</script>

<style lang="scss" scoped>
.per-container{
  width: 170px;
}
.groups-container{
  overflow-x: scroll;
}

.groups-col{
  min-width: 170px;
  width: 170px;
  height: 150px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: yellow;
}
</style>

<template>
  <section>
    <div class="box w-full xl:w-1/3 xxl:w-1/4 p-4 intro-y my-3">
      <Form
      :validation-schema="schema"
      @submit="handleSubmit"
      >
        <div class="font-bold text-lg mb-2">
          Formulario de permisos
        </div>
        <div class="mb-4 w-full ">
          <label
          for="name"
          class="capitalize"
          >
            Nombre del permiso
          </label>
          <Field
          id="name"
          v-model="model.name"
          name="name"
          type="text"
          class="p-2 border border-gray-400 rounded-md block w-full"
          />
          <ErrorMessage name="name" class="text-red-500" />
        </div>
        <div class="mb-4 w-full ">
          <label
          for="description"
          class="capitalize"
          >
            Descripción
          </label>
          <Field
          id="description"
          v-model="model.description"
          name="description"
          type="text"
          class="p-2 border border-gray-400 rounded-md block w-full"
          />
          <ErrorMessage name="description" class="text-red-500" />
        </div>
        <div class="mb-4 w-full ">
          <label
          for="action"
          class="capitalize"
          >
            Acccion
          </label>
          <Field
          id="action"
          v-model="model.action"
          name="action"
          type="text"
          class="p-2 border border-gray-400 rounded-md block w-full"
          />
          <ErrorMessage name="action" class="text-red-500" />
        </div>
        <div>
          <button
          type="submit"
          class="btn btn-primary "
          >
            Guardar
          </button>
        </div>
      </Form>
    </div>
  </section>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import { defineComponent } from 'vue'
import { loadBusiness } from './hooks'

export default defineComponent({
  components: {
    Form,
    Field,
    ErrorMessage
  },
  setup (props, context) {
    return {
      ...loadBusiness(props, context)
    }
  }
})
</script>

<style>

</style>

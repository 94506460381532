<template>
  <Toast />
  <Toolbar class="box mt-4 p-p-2">
    <template #end>
      <div class="flex-col sm:flex-row ">
        <template v-for="item in items" :key="item.label">
          <router-link :to="{name: item.name}" >
            <Button :label="item.label" :icon="item.icon" :class="item.class" :style="item.style" class="mt-1" />
          </router-link>
        </template>
      </div>
    </template>
  </Toolbar>
  <router-view />
</template>
<script>
import { ref } from 'vue'
export default {
  name: 'menuReports',
  setup () {
    const items = ref([
      {
        label: 'General',
        icon: 'pi pi-fw pi-chart-bar',
        name: 'pharmasan.contabilidad.amortizaciones.reportes.general',
        class: 'p-button-info p-button-outlined mr-2'
      },
      {
        label: 'Pago Obligaciones',
        icon: 'pi pi-fw pi-chart-bar',
        name: 'pharmasan.contabilidad.amortizaciones.reportes.pagos-obligaciones',
        class: 'p-button-help p-button-outlined mr-2'
      },
      {
        label: 'Gastos Financieros',
        icon: 'pi pi-fw pi-chart-bar',
        name: 'pharmasan.contabilidad.amortizaciones.reportes.gastos-financieros',
        class: 'p-button-warning p-button-outlined mr-2'
      },
      {
        label: 'Estado Créditos',
        icon: 'pi pi-fw pi-chart-bar',
        name: 'pharmasan.contabilidad.amortizaciones.reportes.estados-creditos',
        class: 'p-button-secondary p-button-outlined mr-2'
      }
    ])
    return {
      items
    }
  }
}
</script>

<style scoped>

</style>

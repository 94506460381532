import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'

export default [
  {
    path: '/pharmasan/administrativa',
    component: Layouts,
    redirect: { name: 'pharmasan.administrativa.solicitud-permisos' },
    beforeEnter: VueRouterMultiguard([auth]),
    children: [
      {
        path: 'solicitud-permisos',
        name: 'pharmasan.administrativa.solicitud-permisos',
        redirect: { name: 'pharmasan.administrativa.solicitud-permisos.listado' },
        children: [
          {
            path: 'listado',
            name: 'pharmasan.administrativa.solicitud-permisos.listado',
            component: () => import('../views/list/index.vue'),
            meta: {
              title: 'Solicitud de permisos - Listado',
              activeMenu: 'pharmasan.administrativa.solicitud-permisos.listado',
              access: [
                'pharmasan.administrativa.solicitud-permisos.listado'
              ]
            }
          },
          {
            path: 'crear',
            name: 'pharmasan.administrativa.solicitud-permisos.crear',
            component: () => import('../views/form/index.vue'),
            meta: {
              title: 'Solicitud de permisos - Crear',
              activeMenu: 'pharmasan.administrativa.solicitud-permisos.listado',
              access: [
                'pharmasan.administrativa.solicitud-permisos.crear'
              ]
            }
          },
          {
            path: 'editar/:id',
            name: 'pharmasan.administrativa.solicitud-permisos.editar',
            component: () => import('../views/form/index.vue'),
            meta: {
              activeMenu: 'pharmasan.administrativa.solicitud-permisos.listado',
              access: [
                'pharmasan.administrativa.solicitud-permisos.editar'
              ]
            }
          },
          {
            path: 'configuracion',
            name: 'pharmasan.administrativa.solicitud-permisos.configuracion',
            component: () => import('../views/configuracion/index.vue'),
            redirect: { name: 'pharmasan.administrativa.solicitud-permisos.configuracion.home' },
            meta: {
              title: 'Solicitud de permisos - Configuración',
              activeMenu: 'pharmasan.administrativa.solicitud-permisos.configuracion',
              access: [
                'pharmasan.administrativa.solicitud-permisos.configuracion'
              ]
            },
            children: [
              {
                path: '',
                name: 'pharmasan.administrativa.solicitud-permisos.configuracion.home',
                component: () => import('../views/configuracion/home/index.vue'),
                meta: {
                  title: 'Solicitud de permisos - Configuración - Home',
                  activeMenu: 'pharmasan.administrativa.solicitud-permisos.configuracion',
                  access: [
                    'pharmasan.administrativa.solicitud-permisos.configuracion'
                  ]
                }
              },
              {
                path: 'motivos',
                name: 'pharmasan.administrativa.solicitud-permisos.configuracion.motivos',
                component: () => import('../views/configuracion/motivos/index.vue'),
                meta: {
                  title: 'Solicitud de permisos - Configuración - Motivos',
                  activeMenu: 'pharmasan.administrativa.solicitud-permisos.configuracion',
                  access: [
                    'pharmasan.administrativa.solicitud-permisos.configuracion.motivos'
                  ]
                }
              },
              {
                path: 'empleados',
                name: 'pharmasan.administrativa.solicitud-permisos.configuracion.empleados',
                component: () => import('../views/configuracion/empleados/index.vue'),
                meta: {
                  title: 'Solicitud de permisos - Configuración - Empleados',
                  activeMenu: 'pharmasan.administrativa.solicitud-permisos.configuracion',
                  access: [
                    'pharmasan.administrativa.solicitud-permisos.configuracion.empleados'
                  ]
                }
              }
            ]
          }
        ]
      }
    ]
  }
]

import securityAccess from '../../../../../middleware/securityAccess'

export default [
  {
    path: 'inventario',
    name: 'pharmasan.informes.inventario',
    component: () => import('../views/home/index.vue'),
    beforeEnter: [securityAccess],
    meta: {
      access: [
        'pharmasan.informes.inventario'
      ],
      activeMenu: 'pharmasan.informes'
    }
  },
  {
    path: 'inventario/stock-mx',
    name: 'pharmasan.informes.inventario.stock-mx',
    component: () => import('../views/stock/stockMx.vue'),
    activeMenu: 'pharmasan.informes.inventario.stock-mx',
    meta: {
      access: [
        'pharmasan.informes.inventario'
      ],
      activeMenu: 'pharmasan.informes'
    }
  },
  {
    path: 'inventario/stock-lotes',
    name: 'pharmasan.informes.inventario.stock-lotes',
    component: () => import('../views/stock/stockLotes.vue'),
    activeMenu: 'pharmasan.informes.inventario.stock-lotes',
    meta: {
      access: [
        'pharmasan.informes.inventario'
      ],
      activeMenu: 'pharmasan.informes'
    }
  }
]

import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
// import securityAccess from '@/middleware/securityAccess'
export default [
  {
    path: '/utpharmedicas',
    name: 'ut-pharmedicas.dashboard',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth]),
    redirect: { name: 'ut-pharmedicas.dashboard.operacion' },
    children: [
      {
        path: 'dashboard-operaciones',
        name: 'ut-pharmedicas.dashboard.operacion',
        component: () => import('@/apps/utpharmedicas/dashboard/views/operaciones/pages/index.vue'),
        meta: {
          access: ['ut-pharmedicas.dashboard.operacion']
        }
      }
    ]
  }
]

<template>
  <Toast />
  <DataTable :value="accounts" responsiveLayout="stack" breakpoint="960px">
    <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
      <template #body="{ data }">
        <Button
          type="button"
          class="p-button-danger p-button-sm"
          icon="pi pi-trash"
          v-tooltip.top="'Eliminar fila'"
          @click="removeRowAccountSap(data)"
        ></Button>
      </template>
    </Column>
    <Column header="Nombre cuenta contable SAP">
      <template #body="{ data }">
        <div class="p-inputgroup">
          <AutocompleteAccountsSap
            ref="autocompleteAccount"
            :disabled="data._key === 0 || (isDisabled && !data.isNew)"
            @changeValues="onChangeValues($event, data._key)"
            v-model="data.acctcode"
            :classes="{ 'p-invalid': errors[`accountsCodeSap[${data._key}].acctcode`] }"
          />
        </div>
        <MessageError :text="errors[`accountsCodeSap[${data._key}].acctcode`]" />
      </template>
    </Column>
    <Column header="Código Cuenta SAP">
      <template #body="{ data }">
        <span>{{ data.acctcode }}</span>
      </template>
    </Column>
    <Column header="Nombre Obligación">
      <template #body="{ data }">
        <span>{{ data.nameaccount }}</span>
      </template>
    </Column>
  </DataTable>

  <div class="p-4">
    <Button
      type="button"
      class="p-button-info p-button-sm"
      icon="pi pi-plus"
      v-tooltip.top="'Agregar fila'"
      @click="addRowAccountSap"
    ></Button>
  </div>
</template>

<script>

import { onMounted, ref, watch } from 'vue'
import AutocompleteAccountsSap from '../../../../../../../components/select/accountsSap'
import deleteAccountSap from '../../../services/deleteAccountSap'
import { error, messageWarningDelete } from '../../../../../../../libs/mensajes'
import { useToast } from 'primevue/usetoast'

export default {
  name: 'tableAccountSap',
  props: {
    listAccountsSap: {
      type: Array,
      default: () => [{ acctcode: null, acctname: null, nameaccount: null, _key: 0 }]
    },
    errors: {
      type: Object
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AutocompleteAccountsSap
  },
  setup (props, context) {
    const toast = useToast()
    const accounts = ref([])
    const accountsBankSap = ref({})
    const filteredAccountsSap = ref([])
    const autocompleteAccount = ref()

    /* Funcion para agregar una nueva fila */
    const addRowAccountSap = (event) => {
      console.log('Event: ', event)
      accounts.value.push({ acctcode: null, acctname: null, nameaccount: null, _key: accounts.value.length, isNew: true })
    }

    /* Funcion para remover la fila */
    const removeRowAccountSap = (data) => {
      const idx = accounts.value.indexOf(data)

      if (idx > 0) {
        if (!props.isEdit || data.isNew) {
          accounts.value.splice(idx, 1)
        } else {
          messageWarningDelete('', 'Eliminar', 'warning', `¿Esta seguro de eliminar la cuenta asociada  <strong>${data.acctcode} - ${data.acctname}</strong>?`).then(({ isConfirmed }) => {
            if (isConfirmed) {
              deleteAccountSap(data.id, data).then(() => {
                toast.add({ severity: 'success', summary: 'Exito', detail: 'Se eliminó la cuenta asociada correctamente', life: 3000 })
                accounts.value.splice(idx, 1)
              }).catch((err) => {
                error(err.response.data.message)
              })
            }
          })
        }
      }
    }

    /* Funcion que setea los valores en el componente hijo del autocomplete */
    const setAccountCode = (objectAccount) => {
      accounts.value = objectAccount
      accounts.value[0].acctcode = objectAccount[0].acctcode
      accounts.value[0].acctname = objectAccount[0].acctname
      autocompleteAccount.value.setCode(accounts.value)
    }

    const onChangeValues = (value, _key) => {
      accounts.value[_key] = { ...value, nameaccount: value.acctname, _key }
    }

    /* Escuchas los cambios de las props */
    watch(() => props.listAccountsSap, (newValue, oldValue) => {
      accounts.value = newValue
    })

    onMounted(() => {
      accounts.value = props.listAccountsSap
    })

    return {
      accountsBankSap,
      accounts,
      addRowAccountSap,
      removeRowAccountSap,
      filteredAccountsSap,
      AutocompleteAccountsSap,
      setAccountCode,
      autocompleteAccount,
      onChangeValues
    }
  }
}
</script>

<style scoped>

</style>

import helper from './helper'
import lodash from './lodash'
import axios from './axios'
import primeVue from './primeVue'
import vueOverlay from './vueOverlay'
import fontAwesome from './font-awesome'
import vueScrollTo from './scrollTo'
import permission from './permission'
import funciones from './funciones'
import vueTippy from './vue-tippy'

export default app => {
  app.use(helper)
  app.use(lodash)
  app.use(axios)
  app.use(primeVue)
  app.use(vueOverlay)
  app.use(fontAwesome)
  app.use(vueScrollTo)
  app.use(permission)
  app.use(funciones)
  app.use(vueTippy)
}

// import Layouts from '@/layouts/main-menu/Main.vue'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '../../../../../middleware/securityAccess'
import catalogo from '../catalogo/router/index.js'
export default
   {
      path: 'proveedores',
      name: 'pharmasan.compras.proveedores',
      meta: {
        access: ['pharmasan.compras.proveedores']
      },
      beforeEnter: VueRouterMultiguard([auth, securityAccess]),
      activeMenu: 'pharmasan.compras.proveedores',
      redirect: { name: 'pharmasan.compras.proveedores.listado' },
      children: [
        {
          path: 'listado',
          name: 'pharmasan.compras.proveedores.listado',
          activeMenu: 'pharmasan.compras.proveedores',
          beforeEnter: VueRouterMultiguard([auth, securityAccess]),
          meta: {
            access: ['pharmasan.compras.proveedores']
          },
          component: () => import('../views/index.vue').then(m => m || m)
        },
        ...catalogo
      ]
   }

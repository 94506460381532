<template>
  <h2>Otros Ajustes</h2>
</template>

<script>
export default {
  name: 'others'
}
</script>

<style scoped>

</style>

<template>
  <div class="card m-2">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6">
        <label for="office">Sede</label>
        <Dropdown id="office" inputId="office" v-model="v$.selectedOffice.$model" :options="offices" optionLabel="name" optionValue="code" :filter="true" placeholder="Seleccione una sede" :class="{'p-invalid':v$.selectedOffice.$invalid && submitted}" />
        <small v-if="(v$.selectedOffice.$invalid && submitted) || v$.selectedOffice.$pending.$response" class="p-error">{{v$.selectedOffice.required.$message.replace('Value', 'Sede')}}</small>
      </div>
      <div class="p-field p-col-12 p-md-6">
        <label for="responsibleUser">Usuario responsable</label>
        <Dropdown id="responsibleUser" inputId="responsibleUser" v-model="v$.responsibleUser.$model" :options="usersList" optionLabel="name" optionValue="code" :filter="true" placeholder="Seleccione una sede" :class="{'p-invalid':v$.responsibleUser.$invalid && submitted}" />
        <small v-if="(v$.responsibleUser.$invalid && submitted) || v$.responsibleUser.$pending.$response" class="p-error">{{v$.responsibleUser.required.$message.replace('Value', 'Usuario responsable')}}</small>
      </div>
      <div class="p-field p-col-12 p-md-6 p-lg-6">
        <label for="name">Nombre</label>
        <InputText id="name" v-model="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && submitted}" placeholder="Nombre de caja menor" />
        <small v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response" class="p-error">{{v$.name.required.$message.replace('Value', 'Name')}}</small>
      </div>
      <div class="p-field p-col-12 p-md-6 p-lg-3">
        <label for="permanentBackground">Fondo permanente </label>
        <InputNumber
          id ="permanentBackground"
          v-model="v$.permanentBackground.$model"
          :class="{'p-invalid':v$.permanentBackground.$invalid && submitted}"
          mode="currency"
          currency="USD"
          locale="en-US"
          :minFractionDigits="0"
          :min="0"
          showButtons
          buttonLayout="horizontal"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          decrementButtonClass="btn-primary"
          incrementButtonClass="btn-primary"
        />
        <small v-if="(v$.permanentBackground.$invalid && submitted) || v$.$pending.$response" class="p-error">Fondo permanente es obligatorio</small>
      </div>
      <div class="p-field p-col-12 p-md-6 p-lg-3">
        <label for="consumptionAtClosing">Consumo al cierre</label>
        <InputNumber
          id ="consumptionAtClosing"
          v-model="v$.consumptionAtClosing.$model"
          :class="{'p-invalid':v$.consumptionAtClosing.$invalid && submitted}"
          suffix=" %"
          :min="0"
          :max="100"
          :minFractionDigits="0"
        />
        <small v-if="(v$.consumptionAtClosing.$invalid && submitted) || v$.$pending.$response" class="p-error">{{v$.consumptionAtClosing.required.$message.replace('Value', 'cierre al consumo')}}</small>
      </div>
      <div class="p-field p-col-12">
        <label for="commentary">Comentario</label>
        <Textarea id="commentary" v-model="state.commentary" rows="4" />
      </div>
    </div>
    <!--  section buttons     -->
    <div class="p-d-flex p-jc-end">
      <div>
        <ConfirmPopup></ConfirmPopup>
        <div class="card">
          <Button @click="confirmSave($event,!v$.$invalid)" icon="pi pi-check-circle" label="Confirmar" class="p-mr-2 btn-primary"></Button>
        </div>
      </div>
      <div>
        <ConfirmPopup></ConfirmPopup>
        <div class="card">
          <Button @click="confirmCancel($event)" icon="pi pi-times-circle" label="Cancelar" class="p-mr-2 btn-danger"></Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from 'vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useConfirm } from 'primevue/useconfirm'
export default {
  setup () {
    onMounted(() => {})
    /** data **/
    const state = reactive({
      name: null,
      selectedOffice: null,
      responsibleUser: null,
      permanentBackground: 0,
      consumptionAtClosing: 30,
      commentary: 'Creación de caja menor'
    })
    const submitted = ref(false)
    const offices = ref([
      { name: 'Abrego', code: 1 },
      { name: 'Aguachica', code: 2 },
      { name: 'Archivo', code: 3 },
      { name: 'Astrea', code: 4 },
      { name: 'Barbosa', code: 5 },
      { name: 'Barrancabermeja', code: 6 },
      { name: 'Bogotá DC Polo', code: 7 },
      { name: 'Bogotá DC Restrepo', code: 8 },
      { name: 'Bosconia', code: 9 },
      { name: 'Bucaramanga Sede 1', code: 10 }
    ])
    const usersList = ref([
      { name: 'Maria Antonieta Rojas', code: 1 },
      { name: 'Andres Cardenas', value: 2 },
      { name: 'Sergio Manuel Otero', code: 3 }
    ])
    /** rules **/
    const rules = {
      name: { required },
      selectedOffice: { required },
      responsibleUser: { required },
      permanentBackground: { required },
      consumptionAtClosing: { required }
    }
    /** methods **/
    const resetForm = () => {
      state.name = null
      state.selectedOffice = null
      state.responsibleUser = null
      state.permanentBackground = 0
      state.consumptionAtClosing = 30
      state.commentary = 'Creación de caja menor'
      submitted.value = false
    }
    const confirm = useConfirm()
    const confirmSave = (event, invalid) => {
      confirm.require({
        target: event.currentTarget,
        message: '¿Estas seguro que deseas continuar?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          handleSubmit(invalid)
        },
        reject: () => {
          console.log('hola')
        }
      })
    }
    const confirmCancel = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: '¿Estas seguro que deseas continuar?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          console.log('hola')
        },
        reject: () => {
          console.log('hola')
        }
      })
    }
    /** validation **/
    const v$ = useVuelidate(rules, state)
    const handleSubmit = (isFormValid) => {
      submitted.value = true
      if (isFormValid) {
        alert('guardar en base de datos')
        console.log(state)
      }
    }
    /** returns **/
    return {
      state,
      v$,
      submitted,
      offices,
      usersList,
      /** functions **/
      handleSubmit,
      resetForm,
      confirmSave,
      confirmCancel
    }
  }
}
</script>
<style lang="scss" scoped>
</style>

import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '../../../middleware/securityAccess'
export default [
  {
    path: '/dailytech/estados-financieros',
    component: Layouts,
    redirect: { name: 'daily-tech.estados-financieros.estados' },
    name: 'daily-tech.estados-financieros',
    beforeEnter: VueRouterMultiguard([auth, securityAccess]),
    meta: {
      access: ['daily-tech.estados-financieros']
    },
    children: [
      {
        path: '',
        name: 'daily-tech.estados-financieros.estados',
        component: () => import('./estados').then(m => m || m),
        meta: {
          activeMenu: 'daily-tech.estados-financieros'
        }
      }
    ]
  }
]

import Layouts from '@/layouts/main-menu/Main'
import { auth } from '@/middleware'
import VueRouterMultiguard from 'vue-router-multiguard'
import securityAccess from '../../../middleware/securityAccess'
export default [
  {
    path: '/pharmasan/contabilidad',
    component: Layouts,
    beforeEnter: VueRouterMultiguard([auth, securityAccess]),
    meta: {
      access: ['pharmasan.contabilidad.estados-financieros']
    },
    children: [
      {
        path: 'estados-financieros',
        name: 'pharmasan.contabilidad.estados-financieros',
        component: () => import('./estados').then(m => m || m),
        meta: {
          activeMenu: 'pharmasan.contabilidad.estados-financieros'
        }
      }
    ]
  }
]

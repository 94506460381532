<template>
  <!-- BEGIN: Mobile Menu -->
  <div class="mobile-menu md:hidden" id="mobile-menu">
    <div class="mobile-menu-bar">
      <a href class="flex mr-auto">
        <img
          alt="Midone Tailwind HTML Admin Template"
          class="w-6"
          src="@/assets/images/logo.svg"
        />
      </a>
      <BarChart2Icon
        class="w-8 h-8 text-white transform -rotate-90"
        @click="toggleMobileMenu"
      />
    </div>
    <transition @enter="enter" @leave="leave">
      <!-- <ul v-if="activeMobileMenu" class="border-t border-theme-29 py-5"> -->
        <!-- BEGIN: First Child -->
          <menuDisplay v-if="activeMobileMenu"
          class="border-t border-theme-29 p-5"
          @redirect="toggleMobileMenu"/>
        <!-- END: First Child -->
      <!-- </ul> -->
    </transition>
  </div>
  <!-- END: Mobile Menu -->
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { helper as $h } from '@/utils/helper'
import {
  activeMobileMenu,
  toggleMobileMenu,
  linkTo,
  enter,
  leave
} from './index'
import { nestedMenu } from '@/layouts/main-menu'
import menuDisplay from '../menu-display'

export default defineComponent({
  components: {
    menuDisplay
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const formattedMenu = ref([])
    const mobileMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    )

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(mobileMenu.value)
      }
    )

    onMounted(() => {
      formattedMenu.value = $h.toRaw(mobileMenu.value)
    })

    return {
      activeMobileMenu,
      toggleMobileMenu,
      formattedMenu,
      router,
      linkTo,
      enter,
      leave
    }
  }
})
</script>

<style lang="scss">

@media print {
  #mobile-menu{
    display: none;
  }
}
#mobile-menu{
  position: fixed;
  z-index: 99;
  width: 100%;
  background-color: #1C3FAA;

  #conten_menus {
      display: block;
      position: fixed;
      z-index: 99;
      background-color: #1C3FAA;
      width: 100%;
      color: #ffffff87;
      overflow: scroll;
      height: 90%;
  }
  .side-menu__title {
      display: flex;
      margin-left: 0.75rem;
      width: 100%;
      justify-content: space-between;
  }
  .side-menu {
    display: flex;
    padding: 0.75rem;

  }
  .side-menu--active {
    color: #fff;
  }
}

</style>

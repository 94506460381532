<template>
  <div>
    <section>
      <div class="box p-2 mt-2">
        <div
          class="boxed-tabs nav nav-tabs text-xs justify-center w-full bg-gray-200 text-gray-600 dark:bg-dark-1 dark:text-gray-500 rounded-md mx-auto flex-col sm:flex-row"
          role="tablist"
        >
          <router-link
            id="pagos-tab"
            :to="{ name: 'capital-state.presupuesto-obra.centro-salud.dashboard' }"
            :class="getActive([
              'capital-state.presupuesto-obra.centro-salud.dashboard',
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <TabletIcon class="w-4 h-4 mr-2" />
            Dashboard
          </router-link>

<!--          <router-link
            id="pagos-tab"
            :to="{ name: 'construction-budget.supports' }"
            :class="getActive([
              'construction-budget.supports',
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <FileTextIcon class="w-4 h-4 mr-2" />
            Soportes
          </router-link>

          <router-link
            id="pagos-tab"
            :to="{ name: 'construction-budget.centro-salud.reports' }"
            :class="getActive([
              'construction-budget.centro-salud.reports',
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <BarChartIcon class="w-4 h-4 mr-2" />
            Reportes
          </router-link>-->

        </div>
      </div>
    </section>
    <router-view />
  </div>
</template>

<script>
import { useRoute } from 'vue-router'

export default {
  name: 'constructionBudgetHome',
  setup () {
    const route = useRoute()

    const getActive = (routeNames = []) => {
      if (routeNames.includes(route.name)) {
        return 'active'
      }
      return ''
    }

    return {
      getActive
    }
  }
}
</script>

<style scoped>

</style>


<template>
  <div>
    <DataTable :value="customers"
               :paginator="true"
               class="p-datatable-sm"
               :rows="10"
               dataKey="id"
               :rowHover="true"
               v-model:selection="selectedCustomers"
               v-model:filters="filters"
               filterDisplay="menu"
               :loading="loading"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :rowsPerPageOptions="[10,25,50]"
               currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} paginas"
               :globalFilterFields="['name','selectedOffice','responsibleUser']"
               responsiveLayout="stack"
               breakpoint="960px"
               showGridlines
               stripedRows
               removableSort
               columnResizeMode="fit"
    >
      <template #header>
        <div class="p-d-flex p-jc-between p-ai-center">
          <h5 class="p-m-0">Customers</h5>
          <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="Búsqueda por" />
          </span>
        </div>
      </template>
      <template #empty>
        No se encontraron datos
      </template>
      <template #loading>
        Cargando datos. Espere por favor.
      </template>
      <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
      <Column field="name" header="Caja" sortable style="min-width: 14rem">
        <template #body="{data}">
          {{data.name}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder=""/>
        </template>
      </Column>
      <Column field="selectedOffice" header="Sede" sortable style="min-width: 14rem">
        <template #body="{data}">
          {{data.selectedOffice}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
        </template>
      </Column>
      <Column field="responsibleUser" header="Resonsable" sortable style="min-width: 14rem">
        <template #body="{data}">
          {{data.responsibleUser}}
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder=""/>
        </template>
      </Column>
      <Column field="permanentBackground" header="Fondo de permanencia" sortable dataType="numeric" style="min-width: 8rem">
        <template #body="{data}">
          {{formatCurrency(data.permanentBackground)}}
        </template>
      </Column>
      <Column field="createdAt" header="Fecha" sortable dataType="date" style="min-width: 8rem">
        <template #body="{data}">
          {{data.createdAt}}
        </template>
      </Column>
      <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
        <template #body>
          <Button type="button" icon="pi pi-cog"></Button>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
// import CustomerService from './service/CustomerService'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  name: 'MinorBoxList',
  setup () {
    /** hooks **/
    onMounted(() => {
      customers.value = customerService.value
      loading.value = false
    })
    /** data **/
    const customers = ref()
    const selectedCustomers = ref()
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      },
      selectedOffice: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      },
      responsibleUser: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      }
    })
    const loading = ref(true)
    const customerService = ref(
      [
        {
          id: 1,
          commentary: 'enim in',
          consumptionAtClosing: 62,
          selectedOffice: 'Chinacota - Droguería Luz Farma Express',
          permanentBackground: 659974,
          name: 'Cebus albifrons',
          responsibleUser: 'Mame Stiller',
          createdAt: '2020-12-14'
        }, {
        id: 2,
        commentary: 'et tempus',
        consumptionAtClosing: 43,
        selectedOffice: 'El Copey',
        permanentBackground: 327564,
        name: 'Zenaida asiatica',
        responsibleUser: 'Wright Lease',
        createdAt: '2021-01-03'
      }, {
        id: 3,
        commentary: 'mi',
        consumptionAtClosing: 71,
        selectedOffice: 'Coomultrasan Giron',
        permanentBackground: 449680,
        name: 'Tyto novaehollandiae',
        responsibleUser: 'Ugo Thornthwaite',
        createdAt: '2020-08-10'
      }
      ]
    )
    /** methods **/
    const formatCurrency = (value) => {
      return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })
    }
    /** returns **/
    return {
      customerService,
      customers,
      filters,
      loading,
      selectedCustomers,
      /** functions **/
      formatCurrency
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  vertical-align: middle;
}
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
</style>
